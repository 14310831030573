import React from "react";
import { useSelector } from "react-redux";
import EqualizerRoundedIcon from "@mui/icons-material/EqualizerRounded";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import AddTaskIcon from "@mui/icons-material/AddTask";
import NewTicketCard from "./NewTicketCard";
import SimpleCardText from "./SimpleCardText";
import AlertsCard from "./AlertsCard";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PizzaCountCard from "../Dashboard/DashboardCardComponent/PizzaCountingCards";
const AlertTopCards = () => {
  const state = useSelector((state) => state);
  var profile = JSON.parse(localStorage.getItem("profile"));
  const iconFont = {
    fontSize: "50px",
    color: "#4318FF",
  };
  const newTicketiconFont = {
    fontSize: "36px",
    color: "#fff",
  };
  const totalAlertCount = (data) => {
    if (data.length === 0) {
      return 0;
    }
    return data.reduce((accumulator, currentValue) => {
      const count =
        currentValue.alert !== undefined
          ? currentValue.alert
          : currentValue.event;
      return accumulator + count;
    }, 0);
  };

  const returnAlertTypeByName = (name) => {
    const matchingItem = state.AlertData.totalAlertTypeData.find(
      (item) => item.alert_type === name
    );
    return matchingItem ? matchingItem.alert_count : 0;
  };

  const returnTopAlert = () => {
    const determinePropertyNames = (data) => {
      const hasAlertCount = "alert_count" in data[0];
      const hasAlertType = "alert_type" in data[0];

      return {
        countProperty: hasAlertCount ? "alert_count" : "event_count",
        typeProperty: hasAlertType ? "alert_type" : "event_type",
      };
    };

    if (state.AlertDashboardStates.selectedMode.value === "day-wise") {
      if (state.AlertData.totalAlertTypeData.length > 0) {
        const { countProperty, typeProperty } = determinePropertyNames(
          state.AlertData.totalAlertTypeData
        );

        return state.AlertData.totalAlertTypeData
          .reduce((acc, curr) =>
            curr[countProperty] > acc[countProperty] ? curr : acc
          )
          [typeProperty].replace(/_/g, " ")
          .replace(/\b\w/g, (c) => c.toUpperCase());
      } else {
        return null;
      }
    } else {
      if (state.AlertData.totalAlertTypeDataDateRange.length > 0) {
        const { countProperty, typeProperty } = determinePropertyNames(
          state.AlertData.totalAlertTypeDataDateRange
        );

        return state.AlertData.totalAlertTypeDataDateRange
          .reduce((acc, curr) =>
            curr[countProperty] > acc[countProperty] ? curr : acc
          )
          [typeProperty].replace(/_/g, " ")
          .replace(/\b\w/g, (c) => c.toUpperCase());
      } else {
        return null;
      }
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-2">
          <AlertsCard
            icon={<EqualizerRoundedIcon sx={iconFont} />}
            headText={
              state.AlertDashboardStates.selectedMode.value === "day-wise"
                ? totalAlertCount(state.AlertData.totalAlertHourly)
                : totalAlertCount(state.AlertData.totalAlertDaywise)
            }
            paraText="Total Alerts"
          />
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-2">
          <AlertsCard
            icon={<EqualizerRoundedIcon sx={iconFont} />}
            headText={
              state.AlertDashboardStates.selectedMode.value === "day-wise"
                ? state.AlertData.totalAlertTypeData.length
                : state.AlertData.totalAlertTypeDataDateRange.length
            }
            paraText="Types Of Alerts"
          />
        </div>

        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-2">
          <SimpleCardText
            icon={<EqualizerRoundedIcon sx={iconFont} />}
            headText={returnTopAlert()}
            paraText="Top Alert Type"
          />
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-2">
          <NewTicketCard
            icon={<AddTaskIcon sx={newTicketiconFont} />}
            headText={
              state.AlertDashboardStates.selectedMode.value === "day-wise"
                ? totalAlertCount(state.AlertData.resolvedAlertHourly)
                : totalAlertCount(state.AlertData.resolvedAlertDaywise)
            }
            paraText="Total Resolved"
          />
        </div>
        {profile.services_selected.Event.includes("opening_closing") &&
          state.AlertDashboardStates.selectedFacility.value != "all" && (
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 mt-2">
              <AlertsCard
                icon={<AccessTimeIcon sx={iconFont} />}
                headText={state.EventTableStates.facilityOpeningTime}
                paraText="Opening Time"
              />
            </div>
          )}
        {profile.services_selected.Event.includes("opening_closing") &&
          state.AlertDashboardStates.selectedFacility.value != "all" && (
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 mt-2">
              <AlertsCard
                icon={<AccessTimeIcon sx={iconFont} />}
                headText={state.EventTableStates.facilityClosingTime}
                paraText="Closing Time"
              />
            </div>
          )}
        {profile.services_selected.Alert.includes("temple_alert_type") && (
          <>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-2">
              <AlertsCard
                icon={<CleaningServicesIcon sx={iconFont} />}
                headText={returnAlertTypeByName("floor_sweeping_adherence")}
                paraText="Floor sweeping adherence"
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-2">
              <AlertsCard
                icon={<CleaningServicesIcon sx={iconFont} />}
                headText={returnAlertTypeByName("cobweb_dusting_adherence")}
                paraText="Cobweb dusting adherence"
              />
            </div>

            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-2">
              <AlertsCard
                icon={<CleaningServicesIcon sx={iconFont} />}
                headText={returnAlertTypeByName(
                  "brass_lamp_cleaning_adherence"
                )}
                paraText="Brass lamp cleaning adherence"
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-2">
              <AlertsCard
                icon={<CleaningServicesIcon sx={iconFont} />}
                headText={returnAlertTypeByName("floor_washing_adherence")}
                paraText="Floor washing adherence"
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-2">
              <AlertsCard
                icon={<CleaningServicesIcon sx={iconFont} />}
                headText={returnAlertTypeByName("dustbin_clearing_adherence")}
                paraText="Dustbin clearing adherence"
              />
            </div>
          </>
        )}
        <PizzaCountCard />
      </div>
    </div>
  );
};

export default AlertTopCards;
