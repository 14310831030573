import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { useSelector, useDispatch } from "react-redux";
import AlertTableActions from "../../../redux/Action/AlertTableActions";
import AlertDashAction from "../../../redux/Action/AlertDashAction";
import { useEffect, useState } from "react";

const inputSelect = {
  boxShadow: "none",
  borderRadius: "8px",
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
  MenuProps: {
    PaperProps: {
      style: {
        backgroundColor: "var(--top-bar-bg-color)",
        maxHeight: 350,
        width: 250, // Adjusted width to fit the checkbox comfortably
        overflowX: "auto",
      },
    },
  },
};

const AlertFilterTable = (propTypes) => {
  const AlertTableStates = useSelector((state) => state.AlertTableStates);
  const AlertDashStates = useSelector((state) => state.AlertDashboardStates);
  const [selectedAlertType, setSelectedAlertType] = useState([]);

  const dispatch = useDispatch();

  function handleAlertOrEvent(option) {
    dispatch(
      AlertTableActions.updateAlertOrEvent(option.alert_or_event.toLowerCase())
    );
  }

  // useEffect to update selectedAlertType when AlertTypes changes
  useEffect(() => {
    const allAlertTypes = AlertTableStates.AlertTypes.map(
      (option) => option.notification_type
    );
    setSelectedAlertType(allAlertTypes);
    if (propTypes.type === "AlertDashboard") {
      dispatch(
        AlertDashAction.updateAlertType({
          value: allAlertTypes.length == 0 ? "all" : allAlertTypes,
        })
      );
    } else {
      dispatch(
        AlertTableActions.updateAlertType({
          value: allAlertTypes.length == 0 ? "all" : allAlertTypes,
        })
      );
    }
  }, [AlertTableStates.AlertTypes]);

  const handleAlertTypeChange = (event) => {
    const { value } = event.target;

    let updatedSelection;

    if (value.includes("all")) {
      if (selectedAlertType.length === AlertTableStates.AlertTypes.length) {
        updatedSelection = [];
      } else {
        updatedSelection = AlertTableStates.AlertTypes.map(
          (option) => option.notification_type
        );
      }
    } else {
      updatedSelection = value;
    }

    setSelectedAlertType(updatedSelection);
  };
  function saveChangeToRedux() {
    if (propTypes.type === "AlertDashboard") {
      dispatch(
        AlertDashAction.updateAlertType({
          value: selectedAlertType.length == 0 ? "all" : selectedAlertType,
        })
      );
    } else {
      dispatch(
        AlertTableActions.updateAlertType({
          value: selectedAlertType.length == 0 ? "all" : selectedAlertType,
        })
      );
    }
  }
  return (
    <div>
      <div className="select-input-lebel">Alert Type</div>
      <Select
        value={selectedAlertType}
        sx={{
          color: "var(--card-text-color)",
          ...inputSelect,
        }}
        multiple={true}
        className="select-input-field"
        MenuProps={inputSelect.MenuProps}
        onChange={handleAlertTypeChange}
        onClose={saveChangeToRedux}
        renderValue={(selected) =>
          selected.length === AlertTableStates.AlertTypes.length
            ? "All"
            : selected.join(", ")
        }
      >
        <MenuItem value="all" sx={{ color: "var(--card-text-color)" }}>
          <Checkbox
            checked={
              selectedAlertType.length > 0 &&
              selectedAlertType.length === AlertTableStates.AlertTypes.length
            }
            sx={{ color: "var(--checkbox-color)" }}
          />
          <ListItemText
            primary={
              selectedAlertType.length > 0 &&
              selectedAlertType.length === AlertTableStates.AlertTypes.length
                ? "Clear"
                : "All"
            }
            onClick={() => handleAlertOrEvent("all")}
          />
        </MenuItem>
        {AlertTableStates.AlertTypes.map((option) => (
          <MenuItem
            key={option.notification_type}
            value={option.notification_type}
            onClick={() => handleAlertOrEvent(option)}
            sx={{
              paddingTop: "2px",
              paddingBottom: "2px",
              color: "var(--card-text-color)",
            }}
          >
            <Checkbox
              checked={selectedAlertType.indexOf(option.notification_type) > -1}
              sx={{ color: "var(--checkbox-color)" }}
            />
            <ListItemText primary={option.name} />
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default AlertFilterTable;
