import { BACKEND_URL } from "../../routes/config";
/**
 * Description: Class containing methods for Face Recognition API endpoints.
 * - uploadKnownFaceDetails: Returns the URL for uploading known face details.
 * - getFaceRecognitionDataTable: Returns the URL for fetching face recognition data table.
 * - getDataForTop5matchedRecognized: Returns the URL for fetching data of top 5 matched recognized faces with filter.
 * - getDataForTop5matchedUnRecognized: Returns the URL for fetching data of top 5 matched unrecognized faces with filter.
 * - knownFacesDataByUserProfileId: Returns the URL for fetching known faces data by user profile ID with pagination.
 * - knownFacesDataById: Returns the URL for fetching known faces data by ID.
 */
class FaceRec {
  constructor() {
    this.uploadKnownFaceDetails = () => {
      return `https://dl.agrexai.com/fr/upload/`;
    };

    this.getFaceRecognitionDataTable = () => {
      return `${BACKEND_URL}/facerec/faces/`;
    };
    this.getDataForTop5matchedRecognized = (uuid, facility_id) => {
      return `${BACKEND_URL}/facerec/faces/similar-with-filter/${uuid}/?facility_id=${facility_id}`;
    };
    this.getDataForTop5matchedUnRecognized = (uuid, facility_id) => {
      return `${BACKEND_URL}/facerec/faces/detected-with-filter/${uuid}/?facility_id=${facility_id}`;
    };
    this.knownFacesDataByUserProfileId = (
      userProfileId,
      pageNumber,
      page_size = 20
    ) => {
      return `${BACKEND_URL}/facility/known-faces/?user_profile_id=${userProfileId}&page=${pageNumber}&page_size=${page_size}`;
    };
    this.knownFacesDataById = (id) => {
      return `${BACKEND_URL}/facility/known-faces/${id}/`;
    };
  }
}

export default new FaceRec();
