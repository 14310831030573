import React, { useRef } from "react";
import { TextField, InputAdornment, Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import PropTypes from "prop-types";
const ChatInput = ({ loading, handleInputChange }) => {
  const inputRef = useRef("");
  function handleInputSubmit(e) {
    e.preventDefault();
    if (loading) return;
    if (!inputRef.current) {
      console.error("Input reference is null.");
      return;
    }
    const inputValue = inputRef.current.value;

    if (!inputValue.trim()) {
      alert("Input cannot be empty.");
      return;
    }
    handleInputChange({ role: "user", content: inputValue });
    inputRef.current.value = "";
  }
  return (
    <div>
      <form
        onSubmit={handleInputSubmit}
        method="POST"
        className="d-flex align-items-center"
      >
        <TextField
          fullWidth
          sx={{
            borderRadius: "30px",
            borderColor: "rgba(67, 24, 255, 0.3)",
          }}
          type="text"
          size="medium"
          placeholder="Send a message..."
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  color="primary"
                  variant="contained"
                  sx={{
                    minWidth: "40px",
                    height: "40px",
                    padding: 0,
                    "& .MuiButton-startIcon": {
                      margin: 0,
                    },
                  }}
                  size="small"
                  onClick={handleInputSubmit}
                  disabled={loading}
                >
                  <SendIcon sx={{ fontSize: "25px" }} />
                </Button>
              </InputAdornment>
            ),
            style: {
              borderRadius: "10px",
              border: "1px solid rgba(67, 24, 255, 0.3)",
              color: "var(--chatbot-welcome-text-color)",
            },
          }}
          inputRef={inputRef}
        />
      </form>
    </div>
  );
};
ChatInput.propTypes = {
  loading: PropTypes.bool.isRequired,
  handleInputChange: PropTypes.func.isRequired,
};
export default ChatInput;
