export const resolutions = [
  { label: "8K", value: "7680x4320" },
  { label: "5K", value: "5120x2880" },
  { label: "DCI 4K", value: "4096x2160" },
  { label: "4K UHD", value: "3840x2160" },
  { label: "2K", value: "2048x1080" },
  { label: "Full HD", value: "1920x1080" },
  { label: "HD", value: "1280x720" },
  { label: "WVGA", value: "854x480" },
  { label: "VGA", value: "640x480" },
  { label: "SVGA", value: "800x600" },
  { label: "XGA", value: "1024x768" },
  { label: "WXGA", value: "1280x800" },
  { label: "SXGA", value: "1280x1024" },
  { label: "WQHD", value: "2560x1440" },
  { label: "QSXGA", value: "2560x2048" },
  { label: "HD+", value: "1600x900" },
  { label: "QSXGA+", value: "3200x2048" },
  { label: "UHD+", value: "3200x2400" },
  { label: "WQUXGA", value: "3840x2400" },
];
export const days = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

export const default_empty_schedule = {
  sunday: [],
  monday: [],
  tuesday: [],
  wednesday: [],
  thursday: [],
  friday: [],
  saturday: [],
};
