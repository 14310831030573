import React, { useState } from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import unknownPerson from "../../assets/images/unknownPerson.png";
import { extractDateTime } from "./utils/FormatDateAndTime";
import { useNavigate } from "react-router-dom";
// import FaceDetecteCardModal from "./FaceDetecteCardModal";

const FaceRecognitionTable = ({ rowData }) => {
  const navigate = useNavigate();

  const divStyle = {
    padding: "10px",
  };

  const handleRowClick = (item) => {
    navigate(`/face-details/${item.id}/${item.is_recognized}`);
  };
  return (
    <div>
      <TableContainer
        component={Paper}
        className="mt-4"
        sx={{ overflowX: "scroll" }}
      >
        <Table aria-label="collapsible table">
          <TableRow>
            <TableCell>Faces Detected</TableCell>
            <TableCell>Camera</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Time</TableCell>
          </TableRow>
          <TableBody style={{ backgroundColor: "var(--side-bar-bg-color)" }}>
            {rowData.map((row, index) => (
              <React.Fragment key={index}>
                <TableRow
                  key={index}
                  onClick={() => handleRowClick(row)}
                  style={divStyle}
                  className="face_recog_table_row"
                >
                  <TableCell>
                    <div className="FacialRecognitionTable">
                      <img
                        src={row.image}
                        alt={`face-${index}`}
                        style={{ width: "70px", height: "70px" }} // Set both height and width
                      />
                      <img
                        src={
                          row.is_recognized
                            ? row.known_face_id.image
                            : unknownPerson
                        }
                        alt={`face-${index}`}
                        style={{ width: "70px", height: "70px" }} // Set both height and width
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    {row.camera_id ? row.camera_id.camera_name : "N/A"}
                  </TableCell>
                  <TableCell>{extractDateTime(row.created_at).date}</TableCell>
                  <TableCell>{extractDateTime(row.created_at).time}</TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

FaceRecognitionTable.propTypes = {
  rowData: PropTypes.array.isRequired,
};

export default FaceRecognitionTable;
