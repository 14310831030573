const initialState = {
  selectedMode: { value: "day-wise" },
  EventPlayback: [],
};
export default function EventTableReducer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_EVENT_PLAYBACK_STREAM":
      return {
        ...state,
        EventPlayback: action.payload,
      };

    default:
      return state;
  }
}
