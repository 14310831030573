import * as DateFns from "date-fns";
import moment from "moment";
const initialState = {
  selectedMode: { value: "day-wise" },
  selectedFacility: { value: "all" },
  selectedDevice: { value: "all" },
  selectedAlertType: { value: "all" },
  time: [0, 23.99],
  timeRange: [0, 23.59],
  selectedClient: { value: "all" },
  AlertTypes: [],
  pizzaCount: { count: 0, distribution: [] },
};

export default function AlertDashReducer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_SELECTED_MODE_ALERT_DASH":
      return {
        ...state,
        selectedMode: action.payload,
      };
    case "UPDATE_TIME_ALERT_DASH":
      return {
        ...state,
        timeRange: action.payload,
      };
    case "UPDATE_FACILITY_ALERT_DASH":
      return {
        ...state,
        selectedFacility: action.payload,
      };
    case "UPDATE_DEVICE_ALERT_DASH":
      return {
        ...state,
        selectedDevice: action.payload,
      };
    case "UPDATE_CLIENT_ALERT_DASH":
      return {
        ...state,
        selectedClient: action.payload,
      };
    case "UPDATE_ALERT_TYPE_ALERT_DASH":
      return {
        ...state,
        selectedAlertType: action.payload,
      };
    case "UPDATE_ALERT_TYPE_SELECTED_ALERT_DASH":
      return {
        ...state,
        AlertTypes: action.payload,
      };
    case "UPDATE_PIZZA_COUNT_STATS":
      return {
        ...state,
        pizzaCount: {
          count: action.payload.count,
          distribution: action.payload.distribution.map((datapoint) => {
            let dater = DateFns.setHours(
              new Date(action.date),
              new Date(datapoint.hour ?? datapoint.hour)
            );
            datapoint.date = moment(dater)
              .subtract(30, "minutes")
              .toLocaleString();
            delete datapoint.hour;
            return datapoint;
          }),
        },
      };
    case "UPDATE_PIZZA_COUNT_STATS_DATE_RANGE":
      return {
        ...state,
        pizzaCount: {
          count: action.payload.count,
          distribution: action.payload.distribution.map((datapoint) => {
            datapoint.date = new Date(
              datapoint.recorded_at__date
            ).toISOString();
            delete datapoint.recorded_at__date;
            return datapoint;
          }),
        },
      };
    case "RESET_ALERT_DASH_STATES":
      return {
        ...state,
        selectedFacility: { value: "all" },
        selectedDevice: { value: "all" },
        selectedAlertType: { value: "all" },
        time: [0, 23.99],
        timeRange: [0, 23.59],
        selectedClient: { value: "all" },
      };
    default:
      return state;
  }
}
