import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { LIVEVIEW_SERVER_URL } from "../../../../routes/config";

const VideoPlayerIframe = ({ src }) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    if (iframeRef.current) {
      iframeRef.current.src = `${LIVEVIEW_SERVER_URL}/stream.html?src=${src.key}`;
    }
  }, [src]);

  return (
    <div style={{ height: "90%", minHeight: "60vh" }}>
      <iframe
        ref={iframeRef}
        allowFullScreen
        style={{
          width: "100%",
          height: "100%",
          border: "none",
        }}
        title="GoToRTC Stream"
      />
    </div>
  );
};

VideoPlayerIframe.propTypes = {
  src: PropTypes.object.isRequired,
};

export default VideoPlayerIframe;
