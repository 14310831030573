import axios from "axios";
import EventApiSpec from "../features/EventEndPoints";
import ApiSpec from "../features/EndPoints";
import {
  notifiToasterWarn,
  notifiToasterSuccess,
} from "../../components/common/UseToast";

class EventTableAction {
  static sendNotificationToFacility(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      await axios
        .get(EventApiSpec.sendEventNotification(data), { headers })
        .then((response) => {
          notifiToasterSuccess("Notification Send Successfully");
        })
        .catch(() => {
          notifiToasterWarn("Unable to send notification");
        });
    };
  }
}

export default EventTableAction;
