import React from "react";
import { Tabs, Tab, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Account from "../../redux/Action/CommonAction";
import { changeTopbarTitle } from "../../redux/Action/ChangeTopbarTitle";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import PlayBackMainPage from "./PlayBack/PlayBackMainPage";
import GridLayout from "./LiveView/GridLayout";
import "./vms.css";
import VmsLiveviewActions from "../../redux/Action/VmsActions";
const VmsHomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  var profile = JSON.parse(localStorage.getItem("profile"));
  useEffect(() => {
    if (profile.live_view !== true) {
      navigate("/unauthorized");
    } else {
      dispatch(Account.toglleSidebar(false));
      dispatch(changeTopbarTitle("Live View"));
      dispatch(Account.toglleVideoSidebar(true));
    }
    return () => {
      dispatch(Account.toglleVideoSidebar(false));
      dispatch(VmsLiveviewActions.replaceSelectedCameraList([]));
    };
  }, []);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue == 0) {
      dispatch(VmsLiveviewActions.UpdateLiveviewFeature("liveview"));
    } else {
      dispatch(VmsLiveviewActions.UpdateLiveviewFeature("playback"));
    }
  };

  return (
    <div style={{ backgroundColor: "var(--side-bar-bg-color)" }}>
      <div className="d-flex justify-content-start mx-2 mb-2">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            centered
            indicatorColor="primary"
            textColor="primary"
            aria-label="live view and playback tabs"
          >
            <Tab label="Live View" />
            <Tab label="Playback" />
          </Tabs>
        </Box>
      </div>
      {value === 0 && (
        <div>
          <GridLayout />
        </div>
      )}
      {value === 1 && (
        <div>
          <PlayBackMainPage />
        </div>
      )}
    </div>
  );
};

export default VmsHomePage;
