import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { MenuItem, Select, Button } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import { DateRangePicker, DateRange } from "react-date-range";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import FacilityFilter from "../../components/common/FilterBar/FacilityFilter";
import DeviceFilter from "../../components/common/FilterBar/DeviceFilter";
import mainCss from "../../assets/css/mainCss";
import Account from "../../redux/Action/CommonAction";
import AnprActions from "../../redux/Action/AnprActions"; // Import anpr-related actions
import { fetchAnprDataWithParam } from "./utils/AnprFunctions"; // Replace with proper ANPR utility function
import CustomReportDownload from "./CustomReportDownload";

const formateDateRange = (date) => {
  return date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

const formateDateRangeShort = (date) => {
  return date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
  });
};

const inputselect = {
  boxShadow: "none",
  borderRadius: "8px",
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
  MenuProps: {
    PaperProps: {
      style: {
        backgroundColor: "var(--top-bar-bg-color)",
        maxHeight: 350,
        width: 50,
        overflowX: "auto",
      },
    },
  },
};

const AnprTopBar = () => {
  const tabRef = useRef(null);
  const [showPicker, setShowPicker] = useState(false);
  const anprDateRange = useSelector((state) => state.anpr.dateRange);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const anprDate = useSelector((state) => state.anpr.selectedDate);
  const anprSelectedMode = useSelector((state) => state.anpr.selectedMode);
  const [addDetailsOpen, setAddDetailsOpen] = useState(false);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(anprDateRange.startDate),
    endDate: new Date(anprDateRange.endDate),
    key: "selection",
  });
  const [selectedDate, setSelectedDate] = useState(new Date(anprDate));
  const [selectedMode, setSelectedMode] = useState(anprSelectedMode);

  const dispatch = useDispatch();

  const handleDateChange = (date) => {
    setSelectedDate(date);
    dispatch(AnprActions.updateDate(date)); // Update action name
  };

  const selectedView = useSelector((state) => state.anpr.selectedView);
  const selectedFacility = useSelector((state) => state.anpr.selectedFacility);
  const selectedCamera = useSelector((state) => state.anpr.selectedCamera);
  const selectedTab = useSelector((state) => state.anpr.selectedTab);
  const currentPage = useSelector((state) => state.anpr.currentPage);
  const pageSize = useSelector((state) => state.anpr.pageSize);

  useEffect(() => {
    const id = JSON.parse(localStorage.getItem("profile")).id;
    dispatch(Account.getStoresByAccount(id));
    dispatch({ type: "UPDATE_ANPR_CURRENT_PAGE", payload: 1 });
  }, []);

  useEffect(() => {
    fetchAnprDataWithParam(
      selectedMode,
      selectedDate,
      selectedFacility,
      selectedCamera,
      selectionRange,
      currentPage,
      pageSize,
      dispatch
    );
  }, [
    selectedMode,
    selectedFacility,
    selectedDate,
    selectedCamera,
    selectionRange,
    selectedTab,
    pageSize,
    currentPage,
  ]);

  const handleDateRangeChange = (dtRange) => {
    setSelectionRange(dtRange.selection);
    const formattedRange = {
      startDate: dtRange.selection.startDate.toISOString(),
      endDate: dtRange.selection.endDate.toISOString(),
      key: "selection",
    };
    dispatch({ type: "UPDATE_ANPR_CURRENT_PAGE", payload: 1 });
    dispatch(AnprActions.updateDateRange(formattedRange));
  };

  const handleModeChange = (event) => {
    setSelectedMode({ value: event.target.value });
    dispatch(AnprActions.updateModeChange({ value: event.target.value }));
    dispatch({ type: "UPDATE_ANPR_CURRENT_PAGE", payload: 1 });
  };

  const togglePicker = () => {
    setShowPicker(!showPicker);
  };

  return (
    <div>
      <div
        className="card card-custom"
        style={{ backgroundColor: "var(--side-bar-bg-color)" }}
      >
        <div className="card-body" style={{ position: "relative" }}>
          <div className="row col-12">
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-2">
              <div className="select-input-lebel">Filters</div>
              <OverlayTrigger
                placement="bottom-start"
                trigger="click"
                rootClose="false"
                overlay={
                  <Popover className="col-9 col-sm-12">
                    <Popover.Body
                      style={{
                        backgroundColor: "var(--side-bar-bg-color)",
                      }}
                    >
                      <div className="select-input-lebel">Mode</div>
                      <Select
                        value={selectedMode.value}
                        sx={{
                          color: "var(--card-text-color)",
                          ...inputselect,
                        }}
                        className="select-input-field"
                        MenuProps={inputselect.MenuProps}
                        onChange={handleModeChange}
                      >
                        <MenuItem
                          value="day-wise"
                          sx={{
                            paddingTop: "2px",
                            paddingBottom: "2px",
                            color: "var(--card-text-color)",
                          }}
                          onClick={(e) => e.stopPropagation()}
                        >
                          Day Wise
                        </MenuItem>
                        <MenuItem
                          value="date-range"
                          sx={{
                            paddingTop: "2px",
                            paddingBottom: "2px",
                            color: "var(--card-text-color)",
                          }}
                          onClick={(e) => e.stopPropagation()}
                        >
                          Date Range
                        </MenuItem>
                      </Select>

                      <div className="mt-2">
                        {selectedMode.value === "date-range" ? (
                          <div>
                            <div className="select-input-lebel">Date Range</div>
                            <div ref={tabRef}>
                              <button
                                className="select-input-field"
                                onClick={togglePicker}
                              >
                                {formateDateRange(selectionRange.startDate)} -
                                {formateDateRange(selectionRange.endDate)}
                              </button>
                              <div
                                className="date-range-picker-container"
                                style={{
                                  display: showPicker ? "block" : "none",
                                  marginTop: "15px",
                                }}
                              >
                                {screenWidth > 1000 ? (
                                  <DateRangePicker
                                    className="select-input-field"
                                    ranges={[selectionRange]}
                                    onChange={handleDateRangeChange}
                                  />
                                ) : (
                                  <DateRange
                                    className="select-input-field"
                                    editableDateInputs={true}
                                    moveRangeOnFirstSelection={false}
                                    ranges={[selectionRange]}
                                    onChange={handleDateRangeChange}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className="select-input-lebel">Date</div>
                            <DatePicker
                              className="select-input-field-date"
                              customInput={
                                <input className="custom-input-date-text" />
                              }
                              selected={selectedDate}
                              onChange={handleDateChange}
                              dateFormat="dd/MM/yyyy"
                            />
                          </div>
                        )}
                      </div>
                    </Popover.Body>
                  </Popover>
                }
              >
                <div className="d-flex filter-div">
                  {selectedMode.value === "date-range" ? (
                    <div className="filter-div-text col-10 mt-1 overflow-hidden">
                      {formateDateRangeShort(selectionRange.startDate)} -
                      {formateDateRangeShort(selectionRange.endDate)}
                    </div>
                  ) : (
                    <div className="filter-div-text col-10 overflow-hidden">
                      {formateDateRange(selectedDate)}
                    </div>
                  )}
                  <div className="filter-drop-icon col-2">
                    <ArrowDropDownOutlinedIcon
                      style={{
                        float: "right",
                        marginRight: "16px",
                        marginTop: "7px",
                      }}
                    />
                  </div>
                </div>
              </OverlayTrigger>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-2">
              <FacilityFilter type="AnprDashboard" />
            </div>
            {selectedFacility.value !== "all" && (
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-2">
                <DeviceFilter type="AnprDashboard" />
              </div>
            )}
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-2">
            <div style={{ position: "absolute", right: 20, bottom: 20 }}>
              <CustomReportDownload />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnprTopBar;
