import React, { useState } from "react";
import ProfileTable from "./ProfileTable";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AddEditProfileDialog from "./AddEditProfile";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";
const ProfileTabMain = () => {
  const [isAddEditMemberOpen, setIsAddEditMemberOpen] = useState(false);
  const [searchProfile, setSearchProfile] = useState("");
  const profileList = useSelector((state) => state.accessControl.profileList);
  const dispatch = useDispatch();
  const [access_permission] = useState(
    JSON.parse(localStorage.getItem("agx_permission")).Profile ?? []
  );
  useEffect(() => {
    if (searchProfile.length > 0) {
      const filterProfile = profileList.filter((item) =>
        item.profile_name.toLowerCase().includes(searchProfile.toLowerCase())
      );
      dispatch({ type: "SEARCH_PROFILE_LIST", payload: filterProfile });
    } else {
      dispatch({ type: "SEARCH_PROFILE_LIST", payload: [] });
    }
  }, [searchProfile]);
  return (
    <div style={{ marginBottom: "0" }}>
      <div
        className="d-flex justify-content-between align-items-end"
        style={{ marginBottom: "0" }}
      >
        <div className="col-4">
          <TextField
            id="outlined-basic-profile"
            label="Search"
            variant="outlined"
            fullWidth
            size="small"
            value={searchProfile}
            onChange={(e) => setSearchProfile(e.target.value)}
            style={{ marginBottom: "0" }}
          />
        </div>
        {access_permission.includes("add_profiles") && (
          <div style={{ paddingBottom: "0" }}>
            <Button
              sx={{
                height: "30px",
                marginRight: "10px",
              }}
              variant="outlined"
              startIcon={<AddIcon />}
              size="small"
              onClick={() => setIsAddEditMemberOpen(true)}
            >
              Add Profile
            </Button>
            {/* <Button variant="outlined" size="small" sx={{ height: "30px" }}>
              Bulk Action
            </Button> */}
          </div>
        )}
      </div>
      <ProfileTable searchValue={searchProfile} />
      <AddEditProfileDialog
        isOpen={isAddEditMemberOpen}
        handleClose={() => setIsAddEditMemberOpen(false)}
        userId={-1}
        isEditable={false}
      />
    </div>
  );
};

export default ProfileTabMain;
