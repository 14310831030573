import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import DeviceFilter from "../../components/common/FilterBar/DeviceFilter";
import mainCss from "../../assets/css/mainCss";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FacilityFilter from "../../components/common/FilterBar/FacilityFilter";
import Account from "../../redux/Action/CommonAction";
import DatePicker from "react-datepicker";
import FaceRecognitionTabs from "./FaceRecognitionTabs";
import AddDetailModal from "./FaceRecognitionModals/AddDetailModal";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { MenuItem, Select } from "@mui/material";
import { DateRangePicker } from "react-date-range";
import { DateRange } from "react-date-range";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import FaceRecognitionActions from "../../redux/Action/FaceRegnitionAction";
import { fetchFaceRecognitionDataWithParam } from "./utils/FaceRecognitionFunction";
const formateDateRange = (date) => {
  return date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};
const formateDateRangeShort = (date) => {
  return date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
  });
};
const inputselect = {
  boxShadow: "none",
  borderRadius: "8px",
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
  MenuProps: {
    PaperProps: {
      style: {
        backgroundColor: "var(--top-bar-bg-color)",
        maxHeight: 350,
        width: 50,
        overflowX: "auto",
      },
    },
  },
};

const FaceRecognitionTopBar = () => {
  const tabRef = useRef(null);
  const [showPicker, setShowPicker] = useState(false);
  const faceRecognitionDateRange = useSelector(
    (state) => state.faceRecognition.dateRange
  );
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const faceRecognitionDate = useSelector(
    (state) => state.faceRecognition.selectedDate
  );
  const faceRecognitionSelectedMode = useSelector(
    (state) => state.faceRecognition.selectedMode
  );
  const [addDetailsOpen, setAddDetailsOpen] = useState(false);
  // const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectionRange, setselectionRange] = useState({
    startDate: new Date(faceRecognitionDateRange.startDate),
    endDate: new Date(faceRecognitionDateRange.endDate),
    key: "selection",
  });
  const [selectedDate, setselectedDate] = useState(
    new Date(faceRecognitionDate)
  );
  const [selectedMode, setselectedMode] = useState(faceRecognitionSelectedMode);
  const handleDateChange = (date) => {
    setselectedDate(date);
    dispatch(FaceRecognitionActions.updateDate(date));
  };
  const selectedView = useSelector(
    (state) => state.faceRecognition.selectedView
  );
  const selectedFacility = useSelector(
    (state) => state.faceRecognition.selectedFacility
  );
  const selectedCamera = useSelector(
    (state) => state.faceRecognition.selectedCamera
  );
  const selectedTab = useSelector((state) => state.faceRecognition.selectedTab);
  const currentPage = useSelector((state) => state.faceRecognition.currentPage);
  const pageSize = useSelector((state) => state.faceRecognition.pageSize);

  const dispatch = useDispatch();

  useEffect(() => {
    const id = JSON.parse(localStorage.getItem("profile")).id;
    dispatch(Account.getStoresByAccount(id));
  }, []);

  useEffect(() => {
    if (selectedFacility.value !== "all") {
      fetchFaceRecognitionDataWithParam(
        selectedMode,
        selectedDate,
        selectedFacility,
        selectedCamera,
        selectionRange,
        selectedTab,
        currentPage,
        pageSize,
        dispatch
      );
    }
  }, [
    selectedMode,
    selectedFacility,
    selectedDate,
    selectedCamera,
    selectionRange,
    selectedMode,
    selectedTab,
    pageSize,
  ]);

  const handleAddDetailsOpen = () => setAddDetailsOpen(true);
  const handleClose = () => setAddDetailsOpen(false);

  function handleViewChange(selectedView) {
    dispatch({ type: "UPDATE_SELECTED_VIEW", payload: selectedView });
    dispatch({
      type: "UPDATE_CURRENT_PAGE_FACE_RECOGNITION",
      payload: 1,
    });
  }

  const handleDateRangeChange = (dtRange) => {
    setselectionRange(dtRange.selection);
    (dtRange = {
      startDate: dtRange.selection.startDate.toISOString(),
      endDate: dtRange.selection.endDate.toISOString(),
      key: "selection",
    }),
      dispatch({
        type: "UPDATE_CURRENT_PAGE_FACE_RECOGNITION",
        payload: 1,
      });
    dispatch(FaceRecognitionActions.updateDateRange(dtRange));
  };

  const handleModeChange = (event) => {
    setselectedMode({ value: event.target.value });
    dispatch(
      FaceRecognitionActions.updateModeChange({ value: event.target.value })
    );
    dispatch({
      type: "UPDATE_CURRENT_PAGE_FACE_RECOGNITION",
      payload: 1,
    });
  };

  const togglePicker = () => {
    setShowPicker(!showPicker);
  };

  return (
    <div className="container-fluid">
      <div
        className="card card-custom"
        style={{ backgroundColor: "var(--side-bar-bg-color)" }}
      >
        <div className="card-body">
          <div className="row col-12">
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-2">
              <div className="select-input-lebel">Filters</div>
              <OverlayTrigger
                placement="bottom-start"
                trigger="click"
                rootClose="false"
                overlay={
                  <Popover className="col-9 col-sm-12">
                    <Popover.Body
                      style={{
                        backgroundColor: "var(--side-bar-bg-color)",
                      }}
                    >
                      <div className="select-input-lebel">Mode</div>
                      <Select
                        value={selectedMode.value}
                        sx={{
                          color: "var(--card-text-color)",
                          ...inputselect,
                        }}
                        className="select-input-field"
                        MenuProps={inputselect.MenuProps}
                        onChange={handleModeChange}
                      >
                        <MenuItem
                          value="day-wise"
                          sx={{
                            paddingTop: "2px",
                            paddingBottom: "2px",
                            color: "var(--card-text-color)",
                          }}
                          onClick={(e) => e.stopPropagation()}
                        >
                          Day Wise
                        </MenuItem>
                        <MenuItem
                          value="date-range"
                          sx={{
                            paddingTop: "2px",
                            paddingBottom: "2px",
                            color: "var(--card-text-color)",
                          }}
                          onClick={(e) => e.stopPropagation()}
                        >
                          Date Range
                        </MenuItem>
                      </Select>

                      <div className="mt-2">
                        {selectedMode.value === "date-range" ? (
                          <div>
                            <div className="select-input-lebel">Date Range</div>
                            <div ref={tabRef}>
                              <button
                                className="select-input-field"
                                onClick={togglePicker}
                              >
                                {formateDateRange(selectionRange.startDate)} -{" "}
                                {formateDateRange(selectionRange.endDate)}
                              </button>
                              <div
                                className="date-range-picker-container"
                                style={{
                                  display: showPicker ? "block" : "none",
                                  marginTop: "15px",
                                }}
                              >
                                {screenWidth > 1000 ? (
                                  <DateRangePicker
                                    className="select-input-field"
                                    ranges={[selectionRange]}
                                    onChange={handleDateRangeChange}
                                  />
                                ) : (
                                  <DateRange
                                    className="select-input-field"
                                    editableDateInputs={true}
                                    moveRangeOnFirstSelection={false}
                                    ranges={[selectionRange]}
                                    onChange={handleDateRangeChange}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className="select-input-lebel">Date</div>
                            <DatePicker
                              className="select-input-field-date"
                              customInput={
                                <input className="custom-input-date-text" />
                              }
                              selected={selectedDate}
                              onChange={handleDateChange}
                              dateFormat="dd/MM/yyyy"
                            />
                          </div>
                        )}
                      </div>
                    </Popover.Body>
                  </Popover>
                }
              >
                <div className="d-flex filter-div">
                  {selectedMode.value === "date-range" ? (
                    <div className="filter-div-text col-10 mt-1 overflow-hidden">
                      {formateDateRangeShort(selectionRange.startDate)} -{" "}
                      {formateDateRangeShort(selectionRange.endDate)}{" "}
                    </div>
                  ) : (
                    <div className="filter-div-text col-10 overflow-hidden">
                      {formateDateRange(selectedDate)}
                    </div>
                  )}
                  <div className="filter-drop-icon col-2">
                    <ArrowDropDownOutlinedIcon
                      style={{
                        float: "right",
                        marginRight: "16px",
                        marginTop: "7px",
                      }}
                    />
                  </div>
                </div>
              </OverlayTrigger>
            </div>

            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-2">
              <FacilityFilter type="FaceRecognitionDashboard" />
            </div>
            {selectedFacility.value != "all" && (
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-2">
                <DeviceFilter type="FaceRecognitionDashboard" />
              </div>
            )}
          </div>
          <div className="row">
            <div
              className="col-12 col-md-3 d-flex justify-content-end mt-4"
              style={{ width: "100%", marginLeft: "auto" }}
            >
              <div>
                <Button
                  variant="contained"
                  onClick={handleAddDetailsOpen}
                  startIcon={<AddCircleOutlineIcon />}
                >
                  Upload
                </Button>
                <AddDetailModal
                  isOpen={addDetailsOpen}
                  handleClose={handleClose}
                  isEditable={false}
                  faceData={null}
                />
              </div>
              <div
                onClick={() => handleViewChange("grid")}
                style={{ cursor: "pointer", marginLeft: "5px" }}
              >
                <ViewModuleIcon
                  fontSize="large"
                  sx={{
                    color:
                      selectedView == "grid"
                        ? "blue"
                        : "var(--black-white-darkmode)",
                  }}
                />
              </div>
              <div
                onClick={() => handleViewChange("list")}
                style={{ cursor: "pointer", marginLeft: "5px" }}
              >
                <FormatListBulletedIcon
                  sx={{
                    color:
                      selectedView == "list"
                        ? "blue"
                        : "var(--black-white-darkmode)",
                  }}
                  fontSize="large"
                />
              </div>
            </div>
          </div>
        </div>
        <FaceRecognitionTabs />
      </div>
    </div>
  );
};

export default FaceRecognitionTopBar;
