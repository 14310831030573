const initialState = {
  liveViewData: [],
};

export default function LiveReducer(state = initialState, action) {
  switch (action.type) {
    case "LIVE_FEEDS":
      return {
        ...state,
        liveViewData: [...state.liveViewData, ...action.payload],
      };
    case "REMOVE_SELECTED_LIVE_FEEDS":
      return {
        ...state,
        liveViewData: state.liveViewData.filter(
          (camera) => camera.cam_id !== action.payload
        ),
      };
    case "LIVE_FEEDS_REMOVE":
      return {
        ...state,
        liveViewData: [],
      };
    default:
      return state;
  }
}
