import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

export default function GroupedCheckboxAutocomplete({
  options,
  label,
  onChange,
  value,
  loading,
  filterOptions,
  size,
  error,
  helperText,
}) {
  const filteredOptions = filterOptions
    ? options.filter(filterOptions)
    : options;

  const isOptionDisabled = (option) => {
    const disabledOptions = [
      "No Camera Present",
      "No NVR Present",
      "No Device Present",
    ];
    return disabledOptions.includes(option.value);
  };

  return (
    <div style={{ width: "100% !important" }}>
      <Autocomplete
        id="grouped-checkbox-demo"
        options={filteredOptions}
        multiple
        disableCloseOnSelect
        size={size}
        limitTags={5}
        groupBy={(option) => {
          const groupName = option.installed_at_name;
          return groupName;
        }}
        getOptionLabel={(option) => option.value}
        onChange={onChange}
        value={value}
        fullWidth={true}
        loading={loading}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            aria-disabled={isOptionDisabled(option)}
            style={{
              pointerEvents: isOptionDisabled(option) ? "none" : "auto",
            }}
          >
            <Checkbox
              style={{ marginRight: 8 }}
              checked={selected}
              disabled={isOptionDisabled(option)}
            />
            <ListItemText primary={option.value} />
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            error={error}
            helperText={helperText}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />
    </div>
  );
}

GroupedCheckboxAutocomplete.propTypes = {
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  loading: PropTypes.bool,
  filterOptions: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};
