import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import mainCss from "../../assets/css/mainCss";
import "../../assets/css/profile.css";
import ProfileTopCard from "./ProfileTopCard";
import ProfileDetails from "./ProfileDetails";
import UserSettings from "./UserSettings";
import { changeTopbarTitle } from "../../redux/Action/ChangeTopbarTitle";
import { getNewUserDetails } from "../../redux/Action/Login";
import WhatsappQR from "./whatsappQR";
import { getProfile } from "../../redux/Action/ProfileAction";
import Button from "@mui/material/Button";
import EmailCustomMainPage from "./EmailCustomization/EmailCustomMainPage";
const Profile = () => {
  const [option, setOption] = useState(1);
  const [accesspermission, setAccessPermissions] = useState(
    JSON.parse(localStorage.getItem("agx_permission")).Profile ?? []
  );

  const dispatch = useDispatch();

  var profile = JSON.parse(localStorage.getItem("profile"));
  useEffect(() => {
    setTitile();
    getNewUserDetails();
    dispatch(getProfile());
  }, []);

  const setTitile = () => {
    dispatch(changeTopbarTitle("Profile"));
  };
  return (
    <div>
      <div style={mainCss.divBelowTopbar}>
        <div>
          <ProfileTopCard profile={profile} />
        </div>
      </div>
      <div className="profile-options-container  mt-2">
        <div className="profile-options-list d-flex ">
          <Button
            variant={option == 1 ? "contained" : "outlined"}
            sx={{ paddingX: "30px", marginRight: "10px" }}
            size="medium"
            onClick={() => {
              setOption(1);
            }}
            className="mr-2"
          >
            Profile Details
          </Button>
          {(accesspermission.includes("view_user") ||
            accesspermission.includes("view_profiles") ||
            accesspermission.includes("view_groups")) && (
            <Button
              variant={option == 2 ? "contained" : "outlined"}
              sx={{ paddingX: "30px", marginRight: "10px" }}
              onClick={() => {
                setOption(2);
              }}
            >
              User Setting
            </Button>
          )}
          <Button
            variant={option == 3 ? "contained" : "outlined"}
            sx={{ paddingX: "30px", marginRight: "10px" }}
            onClick={() => {
              setOption(3);
            }}
          >
            Whatsapp QR
          </Button>

          {accesspermission.includes("email_notification_customization") && (
            <Button
              variant={option == 4 ? "contained" : "outlined"}
              sx={{ paddingX: "30px" }}
              onClick={() => {
                setOption(4);
              }}
            >
              Notification CUstomization
            </Button>
          )}
        </div>
      </div>
      <div>
        {option == 1 ? (
          <div className="profile-dropdown-form-new">
            <ProfileDetails profile={profile} />
          </div>
        ) : null}
        {option == 2 ? (
          <div className="profile-dropdown-div">
            <UserSettings profile={profile} />
          </div>
        ) : null}
        {option == 3 ? (
          <div className="profile-dropdown-div">
            <WhatsappQR />
          </div>
        ) : null}
        {option == 4 ? (
          <div className="profile-dropdown-div">
            <EmailCustomMainPage />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Profile;
