import React, { useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  Collapse,
  List,
  ListItem,
} from "@mui/material";
import ScannerIcon from "@mui/icons-material/Scanner";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSelector, useDispatch } from "react-redux";
import VmsLiveviewActions from "../../../redux/Action/VmsActions";
import LiveViewAction from "../../../redux/Action/LiveViewAction";
import VmsHelperActions from "../../../redux/Action/VmsHelperActions";

const NvrOptions = ({ nvr }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const selectedCameraList = useSelector(
    (state) => state.vms.selectedCameraList
  );
  const activeView = useSelector((state) => state.vms.activeView);
  const playbackCamera = useSelector((state) => state.vms.playbackCamera);
  const currentPage = useSelector((state) => state.vms.currentPage);
  const selectedGrid = useSelector((state) => state.vms.selectedGrid);
  const playlist = useSelector((state) => state.vms.playlist);
  const selectedQuality = useSelector((state) => state.vms.selectedQuality);

  const playCamerasForPlayback = (id) => {
    if (playbackCamera.includes(id)) {
      dispatch(VmsLiveviewActions.removeSelectedCameraForPlayback(id));
      dispatch(VmsLiveviewActions.removePlaybackStream());
      dispatch({
        type: "UPDATE_TIME_RANGE_FOR_PLAYBACK",
        payload: [0.0, 23.59],
      });
      dispatch({
        type: "UPDATE_TIME_FOR_PLAYBACK",
        payload: [0.0, 23.99],
      });
      dispatch({
        type: "UPDATE_PLAYBACK_CHUNKS_AVAILABLE",
        payload: [],
      });
    } else {
      dispatch(VmsLiveviewActions.updateSelectedCameraForPlayback(id));
      if (playbackCamera.length > 0) {
        dispatch(VmsLiveviewActions.updateShowAlertDialog(true));
      }
    }
  };

  const playCamerasByNvr = (event) => {
    event.stopPropagation();
    setOpen(true);
    const camIds = nvr.camera_list.map((item) => item.camera_id);
    dispatch(VmsLiveviewActions.updateSelectedCameraList(camIds));
    let CameraIds = camIds.slice(0, selectedGrid);

    if (CameraIds.length > 0) {
      CameraIds.forEach((item, index) => {
        if (playlist.length < selectedGrid) {
          dispatch(VmsHelperActions.increaseLoadingCameras(index));
          dispatch(LiveViewAction.getLiveViewByIpCameras(item, selectedQuality))
            .then(() => {
              dispatch(VmsHelperActions.decreaseLoadingCameras(index));
            })
            .catch(() => {
              dispatch(VmsHelperActions.decreaseLoadingCameras(index));
            });
        }
      });
    }
  };

  const removeCameraByNvr = (event) => {
    event.stopPropagation();
    setOpen(false);
    const camIds = nvr.camera_list.map((item) => item.camera_id);
    const newCameras = selectedCameraList.filter(
      (item) => !camIds.includes(item)
    );

    dispatch(VmsLiveviewActions.replaceSelectedCameraList(newCameras));
    const newPlaylist = playlist
      .filter((item) => camIds.includes(Number(item.cam_id)))
      .map((item) => item.cam_id);
    dispatch(
      VmsLiveviewActions.removeCameraFromLiveViewData(newPlaylist, playlist)
    );
    if (newPlaylist.length < selectedGrid && newCameras > selectedGrid) {
      if (newCameras.length > 0) {
        newCameras.forEach((item, index) => {
          if (playlist.length < selectedGrid) {
            dispatch(VmsHelperActions.increaseLoadingCameras(index));
            dispatch(
              LiveViewAction.getLiveViewByIpCameras(item, selectedQuality)
            )
              .then(() => {
                dispatch(VmsHelperActions.decreaseLoadingCameras(index));
              })
              .catch(() => {
                dispatch(VmsHelperActions.decreaseLoadingCameras(index));
              });
          }
        });
      }
    }
  };

  const playCamera = useCallback(
    (id) => {
      if (!selectedCameraList.includes(id)) {
        let isCamPlaying = playlist.map((item) => item.id).includes(id);
        if (
          selectedCameraList.length < currentPage * selectedGrid &&
          !isCamPlaying
        ) {
          dispatch(VmsHelperActions.increaseLoadingCameras(playlist.length));

          dispatch(LiveViewAction.getLiveViewByIpCameras(id, selectedQuality))
            .then(() => {
              dispatch(
                VmsHelperActions.decreaseLoadingCameras(playlist.length)
              );
              dispatch(VmsLiveviewActions.updateSelectedCameraList([id]));
            })
            .catch(() => {
              console.log("hello");
              dispatch(
                VmsHelperActions.decreaseLoadingCameras(playlist.length)
              );
            });
        }
      } else {
        const newData = selectedCameraList.filter((item) => item !== id);

        const newPlaylist = playlist
          .filter((item) => item.cam_id == id)
          .map((item) => item.cam_id);

        dispatch(
          VmsLiveviewActions.removeCameraFromLiveViewData(newPlaylist, playlist)
        );
        dispatch(VmsLiveviewActions.replaceSelectedCameraList(newData));
      }
    },
    [dispatch, selectedCameraList, playlist, currentPage, selectedGrid]
  );

  const handleDragStart = (id, e) => {
    if (selectedCameraList.includes(id)) {
      console.log("Item already Present");
    } else {
      e.dataTransfer.setData("cameraId", id);
      // dispatch(VmsLiveviewActions.updateSelectedCameraList([id]));
    }
  };

  const isCameraPlaying = useCallback(
    (id) => {
      return selectedCameraList.includes(id);
    },
    [selectedCameraList]
  );

  const isPlaybackCamPlaying = (id) => {
    return playbackCamera.includes(id);
  };

  const isCameraOfNvrPlaying = useMemo(() => {
    return nvr.camera_list.some((camera) =>
      selectedCameraList.includes(camera.camera_id)
    );
  }, [nvr.camera_list, selectedCameraList]);

  return (
    <>
      <ListItemButton
        sx={{ pl: 4, py: 0 }}
        onClick={() => setOpen((prev) => !prev)}
      >
        <ListItemIcon sx={{ minWidth: "auto", mr: 0.5 }}>
          <ScannerIcon color={isCameraOfNvrPlaying ? "primary" : "secondary"} />
        </ListItemIcon>
        <ListItemText
          primary={nvr.nvr_name}
          primaryTypographyProps={{
            fontSize: 13,
            color: isCameraOfNvrPlaying ? "primary" : "secondary",
          }}
        />
        {activeView !== "playback" && (
          <IconButton
            sx={{ minWidth: "auto", cursor: "pointer" }}
            onClick={
              isCameraOfNvrPlaying ? removeCameraByNvr : playCamerasByNvr
            }
          >
            {isCameraOfNvrPlaying ? <CancelIcon /> : <PlayCircleIcon />}
          </IconButton>
        )}
        {open ? (
          <ExpandLess color="secondary" />
        ) : (
          <ExpandMore color="secondary" />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {nvr.camera_list.length > 0 ? (
            nvr.camera_list.map((camera) => (
              <ListItemButton
                draggable={true}
                onDragStart={(e) => {
                  e.stopPropagation();
                  handleDragStart(camera.camera_id, e);
                }}
                key={camera.camera_id}
                sx={{ pl: 6, py: 0 }}
                onClick={() =>
                  activeView === "liveview"
                    ? playCamera(camera.camera_id)
                    : playCamerasForPlayback(camera.camera_id)
                }
                selected={
                  activeView === "playback"
                    ? playbackCamera.includes(camera.camera_id)
                    : isCameraPlaying(camera.camera_id)
                }
              >
                <ListItemIcon sx={{ minWidth: "auto", m: 0, mr: 0.5 }}>
                  <VideoCallIcon
                    color={
                      activeView === "playback"
                        ? playbackCamera.includes(camera.camera_id)
                          ? "primary"
                          : "secondary"
                        : isCameraPlaying(camera.camera_id)
                        ? "primary"
                        : "secondary"
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primary={camera.camera_name.replace(/_/g, " ")}
                  primaryTypographyProps={{
                    fontSize: 12,
                    color:
                      activeView === "playback"
                        ? playbackCamera.includes(camera.camera_id)
                          ? "primary"
                          : "secondary"
                        : isCameraPlaying(camera.camera_id)
                        ? "primary"
                        : "secondary",
                  }}
                />
                <IconButton sx={{ minWidth: "auto", margin: 0, mr: 0.5 }}>
                  {activeView === "playback" ? (
                    isPlaybackCamPlaying(camera.camera_id) ? (
                      <CancelIcon />
                    ) : (
                      <PlayCircleIcon />
                    )
                  ) : isCameraPlaying(camera.camera_id) ? (
                    <CancelIcon />
                  ) : (
                    <PlayCircleIcon />
                  )}
                </IconButton>
              </ListItemButton>
            ))
          ) : (
            <ListItem sx={{ pl: 6 }}>
              <ListItemText secondary="Liveview/Playback is not enable on any cameras of this NVR/DVR" />
            </ListItem>
          )}
        </List>
      </Collapse>
    </>
  );
};

NvrOptions.propTypes = {
  nvr: PropTypes.shape({
    nvr_id: PropTypes.number.isRequired,
    nvr_name: PropTypes.string.isRequired,
    camera_list: PropTypes.arrayOf(
      PropTypes.shape({
        camera_id: PropTypes.number.isRequired,
        camera_name: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default NvrOptions;
