import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import MenuButton from "./MenuButton";
import { changeTopbarTitle } from "../../../redux/Action/ChangeTopbarTitle";
import RuleBasedAlertAction from "../../../redux/Action/RuleBasedAlertActions";
import PotentialCustomerDialog from "./PotentialCustomerDialog";
import ConfirmationDialog from "../../../components/common/ConfirmationDailog";
import AttendanceModal from "./AttendanceModal";
import AlertRuleDialogs from "./AlertRuleDIalogs";
const SettingMainPage = () => {
  const [openModal, setOPenModal] = useState(false);
  const [openAlertRuleModal, setAlertOpenModal] = useState(false);
  const [openAttendance, SetOpenAttendance] = useState(false);
  const [sendData, setSendData] = useState(null);
  const dispatch = useDispatch();
  const rulesList = useSelector((state) => state.ruleBasedAlerts.ruleList);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    dispatch(changeTopbarTitle("Alerts Customisation"));
    setLoading(true);
    dispatch(RuleBasedAlertAction.getRulesData()).finally(() => {
      setLoading(false);
    });
  }, [dispatch]);

  const handleActiveChange = (id, isActive, type) => {
    // Assuming toggleRuleStatus is an action that handles the API request
    dispatch(RuleBasedAlertAction.toggleRuleStatus(id, !isActive, type));
  };

  function deleteRule(data) {
    dispatch(RuleBasedAlertAction.deleteAlertRule(data.id, data.type));
    setIsDeleteOpen(false);
  }
  function openModalByType(type) {
    type == "potential_customer"
      ? setOPenModal(true)
      : type == "custom_alerts"
      ? setAlertOpenModal(true)
      : SetOpenAttendance(true);
  }

  return (
    <div className="mt-3">
      <div className="d-flex justify-content-end mb-4">
        <MenuButton />
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>S.No</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Facility</TableCell>
              <TableCell>Alert Type</TableCell>
              <TableCell>Active</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={6} align="center">
                  Loading Data...
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {rulesList.length == 0 && (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No Rule Available
                  </TableCell>
                </TableRow>
              )}
              {rulesList.map((item, index) => (
                <React.Fragment key={item.id || index}>
                  {item.type == "custom_alerts" ? (
                    <TableRow key={`alert-${index}`}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>
                        {item.facility_ids.map((facility, index) => (
                          <div key={`facility-${index}`}>
                            {facility.facility_name}
                          </div>
                        ))}
                      </TableCell>
                      <TableCell>
                        {item.type
                          .replace(/_/g, " ")
                          .replace(/\b\w/g, (c) => c.toUpperCase())}
                      </TableCell>
                      <TableCell>
                        <Switch
                          checked={item.is_active}
                          onChange={() =>
                            handleActiveChange(
                              item.id,
                              item.is_active,
                              item.type
                            )
                          }
                        />
                      </TableCell>
                      <TableCell align="center">
                        <span>
                          <IconButton
                            aria-label="edit"
                            onClick={() => {
                              openModalByType(item.type);
                              setSendData(item);
                            }}
                          >
                            <ModeEditIcon />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            onClick={() => {
                              setSelectedUserId({
                                id: item.id,
                                type: item.type,
                              });
                              setIsDeleteOpen(true);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </span>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{item.rule_name}</TableCell>
                      <TableCell>
                        {item.facility && item.facility.facility_name}
                      </TableCell>
                      <TableCell>
                        {item.type
                          .replace(/_/g, " ")
                          .replace(/\b\w/g, (c) => c.toUpperCase())}
                      </TableCell>
                      <TableCell>
                        <Switch
                          checked={item.is_active}
                          onChange={() =>
                            handleActiveChange(
                              item.id,
                              item.is_active,
                              item.type
                            )
                          }
                        />
                      </TableCell>
                      <TableCell align="center">
                        <span>
                          <IconButton
                            aria-label="edit"
                            onClick={() => {
                              openModalByType(item.type);
                              setSendData(item);
                            }}
                          >
                            <ModeEditIcon />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            onClick={() => {
                              setSelectedUserId({
                                id: item.id,
                                type: item.type,
                              });
                              setIsDeleteOpen(true);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </span>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {openModal && (
        <PotentialCustomerDialog
          open={openModal}
          handleClose={() => setOPenModal(false)}
          isEditable={true}
          data={sendData}
        />
      )}
      {openAlertRuleModal && (
        <AlertRuleDialogs
          open={openAlertRuleModal}
          handleClose={() => setAlertOpenModal(false)}
          isEditable={true}
          data={sendData}
        />
      )}
      {openAttendance && (
        <AttendanceModal
          open={openAttendance}
          handleClose={() => SetOpenAttendance(false)}
          isEditable={true}
          data={sendData}
        />
      )}
      <ConfirmationDialog
        title="Delete Rule"
        dialogContent="Are you sure you want to delete this rule?"
        handleConfirm={() => deleteRule(selectedUserId)}
        handleDisconfirm={() => setIsDeleteOpen(false)}
        isOpen={isDeleteOpen}
        handleClose={() => setIsDeleteOpen(false)}
        confirmButtonLabel="Yes"
        disconfirmButtonLabel="No"
        confirmButtonVariant="contained"
        disconfirmButtonVariant="outlined"
      />
    </div>
  );
};

export default SettingMainPage;
