const initialState = {
  cameraList: [],
  ruleList: [],
};

export default function RuleBasedAlertsReducers(state = initialState, action) {
  switch (action.type) {
    case "CAMERA_LIST_FOR_MULTIPLE_FACILITIES":
      return {
        ...state,
        cameraList: [...state.cameraList, ...action.payload],
      };
    case "RULE_LIST_FOR_ALERT":
      return {
        ...state,
        ruleList: action.payload,
      };

    default:
      return state;
  }
}
