import axios from "axios";
import AlertApiSpec from "../features/AlertEndPoints";
import EndPoints from "../features/EndPoints";

class AlertTableDataAction {
  static getAlertViaUSecase(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      axios
        .get(EndPoints.getAlertEventViaUsecase(data), {
          headers,
        })
        .then((response) => {
          dispatch({
            type: "TABLE_DATA_ALERT",
            payload: response.data,
            date: data.date,
          });
        });
    };
  }
}
export default AlertTableDataAction;
