import React from "react";
import ApiIcon from "@mui/icons-material/Api";
import MovingIcon from "@mui/icons-material/Moving";
import CenterFocusWeakIcon from "@mui/icons-material/CenterFocusWeak";
import mainCss from "../../assets/css/mainCss";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

const RemoteMonitoringStatitics = ({ loading }) => {
  const { totalNumberOfNvr, totalOnlineNvrCount } = useSelector(
    (state) => state.rhmsData
  );

  return (
    <div className="divbelowtopbarsecond">
      <div className="select-input-div card-custom card-stretch gutter-b">
        <div className="card-body-div">
          <div className="row">
            <div className="col-12 col-sm-6 col-xl-4">
              <div style={{ display: "flex" }}>
                <div>
                  <button
                    className="btn btn-sm m-1 shadow-none"
                    style={mainCss.bigButtonIcon}
                  >
                    <MovingIcon sx={mainCss.bigIconInRhms} />
                  </button>
                </div>
                <div style={{ marginLeft: "10px", marginTop: "10px" }}>
                  <h5>NVR/DVR Count</h5>
                  <h5>{!loading ? totalNumberOfNvr : "---"}</h5>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4">
              <div style={{ display: "flex" }}>
                <div>
                  <button
                    className="btn btn-sm m-1 shadow-none"
                    style={mainCss.bigButtonIcon}
                  >
                    <ApiIcon sx={mainCss.bigIconInRhms} />
                  </button>
                </div>
                <div style={{ marginLeft: "10px", marginTop: "10px" }}>
                  <h5>Online NVR/DVR Count</h5>
                  <h5>{!loading ? totalOnlineNvrCount : "---"}</h5>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4">
              <div style={{ display: "flex" }}>
                <div>
                  <button
                    className="btn btn-sm m-1 shadow-none"
                    style={mainCss.bigButtonIcon}
                  >
                    <CenterFocusWeakIcon sx={mainCss.bigIconInRhms} />
                  </button>
                </div>
                <div style={{ marginLeft: "10px", marginTop: "10px" }}>
                  <h5>Offline NVR/DVR Count</h5>
                  <h5>
                    {!loading ? totalNumberOfNvr - totalOnlineNvrCount : "---"}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

RemoteMonitoringStatitics.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default RemoteMonitoringStatitics;
