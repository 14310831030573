import React, { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
const HeatmapChart = ({ data, title }) => {
  HeatmapChart.propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
  };

  const [dataPointTooltipText, setDataPointTooltipText] =
    useState("Show data points");
  const [dataLabelsEnabled, setDataLabelsEnabled] = useState(false);

  // Sample data and other configurations (hours, dates, series, etc.)
  const hours = useMemo(
    () => Array.from(new Set(data.map((item) => item.hour))),
    [data]
  );
  const dates = useMemo(
    () => Array.from(new Set(data.map((item) => item.date))).reverse(),
    [data]
  );
  const dataLookup = {};
  data.forEach((item) => {
    const key = `${item.date}-${item.hour}`;
    dataLookup[key] = item.time;
  });
  // Transform the data using the lookup object
  const series = hours.map((hour) => ({
    name: changeFormatOfTime(hour),
    data: dates.map((date) => {
      const key = `${date}-${hour}`;
      return {
        x: date,
        y: dataLookup[key] ? Math.ceil(dataLookup[key]) : 0,
      };
    }),
  }));
  series.reverse();

  const dataPointsToggle = (ev) => {
    const check = ev.currentTarget.checked;
    setDataLabelsEnabled(check);
    setDataPointTooltipText(check ? "Hide data points" : "Show data points");
  };
  function changeFormatOfTime(value) {
    if (value == 0) {
      return "12 AM";
    } else if (value == 12) {
      return `${12} PM`;
    } else if (value > 12) {
      let hour = value % 12;
      return `${hour} PM`;
    } else {
      return `${value} AM`;
    }
  }
  function changeFormatOfDate(value) {
    const date = new Date(value);
    return `${moment(date).format("DD-MM-YY")}`;
  }
  const options = {
    chart: {
      type: "heatmap",
      toolbar: {
        show: false,
      },
    },

    xaxis: {
      categories: dates,
      labels: {
        style: {
          colors: "var(--card-text-color)", //change text color to var(--card-text-color)
        },
        formatter: function (value) {
          return changeFormatOfDate(value);
        },
      },
      title: {
        text: dataLabelsEnabled ? "Date" : "",
      },
      tooltip: {
        enabled: false,
      },
    },
    legend: {
      position: "top",
      labels: {
        colors: "var(--card-text-color)",
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "var(--card-text-color)", //change text color to var(--card-text-color)
        },
        formatter: function (value) {
          return value;
        },
      },
      title: {
        style: {
          colors: "var(--card-text-color)", //change text color to var(--card-text-color)
        },
        text: dataLabelsEnabled ? "Hour" : "",
      },
    },
    dataLabels: {
      enabled: dataLabelsEnabled ? true : false,
    },

    colors: ["#058226"],
    plotOptions: {
      heatmap: {
        radius: 0,
        pointWidth: 80, // Adjust the width of the boxes (in pixels)
        pointHeight: 80, // Adjust the height of the boxes (in pixels)
        colorScale: {
          ranges: [
            {
              from: -10,
              to: 0,
              name: " ",
              color: "#ffffff",
            },
            {
              from: 0,
              to: 0,
              name: "Offline",
              color: "#fc0505",
            },
            {
              from: 1,
              to: 20,
              name: "0 - 20 Min",
              color: "#BCE7C6",
            },
            {
              from: 21,
              to: 40,
              name: "20 - 40 Min",
              color: "#7DCD8E",
            },
            {
              from: 41,
              to: 60,
              name: "40 - 60 Min",
              color: "#19A437",
            },
          ],
        },
      },
    },
    stroke: {
      width: 1, // Set the stroke width here
      colors: ["#d5dfed"],
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const hour = w.config.series[seriesIndex].name;
        const date = changeFormatOfDate(
          w.config.xaxis.categories[dataPointIndex]
        );
        const value = series[seriesIndex][dataPointIndex];
        if (value < 0) {
          return `<div class="custom-tooltip"><span>Date: ${date}</span><span>Hour: ${hour}<br /></span></div>`;
        }

        return `<div class="custom-tooltip"><span>Date: ${date}</span><span>Hour: ${hour}<br /></span><span>${title} : ${value} Min</span></div>`;
      },
    },
    useFillColorAsStroke: true,
  };

  return (
    <div className="chart-div">
      <div className="d-sm-flex">
        <p
          className="chart-title"
          style={{ textAlign: "left", marginLeft: "20px" }}
        >
          {title}
        </p>{" "}
        <span
          className="text-dark font-weight-bolder"
          style={{ marginLeft: "auto", marginRight: "20px" }}
        >
          <label className="checkbox checkbox-lg checkbox-single flex-shrink-0 m-0 mx-4 checkbox-light-secondary">
            <span style={{ color: "grey" }}>{dataPointTooltipText} </span>
            <span style={{ marginLeft: "auto" }}>
              <input
                className="chart-checkbox"
                type="checkbox"
                onChange={dataPointsToggle}
              />
            </span>
          </label>
        </span>
      </div>
      <div style={{ overflow: "hidden" }}>
        <ReactApexChart
          options={options}
          series={series}
          type="heatmap"
          height={450}
        />
      </div>
    </div>
  );
};

export default HeatmapChart;
