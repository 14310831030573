//this function is a reusable function which is used to append pamams on the urls
export default function buildUrlWithParams(params, urls) {
  // Initialize URL with the base part
  const url = new URL(urls);

  // Iterate over the params object
  Object.keys(params).forEach((key) => {
    if (params[key] !== undefined && params[key] !== null) {
      // Append only if the parameter is not undefined or null
      url.searchParams.append(key, params[key]);
    }
  });

  // Return the final URL
  return url.toString();
}
