import React from "react";
import { useState } from "react";
import DemographicCharts from "./ChartComponnets.js/DemographicCharts";
import DwellTimeCharts from "./ChartComponnets.js/DwellTimeCharts";
import OccupancyChart from "./ChartComponnets.js/OccupancyChart";
import WeeklyAndDayWiseComparison from "./ChartComponnets.js/WeeklyAndDayWiseComparision";
import GroupCountChart from "./ChartComponnets.js/GroupCountChart";
import PeopleCountCharts from "./ChartComponnets.js/PeopleCountCharts";

const DashboardCharts = (propTypes) => {
  var profile = JSON.parse(localStorage.getItem("profile"));
  const [access_permission] = useState(
    JSON.parse(localStorage.getItem("agx_permission")).Dashboard ?? []
  );
  return (
    <div>
      <OccupancyChart selectedMode={propTypes.selectedMode} />
      {profile.services_selected.Dashboard.includes("people_count") &&
        access_permission.includes("view_people_count_analytics") && (
          <div>
            <PeopleCountCharts selectedMode={propTypes.selectedMode} />
            <DwellTimeCharts selectedMode={propTypes.selectedMode} />
            <DemographicCharts selectedMode={propTypes.selectedMode} />
            <WeeklyAndDayWiseComparison selectedMode={propTypes.selectedMode} />
            <GroupCountChart selectedMode={propTypes.selectedMode} />
          </div>
        )}
    </div>
  );
};

export default DashboardCharts;
