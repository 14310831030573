import { React, useEffect, useState } from "react";
import moment from "moment";
import Chart from "react-apexcharts";

const AlertLineChart = (propTypes) => {
  const [tickAmount, settickAmount] = useState(0);
  const [series, setseries] = useState([]);
  const [selectedMode] = useState("hourly");

  useEffect(() => {
    if (
      propTypes.data.length > 0 &&
      Object.prototype.hasOwnProperty.call(propTypes.data[0], "data")
    ) {
      assignData(propTypes.data);
    }
  }, [propTypes.data]);

  const assignData = (val) => {
    settickAmount(val[0].data.length);
    let seriesTemp = [];
    for (let i = 0; i < val.length; i++) {
      seriesTemp.push({
        name: val[i].name,
        data: val[i].data,
      });
      if (tickAmount < val[i].data.length) {
        settickAmount(val[i].data.length);
      }
    }
    setseries(seriesTemp);
  };

  const totalAlert = (data) => {
    return data.reduce(
      (accumulator, currentValue) => accumulator + currentValue.y,
      0
    );
  };

  function toolTipObj() {
    if (propTypes.type === "week") {
      return {
        shared: true,
        intersect: false,
        followCursor: true,
        x: {
          formatter: (val, { seriesIndex, dataPointIndex, w }) => {
            return moment(
              w.config.series[seriesIndex].data[dataPointIndex].x
            ).format("dddd, MMMM D YYYY");
          },
        },
        y: {
          formatter: (value, { series, seriesIndex }) => {
            const total = series[seriesIndex].reduce(
              (acc, cur) => acc + cur,
              0
            );
            return `${value} (${((value / total) * 100).toFixed(2)}%)`;
          },
        },
      };
    } else if (propTypes.type === "daterange") {
      return {
        shared: true,
        intersect: false,
        followCursor: true,
        x: {
          formatter: (val, { seriesIndex, dataPointIndex, w }) => {
            return moment(
              w.config.series[seriesIndex].data[dataPointIndex].x
            ).format("dddd, MMMM D YYYY");
          },
        },
        y: {
          formatter: (value, { series, seriesIndex }) => {
            const total = series[seriesIndex].reduce(
              (acc, cur) => acc + cur,
              0
            );
            return `${value} (${((value / total) * 100).toFixed(2)}%)`;
          },
        },
      };
    } else {
      return {
        shared: true,
        intersect: false,
        followCursor: true,
        theme: false,
        x: {
          formatter: (val) => {
            return moment(val).format("h:mm A");
          },
        },
        y: {
          formatter: (value, { series, seriesIndex }) => {
            const total = series[seriesIndex].reduce(
              (acc, cur) => acc + cur,
              0
            );
            return `${value} (${((value / total) * 100).toFixed(2)}%)`;
          },
        },
      };
    }
  }

  function xaxisForChart() {
    if (propTypes.type === "week") {
      return {
        type: "date",
        labels: {
          formatter: (val) => {
            if (selectedMode === "hourly") {
              return moment(new Date(val)).format("ddd");
            }
            return moment(new Date(val)).format("DD-MM-YY");
          },
        },
        title: {
          text: "Day",
        },
        tooltip: {
          enabled: false,
        },
      };
    } else if (propTypes.type === "daterange") {
      return {
        type: "datetime",
        labels: {
          formatter: (val) => {
            return moment(new Date(val)).format("DD-MM-YY");
          },
        },
        tooltip: {
          enabled: false,
        },
        tickAmount:
          tickAmount === 0 ? 0 : tickAmount === 1 ? 2 : tickAmount - 1,
      };
    } else {
      return {
        type: "datetime",
        tickAmount:
          tickAmount === 0 ? 0 : tickAmount === 1 ? 2 : tickAmount - 1,
        labels: {
          formatter: (val) => {
            if (selectedMode === "hourly") {
              return moment(new Date(val)).format("h A");
            }
            return moment(new Date(val)).format("DD-MM-YY");
          },
          style: {
            colors: "var(--card-text-color)", //change text color to var(--card-text-color)
          },
        },
        tooltip: {
          enabled: false,
        },
        title: {
          text: "",
        },
      };
    }
  }
  const options = {
    chart: {
      id: "line-chart",
      height: 350,
      type: "line",
      stacked: false,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    xaxis: xaxisForChart(),
    // tooltip: toolTipObj(),
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    yaxis: {
      title: {
        text: "",
      },
      labels: {
        show: true,
        style: {
          colors: "var(--card-text-color)", // Change text color to var(--card-text-color)
        },
      },
    },
    stroke: {
      curve: "smooth",
    },
    legend: {
      position: "top",
    },
    colors: ["#4339F2", "#DAD7FE"],
  };
  return (
    <div className="alertchartdiv">
      <div className="chart-div-alert">
        <div
          style={{
            textAlign: "left",
            marginLeft: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <div className="chart-title">
              {totalAlert(propTypes.data[0].data)}
            </div>
            <span className="alert-chart-text">{propTypes.head}</span>
          </div>
        </div>

        <div style={{ marginLeft: "0px" }}>
          <div style={{ overflow: "hidden" }}>
            <Chart options={options} series={series} type="line" height={300} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertLineChart;
