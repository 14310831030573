import React, { useState } from "react";
import UserTable from "./UserTable";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import AddEditUserDialog from "./AddEditUserDialog";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import AccessControlActions from "../../../../redux/Action/AccessControlAction";
const UserTab = () => {
  const [isAddEditMemberOpen, setIsAddEditMemberOpen] = useState(false);
  const [searchUser, SetSearchUser] = useState("");
  const userList = useSelector((state) => state.accessControl.userList);
  const [access_permission] = useState(
    JSON.parse(localStorage.getItem("agx_permission")).Profile ?? []
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (searchUser.length > 0) {
      const filterUser = userList.filter((item) =>
        item.name.toLowerCase().includes(searchUser.toLowerCase())
      );
      dispatch({ type: "SEARCH_USER_LIST", payload: filterUser });
    } else {
      dispatch({ type: "SEARCH_USER_LIST", payload: [] });
    }
  }, [searchUser, userList, dispatch]);
  useEffect(() => {
    dispatch(AccessControlActions.getUserToAccessControlList());
  }, []);
  return (
    <div style={{ marginBottom: "0" }}>
      <div
        className="d-flex justify-content-between align-items-end"
        style={{ marginBottom: "0" }}
      >
        <div className="col-4">
          <TextField
            id="outlined-basic-user"
            label="Search"
            variant="outlined"
            fullWidth
            size="small"
            value={searchUser}
            onChange={(e) => SetSearchUser(e.target.value)}
            style={{ marginBottom: "0" }}
          />
        </div>
        {access_permission.includes("add_user") && (
          <div style={{ paddingBottom: "0" }}>
            <Button
              sx={{
                height: "30px",
                marginRight: "10px",
              }}
              variant="outlined"
              startIcon={<AddIcon />}
              size="small"
              onClick={() => setIsAddEditMemberOpen(true)}
            >
              Add User
            </Button>
            {/* <Button variant="outlined" size="small" sx={{ height: "30px" }}>
              Bulk Action
            </Button> */}
          </div>
        )}
      </div>
      <UserTable searchValue={searchUser} />
      <AddEditUserDialog
        isOpen={isAddEditMemberOpen}
        handleClose={() => setIsAddEditMemberOpen(false)}
        userId={-1}
        isEditable={false}
      />
    </div>
  );
};

export default UserTab;
