import React, { useEffect, useState } from "react";
import { Bars } from "react-loader-spinner";
import PropTypes from "prop-types";
import VideoPlayerIframe from "../../VMS/PlayBack/VideoPlayer/VideoPlayerIframe";
import EventTableAction from "../../../redux/Action/EventTableActions";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AlertEventTableActions from "../../../redux/Action/AlertEventTableActions";
const NoVideoFound = () => {
  return (
    <div>
      <center>
        <VideocamOffIcon sx={{ fontSize: "40px" }} />
      </center>
      <center>
        <p style={{ fontSize: "20px" }}>Video Not Found</p>
      </center>
    </div>
  );
};
function PlaybackModalEvent({ item, show, handleClose }) {
  PlaybackModalEvent.propTypes = {
    handleClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    item: PropTypes.object.isRequired,
  };
  const playback = useSelector(
    (state) => state.AlertTableStates.playbackModalStream
  );
  const dispatch = useDispatch();
  const converDateInformate = (date) => {
    const dateString = date;
    const parts = dateString.split("T")[0].split("-");
    const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
    return formattedDate;
  };
  const [isLoading, setIsLaoding] = useState(true);

  function transformTime(time) {
    setIsLaoding(true);
    const date = new Date(time);

    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");

    let seconds = date.getUTCSeconds();

    let startSec = seconds - 10;
    let startMin = date.getUTCMinutes();
    let starthour = date.getUTCHours();

    let endMin = date.getUTCMinutes();
    let endHour = date.getUTCHours();
    let endSec = seconds + 20;

    if (startSec < 0) {
      startSec += 60;
      startMin -= 1;
      if (startMin < 0) {
        startMin += 60;
        starthour -= 1;
        if (starthour < 0) {
          starthour += 24; // Assuming 24-hour format
        }
      }
    }

    if (endSec > 59) {
      endSec %= 60;
      endMin += 1;
      if (endMin > 59) {
        endMin = 0;
        endHour += 1;
        if (endHour > 23) {
          endHour %= 24; // Assuming 24-hour format
        }
      }
    }

    const Fdate = `${year}-${month}-${day}`;
    const startTime = `${String(starthour).padStart(2, "0")}:${String(
      startMin
    ).padStart(2, "0")}:${String(startSec).padStart(2, "0")}`;

    const endTime = `${String(endHour).padStart(2, "0")}:${String(
      endMin
    ).padStart(2, "0")}:${String(endSec).padStart(2, "0")}`;
    // return [Fdate, startTime, endTime];
    dispatch(
      AlertEventTableActions.getPlaybackStreamAlertEvent({
        cam_id: item.camera[1],
        date: Fdate,
        startTime: startTime,
        endTime: endTime,
      })
    )
      .then(() => setIsLaoding(false))
      .catch(() => setIsLaoding(false));
  }
  useEffect(() => {
    transformTime(item.event_recieved_at);
  }, []);
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={show}
      maxWidth={"md"}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "md",
        },
      }}
    >
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        id="customized-dialog-title"
        className="header-background modal-title-color"
        style={{
          backgroundColor: "#4D3EF8",
          color: "white",
        }}
      >
        {item.event_type
          .replace(/_/g, " ")
          .replace(/\b\w/g, (c) => c.toUpperCase())}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers
        style={{ backgroundColor: "var( --side-bar-bg-color" }}
      >
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex">
            <h5
              className="card-title"
              style={{ fontSize: "24px", margin: "0" }}
            >
              Date :{" "}
            </h5>
            <div className="modal-card-text-value ">
              {item.event_recieved_at
                ? converDateInformate(item.event_recieved_at)
                : ""}
            </div>
          </div>
          <div className="d-flex">
            <h5 className="card-title" style={{ fontSize: "24px" }}>
              Time :{" "}
            </h5>
            <div className="modal-card-text-value">
              {" "}
              {moment.utc(item.event_recieved_at).format("h:mm A")}
            </div>
          </div>
        </div>
        {isLoading ? (
          <div>
            <div className="d-flex justify-content-center">
              <Bars
                height="80"
                width="80"
                color="var(--card-text-color)"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
            <center>
              <p>Fetching Your Video</p>
            </center>
          </div>
        ) : (
          <div style={{ height: "60vh" }}>
            {playback.length > 0 ? (
              <VideoPlayerIframe
                src={playback[0]}
                onClose={handleClose}
                usedIn={"event"}
              />
            ) : (
              <NoVideoFound />
            )}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default PlaybackModalEvent;
