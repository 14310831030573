const initialState = {
  deviceControl: {},
};

export default function DeviceControlReducer(state = initialState, action) {
  switch (action.type) {
    case "DEVICE_CONTROL_DATA":
      return { ...state, deviceControl: action.payload };

    default:
      return state;
  }
}
