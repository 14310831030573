import { React, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import mainCss from "../../assets/css/mainCss";
import "../../assets/css/main.css";
import { useSelector } from "react-redux";
import { changeTopbarTitle } from "../../redux/Action/ChangeTopbarTitle";
import getHeatMapData from "../../pages/Heatmap/HeatMapData";
import HeatMapAction from "../../redux/Action/HeatMapAction";
import Account from "../../redux/Action/CommonAction";
import { MenuItem, Select } from "@mui/material";
import Pagination from "../../components/common/Pagination";

import { useNavigate } from "react-router-dom";
import HeatmapCard from "./HeatmapCard";
const inputselect = {
  boxShadow: "none",
  borderRadius: "8px",
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
  MenuProps: {
    PaperProps: {
      style: {
        backgroundColor: "var(--top-bar-bg-color)",
        maxHeight: 350,
        width: 50,
        overflowX: "auto",
      },
    },
  },
};
const HeatMapDashboard = () => {
  // Step 2
  const state = useSelector((state) => state);
  const [selectedStore, setSelectedStore] = useState({ value: "all" });
  const [selectedDate, setselectedDate] = useState(new Date());

  var profile_id = JSON.parse(localStorage.getItem("profile_id"));
  var profile = JSON.parse(localStorage.getItem("profile"));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState([]);
  function handleCurrentPageData(startIndex, endIndex) {
    setCurrentPageData(
      state.heatMapData.heatMapData.slice(startIndex, endIndex)
    );
  }
  useEffect(() => {
    setCurrentPage(1);
    handleCurrentPageData(0, 24);
  }, [state.heatMapData.heatMapData]);

  const handlePagination = (page) => {
    setCurrentPage(page);
    let startIndex = (page - 1) * 24;
    let endIndex = Math.min(page * 24, state.heatMapData.heatMapData.length);
    handleCurrentPageData(startIndex, endIndex);
  };

  useEffect(() => {
    if (profile.heatmap !== true) {
      navigate("/unauthorized");
    } else {
      dispatch(changeTopbarTitle("HeatMap"));
      Account.getStoresByAccount(profile_id)(dispatch);
    }
    return () => {
      HeatMapAction.dismissHeatMapData()(dispatch);
    };
  }, []);

  const handleDateChange = (date) => {
    setselectedDate(date);
  };

  const handleStoreChange = (event) => {
    setSelectedStore({ value: event.target.value });
    if (event.target.value != "all") {
      setSelectedStore({ value: event.target.value });
    } else {
      HeatMapAction.dismissHeatMapData()(dispatch);
    }
  };

  useEffect(() => {
    getHeatMapData(selectedDate, selectedStore.value, dispatch);
  }, [selectedStore, selectedDate]);

  return (
    <div style={mainCss.divBelowTopbar}>
      <div className="divbelowtopbarsecond">
        {/*Alert Filters start  */}

        <div className="select-input-div card-custom card-stretch gutter-b">
          <div className="card-body-div">
            <div className="row">
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-2 mt-2">
                <div className="select-input-lebel">Date</div>
                <DatePicker
                  className="select-input-field-date"
                  selected={selectedDate}
                  customInput={<input className="custom-input-date-text" />}
                  onChange={handleDateChange}
                  style={{ zIndex: "99 !important" }}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-2 mt-2">
                <div className="select-input-lebel">Facility</div>
                <Select
                  value={selectedStore.value}
                  onChange={handleStoreChange}
                  sx={{
                    color: "var(--card-text-color)",
                    ...inputselect,
                  }}
                  className="select-input-field"
                  MenuProps={inputselect.MenuProps}
                >
                  {state.commonData.storeList.map((item) => {
                    if (item.value === "all") {
                      return (
                        <MenuItem
                          key={item.value}
                          value={item.value}
                          sx={{ color: "var(--card-text-color)" }}
                        >
                          {selectedStore.value === "all"
                            ? "Select"
                            : "Unselect all"}
                        </MenuItem>
                      ); // Skip rendering the item with value "all"
                    }
                    return (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        sx={{ color: "var(--card-text-color)" }}
                      >
                        {item.text}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
            </div>
          </div>
        </div>
        {/*Alert Filters end   */}
      </div>

      <div></div>
      {state.heatMapData.heatMapData.length > 0 ? (
        <div className="divbelowtopbarsecond">
          <div className="row p-3">
            {currentPageData.map((item, index) => (
              <div
                className=" col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12"
                key={index}
              >
                <HeatmapCard item={item} />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <center>
          <div className="heatmap-no-data-to-show">
            <p>SELECT STORE FROM ABOVE</p>
          </div>
        </center>
      )}

      <div className="pagination-div">
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={state.heatMapData.heatMapData.length}
          pageSize={24}
          onPageChange={(page) => handlePagination(page)}
          usedIn="videowall"
        />
      </div>
    </div>
  );
};

export default HeatMapDashboard;
