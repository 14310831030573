import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";

const determineColor = (value) => {
  if (value >= 70) return "success";
  if (value >= 50) return "secondary";
  return "inherit";
};

const FaceRecognitionCircularBar = ({ value }) => {
  const [state, setState] = useState({ similarity: "0", color: "inherit" });

  useEffect(() => {
    const roundedValue = Math.round(value);
    setState({
      similarity: roundedValue,
      color: determineColor(roundedValue),
    });
  }, [value]);

  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        color={state.color}
        variant="determinate"
        value={state.similarity}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${state.similarity}%`}
        </Typography>
      </Box>
    </Box>
  );
};

FaceRecognitionCircularBar.propTypes = {
  value: PropTypes.number.isRequired,
};

export default FaceRecognitionCircularBar;
