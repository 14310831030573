import { BACKEND_URL } from "../../routes/config";

class AlertApiSpec {
  constructor() {
    // get ten Alerst Site

    this.getTopTenAlertSites = (
      account_id,
      date,
      priority,
      alert_type,
      start_time,
      end_time
    ) => {
      return `${BACKEND_URL}/alert_event_merger/site_wise_notification_count/${account_id}/${date}/all?priority=${priority}&start_time=${start_time}&end_time=${end_time}&alert_type=${alert_type}`;
    };
    this.getTopTenAlertSitesDateRange = (account_id, start_date, end_date) => {
      return `${BACKEND_URL}/alerts/dash/daterange/userprofile/${account_id}/${start_date}/${end_date}/`;
    };
    // get ten Alerst Site
    this.getTopTenAlertSitesBySites = (store_ids, date) => {
      return `${BACKEND_URL}/alerts/dash/userprofile/${store_ids}/${date}/`;
    };

    // // get ten site data alerts
    this.getTenSiteAlertsDataByAccount = (account_id, date) => {
      return `${BACKEND_URL}/alerts/dash/userprofile/${account_id}/${date}/`;
    };
    this.getTenSiteAlertsDataByStore = (
      account_id,
      facility_ids,
      date,
      priority,
      alertOrEvent,
      start_time,
      end_time
    ) => {
      return `${BACKEND_URL}/alert_event_merger/site_wise_notification_count/${account_id}/${date}/${facility_ids}?start_time=${start_time}&end_time=${end_time}&priority=${priority}`;
    };

    this.generateEnjayTicket = (data) => {
      return `${BACKEND_URL}/enjay/${data}/`;
    };
    this.getAlertsDataDownloadByAccount = (
      account_id,
      type,
      date,
      priority,
      alertOrEvent
    ) => {
      let userProfileId = JSON.parse(localStorage.getItem("profile")).id;
      if (priority == "all") {
        return `${BACKEND_URL}/alert_event_merger/all_alert_download_userprofile_facility_camera/${account_id}/0/0/${date}/${type}`;
      } else if (priority == "high") {
        return `${BACKEND_URL}/alerts/report-download/userprofile/${account_id}/${type}/${date}/`;
      } else if (priority == "normal") {
        return `${BACKEND_URL}/events/report-download/userprofile/${account_id}/${type}/${date}/`;
      }
    };

    this.getAlertsDataDownloadByStore = (
      store_id,
      type,
      date,
      priority,
      alertOrEvent
    ) => {
      const user_profile_id = JSON.parse(localStorage.getItem("profile")).id;
      if (priority == "all") {
        return `${BACKEND_URL}/alert_event_merger/all_alert_download_userprofile_facility_camera/${user_profile_id}/${store_id}/0/${date}/${type}`;
      } else if (priority == "high") {
        return `${BACKEND_URL}/alerts/report-download/facility/${store_id}/${type}/${date}/${user_profile_id}`;
      } else if (priority == "normal") {
        return `${BACKEND_URL}/events/report-download/facility/${store_id}/${type}/${date}/${user_profile_id}`;
      }
    };

    this.getAlertsDataDownloadByCamera = (
      cam_id,
      type,
      date,
      priority,
      alertOrEvent
    ) => {
      const user_profile_id = JSON.parse(localStorage.getItem("profile")).id;
      if (priority == "all") {
        return `${BACKEND_URL}/alert_event_merger/all_alert_download_userprofile_facility_camera/${user_profile_id}/0/${cam_id}/${date}/${type}`;
      } else if (priority == "high") {
        return `${BACKEND_URL}/alerts/report-download/camera/${cam_id}/${type}/${date}/`;
      } else if (priority == "normal") {
        return `${BACKEND_URL}/events/report-download/camera/${cam_id}/${type}/${date}/`;
      }
    };

    this.getAlertTypeStaticsByAccount = (account_id, date) => {
      return `${BACKEND_URL}/alerts/dash/message_type/userprofile/${account_id}/${date}/`;
    };
    this.getAlertTypeStaticsByFacility = (facility_id, date) => {
      return `${BACKEND_URL}/alerts/dash/message_type/facility/${facility_id}/${date}/`;
    };
    this.getAlertTypeStaticsByCamera = (cam_id, date) => {
      return `${BACKEND_URL}/alerts/dash/message_type/camera/${cam_id}/${date}/`;
    };
    this.sendAlertNotification = (alert_id) => {
      return `${BACKEND_URL}/alerts/sendnotification/${alert_id}/`;
    };
    this.updateAlertInstance = (alert_id) => {
      return `${BACKEND_URL}/alerts/update/${alert_id}/`;
    };
    this.updateEventInstance = (events_id) => {
      return `${BACKEND_URL}/events/update/${events_id}/`;
    };
    this.getPizzaCountByUserProfileFacilityCameras = () => {
      return `${BACKEND_URL}/counting/count/`;
    };
  }
}

export default new AlertApiSpec();
