import React, { useState, useEffect } from "react";
import FaceRecognitionTopMatchTable from "../FaceRecognitionTopMatchTable";
import "../FaceRecognitionModals/AddDetailModal";
import AddDetailModal from "../FaceRecognitionModals/AddDetailModal";
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useSelector } from "react-redux";
import FaceRecognitionActions from "../../../redux/Action/FaceRegnitionAction";
import PropTypes from "prop-types";
import { extractDateTime } from "../utils/FormatDateAndTime";
const PersonUnrecognized = ({ data }) => {
  let formattedDateTime = extractDateTime(data.created_at);
  const [top5list, setTop5List] = useState([]);
  const [loading, setLoading] = useState(false);
  const selectedFacility = useSelector(
    (state) => state.faceRecognition.selectedFacility
  );
  useEffect(() => {
    if (data.uuid) {
      const fetchListData = async () => {
        setLoading(true);
        let listdata = await FaceRecognitionActions.getTop5MatchedUnRecognized(
          data.uuid,
          selectedFacility.value
        );
        setLoading(false);
        setTop5List(listdata);
      };

      fetchListData();
    }
  }, [data]);
  const [addDetailsOpen, setAddDetailsOpen] = React.useState(false);
  const handleAddDetailsOpen = () => setAddDetailsOpen(true);
  const handleClose = () => setAddDetailsOpen(false);

  return (
    <div
      className="col-12  my-3"
      style={{ backgroundColor: "var(--side-bar-bg-color)" }}
    >
      <div
        className="col-12 row card-container-face-rec-page d-flex justify-content-between px-3"
        style={{
          backgroundColor: "var(--side-bar-bg-color)",
          color: "var(--black-white-darkmode)",
          margin: 0,
        }}
      >
        <div className="col-6 col-lg-4">
          <h3>Detection</h3>
          <img
            src={data.image}
            className="img-fluid"
            style={{ height: "90%" }}
          />
        </div>
        <div className="col-12 col-lg-4 mt-4 row">
          <div className="col-12 col-sm-6 col-lg-12">
            <div>
              <span className="event-details-heading">Name:</span>
              <span className="event-details-content">Unknown</span>
            </div>
            <div>
              <span className="event-details-heading">Age:</span>
              <span className="event-details-content">N/A</span>
            </div>

            <div>
              <span className="event-details-heading">Gender :</span>
              <span className="event-details-content">N/A</span>
            </div>
            <div>
              <span className="event-details-heading">Camera :</span>
              <span className="event-details-content">
                {data.camera_id
                  ? data.camera_id.camera_name.replace(/_/g, " ")
                  : "N/A"}
              </span>
            </div>
            <div>
              <span className="event-details-heading">Date:</span>
              <span className="event-details-content">
                {" "}
                {formattedDateTime.date}
              </span>
            </div>
            <div>
              <span className="event-details-heading">Time:</span>
              <span className="event-details-content">
                {formattedDateTime.time}
              </span>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-4 align-self-end d-flex justify-content-end">
          <div></div>
          <div>
            <Button
              variant="contained"
              onClick={handleAddDetailsOpen}
              startIcon={<AddCircleOutlineIcon />}
            >
              Add Details
            </Button>
            <AddDetailModal
              isOpen={addDetailsOpen}
              handleClose={handleClose}
              isEditable={false}
              faceData={null}
            />
          </div>
        </div>
      </div>
      <div className="col-12">
        <FaceRecognitionTopMatchTable
          listData={top5list}
          loading={loading}
          usedIn="unrecognized"
        />
      </div>
    </div>
  );
};
PersonUnrecognized.propTypes = {
  data: PropTypes.object.isRequired,
};
export default PersonUnrecognized;
