import axios from "axios";
import ApiSpec from "../features/EndPoints";

// get DevoceControl Data

export const getDeviceControlData = () => {
  return async (dispatch) => {
    var token = localStorage.getItem("token");
    let headers = {
      Authorization: `Token ${token}`,
    };
    axios.get(ApiSpec.getuserprofile, { headers }).then((response) => {
      dispatch({ type: "DEVICE_CONTROL_DATA", payload: response.data });
    });
  };
};

// activate Device by Dash

export const activateDeviceControl = () => {
  return async (dispatch) => {
    var token = localStorage.getItem("token");
    let headers = {
      Authorization: `Token ${token}`,
    };
    axios.get(ApiSpec.getuserprofile, { headers }).then((response) => {
      dispatch({ type: "DEVICE_CONTROL_DATA", payload: response.data });
    });
  };
};
export const getDeviceStatus = () => {
  return async (dispatch) => {
    var token = localStorage.getItem("token");
    const profile = JSON.parse(localStorage.getItem("profile"));
    let headers = {
      Authorization: `Token ${token}`,
    };
    await axios
      .get(ApiSpec.getDeviceStatusData(profile.id), { headers })
      .then((response) => {
        dispatch({ type: "DEVICE_STATUS_DATA", payload: response.data });
      });
  };
};
