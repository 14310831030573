import React, { useEffect, useState } from "react"; // Corrected import to include useEffect and useState
import PropTypes from "prop-types";
import DashboardFunction from "../DashboardFunctions";
import DashBarChart from "../../../components/charts/DashBarChart";
import { DashWeekBarChart } from "../../../components/charts/DashWeekBarChart"; // Corrected import (assuming it's a default export)
import { useSelector } from "react-redux"; // Assuming you are using Redux for state management

const OccupancyChart = ({ selectedMode }) => {
  const [profile, setProfile] = useState({}); // Initialize profile state
  const [accessPermission, setAccessPermission] = useState([]); // Initialize access permissions state

  // Assuming retailData is part of your Redux state
  const retailData = useSelector((state) => state.retailData);

  useEffect(() => {
    // Fetch and set profile from localStorage once to avoid multiple accesses
    const storedProfile = JSON.parse(localStorage.getItem("profile") || "{}");
    setProfile(storedProfile);

    // Fetch and set access permissions from localStorage once
    const storedPermissions =
      JSON.parse(localStorage.getItem("agx_permission") || "{}").Dashboard ||
      [];
    setAccessPermission(storedPermissions);
  }, []); // Empty dependency array means this runs once after the initial render

  return (
    <div>
      {profile.services_selected?.Dashboard.includes("Occupancy") &&
      accessPermission.includes("view_occupancy_analytics") ? (
        <div className="row">
          {selectedMode.value === "day-wise" ? (
            <div className="col-xxl-12">
              <DashBarChart
                classNameActive={true}
                data={DashboardFunction.PeopleCountInOutGraphData(
                  retailData.peopleCountHourly
                )}
                type="hourly"
                title="People Count In & People Count Out"
              />
            </div>
          ) : (
            <div className="col-xxl-12">
              <DashWeekBarChart
                classNameActive={true}
                data={DashboardFunction.specificDayGraphDataInOut(
                  retailData.peopleCountDayData
                )}
                xlabel="date"
                title="People Count In & People Count Out"
              />
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

OccupancyChart.propTypes = {
  selectedMode: PropTypes.object.isRequired,
};

export default OccupancyChart;
