import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";

const Textfield = ({
  label,
  value,
  onChange,
  type,
  placeholder,
  name,
  isrequired,
}) => {
  return (
    <div style={{ width: "100%" }}>
      <TextField
        name={name}
        id={`outlined-basic-${label}-${value}`}
        type={type}
        label={label}
        variant="outlined"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        sx={{ width: "100%" }}
        required={isrequired}
      />
    </div>
  );
};
Textfield.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.any,
  name: PropTypes.any,
  isrequired: PropTypes.bool.isRequired,
};
export default Textfield;
