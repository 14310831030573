import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
  Typography,
} from "@mui/material";
import StoreIcon from "@mui/icons-material/Store";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import NvrOptions from "./VmsSidebarNvrOptions";
import { useSelector, useDispatch } from "react-redux";
const FacilityOptions = ({ item }) => {
  const [open, setOpen] = useState(false);
  const activeView = useSelector((state) => state.vms.activeView);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
          <StoreIcon color="secondary" />
        </ListItemIcon>
        <ListItemText
          primary={item.facility_name}
          sx={{ margin: 0 }}
          primaryTypographyProps={{
            fontSize: 14,
            color: "secondary",
          }}
        />
        {open ? (
          <ExpandLess color="secondary" />
        ) : (
          <ExpandMore color="secondary" />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {item.nvr_list.length > 0 ? (
            item.nvr_list.map((nvr) => (
              <NvrOptions key={nvr.nvr_id} nvr={nvr} />
            ))
          ) : (
            <ListItemText
              sx={{ pl: 8 }}
              secondary={
                activeView === "playback"
                  ? "NO NVR Support Playback"
                  : "No NVR available"
              }
            ></ListItemText>
          )}
        </List>
      </Collapse>
    </>
  );
};

FacilityOptions.propTypes = {
  item: PropTypes.shape({
    facility_id: PropTypes.number.isRequired,
    facility_name: PropTypes.string.isRequired,
    nvr_list: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
};
export default FacilityOptions;
