import React, { useState } from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import FaceRecognitionCircularBar from "./persondetailspagecomponents/FaceRecognitionCircularBar";
import PropTypes from "prop-types";
import PersonDetailViewModal from "./FaceRecognitionModals/PersonDetailViewModal";
const FaceRecognitionTopMatchTable = ({ listData, loading, usedIn }) => {
  console.log(loading);
  const [isModalOpen, setModalOpen] = useState(false);
  const [data, setData] = useState(null);
  return (
    <div
      className="col-12"
      style={{
        backgroundColor: "var(--side-bar-bg-color)",
        color: "var(--black-white-darkmode)",
      }}
    >
      <h2 className="my-4">Top Matches</h2>
      <table className="table face-rec-details-table mx-auto">
        <thead>
          <tr>
            <th className="face-rec-heading text-center">Photo</th>
            <th className="face-rec-heading text-center">Similarity %</th>
            <th className="face-rec-heading text-center">Name</th>
            {usedIn == "recognized" && (
              <th className="face-rec-heading text-center">Details</th>
            )}
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td
                className="text-center pt-4"
                colSpan={usedIn == "recognized" ? 4 : 3}
                style={{
                  backgroundColor: "var(--side-bar-bg-color)",
                  color: "var(--black-white-darkmode)",
                }}
              >
                Loading Data ...
              </td>
            </tr>
          ) : listData.length > 0 ? (
            listData.map((item, index) => (
              <tr key={index}>
                <td
                  className="text-center"
                  style={{
                    backgroundColor: "var(--side-bar-bg-color)",
                    color: "var(--black-white-darkmode)",
                  }}
                >
                  <img
                    src={item.image_url}
                    alt="Match"
                    style={{
                      width: "50px",
                      maxHeight: "80px",
                      objectFit: "fill",
                    }}
                  />
                </td>
                <td
                  className="text-center pt-4"
                  style={{
                    backgroundColor: "var(--side-bar-bg-color)",
                    color: "var(--black-white-darkmode)",
                  }}
                >
                  <FaceRecognitionCircularBar value={item.score * 100} />
                </td>
                <td
                  className="text-center pt-4"
                  style={{
                    backgroundColor: "var(--side-bar-bg-color)",
                    color: "var(--black-white-darkmode)",
                  }}
                >
                  {usedIn == "recognized" ? item.metadata.name : "N/A"}
                </td>
                {usedIn == "recognized" && (
                  <td
                    className="text-center pt-4"
                    style={{
                      backgroundColor: "var(--side-bar-bg-color)",
                      color: "var(--black-white-darkmode)",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => {
                        setModalOpen(true), setData(item);
                      }}
                    >
                      View
                    </Button>
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td
                className="text-center pt-4"
                colSpan={usedIn == "recognized" ? 4 : 3}
                style={{
                  backgroundColor: "var(--side-bar-bg-color)",
                  color: "var(--black-white-darkmode)",
                }}
              >
                No Data Found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {data && isModalOpen && (
        <PersonDetailViewModal
          isOpen={isModalOpen}
          handleClose={() => setModalOpen(false)}
          data={{
            image_url: data.image_url,
            name: data.metadata.name,
            gender: data.metadata.gender,
            created_at: data.metadata.created_at,
            age: data.metadata ? data.metadata.age : "",
            tag: data.metadata ? data.metadata.tag : [],
          }}
        />
      )}
    </div>
  );
};
FaceRecognitionTopMatchTable.propTypes = {
  listData: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  usedIn: PropTypes.string.isRequired,
};
export default FaceRecognitionTopMatchTable;
