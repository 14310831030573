import { React, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import moment from "moment";
import { styled } from "@mui/material/styles";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 15,
  },
}));

const DashBarChart = (propTypes) => {
  const [tickAmount, settickAmount] = useState(0);
  const [series, setseries] = useState([]);
  const [selectedMode, setselectedMode] = useState("hourly");
  const [dataLabelsEnabled, setDataLabelsEnabled] = useState(false);
  const [dataPointTooltipText, setDataPointTooltipText] =
    useState("Show data points");
  useEffect(() => {
    if (
      propTypes.data &&
      propTypes.data.length > 0 &&
      Object.prototype.hasOwnProperty.call(propTypes.data[0], "data")
    ) {
      assignData(propTypes.data);
    }
  }, [propTypes.data]);

  const assignData = (val) => {
    // settickAmount(val[1].data.length);
    settickAmount(val[0].data.length);
    let seriesTemp = [];
    for (let i = 0; i < val.length; i++) {
      seriesTemp.push({
        name: val[i].name,
        data: val[i].data,
      });
      if (tickAmount < val[i].data.length) {
        settickAmount(val[i].data.length);
      }
    }
    setseries(seriesTemp);
  };

  function toolTipObj() {
    if (propTypes.type === "week") {
      return {
        shared: true,
        intersect: false,
        followCursor: true,
        x: {
          formatter: (val, { seriesIndex, dataPointIndex, w }) => {
            return moment(
              w.config.series[seriesIndex].data[dataPointIndex].x
            ).format("dddd, MMMM D YYYY");
          },
        },
        y: {
          formatter: (value, { series, seriesIndex }) => {
            const total = series[seriesIndex].reduce(
              (acc, cur) => acc + cur,
              0
            );
            // return `${value} (${((value / total) * 100).toFixed(2)}%)`;
            var percent = ((value / total) * 100).toFixed(2);
            if (isNaN(percent)) {
              percent = (0).toFixed(2);
            }
            return `${value} (${percent}%)`;
          },
        },
      };
    } else {
      return {
        shared: true,
        intersect: false,
        followCursor: true,
        x: {
          formatter: (val) => {
            return moment(val).format("MMMM D YYYY, h:mm A");
          },
        },
        y: {
          formatter: (value, { series, seriesIndex }) => {
            const total = series[seriesIndex].reduce(
              (acc, cur) => acc + cur,
              0
            );
            // return `${value} (${((value / total) * 100).toFixed(2)}%)`;
            var percent = ((value / total) * 100).toFixed(2);
            if (isNaN(percent)) {
              percent = (0).toFixed(2);
            }
            return `${value} (${percent}%)`;
          },
        },
      };
    }
  }

  const dataPointsToggle = (ev) => {
    const check = ev.currentTarget.checked;
    setDataLabelsEnabled(check);
    setDataPointTooltipText(check ? "Hide data points" : "Show data points");
  };

  function xaxisForChart() {
    if (propTypes.type === "week") {
      return {
        type: "numeric",
        labels: {
          formatter: (val) => {
            if (selectedMode === "hourly") {
              return moment(new Date(val)).format("ddd");
            }
            return moment(new Date(val)).format("DD-MM-YY");
          },
          style: {
            colors: "var(--card-text-color)", //change text color to var(--card-text-color)
          },
        },
        text: dataLabelsEnabled ? "Day" : "",
        style: {
          fontSize: "16px", // Set the font size
          fontFamily: "Roboto", // Set the font family
          fontWeight: "bold", // Set the font weight
          color: "var(--card-text-color)", // Set the font color
        },
        tooltip: {
          enabled: false,
        },
      };
    } else {
      return {
        type: "datetime",
        tickAmount:
          window.innerWidth < 520
            ? 10
            : tickAmount === 0
            ? 0
            : tickAmount === 1
            ? 2
            : tickAmount - 1,
        labels: {
          formatter: (val) => {
            if (selectedMode === "hourly") {
              return moment(new Date(val)).format("h A");
            }
            return moment(new Date(val)).format("DD-MM-YY");
          },
          offsetX: 0,
          offsetY: 0,
          style: {
            colors: "var(--card-text-color)", //change text color to var(--card-text-color)
          },
        },
        title: {
          text: dataLabelsEnabled ? "Time" : "",
          style: {
            fontSize: "16px", // Set the font size
            fontFamily: "Roboto", // Set the font family
            fontWeight: "bold", // Set the font weight
            color: "var(--card-text-color)", // Set the font color
          },
        },
        tooltip: {
          enabled: false,
        },
      };
    }
  }

  const options = {
    chart: {
      type: "bar",
      height: 430,
      stacked: false,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },

    labels: {
      show: true,
      formatter: function (val) {
        return val + "%";
      },
    },
    colors:
      propTypes.data.length == 1
        ? ["#4339F2"]
        : ["#C4C1E4", "#4339F2", "#1BC5BD"],
    plotOptions: {
      bar: {
        dataLabels: {
          position: "top",
        },
        columnWidth: "70%",
      },
    },
    dataLabels: {
      enabled: dataLabelsEnabled,
      offsetY: -15,
      style: {
        fontSize: "14px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: "bold",
        colors: undefined,
      },
      background: {
        enabled: true,
        padding: 5,
        borderWidth: 1,
        borderRadius: 0,
        borderColor: "#fff",
      },
    },
    legend: {
      position: "top",
      onItemClick: {
        toggleDataSeries: false,
      },
      onClick: function (event, chartContext, seriesIndex) {
        // Update the tickAmount here
        settickAmount(chartContext.w.globals.series[seriesIndex].length);
      },
      labels: {
        colors: "var(--card-text-color)",
      },
      markers: {
        radius: 12,
        width: 27,
        height: 13,
      },
    },

    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        left: 20,
        right: 40,
      },
    },
    markers: {
      shape: "circle",
      position: "top",
      toolbar: {
        show: false,
      },
    },
    yaxis: {
      title: {
        text: dataLabelsEnabled ? propTypes.labelToShowY : "",
        style: {
          fontSize: "16px", // Set the font size
          fontFamily: "Roboto", // Set the font family
          fontWeight: "bold", // Set the font weight
          color: "var(--card-text-color)", // Set the font color
        },
      },
      labels: {
        style: {
          colors: "var(--card-text-color)", //change text color to var(--card-text-color)
        },
      },
    },
    tooltip: toolTipObj(),
    xaxis: xaxisForChart(),
  };

  return (
    <div className={propTypes.classNameActive ? "chart-container" : null}>
      <div className={propTypes.classNameActive ? "chart-div" : null}>
        <div className="chart-div">
          <p
            className="chart-title"
            style={{ textAlign: "left", marginLeft: "20px" }}
          >
            {propTypes.title}
          </p>
          <div
            className="chart-title"
            style={{ textAlign: "right", marginLeft: "20px" }}
          >
            <span className="d-block text-dark font-weight-bolder">
              <label className="checkbox checkbox-lg checkbox-single flex-shrink-0 m-0 mx-4 checkbox-light-secondary">
                <LightTooltip placement="top" title={dataPointTooltipText}>
                  <input
                    className="chart-checkbox"
                    type="checkbox"
                    onChange={dataPointsToggle}
                  />
                </LightTooltip>

                <span className="checkbox-icon"></span>
              </label>
            </span>
          </div>
          <div style={{ overflow: "hidden" }}>
            <Chart
              options={options}
              series={series}
              type="bar"
              height={400}
              style={{ margin: "0 auto" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashBarChart;
