import React from "react";
import Profile from "../Profile/Profile";

const HomePage = () => {
  return (
    <div>
      <Profile />{" "}
    </div>
  );
};

export default HomePage;
