import React, { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import OnboardingActions from "../../redux/Action/OnboardingActions";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  notifiToasterSuccess,
  notifiToasterWarn,
} from "../../components/common/UseToast";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const OnboardingOrganization = () => {
  const [formData, setFormData] = useState({
    accountName: "",
    primaryEmail: "",
    secondaryEmail: "",
    gstin: "",
    pan: "",
    headOfficeAddress: "",
    telephoneNumber: "",
    logo: "Agrex",
    profileImage: "",
    isClient: false,
    isOperation: false,
    isActive: true,
  });
  const activeStep = useSelector((state) => state.onbaording.currentStep);
  // const [selectedFile, setSelectedFile] = useState(null);
  const dispatch = useDispatch();
  const [isEditable, setIsEditable] = useState(false);
  const [id, setId] = useState(-1);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const [fileInputKey, setFileInputKey] = useState(Date.now());
  const [newError, setNewError] = useState({
    accountName: "",
    email: "",
    phone: "",
  });
  const removeSelectedImage = () => {
    setImagePreview(null); // Remove image preview
    setSelectedFile(null);
    setFileInputKey(Date.now()); // Reset the key to force clear the file input
    localStorage.removeItem("profileImage"); // Remove image from localStorage
    console.log("File removed");
  };
  const handleBoxClick = () => {
    fileInputRef.current.click();
    console.log("Added");
    console.log(fileInputRef.current.files);
    fileInputRef.current.files = null;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        localStorage.setItem("profileImage", base64String); // Save image as Base64 string
        setImagePreview(base64String); // For image preview using Base64
      };
      reader.readAsDataURL(file);
    } else {
      notifiToasterWarn("Please select a valid image file (JPEG or PNG).");
      event.target.value = null; // Reset the input
    }
  };

  const handleClick = async () => {
    const isUser = JSON.parse(localStorage.getItem("onboardUser") || "null");
    const user_id = isUser?.userId;

    //checking is the account created before
    if (!user_id) {
      notifiToasterWarn("User not found. Please create the user first.");
      dispatch({ type: "updateCurrentStep", payload: 0 });
      return;
    }
    const config = {
      client: "False",
      Operation: "False",
    };
    const newError = { accountName: "", email: "", phone: "" };
    let valid = true;

    // Validate organization name
    if (!formData.accountName || formData.accountName.length <= 2) {
      valid = false;
      newError.accountName = "Please enter the Organization name";
    }

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!formData.primaryEmail || !emailPattern.test(formData.primaryEmail)) {
      valid = false;
      newError.email = "Enter a valid email address";
    }

    // Check for file selection unless it's editable
    if (!selectedFile && !isEditable) {
      valid = false;
      alert("Please select a file first!");
    }

    // Validate phone number
    const phoneNumberPattern = /^\d{10}$/;
    if (
      !formData.telephoneNumber ||
      !phoneNumberPattern.test(formData.telephoneNumber)
    ) {
      valid = false;
      newError.phone = "Enter a 10-digit phone number";
    }

    if (!valid) {
      setErrors(newError);
      return;
    } else {
      setErrors(newError);
    }

    // Prepare form data
    const accountData = new FormData();
    if (selectedFile) {
      accountData.append("profile_image", selectedFile);
    }
    accountData.append("name", formData.accountName);
    accountData.append("is_active", true);
    accountData.append("primary_email_address", formData.primaryEmail);
    accountData.append("telephone_number", formData.telephoneNumber);
    accountData.append("config", JSON.stringify(config));
    const onboardUser = localStorage.getItem("onboardUser");
    if (onboardUser) {
      accountData.append("user_id", JSON.parse(onboardUser).userId);
    }

    try {
      if (isEditable) {
        const onboardAccount = localStorage.getItem("onboardAccount");
        if (onboardAccount) {
          const acc_id = JSON.parse(onboardAccount).account_id;
          await OnboardingActions.updateAccountInfo(acc_id, accountData);
        }
      } else {
        await OnboardingActions.addAccountOnboarding(accountData);
        localStorage.setItem("completedStep", 1);
      }
      dispatch({ type: "updateCurrentStep", payload: activeStep + 1 });
    } catch (error) {
      console.error("Error processing account info:", error);
    }
  };

  useEffect(() => {
    try {
      const storedData = localStorage.getItem("onboardAccount");
      if (storedData) {
        const data = JSON.parse(storedData);
        setFormData((formData) => ({
          ...formData,
          accountName: data.name,
          primaryEmail: data.primary_email_address,
          telephoneNumber: data.telephone_number,
        }));
        setIsEditable(true);
      }
      const storedImage = localStorage.getItem("profileImage");
      if (storedImage) {
        setImagePreview(storedImage);
      }
    } catch (error) {
      console.error("Failed to load data from localStorage", error);
    }
  }, []);

  return (
    <div className="container">
      {!isEditable && (
        <div>
          <h2 className="mt-2 mb-4">
            {!isEditable ? "Add Organization Details:" : "Organization Details"}
          </h2>
        </div>
      )}
      <div className="row">
        <div className="col-4">
          <div className="form-group col-12  mb-3">
            <TextField
              id="accountName"
              label="Organization Name"
              variant="outlined"
              fullWidth
              type="text"
              name="accountName"
              value={formData.accountName}
              onChange={handleChange}
              error={Boolean(errors.accountName)}
              helperText={errors.accountName}
              required
            />
          </div>
          <div className="form-group col-12  mb-3">
            <TextField
              id="primaryEmail"
              label="Email"
              variant="outlined"
              fullWidth
              type="email"
              name="primaryEmail"
              value={formData.primaryEmail}
              onChange={handleChange}
              error={Boolean(errors.email)}
              helperText={errors.email}
            />
          </div>
          <div className="form-group col-12  mb-3">
            <TextField
              id="telephoneNumber"
              label="Phone Number"
              variant="outlined"
              fullWidth
              type="text"
              name="telephoneNumber"
              value={formData.telephoneNumber}
              onChange={handleChange}
              error={Boolean(errors.phone)}
              helperText={errors.phone}
            />
          </div>
        </div>
        <div className="form-group col-12 col-sm-6 col-lg-6 mb-3 d-flex justify-content-center position-relative">
          {imagePreview ? (
            <div className="image-preview-container">
              <img src={imagePreview} alt="Preview" style={{ width: "100%" }} />
              <Button
                variant="contained"
                color="error"
                onClick={removeSelectedImage}
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  margin: "10px",
                }}
              >
                <CancelIcon />
              </Button>
            </div>
          ) : (
            <Box className="file-upload-container" onClick={handleBoxClick}>
              <UploadFileIcon
                sx={{
                  fontSize: "70px",
                  fontWeight: "normal",
                  color: "gray",
                  marginBottom: "30px",
                }}
              />
              <h6 style={{ textAlign: "center" }}>
                Select a file to Upload Profile Image
              </h6>
              <p>JPG, PNG Files, Size Not more than 10MB</p>
            </Box>
          )}
          <VisuallyHiddenInput
            key={fileInputKey} // Use the state key here
            ref={fileInputRef}
            type="file"
            onChange={handleFileChange}
          />
        </div>
        <div className="submit-button-onboarding">
          <Button
            variant="contained"
            onClick={loading ? null : handleClick}
            disabled={loading}
            sx={{ minWidth: "100px" }}
          >
            {loading ? (
              <CircularProgress sx={{ color: "white" }} />
            ) : isEditable ? (
              "Update Details"
            ) : (
              "Add Account"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OnboardingOrganization;
