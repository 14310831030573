import React from "react";
import { useDispatch } from "react-redux";
import AlertTableActions from "../../redux/Action/AlertTableActions";
import { useEffect } from "react";
const CommonToolAction = () => {
  const dispatch = useDispatch();

  return <div></div>;
};

export default CommonToolAction;
