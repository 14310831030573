import { BACKEND_URL } from "../../routes/config";

class AlertEventMergedEndpoints {
  constructor() {
    this.getAllAlertEventMerged = (user_profile_id) => {
      return `${BACKEND_URL}/alert_event/type?user_profile_id=${user_profile_id}`;
    };
    this.getAllNotificationList = () => {
      return `${BACKEND_URL}/alert_event_merger/alert_event_merger/`;
    };

    this.getMergedTableDataUsingParams = () => {
      return `${BACKEND_URL}/alert_event_merger/merged_response_by_all`;
    };
    this.yumfoodChartAnalysis = (userprofile_id, start_date, end_date) => {
      return `${BACKEND_URL}/events/yum-foods/userprofile/event-count/hourly/${userprofile_id}/${start_date}/${end_date}`;
    };
    // this api endpoints is used to fetch data of both alert and event and alert, events, by userprofile,facility,camera, day and daterange for show in graphs in dashboard
    this.getAlertEventMergedDataForDashboard = (
      graph_type,
      priority,
      alertOrEvent
    ) => {
      if (priority == "all" && alertOrEvent == "all")
        return `${BACKEND_URL}/alert_event_merger/merged_response_dashboard/${graph_type}`;
      else if (priority == "high" || alertOrEvent == "alert") {
        return `${BACKEND_URL}/alert_event_merger/dashboard_alerts_by_userprofile_facility_camera_day_daterange_weekly/${graph_type}`;
      } else if (priority == "normal" || alertOrEvent == "event") {
        return `${BACKEND_URL}/alert_event_merger/dashboard_events_by_userprofile_facility_camera_day_daterange_weekly/${graph_type}`;
      }
    };

    this.getTotalAlertEvent = (params) => {
      if (params.camera_id) {
        return `${BACKEND_URL}/alert_event_merger/combined_notification_weekly/${params.user_profile_id}/0/${params.camera_id}/${params.start_date}`;
      } else if (params.facility_id) {
        return `${BACKEND_URL}/alert_event_merger/combined_notification_weekly/${params.user_profile_id}/${params.facility_id}/0/${params.start_date}`;
      } else
        return `${BACKEND_URL}/alert_event_merger/combined_notification_weekly/${params.user_profile_id}/0/0/${params.start_date}`;
    };
    this.getTop7SiteAlertEvent = () => {
      return `${BACKEND_URL}/alert_event_merger/top7facilitydaydaterange`;
    };
    this.getAlertEventTypeClassification = () => {
      return `${BACKEND_URL}/alert_event_merger/alert_event_count_by_type_consolidated`;
    };
  }
}

export default new AlertEventMergedEndpoints();
