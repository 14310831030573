import { BACKEND_URL } from "../../routes/config";

class AnanlyticsDashEndpoints {
  constructor() {
    //this  api will return the people count consildated based on params
    this.getPeopleCountForFacilityCameraUserDayDateRange = () => {
      return `${BACKEND_URL}/peoplecount/people_count_daily_and_daterange_by_all`;
    };
    //this  api will return the weekly people count consildated based on params
    this.getWeeklyAnalyticsPeopleCount = () => {
      return `${BACKEND_URL}/peoplecount/people_count_weekly_weekday_3weekly_by_all`;
    };
    //this  api will return the individual and group count consildated based on params of people count
    this.getGroupCountOfPeopleCount = () => {
      return `${BACKEND_URL}/peoplecount/group_count_by_all`;
    };
    //this  api will return the passerby count consildated based on params
    this.getPasserByCountForFacilityCameraUserDayDateRange = () => {
      return `${BACKEND_URL}/passer/passersby_by_all`;
    };
    //this  api will return the weekly passer count consildated based on params
    this.getWeeklyAnalyticsPasserByCount = () => {
      return `${BACKEND_URL}/passer/passerby_weekly_weekday_3weekly_by_all`;
    };
    //this  api will return the demographics data for people count and passer count consildated based on params
    this.getDemographicsDataAll = () => {
      return `${BACKEND_URL}/demographic/all_demographics_hourly_daterange_combined`;
    };
    //this api will return the weekly demographics data for people count consildated based on params
    this.getDemographicsWeeklyData = () => {
      return `${BACKEND_URL}/demographic/all_mvf_weekly_by_device_facility_userprofile`;
    };
    //this api will return the dwell time data for people count consildated based on params
    this.getDwellTimeDataByUserProfileFacilityDevice = () => {
      return `${BACKEND_URL}/dwell-time/userprofile_facility_device_day_daterange_weekly`;
    };
    //this api will return the dwell time data for people count consildated based on params for nicobar only
    this.getDwellTimeDataByUserProfileFacilityDeviceForNicobar = () => {
      return `${BACKEND_URL}/dwell-time/calc_dwell_time_device_facility_userprofile`;
    };
  }
}

export default new AnanlyticsDashEndpoints();
