export const tableTheme = {
  MuiTableContainer: {
    styleOverrides: {
      root: {
        borderRadius: "10px",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        backgroundColor: "var( --side-bar-bg-color)",
      },
    },
  },
};
export const tableHeadTheme = {
  MuiTableHead: {
    styleOverrides: {
      root: {
        borderRadius: "10px",
        backgroundColor: "#425CDF",
        position: "relative",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        zIndex: "1", // Ensure it's above the TableBody
        "&:after": {
          content: '""',
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 0,
          height: "10px",
          boxShadow: "0 5px 5px -5px rgba(0, 0, 0, 0.5)",
          zIndex: "-1", // Ensures it does not overlap the table content
        },
        "& .MuiTableCell-root": {
          // This targets TableCell within TableHead
          color: "white", // Set text color to white
          borderBottom: "none",
        },
        "& .MuiTableCell-root:first-of-type": {
          borderBottomLeftRadius: "10px",
        },
        "& .MuiTableCell-root:last-child": {
          borderBottomRightRadius: "10px",
        },
      },
    },
  },
};

export const tableCellTheme = {
  MuiTableCell: {
    styleOverrides: {
      root: {
        color: "var(--black-white-darkmode)",
        fontSize: "14px",
      },
      "& span": {
        fontSize: "14px",
      },
    },
  },
};
export const tableRowTheme = {
  MuiTableRow: {
    styleOverrides: {
      root: {},
    },
  },
};
