import axios from "axios";
import AnalyticsDashboardEndpoints from "../features/AnalyticsDashboardEndpoints";
import buildUrlWithParams from "../../redux/Action/AddUrlsWIthParams";
function getDispatchType(week_range) {
  if (week_range == "three_weekly") return "THREE_WEEK_PASSER_COUNT_DATA";
  else if (week_range == "weekday") return "WEEKDAY_PASSER_COUNT_DATA";
  else if (week_range == "weekly") return "WEEKLY_PASSER_COUNT_DATA";
}
class PasserCountAnctions {
  /**
   * Triggers an asynchronous API call to fetch passerby  data analytics, including date wise and hourly
   * information for user profiles, facilities, and devices. It dispatches actions to manage the loading state
   * and to handle the response data or errors accordingly.
   *
   * @param {Object} params Parameters to be sent with the API call, including the date,ids, and any filters.
   * @returns {Function} A thunk action that can be dispatched by Redux's `dispatch` method.
   */
  static getPasserbyCountData(params) {
    return async (dispatch) => {
      dispatch({ type: "UPDATE_LOADING_FOR_PASSERBY", payload: true });
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };

      const urls =
        AnalyticsDashboardEndpoints.getPasserByCountForFacilityCameraUserDayDateRange();
      axios
        .get(buildUrlWithParams(params, urls), { headers })
        .then((response) => {
          dispatch({
            type:
              params.start_date === params.end_date
                ? "PASSER_COUNT_HOURLY_DATA"
                : "PASSER_COUNT_DAY_DATA",
            payload: response.data.queryset,
            date: params.start_date,
          });
          dispatch({
            type: "UPDATE_LOADING_FOR_PASSERBY",
            payload: false,
          });
        })
        .catch(() => {
          dispatch({ type: "UPDATE_LOADING_FOR_PASSERBY", payload: false });
        });
    };
  }
  /**
   * Fetches the data for weekly data for passer by count
   * The parameters include user profile id, facility id, camera id, date etc  .
   * If an error occurs, or the response is not in the provisioned format, the function
   * stops the loading and dispatches an action with a predefined structure.
   *
   * @param {object} params - Parameters used to form the URL for the data request.
   * @return {function} - A dispatch function that performs async data fetch and state update
   */
  static getweeklyDataAnalyticsForPasserbyCount(params) {
    return async (dispatch) => {
      dispatch({ type: "UPDATE_LOADING_FOR_PEOPLE_COUNT", payload: true });
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      const urls =
        AnalyticsDashboardEndpoints.getWeeklyAnalyticsPasserByCount();
      axios
        .get(buildUrlWithParams(params, urls), { headers })
        .then((response) => {
          dispatch({
            type: getDispatchType(params.week_range),
            payload: response.data,
            // date: params.date,
          });
          dispatch({
            type: "UPDATE_LOADING_FOR_PEOPLE_COUNT",
            payload: false,
          });
        })
        .catch(() => {
          dispatch({ type: "UPDATE_LOADING_FOR_PEOPLE_COUNT", payload: false });
        });
    };
  }
}
export default PasserCountAnctions;
