import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";

const ConfirmationDialog = ({
  title,
  dialogContent,
  handleConfirm,
  handleDisconfirm,
  isOpen,
  handleClose,
  confirmButtonLabel,
  disconfirmButtonLabel,
  confirmButtonVariant,
  disconfirmButtonVariant,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className="p-3">
        <DialogContent className="px-5">
          <div className="text-center">
            {/* Use Typography for consistent text styling */}
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              {title}
            </Typography>
          </div>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: "var(--black-white-darkmode)" }}
            className="text-center"
          >
            <Typography variant="body1">{dialogContent}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          {/* Button for Confirm Action */}
          <Button
            onClick={handleConfirm}
            variant={confirmButtonVariant}
            sx={{ paddingX: "30px" }}
          >
            {confirmButtonLabel}
          </Button>
          {/* Button for Disconfirm (Cancel) Action */}
          <Button
            onClick={handleDisconfirm}
            variant={disconfirmButtonVariant}
            sx={{ paddingX: "30px" }}
          >
            {disconfirmButtonLabel}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

// PropTypes for type-checking and documentation
ConfirmationDialog.propTypes = {
  title: PropTypes.string.isRequired,
  dialogContent: PropTypes.string.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleDisconfirm: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  confirmButtonLabel: PropTypes.string,
  disconfirmButtonLabel: PropTypes.string,
  confirmButtonVariant: PropTypes.string,
  disconfirmButtonVariant: PropTypes.string,
};

// Default props for optional values
ConfirmationDialog.defaultProps = {
  confirmButtonLabel: "Yes",
  disconfirmButtonLabel: "No",
  confirmButtonVariant: "contained",
  disconfirmButtonVariant: "outlined",
};

export default ConfirmationDialog;
