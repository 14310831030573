// Define a function to safely parse JSON from local storage
const safeParse = (json, defaultValue) => {
  try {
    return JSON.parse(json) || defaultValue;
  } catch {
    return defaultValue;
  }
};

// Try to load the current step from local storage or default to 0 if not found
const loadInitialState = () => {
  return {
    facilitiesList: [],
    cameraList: [],
    nvrList: [],
    currentStep: safeParse(localStorage.getItem("currentStep"), 0),
  };
};

// Initial state is now based on what's in local storage or defaults
const initialState = loadInitialState();

export default function OnboardingReducer(state = initialState, action) {
  switch (action.type) {
    case "updateCurrentStep":
      // Update local storage with the new current step
      localStorage.setItem("currentStep", JSON.stringify(action.payload));
      return {
        ...state,
        currentStep: action.payload,
      };

    default:
      return state;
  }
}
