import { BACKEND_URL } from "../../routes/config";
class systemParametersEndpoints {
  constructor() {
    /**
     * Returns the URL for creating new rules.
     * @returns {string} The URL for creating new rules.
     */
    this.getFacilityByUserProfile = (id) => {
      return `${BACKEND_URL}/facility/${id}/`;
    };
    this.addFacilityParameters = () => {
      return `${BACKEND_URL}/operations/facility/`;
    };
    this.editFacilityParameters = (id) => {
      return `${BACKEND_URL}/operations/facility/${id}/`;
    };
    this.getNVRByStore = (facility_id) => {
      let user_profile_id = JSON.parse(localStorage.getItem("profile")).id;
      return `${BACKEND_URL}/facility/nvr/${facility_id}/${user_profile_id}/`;
    };
    this.getCameraByNVR = (nvrId) => {
      return `${BACKEND_URL}/operations/camera_from_nvr/${nvrId}`;
    };
    this.getCameraByUserProfileId = (
      user_profile_id,
      fac_id,
      nvr_id,
      page,
      rowsPerPage,
      search
    ) => {
      let queryString = `${BACKEND_URL}/controlled_access/camera/userprofile?user_profile_id=${user_profile_id}`;

      if (nvr_id !== "all") {
        queryString += `&nvr_id=${nvr_id}`;
      }

      if (fac_id !== "all") {
        queryString += `&facility_id=${fac_id}`;
      }

      queryString += `&page=${page}&page_size=${rowsPerPage}`;

      if (search) {
        queryString += `&search_value=${search}`;
      }

      return queryString;
    };
    this.getNVRByUserProfileId = (user_profile_id) => {
      return `${BACKEND_URL}/controlled_access/nvr/userprofile?user_profile_id=${user_profile_id}`;
    };
    this.getCamerasByFacility = (user_profile_id, facility_id) => {
      return `${BACKEND_URL}/controlled_access/camera/facility?user_profile_id=${user_profile_id}&facility_id=${facility_id}`;
    };
    this.addCameraParameters = () => {
      return `${BACKEND_URL}/operations/camera/`;
    };
    this.editCameraParameters = (id) => {
      return `${BACKEND_URL}/operations/camera/${id}/`;
    };
  }
}

export default new systemParametersEndpoints();
