import moment from "moment";
import PeopleCountAction from "../../redux/Action/PeopleCountAction";
import PasserCountActions from "../../redux/Action/PasserbyCountAction";
import DemographicsActions from "../../redux/Action/DemographicsActions";
import DwellTimeActiosCombined from "../../redux/Action/DwellTimeActions";
import AlertEventTableActions from "../../redux/Action/AlertEventTableActions";
export const getChartDataConsolidated = (
  dateValue,
  mode, // Note: 'mode' is defined but not used. Consider removing it if unnecessary.
  store,
  device,
  dtRange,
  StartHour,
  EndHour,
  dispatch
) => {
  const formattedDate = moment(dateValue).format("YYYY-MM-DD");
  const startDate = moment(dtRange.startDate).format("YYYY-MM-DD");
  const endDate = moment(dtRange.endDate).format("YYYY-MM-DD");

  const profile = JSON.parse(localStorage.getItem("profile"));
  const access_permission =
    JSON.parse(localStorage.getItem("agx_permission"))?.Dashboard ?? [];

  const hasPeopleCountPermission =
    profile?.people_count &&
    access_permission.includes("view_people_count_analytics");
  const hasPasserbyCountPermissions =
    profile?.passer_by &&
    access_permission.includes("view_passer_by_analytics");

  // constructing the params according to use cases
  const specificParams = {
    user_profile_id: profile?.id,
    ...(store !== "all" && device == "all" && { facility_id: store }),
    ...(device !== "all" && device !== "all" && { device_id: device }),
    start_date: startDate === endDate ? formattedDate : startDate,
    end_date: startDate === endDate ? formattedDate : endDate,
    start_time: StartHour,
    end_time: EndHour,
  };
  // get data of people count if people count permisssion is enabled
  if (hasPeopleCountPermission) {
    dispatch(PeopleCountAction.getPeopleCountData(specificParams));
  }
  //get data of passerby if passerby is  enable
  if (hasPasserbyCountPermissions) {
    dispatch(PasserCountActions.getPasserbyCountData(specificParams));
  }

  //reconstructing the params to get the weeky data for people count and passerby
  const weeklyParams = {
    ...specificParams,
    week_range: "weekly",
    date: formattedDate,
  };
  // Remove date-specific keys for weekly analytics
  delete weeklyParams.start_date;
  delete weeklyParams.end_date;

  dispatch(
    PeopleCountAction.getweeklyDataAnalyticsForPeopleCount(weeklyParams)
  );
  if (hasPasserbyCountPermissions) {
    dispatch(
      PasserCountActions.getweeklyDataAnalyticsForPasserbyCount(weeklyParams)
    );
  }
  //reconstructing the params to get the three week data for comparision for people count and passerby
  const threeWeeklyParams = {
    ...specificParams,
    week_range: "three_weekly",
    date: formattedDate,
  };
  delete threeWeeklyParams.start_date;
  delete threeWeeklyParams.end_date;
  dispatch(
    PeopleCountAction.getweeklyDataAnalyticsForPeopleCount(threeWeeklyParams)
  );
  if (hasPasserbyCountPermissions) {
    dispatch(
      PasserCountActions.getweeklyDataAnalyticsForPasserbyCount(
        threeWeeklyParams
      )
    );
  }
  //reconstructing the params to get the weekday data for comparision for people count and passerby
  const weekDaysCount = {
    ...specificParams,
    week_range: "weekday",
    date: formattedDate,
  };
  delete weekDaysCount.start_date;
  delete weekDaysCount.end_date;
  dispatch(
    PeopleCountAction.getweeklyDataAnalyticsForPeopleCount(weekDaysCount)
  );
  if (hasPasserbyCountPermissions) {
    dispatch(
      PasserCountActions.getweeklyDataAnalyticsForPasserbyCount(weekDaysCount)
    );
  }
  //reconstructing the params to get the group count data and individual data for people count
  const groupCountParams = {
    ...specificParams,
    date: formattedDate,
  };
  delete groupCountParams.start_date;
  delete groupCountParams.end_date;
  delete groupCountParams.start_time;
  delete groupCountParams.end_time;
  dispatch(
    PeopleCountAction.getGroupCountAnalyticsForPeopleCount(groupCountParams)
  );
  //reconstructing the params to get the demographics weeky data for comparision
  dispatch(
    DemographicsActions.getDemographicsWeeklyByUserProfileFacility(
      groupCountParams
    )
  );
  //reconstructing the params to get the demographics data for selected types like (agd,mvf,emd)
  const demographicsParams = {
    ...specificParams,
    camera_type: "people_count",
    query_type: "emd,agd,mvf",
  };
  delete demographicsParams.start_time;
  delete demographicsParams.end_time;
  const passerDemographicParams = {
    ...demographicsParams,
    camera_type: "passer_by",
  };
  if (startDate == endDate) {
    //if mode if day-wise then get data of all three type of demographics (agd,mvf,emd)
    dispatch(
      DemographicsActions.getDemographicsDataForAllPeopleCount(
        demographicsParams
      )
    );
    dispatch(
      DemographicsActions.getDemographicsDataForAllPasserbyCount(
        passerDemographicParams
      )
    );
  } else {
    //if mode if date range then get data of all of mvf demographics
    const newDemographicParams = {
      ...demographicsParams,
      query_type: "mvf",
    };
    dispatch(
      DemographicsActions.getDemographicsDataForAllPeopleCount(
        newDemographicParams
      )
    );
    dispatch(
      DemographicsActions.getDemographicsDataForAllPasserbyCount(
        passerDemographicParams
      )
    );
  }
  if (profile.services_selected.Dashboard.includes("nicobar_dwell_time")) {
    let dwellTimeParams = { ...specificParams };
    delete dwellTimeParams.start_time;
    delete dwellTimeParams.end_time;
    dispatch(
      DwellTimeActiosCombined.getNicobarDwellTimeCountHourlyByAccountFacilityDevice(
        dwellTimeParams
      )
    );
  } else {
    //api for getting the data of dwell time based on day and date range w.r.t to user id, facility and device_id
    dispatch(DwellTimeActiosCombined.getDwellTimeForAllTypes(specificParams));
    const weeklyDweelTime = {
      ...specificParams,
      weekly: true,
    };
    //api for getting the data of dwell time of a week w.r.t to user id, facility and device_id
    dispatch(DwellTimeActiosCombined.getDwellTimeForAllTypes(weeklyDweelTime));
  }
  let eventTypeParams = {
    ...specificParams,
    notification_type: "potential_customer",
    alert_or_event: "event",
    priority: "normal",
    is_false: false,
    is_resolved: false,
    page_size: 12,
    page_no: 1,
  };

  if (profile.services_selected.Event.includes("potential_customer")) {
    dispatch(
      AlertEventTableActions.AlertEventMergedAction(eventTypeParams, false)
    );
  }
};
