import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Edit from "@mui/icons-material/Edit";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import AddDetailModal from "../FaceRecognitionModals/AddDetailModal";
import RenderTagCell from "./RenderTagCell";
import PersonDetailViewModal from "../FaceRecognitionModals/PersonDetailViewModal";
import FaceRecognitionActions from "../../../redux/Action/FaceRegnitionAction";
import Pagination from "../../../components/common/Pagination";
import { useDispatch, useSelector } from "react-redux";
const KnowFacesTable = () => {
  const dispatch = useDispatch();
  const [detailsModalOpen, setDetailsOpen] = useState(false);
  const [detailsData, setDetailsData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [searchData, setSearchData] = useState([]);
  const knownFaceData = useSelector(
    (state) => state.faceRecognition.knownFaceList
  );
  const savedCurrentPage = useSelector(
    (state) => state.faceRecognition.currentPageKnown
  );
  const knownFaceDataCount = useSelector(
    (state) => state.faceRecognition.knowFaceDataCount
  );
  const [filteredData, setFilteredData] = useState([]);

  const loading = useSelector(
    (state) => state.faceRecognition.knownFaceLoading
  );
  let pageSize = 20;
  const [modalProperties, setModalProperties] = useState({
    isOpen: false,
    faceData: null,
    isEditable: false,
  });
  function getKnownFacesData() {
    let userprofileid = JSON.parse(localStorage.getItem("profile")).id;
    dispatch(
      FaceRecognitionActions.getListOfKnownFaces(
        userprofileid,
        savedCurrentPage,
        20
      )
    );
  }
  useEffect(() => {
    getKnownFacesData();
  }, [savedCurrentPage]);
  useEffect(() => {
    if (searchValue.length > 0) {
      let data = knownFaceData.filter((item) =>
        item.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredData(data);
    } else {
      handlePagination(1);
    }
  }, [searchValue]);
  function handleOpenModal(data, iseditable) {
    setModalProperties({
      isOpen: true,
      faceData: data,
      isEditable: iseditable,
    });
  }
  function handleCloseModal() {
    setModalProperties({
      isOpen: false,
      faceData: null,
      isEditable: false,
    });
  }
  function handleCurrentPageData(startIndex, endIndex) {
    setFilteredData(knownFaceData.slice(startIndex, endIndex));
  }
  const handlePagination = (newPage) => {
    dispatch({ type: "UPDATE_KNOWN_FACE_CURRENT_PAGE", payload: newPage });
    setCurrentPage(newPage);
    handleCurrentPageData((newPage - 1) * pageSize, newPage * pageSize);
  };
  useEffect(() => {
    handleCurrentPageData(0, pageSize);
  }, [knownFaceData]);
  const handleKeyPress = (event, setSearchValue) => {
    if (event.key === "Enter") {
      setSearchValue(event.target.value);
    }
  };

  return (
    <div>
      <div className="d-md-flex justify-content-between mt-4">
        <div>
          <TextField
            id="outlined-basic"
            label="Search"
            variant="outlined"
            size="small"
            onKeyDown={(e) => handleKeyPress(e, setSearchValue)}
          />
        </div>
        <div className="d-md-flex gap-4">
          <Button
            startIcon={<AddAPhotoIcon />}
            variant="contained"
            onClick={() => handleOpenModal(null, false)}
          >
            Add Faces
          </Button>
        </div>
      </div>
      <TableContainer component={Paper} className="mt-4 ">
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Photo</TableCell>
              <TableCell>Name</TableCell>
              {/* <TableCell>ID</TableCell> */}
              <TableCell>Tags</TableCell>
              <TableCell>Gender</TableCell>
              <TableCell>Age</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  Loading Data...
                </TableCell>
              </TableRow>
            ) : knownFaceData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  No data available
                </TableCell>
              </TableRow>
            ) : searchValue.length > 0 ? (
              filteredData.map((item, index) => (
                <TableRow key={index}>
                  <TableCell align="center">
                    <img
                      src={item.image}
                      style={{ height: "70px" }}
                      onClick={() => {
                        setDetailsOpen(true);
                        setDetailsData(item);
                      }}
                    />
                  </TableCell>
                  <TableCell>{item.name}</TableCell>
                  <RenderTagCell item={item} />
                  <TableCell>{item.gender}</TableCell>
                  <TableCell>{item.age}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleOpenModal(item, true)}>
                      <Edit />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              knownFaceData.map((item, index) => (
                <TableRow key={index}>
                  <TableCell align="center">
                    <img
                      src={item.image}
                      style={{ height: "70px" }}
                      onClick={() => {
                        setDetailsOpen(true);
                        setDetailsData(item);
                      }}
                    />
                  </TableCell>
                  <TableCell>{item.name}</TableCell>
                  <RenderTagCell item={item} />
                  <TableCell>{item.gender}</TableCell>
                  <TableCell>{item.age}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleOpenModal(item, true)}>
                      <Edit />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {modalProperties.isOpen && (
        <AddDetailModal
          isOpen={modalProperties.isOpen}
          handleClose={handleCloseModal}
          isEditable={modalProperties.isEditable}
          faceData={modalProperties.faceData}
        />
      )}
      {detailsData && (
        <PersonDetailViewModal
          isOpen={detailsModalOpen}
          handleClose={() => setDetailsOpen(false)}
          data={detailsData}
        />
      )}
      <div className="d-flex justify-content-center mt-4">
        {!loading && (
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={
              searchValue.length > 0 ? searchData.length : knownFaceDataCount
            }
            pageSize={pageSize}
            onPageChange={(page) => handlePagination(page)}
            usedIn="videowall"
          />
        )}
      </div>
    </div>
  );
};

export default KnowFacesTable;
