import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../assets/css/main.css";
import { getDeviceStatus } from "../../redux/Action/DeviceControlAction";
import { changeTopbarTitle } from "../../redux/Action/ChangeTopbarTitle";
import DeviceStatusTable from "./DeviceStatusTable";
import DeviceStatusStatistics from "./DeviceStatusStatistics";

const DeviceStatus = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const deviceData = useSelector(
    (state) => state.deviceStatusData.deviceStatus
  );

  useEffect(() => {
    let profile = localStorage.getItem("profile");
    if (profile) {
      profile = JSON.parse(profile);
      if (profile.device_status !== true) {
        navigate("/unauthorized");
        return;
      }
    } else {
      navigate("/login"); // or any other fallback
      return;
    }

    setLoading(true);
    dispatch(getDeviceStatus())
      .then(() => setLoading(false))
      .catch(() => setLoading(false));

    dispatch(changeTopbarTitle("Device Status"));
  }, [dispatch, navigate]);

  return (
    <div style={{ borderRadius: "15px" }}>
      <DeviceStatusStatistics data={deviceData} loading={loading} />
      <DeviceStatusTable data={deviceData} loading={loading} />
    </div>
  );
};

export default DeviceStatus;
