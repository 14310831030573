import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import DashLineChart from "../../../components/charts/DashLineChart";
import DashboardFunction from "../DashboardFunctions"; // Make sure this import path is correct and includes hourlyGroupCount

const GroupCountChart = ({ selectedMode }) => {
  const { services_selected: servicesSelected } = JSON.parse(
    localStorage.getItem("profile") || "{}"
  );
  const retailData = useSelector((state) => state.retailData);

  return (
    <div>
      {servicesSelected?.Dashboard.includes("group_count") &&
        selectedMode.value === "day-wise" && (
          <div className="row">
            <div className="col-12" style={{ overflow: "hidden" }}>
              <DashLineChart
                classNameActive={true}
                data={DashboardFunction.hourlyGroupCount(
                  retailData.groupCountHourly
                )}
                title="Group Count"
                type="hourly"
                head="Group Count"
                barsie={10} // Ensure "barsie" is a valid prop for DashLineChart. If not, this might be a typo or needs to be removed.
              />
            </div>
          </div>
        )}
    </div>
  );
};

// Prop validation
GroupCountChart.propTypes = {
  selectedMode: PropTypes.object.isRequired,
};

export default GroupCountChart;
