import AdjustIcon from "@mui/icons-material/Adjust";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import PropTypes from "prop-types";
import "./../chat/chat.css";
import ChatInput from "./ChatInput";
const ChatBotHomePage = ({ handleInputChange, loading }) => {
  const prefilledPromts = [
    {
      heading: "Provide Report",
      subHead: "For all the alerts recieved on today",
    },
    {
      heading: "Provide Report",
      subHead: "Count on all stores for today",
    },
    {
      heading: "Give me name",
      subHead: "of store recieved most alerts today",
    },
    {
      heading: "Provide Report",
      subHead: "For all the alerts recieved on today",
    },
    {
      heading: "Provide Report",
      subHead: "Count on all stores for today",
    },
    {
      heading: "Give me name",
      subHead: "of store recieved most alerts today",
    },
  ];

  return (
    <div>
      <div className="container-fluid">
        <div className="col-12 chatbox-mainframe">
          <div>
            <center>
              <p className="welcome-text">
                Welcome to <span className="welcome-text-span">Agrex.ai</span>
              </p>
              <div className="welcome-subtext">
                <p>The power of AI at your service</p>
              </div>
            </center>
          </div>
          <div className="row justify-content-center">
            {prefilledPromts.map((item, index) => (
              <div
                className="example-propmts mt-2 col-10 col-md-3"
                key={`heading-${index}`}
                onClick={() =>
                  handleInputChange({
                    role: "user",
                    content: item.heading + " " + item.subHead,
                  })
                }
              >
                <h6>{item.heading}</h6>
                <p>{item.subHead} </p>
              </div>
            ))}
          </div>
          <center>
            <div className="col-10 col-sm-10 col-md-10 col-lg-8 col-xl-6 mt-4">
              <ChatInput
                loading={loading}
                handleInputChange={handleInputChange}
              />
            </div>
          </center>
          <center>
            <div className="col-12 col-sm-12 col-md-10 col-lg-8  d-md-flex chat-features">
              <div className="col-10 col-sm-10 col-md-4 feature-item">
                <center>
                  <AutoAwesomeIcon className="feature-item-icon" />
                </center>

                <div className="feature-item-heading">
                  <center>
                    {" "}
                    <p id="chat-feature-heading-text">Clear And precise</p>
                  </center>
                </div>
                <div>
                  <center>
                    <p id="chat-feature-text">
                      Get Information in simple and concise manner{" "}
                    </p>
                  </center>
                </div>
              </div>
              <div className="col-10 col-sm-10 col-md-4 feature-item">
                <center>
                  <AdjustIcon className="feature-item-icon" />
                </center>

                <div className="feature-item-heading">
                  <center>
                    {" "}
                    <p id="chat-feature-heading-text">
                      Personalized answers
                    </p>{" "}
                  </center>
                </div>

                <div>
                  <center>
                    <p id="chat-feature-text">
                      Get answer according to data of your dashboard{" "}
                    </p>
                  </center>
                </div>
              </div>
              <div className="col-10 col-sm-10 col-md-4 feature-item">
                <center>
                  <ShowChartIcon className="feature-item-icon" />
                </center>
                <div className="feature-item-heading">
                  <center>
                    <p id="chat-feature-heading-text">Increased efficiency</p>
                  </center>
                </div>

                <div>
                  <center>
                    <p id="chat-feature-text">
                      Save times and energy in finding information
                    </p>
                  </center>
                </div>
              </div>
            </div>
          </center>
        </div>{" "}
      </div>
    </div>
  );
};
ChatBotHomePage.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
export default ChatBotHomePage;
