import React from "react";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import mainCss from "../../assets/css/mainCss";
import { useSelector } from "react-redux";
import Filter from "./../../components/common/FilterBar/Filter";
import "../../assets/css/Login.css";
import Account from "../../redux/Action/CommonAction";
import { getTableData } from "../Alert/AlertChartData";
// import { getAlertEventTableData } from "../Alert/AlertChartData";
import Pagination from "../../components/common/Pagination";
import { changeTopbarTitle } from "../../redux/Action/ChangeTopbarTitle";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import AlertTableActions from "./../../redux/Action/AlertTableActions";
import TableDownloadAndEmail from "./TableDownloadAndEmail";
import AlertTableTabs from "./AlertTableTabs";
import AlertFilter from "../../components/common/FilterBar/AlertFilter";
import PrioityFilter from "../../components/common/FilterBar/PriorityFilter";
import FacilityFilter from "../../components/common/FilterBar/FacilityFilter";
import DeviceFilter from "../../components/common/FilterBar/DeviceFilter";
import SearchFilter from "../../components/common/FilterBar/SearchFilter";
import ClientFiler from "../../components/common/FilterBar/ClientFilter";
import OperationModeAction from "../../redux/Action/OperationModeAction";
import LiveViewAction from "../../redux/Action/LiveViewAction";
import UseCaseFilter from "../../components/common/FilterBar/UseCaseFilter";
import { useNavigate } from "react-router-dom";
import AlertFilterTable from "../../components/common/FilterBar/AlertFilterTable";

const AlertTable = () => {
  const [profileId] = useState(JSON.parse(localStorage.getItem("profile_id")));
  //If profileId is 63(DPS) then set active tab to "resolved"
  const state = useSelector((state) => state);
  const [currentPage, setCurrentPage] = useState(1);
  const [access_permission] = useState(
    JSON.parse(localStorage.getItem("agx_permission")).Alerts ?? []
  );
  const alertCardLoading = useSelector(
    (state) => state.AlertTableStates.loadingTableData
  );
  var profile = JSON.parse(localStorage.getItem("profile"));
  var profile_id = JSON.parse(localStorage.getItem("profile_id"));
  const [mounted, setMounted] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectionRange] = useState({
    startDate: new Date(state.commonData.dateRange.startDate),
    endDate: new Date(state.commonData.dateRange.endDate),
    key: "selection",
  });
  //state fro checking screen width

  useEffect(() => {
    setCurrentPage(1);
    dispatch(AlertTableActions.updateCurrentPage(1));
  }, [
    state.AlertTableStates.selectedMode,
    state.AlertTableStates.selectedFacility,
    state.AlertTableStates.selectedDevice,
    state.AlertTableStates.searchValue,
    state.AlertTableStates.tabValue,
    state.AlertTableStates.selectedAlertType,
  ]);

  useEffect(() => {
    if (mounted && selectionRange.startDate !== selectionRange.endDate) {
      getTableDataFunc();
    } else {
      setMounted(true);
    }
  }, [selectionRange]);

  useEffect(() => {
    dispatch(changeTopbarTitle("Alerts Table"));
    if (profile.alerts !== true) {
      navigate("/unauthorized");
    } else {
      if (profile.account.config["client"] == "True") {
        var obj = JSON.parse(localStorage.getItem("profile"));
        obj.id = localStorage.getItem("profile_id");
        localStorage.setItem("profile", JSON.stringify(obj));
        Account.getClientList(profile_id)(dispatch);
        Account.getStoresByAccount(profile.id)(dispatch);
      } else {
        Account.getStoresByAccount(profile.id)(dispatch);
      }
      if (profile.account.config["Operation"] == "True") {
        OperationModeAction.getStoresByAccount(profile.id)(dispatch);
        OperationModeAction.getAllAccount(profile.id)(dispatch);
      }
      dispatch(
        AlertTableActions.updateTabValue(
          profileId === 63 ? [false, true] : [false, false]
        )
      );
      dispatch(
        AlertTableActions.updateTabName(profileId === 63 ? "resolved" : "alert")
      );
    }
    return () => {
      try {
        const PrevRequestToStop = JSON.parse(
          sessionStorage.getItem("NewItems")
        );
        if (PrevRequestToStop) {
          PrevRequestToStop.map((item) => {
            const stop = {
              stream_id: item.stream_id,
              vpn_tunnel: item.vpn_tunnel,
            };
            LiveViewAction.stopLiveStream(stop)(dispatch);
          });
          sessionStorage.removeItem("NewItems");
        }
      } catch (error) {
        console.error(
          "An error occurred while executing the previous live stream request:",
          error
        );
      }
    };
  }, []);

  useEffect(() => {
    getTableDataFunc();
  }, [state.AlertTableStates.searchValue]);

  const handlePagination = (page) => {
    setCurrentPage(page);
    dispatch(AlertTableActions.updateCurrentPage(page));
  };

  const getTableDataFunc = () => {
    getTableData(
      state.commonData.alerteventDate,
      state.AlertTableStates.selectedMode.value,
      state.AlertTableStates.selectedAlertType.value,
      state.AlertTableStates.selectedFacility.value,
      state.AlertTableStates.selectedDevice.value,
      state.AlertTableStates.timeRange,
      {
        startDate: new Date(state.commonData.dateRange.startDate),
        endDate: new Date(state.commonData.dateRange.endDate),
        key: "selection",
      },
      state.AlertTableStates.currentPage,
      state.AlertTableStates.tabValue,
      state.AlertTableStates.searchValue,
      state.commonData.pageSize,
      state.AlertTableStates.selectedUseCase,
      state.AlertTableStates.selectedPriority.value,
      state.AlertTableStates.alertOrEvent,
      dispatch
    );
  };

  return (
    <div style={mainCss.divBelowTopbar}>
      <div className="divbelowtopbarsecond">
        <div className="card-body-div">
          <div className="row">
            {access_permission.includes("day_time_range_selection") && (
              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-2 mt-2">
                <Filter type="AlertTable" />
              </div>
            )}
            {profile.account.config["client"] == "True" && (
              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-2 mt-2">
                <ClientFiler type="AlertTable" />
              </div>
            )}
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-2 mt-2">
              <PrioityFilter type="AlertTable" />
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-2 mt-2">
              <AlertFilterTable type="AlertTable" />
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-2 mt-2">
              <FacilityFilter type="AlertTable" />
            </div>
            {state.AlertTableStates.selectedFacility.value !== "all" && (
              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-2 mt-2">
                <DeviceFilter type="AlertTable" />
              </div>
            )}
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-2 mt-2">
              <SearchFilter type="AlertTable" />
            </div>
            {profile.account.config["Operation"] == "True" && (
              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-2 mt-2">
                <UseCaseFilter type="AlertTable" />
              </div>
            )}
            {access_permission.includes("alerts_report_email_download") && (
              <div className="col">
                <TableDownloadAndEmail />
              </div>
            )}
          </div>
        </div>
      </div>
      <AlertTableTabs />
      {!alertCardLoading && (
        <div className="pagination-div">
          <Pagination
            className="pagination-bar"
            currentPage={state.AlertTableStates.currentPage}
            totalCount={state.AlertData.tableAlertCount}
            pageSize={state.commonData.pageSize}
            onPageChange={(page) => handlePagination(page)}
            usedIn="alerts"
          />
        </div>
      )}
    </div>
  );
};

export default AlertTable;
