import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { MenuItem, Select } from "@mui/material";
import Account from "../../../redux/Action/CommonAction";
import AlertTableAction from "../../../redux/Action/AlertTableActions";
import AlertDashAction from "../../../redux/Action/AlertDashAction";
import FaceRecognitionActions from "../../../redux/Action/FaceRegnitionAction";
import AnprActions from "../../../redux/Action/AnprActions";

const inputselect = {
  boxShadow: "none",
  borderRadius: "8px",
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
  MenuProps: {
    PaperProps: {
      style: {
        backgroundColor: "var(--top-bar-bg-color)",
        maxHeight: 350,
        width: 50,
        overflowX: "auto",
      },
    },
  },
};

const FacilityFilter = (propTypes) => {
  const state = useSelector((state) => state);
  const [timeRange, setTimeRange] = React.useState(
    propTypes.type === "AlertTable"
      ? state.AlertTableStates.timeRange
      : propTypes.type === "EventTable"
      ? state.EventTableStates.timeRange
      : [0, 23.59]
  );
  const dispatch = useDispatch();
  const handleStoreChange = (event) => {
    const value = event.target.value;
    const device = { value: "all" };
    if (propTypes.type === "AlertTable") {
      dispatch(AlertTableAction.updateFacility({ value: value }));
      dispatch(AlertTableAction.updateDevice({ value: "all" }));
    } else if (propTypes.type === "AlertDashboard") {
      dispatch(AlertDashAction.updateFacility({ value: value }));
      dispatch(AlertDashAction.updateDevice({ value: "all" }));
    } else if (propTypes.type === "FaceRecognitionDashboard") {
      dispatch(FaceRecognitionActions.updateFacility({ value: value }));
      dispatch(FaceRecognitionActions.updateDevice({ value: "all" }));
      dispatch({
        type: "UPDATE_CURRENT_PAGE_FACE_RECOGNITION",
        payload: 1,
      });
      if (value != "all") {
        sessionStorage.setItem(
          "face_rec_selected_facility",
          JSON.stringify({ value: value })
        );
      }
    } else if (propTypes.type === "AnprDashboard") {
      dispatch(AnprActions.updateFacility({ value: value }));
      dispatch(AnprActions.updateDevice({ value: "all" }));
      dispatch({
        type: "UPDATE_ANPR_CURRENT_PAGE",
        payload: 1,
      });
    }
    if (value !== "all") {
      Account.getCamerasByStore(value)(dispatch);
    }
  };
  return (
    <div>
      <div className="select-input-lebel">Facility</div>
      <Select
        value={
          propTypes.type === "AlertTable"
            ? state.AlertTableStates.selectedFacility.value
            : propTypes.type === "AlertDashboard"
            ? state.AlertDashboardStates.selectedFacility.value
            : propTypes.type === "FaceRecognitionDashboard"
            ? state.faceRecognition.selectedFacility.value
            : propTypes.type === "AnprDashboard"
            ? state.anpr.selectedFacility.value
            : "all"
        }
        onChange={handleStoreChange}
        sx={{
          color: "var(--card-text-color)",
          ...inputselect,
        }}
        className="select-input-field"
        MenuProps={inputselect.MenuProps}
      >
        {state.commonData.storeList.map((item) => {
          return (
            <MenuItem
              key={item.value}
              value={item.value}
              sx={{
                paddingTop: "2px",
                paddingBottom: "2px",
                color: "var(--card-text-color)",
              }}
            >
              {item.text}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
};

export default FacilityFilter;
