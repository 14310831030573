import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AlertRuleDialogs from "./AlertRuleDIalogs";
import PotentialCustomerDialog from "./PotentialCustomerDialog";
import AttendanceModal from "./AttendanceModal";
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function MenuButton() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showAlertModal, setShowAlertModal] = React.useState(false);
  const [showPotentialCustomerModal, setPotentialCustomerModel] =
    React.useState(false);
  const [showAttendance, setShowAttendance] = React.useState(false);
  const open = Boolean(anchorEl);
  const profile =
    JSON.parse(localStorage.getItem("profile")).services_selected.Event ?? [];
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Add New Rule
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {/* <MenuItem onClick={() => setShowAlertModal(true)} disableRipple>
          Alert Rule
        </MenuItem> */}

        {profile.includes("potential_customer") && (
          <MenuItem
            onClick={() => setPotentialCustomerModel(true)}
            disableRipple
          >
            Potentail Customer
          </MenuItem>
        )}
        {profile.includes("teacher_present") && (
          <div>
            <MenuItem onClick={() => setShowAttendance(true)} disableRipple>
              Attendance
            </MenuItem>
          </div>
        )}
      </StyledMenu>
      <AlertRuleDialogs
        open={showAlertModal}
        handleClose={() => setShowAlertModal(false)}
        isEditable={false}
        data={null}
      />
      <PotentialCustomerDialog
        open={showPotentialCustomerModal}
        handleClose={() => setPotentialCustomerModel(false)}
        isEditable={false}
        data={null}
      />
      <AttendanceModal
        open={showAttendance}
        handleClose={() => setShowAttendance(false)}
        isEditable={false}
        data={null}
      />
    </div>
  );
}
