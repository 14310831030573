import React from "react";
import Slider from "@mui/material/Slider";
import { DateRangePicker } from "react-date-range";
import DatePicker from "react-datepicker";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import { DateRange } from "react-date-range";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import mainCss from "../../../assets/css/mainCss";
import { MenuItem, Select } from "@mui/material";
import { useSelector } from "react-redux";
import Account from "../../../redux/Action/CommonAction";
import AlertTableAction from "../../../redux/Action/AlertTableActions";
import AlertDashAction from "../../../redux/Action/AlertDashAction";
import { getTableData } from "../../../pages/Alert/AlertChartData";
const inputselect = {
  boxShadow: "none",
  borderRadius: "8px",
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
  MenuProps: {
    PaperProps: {
      style: {
        backgroundColor: "var(--top-bar-bg-color)",
        maxHeight: 350,
        width: 50,
        overflowX: "auto",
      },
    },
  },
};

const Filter = (propTypes) => {
  const state = useSelector((state) => state);

  const [time, setTime] = React.useState([0, 23.99]);
  const [timeRange, setTimeRange] = React.useState([0, 23.59]);
  const [showPicker, setShowPicker] = useState(false);
  const [selectedMode, setselectedMode] = useState(
    propTypes.type === "AlertTable"
      ? state.AlertTableStates.selectedMode
      : propTypes.type === "AlertDashboard"
      ? state.AlertDashboardStates.selectedMode
      : propTypes.type === "EventTable"
      ? state.EventTableStates.selectedMode
      : { value: "day-wise" }
  );
  const [selectedDate, setselectedDate] = useState(
    new Date(state.commonData.alerteventDate)
  );
  const [mounted, setMounted] = useState(false);
  const dispatch = useDispatch();
  //state fro checking screen width
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  //code for checking the screen width
  useEffect(() => {
    // Update the screenWidth state when the window is resized
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Add event listener to listen for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const togglePicker = () => {
    setShowPicker(!showPicker);
  };
  const handleDateChange = (date) => {
    setselectedDate(date); // update local state
    dispatch(Account.updateDate(date)); // dispatch updateDate action with the selected date
  };
  const formateDateRange = (date) => {
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };
  const formateDateRangeShort = (date) => {
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    });
  };

  const handleDateRangeChange = (dtRange) => {
    setselectionRange(dtRange.selection);
    (dtRange = {
      startDate: dtRange.selection.startDate.toISOString(),
      endDate: dtRange.selection.endDate.toISOString(),
      key: "selection",
    }),
      dispatch(Account.updateDateRange(dtRange));
    // dispatch updateDate action with the selected date
    let mode = "day-wise";
    if (dtRange.startDate != dtRange.endDate) {
      mode = "date-range";
    }
    if (propTypes.type === "AlertDashboard") {
      dispatch(AlertDashAction.updateSelectedMode({ value: mode }));
    }
  };

  const handleModeChange = (event) => {
    setselectedMode({ value: event.target.value });
    if (propTypes.type === "AlertTable") {
      dispatch(
        AlertTableAction.updateSelectedMode({ value: event.target.value })
      );
    } else if (
      propTypes.type === "AlertDashboard" &&
      event.target.value == "day-wise"
    ) {
      dispatch(
        AlertDashAction.updateSelectedMode({ value: event.target.value })
      );
    }
  };
  const handleTimeRangeCommit = (event, newValue) => {
    const min = newValue[0];
    const max = newValue[1];
    const minHour = Math.floor(min);
    const minMinutes = Math.round((min - minHour) * 60);
    const maxHour = Math.floor(max);
    const maxMinutes = Math.round((max - maxHour) * 60);
    const minStr = `${minHour}.${minMinutes.toString().padStart(2, "0")}`;
    const maxStr = `${maxHour}.${maxMinutes.toString().padStart(2, "0")}`;
    setTimeRange([minStr, maxStr]);
    if (propTypes.type === "AlertTable") {
      dispatch(AlertTableAction.updateTime([minStr, maxStr]));
    } else if (propTypes.type === "AlertDashboard") {
      dispatch(AlertDashAction.updateTime([minStr, maxStr]));
    }
  };
  const handleTimeChange = (event, newValue) => {
    setTime(newValue);
  };
  const handleWindowClick = (event) => {
    if (tabRef.current && !tabRef.current.contains(event.target)) {
      setShowPicker(false);
    }
  };
  useEffect(() => {
    window.addEventListener("click", handleWindowClick);

    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
  }, []);

  function valuetext(time) {
    const hour = Math.floor(time);
    const minutes = Math.round((time - hour) * 60);
    return `${hour}:${minutes.toString().padStart(2, "0")} hr`;
  }
  function valueLabelFormat(time) {
    const hour = Math.floor(time);
    const minutes = Math.round((time - hour) * 60);
    return `${hour}:${minutes.toString().padStart(2, "0")} hr`;
  }
  const tabRef = useRef(null);
  const getTableDataFunc = () => {
    if (propTypes.type === "AlertTable") {
      getTableData(
        state.commonData.alerteventDate,
        state.AlertTableStates.selectedMode.value,
        state.AlertTableStates.selectedAlertType.value,
        state.AlertTableStates.selectedFacility.value,
        state.AlertTableStates.selectedDevice.value,
        state.AlertTableStates.timeRange,
        {
          startDate: new Date(state.commonData.dateRange.startDate),
          endDate: new Date(state.commonData.dateRange.endDate),
          key: "selection",
        },
        state.AlertTableStates.currentPage,
        state.AlertTableStates.tabValue,
        state.AlertTableStates.searchValue,
        state.commonData.pageSize,
        state.AlertTableStates.selectedUseCase,
        state.AlertTableStates.selectedPriority.value,
        state.AlertTableStates.alertOrEvent,
        dispatch
      );
    }
  };

  const [selectionRange, setselectionRange] = useState({
    startDate: new Date(state.commonData.dateRange.startDate),
    endDate: new Date(state.commonData.dateRange.endDate),
    key: "selection",
  });

  useEffect(() => {
    if (mounted && selectionRange.startDate !== selectionRange.endDate) {
      getTableDataFunc();
    } else {
      setMounted(true);
    }
  }, [selectionRange]);

  return (
    <div>
      <div className="select-input-lebel">Filters</div>
      <OverlayTrigger
        placement="bottom-start"
        trigger="click"
        rootClose="false"
        overlay={
          <Popover className="col-9 col-sm-12">
            <Popover.Body
              style={{ backgroundColor: "var(--side-bar-bg-color)" }}
            >
              <div className="select-input-lebel">Mode</div>
              <Select
                value={selectedMode.value}
                sx={{
                  color: "var(--card-text-color)",
                  ...inputselect,
                }}
                className="select-input-field"
                MenuProps={inputselect.MenuProps}
                onChange={handleModeChange}
              >
                <MenuItem
                  value="day-wise"
                  sx={{
                    paddingTop: "2px",
                    paddingBottom: "2px",
                    color: "var(--card-text-color)",
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  Day Wise
                </MenuItem>
                <MenuItem
                  value="date-range"
                  sx={{
                    paddingTop: "2px",
                    paddingBottom: "2px",
                    color: "var(--card-text-color)",
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  Date Range
                </MenuItem>
              </Select>
              {selectedMode.value !== "date-range" && (
                <div className="col-11 m-2">
                  <div className="select-input-lebel">Time</div>
                  <Slider
                    sx={mainCss.sliderDesign}
                    getAriaLabel={() => "Temperature range"}
                    value={time}
                    onChange={handleTimeChange}
                    valueLabelDisplay="auto"
                    onChangeCommitted={handleTimeRangeCommit}
                    getAriaValueText={valuetext}
                    valueLabelFormat={valueLabelFormat}
                    min={0.0}
                    step={0.01}
                    max={23.99}
                  />
                </div>
              )}
              <div className="mt-2">
                {selectedMode.value === "date-range" ? (
                  <div>
                    <div className="select-input-lebel">Date Range</div>
                    <div ref={tabRef}>
                      <button
                        className="select-input-field"
                        onClick={togglePicker}
                      >
                        {formateDateRange(selectionRange.startDate)} -{" "}
                        {formateDateRange(selectionRange.endDate)}
                      </button>
                      <div
                        className=" date-range-picker-container"
                        style={{
                          display: showPicker ? "block" : "none",
                          marginTop: "15px",
                        }}
                      >
                        {screenWidth > 1000 ? (
                          <DateRangePicker
                            className="select-input-field"
                            ranges={[selectionRange]}
                            onChange={handleDateRangeChange}
                          />
                        ) : (
                          <DateRange
                            className="select-input-field"
                            editableDateInputs={true}
                            moveRangeOnFirstSelection={false}
                            ranges={[selectionRange]}
                            onChange={handleDateRangeChange}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="select-input-lebel">Date</div>
                    <DatePicker
                      className="select-input-field-date"
                      customInput={<input className="custom-input-date-text" />}
                      selected={selectedDate}
                      onChange={handleDateChange}
                      dateFormat="dd/MM/yyyy"
                    />
                  </div>
                )}
              </div>
            </Popover.Body>
          </Popover>
        }
      >
        <div className="d-flex filter-div">
          {selectedMode.value === "date-range" ? (
            <div className="filter-div-text col-10 mt-1 overflow-hidden">
              {formateDateRangeShort(selectionRange.startDate)} -{" "}
              {formateDateRangeShort(selectionRange.endDate)}{" "}
            </div>
          ) : (
            <div className="filter-div-text col-10 overflow-hidden">
              {formateDateRange(selectedDate)}
            </div>
          )}
          <div className="filter-drop-icon " style={{ minWidth: "15px" }}>
            <ArrowDropDownOutlinedIcon
              style={{
                float: "right",
                marginRight: "5px",
                marginTop: "7px",
              }}
            />
          </div>
        </div>
      </OverlayTrigger>
    </div>
  );
};

export default Filter;
