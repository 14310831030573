import React, { useEffect, useState, useCallback } from "react";
import "../../../assets/css/live_view.css";
import Pagination from "../../../components/common/Pagination";
import SelectedGridView from "./SelectedGridView";
import Button from "@mui/material/Button";
import QualitySetting from "./QualitySetting";
import LiveViewTransitionTimer from "./TransitionTimer";
import { useDispatch, useSelector } from "react-redux";
import VmsLiveviewActions from "../../../redux/Action/VmsActions";
import LiveViewAction from "../../../redux/Action/LiveViewAction";
import VmsHelperActions from "../../../redux/Action/VmsHelperActions";

const GridLayout = () => {
  const dispatch = useDispatch();
  const gridSizes = [4, 9, 16, 25, 36];
  const selectedGrid = useSelector((state) => state.vms.selectedGrid);
  const currentPage = useSelector((state) => state.vms.currentPage);
  const selectedQuality = useSelector((state) => state.vms.selectedQuality);
  const selectedCameraList = useSelector(
    (state) => state.vms.selectedCameraList
  );
  const isTransitionTimeEnabled = useSelector(
    (state) => state.vmsHelper.timerEnabled
  );
  const transitionTime = useSelector(
    (state) => state.vmsHelper.TransitionTimer
  );
  const playlist = useSelector((state) => state.vms.playlist);
  const [showOverlay, setShowOverlay] = useState(false);
  const [dragOver, setDragOver] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(transitionTime);
  // Handle page change
  const handlePageChange = useCallback(
    (page) => {
      // Dispatch an action to update the current page in the state
      dispatch(VmsLiveviewActions.updateCurrentPage(page)).then(() => {
        // Calculate the start and end indices for the cameras to be displayed on the new page
        const startIndex = (page - 1) * selectedGrid;
        const endIndex = Math.min(
          page * selectedGrid,
          selectedCameraList.length
        );

        // Get the list of camera IDs to be displayed on the new page
        let newCameraIds = selectedCameraList.slice(startIndex, endIndex);

        // Get the list of camera IDs to be removed from the current playlist
        let camerasToRemove = playlist
          .filter((item) => !newCameraIds.includes(item.cam_id))
          .map((item) => item.cam_id);

        // Dispatch an action to remove the cameras that are no longer needed from the live view data
        dispatch(
          VmsLiveviewActions.removeCameraFromLiveViewData(
            camerasToRemove,
            playlist
          )
        ).then(() => {
          // Get the list of camera IDs to be added to the new page
          let camerasToAdd = newCameraIds.filter(
            (newCamId) => !playlist.some((item) => item.cam_id === newCamId)
          );

          // If there are cameras to be added, dispatch actions to load their live view data
          if (camerasToAdd.length > 0) {
            camerasToAdd.forEach((item, index) => {
              // Increase the loading camera count
              dispatch(VmsHelperActions.increaseLoadingCameras(index));
              // Dispatch an action to get the live view data for the camera
              dispatch(
                LiveViewAction.getLiveViewByIpCameras(item, selectedQuality)
              )
                .then(() => {
                  // Decrease the loading camera count after the live view data is successfully loaded
                  dispatch(VmsHelperActions.decreaseLoadingCameras(index));
                })
                .catch(() => {
                  // Decrease the loading camera count if there is an error loading the live view data
                  dispatch(VmsHelperActions.decreaseLoadingCameras(index));
                });
            });
          }
        });
      });
    },
    [dispatch, selectedGrid, selectedCameraList, playlist, selectedQuality]
  );

  // Update timeRemaining whenever transitionTime changes
  useEffect(() => {
    setTimeRemaining(transitionTime);
  }, [transitionTime]);

  // Clear selected camera list when component unmounts
  useEffect(() => {
    return () => {
      dispatch(VmsLiveviewActions.replaceSelectedCameraList([]));
    };
  }, [dispatch]);

  // Adjust current page if selectedCameraList or selectedGrid changes
  useEffect(() => {
    let calculatedPage =
      Math.ceil(selectedCameraList.length / selectedGrid) ?? 1;
    if (currentPage > calculatedPage && calculatedPage !== 0) {
      handlePageChange(calculatedPage);
    }
  }, [selectedCameraList, currentPage, selectedGrid, handlePageChange]);

  // Handle page transitions and timer intervals
  useEffect(() => {
    let pageInterval; // Variable to store the interval for page transitions
    let timerInterval; // Variable to store the interval for the countdown timer
    const totalPages = Math.ceil(selectedCameraList.length / selectedGrid); // Calculate the total number of pages based on the number of selected cameras and the grid size

    // Check if transition time is enabled and there is more than one page
    if (isTransitionTimeEnabled && totalPages > 1) {
      setTimeRemaining(transitionTime); // Initialize the time remaining with the transition time

      // Set an interval to handle page transitions
      pageInterval = setInterval(() => {
        // Calculate the new page number, wrapping around to the first page if necessary
        const newPage = currentPage + 1 <= totalPages ? currentPage + 1 : 1;
        handlePageChange(newPage); // Change to the new page
        setTimeRemaining(transitionTime); // Reset the time remaining to the transition time
      }, transitionTime * 1000); // Interval duration is the transition time in milliseconds

      // Set an interval to handle the countdown timer
      timerInterval = setInterval(() => {
        // Decrement the time remaining, resetting to the transition time if it reaches 1
        setTimeRemaining((prev) => (prev === 1 ? transitionTime : prev - 1));
      }, 1000); // Interval duration is 1 second
    }

    // Cleanup function to clear the intervals when the component unmounts or dependencies change
    return () => {
      clearInterval(pageInterval); // Clear the page transition interval
      clearInterval(timerInterval); // Clear the countdown timer interval
    };
  }, [
    isTransitionTimeEnabled, // Dependency: whether the transition time is enabled
    transitionTime, // Dependency: the transition time duration
    currentPage, // Dependency: the current page number
    selectedCameraList.length, // Dependency: the number of selected cameras
    selectedGrid, // Dependency: the grid size
    handlePageChange, // Dependency: the function to handle page changes
  ]);

  // Handle grid size change
  const handleGridChange = (newGrid) => {
    // Create an array of the new grid size filled with "idle" status
    const array = new Array(newGrid).fill("idle");
    // Dispatch an action to update the loading camera grid with the new array
    dispatch(VmsHelperActions.updateLoadingCameraGrid(array));

    // Calculate the new current page based on the new grid size
    const newCurrentPage =
      Math.floor(((currentPage - 1) * selectedGrid) / newGrid) + 1;
    // Dispatch an action to update the current page in the state
    dispatch(VmsLiveviewActions.updateCurrentPage(newCurrentPage)).then(() => {
      // After updating the current page, dispatch an action to update the selected grid size
      dispatch(VmsLiveviewActions.updateSelectedGrid(newGrid));
    });

    // Calculate the start and end indices for the cameras to be displayed in the new grid
    const startIndex = 0;
    const endIndex = Math.min(newGrid, selectedCameraList.length);

    // Get the list of camera IDs to be displayed in the new grid
    let newCameraIdList = selectedCameraList.slice(startIndex, endIndex);

    // Get the list of camera IDs to be removed from the current playlist
    let camerasToBeRemoved = playlist
      .filter((item) => !newCameraIdList.includes(item.cam_id))
      .map((item) => item.cam_id);

    // Dispatch an action to remove the cameras that are no longer needed from the live view data
    dispatch(
      VmsLiveviewActions.removeCameraFromLiveViewData(
        camerasToBeRemoved,
        playlist
      )
    ).then(() => {
      // Get the list of camera IDs to be added to the new grid
      let camerasToBeAdded = newCameraIdList.filter(
        (newCamId) => !playlist.some((item) => item.cam_id === newCamId)
      );

      // Get the current length of the playlist
      let length = playlist.length;

      // If there are cameras to be added, dispatch actions to load their live view data
      if (camerasToBeAdded.length > 0) {
        camerasToBeAdded.forEach((item, index) => {
          // Increase the loading camera count
          dispatch(VmsHelperActions.increaseLoadingCameras(length + index));
          // Dispatch an action to get the live view data for the camera
          dispatch(LiveViewAction.getLiveViewByIpCameras(item, selectedQuality))
            .then(() => {
              // Decrease the loading camera count after the live view data is successfully loaded
              dispatch(VmsHelperActions.decreaseLoadingCameras(length + index));
            })
            .catch(() => {
              // Decrease the loading camera count if there is an error loading the live view data
              dispatch(VmsHelperActions.decreaseLoadingCameras(length + index));
            });
        });
      }
    });
  };

  // Toggle overlay visibility
  const toggleOverlay = () => {
    setShowOverlay(!showOverlay);
  };

  // Handle drag enter event
  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  // Handle drag leave event
  const handleDragLeave = (e) => {
    e.preventDefault();
    const rect = e.currentTarget.getBoundingClientRect();
    if (
      e.clientX > rect.right ||
      e.clientX < rect.left ||
      e.clientY > rect.bottom ||
      e.clientY < rect.top
    ) {
      setDragOver(false);
    }
  };

  // Handle drop event
  const handleDrop = (e) => {
    e.preventDefault(); // Prevent default behavior of the drop event
    setDragOver(false); // Set dragOver state to false to hide the drag overlay

    // Retrieve the camera ID from the dataTransfer object
    const id = Number(e.dataTransfer.getData("cameraId"));

    // Check if the ID is valid
    if (id) {
      // Check if the camera ID is not already in the selectedCameraList
      if (!selectedCameraList.includes(id)) {
        // Update the selected camera list with the new camera ID
        dispatch(VmsLiveviewActions.updateSelectedCameraList([id]));

        // Check if the camera is already playing in the playlist
        let isCamPlaying = playlist.map((item) => item.id).includes(id);

        // Check if there is space in the current page to add the new camera and if the camera is not already playing
        if (
          selectedCameraList.length < currentPage * selectedGrid &&
          !isCamPlaying
        ) {
          // Increase the loading camera count
          dispatch(VmsHelperActions.increaseLoadingCameras(playlist.length));

          // Fetch the live view for the new camera
          dispatch(LiveViewAction.getLiveViewByIpCameras(id, selectedQuality))
            .then(() => {
              // Decrease the loading camera count on success
              dispatch(
                VmsHelperActions.decreaseLoadingCameras(playlist.length)
              );
            })
            .catch(() => {
              // Decrease the loading camera count on failure
              dispatch(
                VmsHelperActions.decreaseLoadingCameras(playlist.length)
              );
            });
        }
      }
    }
  };

  return (
    <>
      <div
        className="my-new-live-view"
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={(e) => e.preventDefault()}
        onDrop={handleDrop}
        style={{
          position: "relative",
          border: dragOver ? "2px dashed #2b3674" : "none",
        }}
      >
        {dragOver && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 10,
              color: "#2b3674",
              fontSize: "24px",
            }}
          >
            Drop the camera here to play
          </div>
        )}
        <SelectedGridView gridSize={selectedGrid} />
      </div>

      <div className="my-new-live-view-footer mt-2 px-1">
        <ul className="pagination-container">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
            }}
          >
            <li>
              <span onClick={toggleOverlay}>
                <QualitySetting />
              </span>
            </li>
            <li className="d-flex align-items-center">
              <LiveViewTransitionTimer />
              {timeRemaining} seconds
            </li>
          </div>
          <div className="pagination-item-container">
            {gridSizes.map((gridDimension) => (
              <Button
                key={`page${gridDimension}`}
                variant={
                  gridDimension === selectedGrid ? "contained" : "outlined"
                }
                style={{
                  padding: 4,
                  margin: 3,
                  minWidth: "50px",
                }}
                onClick={() => handleGridChange(gridDimension)}
              >
                {gridDimension}
              </Button>
            ))}
          </div>
        </ul>
      </div>

      <div className="my-new-live-view-footer-pagination mt-2">
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={selectedCameraList.length}
          pageSize={selectedGrid}
          onPageChange={(page) => handlePageChange(page)}
          usedIn="videowall"
        />
      </div>
    </>
  );
};

export default GridLayout;
