// Action Type Constants
const UPDATE_SELECTED_MODE = "UPDATE_SELECTED_MODE_ALERT_DASH";
const UPDATE_FACILITY = "UPDATE_FACILITY_ALERT_DASH";
const UPDATE_CLIENT = "UPDATE_CLIENT_ALERT_DASH";
const UPDATE_DEVICE = "UPDATE_DEVICE_ALERT_DASH";
const UPDATE_ALERT_TYPE = "UPDATE_ALERT_TYPE_ALERT_DASH";
const UPDATE_TIME = "UPDATE_TIME_ALERT_DASH";
const UPDATE_ALERT_TYPE_SELECTED = "UPDATE_ALERT_TYPE_SELECTED_ALERT_DASH";
class AlertDashAction {
  static updateSelectedMode(data) {
    return {
      type: UPDATE_SELECTED_MODE,
      payload: data,
    };
  }

  static updateFacility(data) {
    return {
      type: UPDATE_FACILITY,
      payload: data,
    };
  }

  static updateClient(data) {
    return {
      type: UPDATE_CLIENT,
      payload: data,
    };
  }

  static updateDevice(data) {
    return {
      type: UPDATE_DEVICE,
      payload: data,
    };
  }

  static updateAlertType(data) {
    return {
      type: UPDATE_ALERT_TYPE,
      payload: data,
    };
  }

  static updateTime(data) {
    return {
      type: UPDATE_TIME,
      payload: data,
    };
  }

  static updateAlertTypesSelected(data) {
    return {
      type: UPDATE_ALERT_TYPE_SELECTED,
      payload: data,
    };
  }
}

export default AlertDashAction;
