import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import DashPieChart from "../../../components/charts/DashPieChart";
import DashBarChart from "../../../components/charts/DashBarChart";
import DashAreaChart from "../../../components/charts/DashAreaChart";
import DashDonuChart from "../../../components/charts/DashDonuChart";
import DemographicsFunction from "../DemographicsFunctions";
import DashboardFunction from "../DashboardFunctions";
const DemographicCharts = ({ selectedMode }) => {
  const { services_selected: servicesSelected } = JSON.parse(
    localStorage.getItem("profile") || "{}"
  );
  const { Dashboard: accessPermissions = [] } = JSON.parse(
    localStorage.getItem("agx_permission") || "{}"
  );

  const emotionData = useSelector((state) => state.emotion);
  const genderData = useSelector((state) => state.gender);
  const ageData = useSelector((state) => state.age);

  const isDayWise = selectedMode.value === "day-wise";
  const canViewDemographics =
    servicesSelected.Dashboard.includes("demographics") &&
    accessPermissions.includes("view_demographics_analytics");

  return (
    <div>
      {canViewDemographics && (
        <>
          {servicesSelected.Dashboard.includes("passerby_demographic") &&
            isDayWise && (
              <div className="row">
                <div className="col-6">
                  <DashBarChart
                    classNameActive={true}
                    data={DemographicsFunction.demographicsHourlyData(
                      genderData.totalMaleFemalHourly
                    )}
                    type="hourly"
                    title="Demographics Hourly Comparison"
                    labelToShowY="People"
                  />
                </div>
              </div>
            )}

          {isDayWise && (
            <>
              <div className="chart-container">
                <div className="chart-div">
                  <div className="row">
                    <div
                      className="col-xxl-4"
                      style={{ borderRight: "2px solid #BDBDBD" }}
                    >
                      <DashPieChart
                        data={[
                          genderData.peopleCountGender.male,
                          genderData.peopleCountGender.female,
                        ]}
                        title="Gender Count"
                        labels={["Male", "Female"]}
                      />
                    </div>
                    <div className="col-xxl-8">
                      <DashBarChart
                        classNameActive={false}
                        data={DemographicsFunction.maleFemaleHourlyGraphData(
                          genderData.peopleCountGenderHourly
                        )}
                        type="hourly"
                        title="Gender Count Hourly Comparison"
                        labelToShowY="Count"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="chart-container">
                <div className="chart-div">
                  <div className="row">
                    <div
                      className="col-xxl-4"
                      style={{ borderRight: "2px solid #BDBDBD" }}
                    >
                      <DashPieChart
                        data={[
                          ageData.ageDistribution.age_0_12,
                          ageData.ageDistribution.age_13_18,
                          ageData.ageDistribution.age_19_25,
                          ageData.ageDistribution.age_26_35,
                          ageData.ageDistribution.age_36_45,
                          ageData.ageDistribution.age_46_60,
                          ageData.ageDistribution.age_60_ahead,
                        ]}
                        title="Age Chart"
                        labels={[
                          "0-12 y/o",
                          "13-18 y/o",
                          "19-25 y/o",
                          "26-35 y/o",
                          "36-45 y/o",
                          "46-60 y/o",
                          "61+ y/o",
                        ]}
                      />
                    </div>
                    <div className="col-xxl-8">
                      <DashAreaChart
                        classNameActive={false}
                        data={DemographicsFunction.ageDistributionHourlyGraphData(
                          ageData.ageHourly
                        )}
                        type="hourly"
                        title="Age Hourly Comparison"
                        labelToShowY="People"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="chart-container">
                <div className="chart-div">
                  <div className="row">
                    <div
                      className="col-xxl-4"
                      style={{ borderRight: "2px solid #BDBDBD" }}
                    >
                      <DashDonuChart
                        data={{
                          happiness: emotionData.emotionData.happiness,
                          neutral: emotionData.emotionData.neutral,
                        }}
                        title="Emotions Chart"
                      />
                    </div>
                    <div className="col-xxl-8">
                      <DashBarChart
                        classNameActive={false}
                        data={DashboardFunction.emotionGraphDataHourly(
                          emotionData.emotionHoulry
                        )}
                        type="hourly"
                        title="Emotion Hourly Comparison"
                        labelToShowY="Count"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

DemographicCharts.propTypes = {
  selectedMode: PropTypes.object.isRequired,
};

export default DemographicCharts;
