const initialState = {
  deviceStatus: [],
};

export default function DeviceStatusReducer(state = initialState, action) {
  switch (action.type) {
    case "DEVICE_STATUS_DATA":
      return { ...state, deviceStatus: action.payload };

    default:
      return state;
  }
}
