import axios from "axios";
import SystemParametersEndpoints from "../features/SystemParametersEndpoints";
import {
  notifiToasterSuccess,
  notifiToasterWarn,
} from "../../components/common/UseToast";
import AccessControlActions from "./AccessControlAction";

class SystemParametersActions {
  static getFacilityByUserProfile() {
    return async (dispatch) => {
      let token = localStorage.getItem("token");
      let headers = token ? { Authorization: `Token ${token}` } : {};
      let userprofileId = JSON.parse(localStorage.getItem("profile")).id;
      await axios
        .get(
          SystemParametersEndpoints.getFacilityByUserProfile(userprofileId),
          {
            headers,
          }
        )
        .then((response) => {
          // Save response in session storage
          sessionStorage.setItem(
            "facilityListSystemParameters",
            JSON.stringify(response.data)
          );

          dispatch({
            type: "SYSTEM_PARAMTER_ADD_FACILITY_LIST",
            payload: response.data,
          });
        })
        .catch((error) => {
          console.error("Error fetching facility list", error);
        });
    };
  }

  static addFacility(facilityData) {
    return async (dispatch) => {
      let token = localStorage.getItem("token");
      let headers = token ? { Authorization: `Token ${token}` } : {};
      await axios
        .post(SystemParametersEndpoints.addFacilityParameters(), facilityData, {
          headers,
        })
        .then((response) => {
          let profile_id = JSON.parse(localStorage.getItem("profile")).id;
          let group_data = {
            user_profile_id: profile_id,
            facilities: [response.data.id],
          };
          dispatch(AccessControlActions.updateGroupData(group_data));
          dispatch(this.getFacilityByUserProfile()); // Refresh facility list after addition
          notifiToasterSuccess("Facility added successfully!");
        })
        .catch((error) => {
          console.error("Error adding facility", error);
          notifiToasterWarn("Failed to add facility. Please try again.");
        });
    };
  }

  static editFacility(id, facilityData) {
    let token = localStorage.getItem("token");
    let headers = token ? { Authorization: `Token ${token}` } : {};
    return async (dispatch) => {
      await axios
        .put(
          SystemParametersEndpoints.editFacilityParameters(id),
          facilityData,
          { headers }
        )
        .then((response) => {
          dispatch(this.getFacilityByUserProfile()); // Refresh facility list after editing
          notifiToasterSuccess("Facility updated successfully!");
        })
        .catch((error) => {
          console.error("Error updating facility", error);
          notifiToasterWarn("Failed to update facility. Please try again.");
        });
    };
  }

  static deleteFacility(id) {
    return async (dispatch) => {
      let token = localStorage.getItem("token");
      let headers = token ? { Authorization: `Token ${token}` } : {};
      await axios
        .delete(SystemParametersEndpoints.editFacilityParameters(id), {
          headers,
        })
        .then((response) => {
          dispatch(this.getFacilityByUserProfile()); // Refresh facility list after deletion
          notifiToasterSuccess("Facility deleted successfully!");
        })
        .catch((error) => {
          console.error("Error deleting facility", error);
          notifiToasterWarn("Failed to delete facility. Please try again.");
        });
    };
  }

  static getNVRsByStore = (data) => {
    return async (dispatch) => {
      let token = localStorage.getItem("token");
      let headers = token ? { Authorization: `Token ${token}` } : {};
      await axios
        .get(SystemParametersEndpoints.getNVRByStore(data), { headers })
        .then((response) => {
          let nvr = [{ label: "All", value: "all" }];
          response.data.forEach((element) => {
            nvr.push({ label: element.name, value: element.id });
          });

          dispatch({ type: "SYSTEM_PARAMTER_ADD_NVR_LIST", payload: nvr });
        });
    };
  };
  static getNVRsByUserprofile = (data) => {
    return async (dispatch) => {
      let token = localStorage.getItem("token");
      let headers = token ? { Authorization: `Token ${token}` } : {};
      await axios
        .get(SystemParametersEndpoints.getNVRByUserProfileId(data), {
          headers,
        })
        .then((response) => {
          let nvr = [{ label: "All", value: "all" }];
          response.data.forEach((element) => {
            nvr.push({ label: element.name, value: element.id });
          });

          const payload = nvr;
          sessionStorage.setItem(
            "nvrListSystemParameters",
            JSON.stringify(payload)
          );
          dispatch({ type: "SYSTEM_PARAMTER_ADD_NVR_LIST", payload });
        });
    };
  };
  static getCamerasByUserProfile = (
    Uid,
    facility_id,
    camera_id,
    page,
    rowsPerPage,
    search
  ) => {
    return async (dispatch) => {
      let token = localStorage.getItem("token");
      let headers = token ? { Authorization: `Token ${token}` } : {};
      await axios
        .get(
          SystemParametersEndpoints.getCameraByUserProfileId(
            Uid,
            facility_id,
            camera_id,
            page,
            rowsPerPage,
            search
          ),
          {
            headers,
          }
        )
        .then((response) => {
          const payload = {
            count: response.data.count,
            data: response.data.results,
          };
          sessionStorage.setItem(
            "cameraListSystemParameters",
            JSON.stringify(payload)
          );
          dispatch({
            type: "SYSTEM_PARAMTER_ADD_CAMERA_LIST",
            payload,
          });
        });
    };
  };
  static getCamerasByFacility = (user_profile_id, facility_id) => {
    return async (dispatch) => {
      let token = localStorage.getItem("token");
      let headers = token ? { Authorization: `Token ${token}` } : {};
      await axios
        .get(
          SystemParametersEndpoints.getCamerasByFacility(
            user_profile_id,
            facility_id
          ),
          { headers }
        )
        .then((response) => {
          const payload = { count: response.data.length, data: response.data };
          sessionStorage.setItem(
            "cameraListSystemParameters",
            JSON.stringify(payload)
          );
          dispatch({
            type: "SYSTEM_PARAMTER_ADD_CAMERA_LIST",
            payload,
          });
        });
    };
  };
  static getCameraByNVR = (data) => {
    return async (dispatch) => {
      let token = localStorage.getItem("token");
      let headers = token ? { Authorization: `Token ${token}` } : {};
      await axios
        .get(SystemParametersEndpoints.getCameraByNVR(data), { headers })
        .then((response) => {
          dispatch({
            type: "SYSTEM_PARAMTER_ADD_CAMERA_LIST",
            payload: {
              count: response.data.data.length,
              data: response.data.data,
            },
          });
        });
    };
  };
  static addCamera = (cameraData, page_size) => async (dispatch) => {
    try {
      let token = localStorage.getItem("token");
      let headers = token ? { Authorization: `Token ${token}` } : {};
      const response = await axios.post(
        SystemParametersEndpoints.addCameraParameters(),
        cameraData,
        { headers }
      );
      let profile_id = JSON.parse(localStorage.getItem("profile")).id;
      let group_data = {
        user_profile_id: profile_id,
        cameras: [response.data.id],
      };
      dispatch(AccessControlActions.updateGroupData(group_data));
      dispatch(
        SystemParametersActions.getCamerasByUserProfile(
          profile_id,
          "all",
          "all",
          1,
          page_size,
          ""
        )
      );
      notifiToasterSuccess("Camera added successfully!");
    } catch (error) {
      console.error("Error adding facility", error);
      notifiToasterWarn("Failed to add camera. Please try again.");
    }
  };

  static editCamera = (id, cameraData, page_size) => async (dispatch) => {
    try {
      let token = localStorage.getItem("token");
      let headers = token ? { Authorization: `Token ${token}` } : {};
      const profileId = JSON.parse(localStorage.getItem("profile")).id;

      const response = await axios.put(
        SystemParametersEndpoints.editCameraParameters(id),
        cameraData,
        { headers }
      );
      await dispatch(
        SystemParametersActions.getCamerasByUserProfile(
          profileId,
          "all",
          "all",
          1,
          page_size,
          ""
        )
      );
      notifiToasterSuccess("Camera edited successfully!");
    } catch (error) {
      console.error("Error updating cameras", error);
      notifiToasterWarn("Failed to update cameras. Please try again.");
    }
  };
}

export default SystemParametersActions;
