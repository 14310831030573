import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import AddEditUserDialog from "./AddEditUserDialog";
import ConfirmationDialog from "../../../../components/common/ConfirmationDailog";
import { useDispatch, useSelector } from "react-redux";
import AccessControlActions from "../../../../redux/Action/AccessControlAction";
import PropTypes from "prop-types";

const UserTable = ({ searchValue }) => {
  const [isAddEditMemberOpen, setIsAddEditMemberOpen] = useState(false);
  const [isDeleteUserOpen, setIsDeleteUserOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [access_permission] = useState(
    JSON.parse(localStorage.getItem("agx_permission")).Profile ?? []
  );
  const isShowActionTab =
    access_permission.includes("delete_user") ||
    access_permission.includes("edit_user");
  // const [userList, setUserList] = useState(userData);
  const userList = useSelector((state) => state.accessControl.userList);
  const filteredUser = useSelector((state) => state.accessControl.filteredUser);
  const dispatch = useDispatch();
  const isUserLoading = useSelector((state) => state.accessControl.userLoading);
  console.log(isShowActionTab);
  // useEffect(() => {
  //   dispatch(AccessControlActions.getUserToAccessControlList());
  // }, []);

  const handleDeleteUser = async (userId) => {
    try {
      // Await the dispatch call to delete the profile
      await dispatch(AccessControlActions.deleteUserToAccessControl(userId));

      // After the profile has been successfully deleted, proceed to get the profile list
      const userprofileId = JSON.parse(localStorage.getItem("profile")).id;
      await dispatch(AccessControlActions.getUserToAccessControlList());

      // Close the delete user modal/dialog
      setIsDeleteUserOpen(false);
    } catch (error) {
      console.error("Error during profile deletion:", error.message);
      // Optionally, handle the error (e.g., showing an error message to the user)
    } finally {
      setIsDeleteUserOpen(false);
    }
  };
  const handleShowDeleteConfirmation = (userId) => {
    setSelectedUserId(userId);
    setIsDeleteUserOpen(true);
  };
  const handleAddEditGroup = (userId) => {
    setSelectedUserId(userId);
    setIsAddEditMemberOpen(true);
  };

  const handleActiveChange = (userId) => (event) => {
    console.log("useri s is fnjd", userId);
    const updatedUsers = userList.map((user) => {
      if (user.user_id === userId) {
        return { ...user, active_status: event.target.checked };
      }

      return user;
    });
    dispatch({ type: "ADD_DATA_TO_USER_LIST", payload: updatedUsers });
    dispatch(
      AccessControlActions.changeActiveStatus(userId, {
        is_active: event.target.checked,
      })
    );

    console.log(`User ID: ${userId}, Active: ${event.target.checked}`);
  };

  return (
    <div>
      <TableContainer component={Paper} className="mt-3">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>S.No</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>User Name</TableCell>
              <TableCell>Profile Type</TableCell>
              <TableCell>Group</TableCell>
              {access_permission.includes("change_user_status") && (
                <TableCell>Active</TableCell>
              )}
              {isShowActionTab && <TableCell align="center">Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {isUserLoading ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  Loading Data ...
                </TableCell>
              </TableRow>
            ) : searchValue.length > 0 && filteredUser.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No Data Found
                </TableCell>
              </TableRow>
            ) : (
              (searchValue.length === 0 ? userList : filteredUser).map(
                (user, index) => (
                  <TableRow key={user.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.username}</TableCell>
                    <TableCell>{user.profile_name}</TableCell>
                    <TableCell>{user.group_name}</TableCell>
                    {access_permission.includes("change_user_status") && (
                      <TableCell sx={{ padding: 0, margin: 0 }}>
                        <Switch
                          checked={user.active_status}
                          onChange={handleActiveChange(user.user_id)}
                        />
                      </TableCell>
                    )}
                    {isShowActionTab && (
                      <TableCell align="center" sx={{ padding: 0, margin: 0 }}>
                        <span>
                          {access_permission.includes("edit_user") && (
                            <IconButton
                              aria-label="edit"
                              onClick={() =>
                                handleAddEditGroup(user.user_profile_id)
                              }
                            >
                              <ModeEditIcon />
                            </IconButton>
                          )}
                          {access_permission.includes("delete_user") && (
                            <IconButton
                              aria-label="delete"
                              onClick={() =>
                                handleShowDeleteConfirmation(
                                  user.user_profile_id
                                )
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </span>
                      </TableCell>
                    )}
                  </TableRow>
                )
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmationDialog
        title={"Delete User"}
        dialogContent={"Are you sure to delete the user"}
        handleConfirm={() => handleDeleteUser(selectedUserId)}
        handleDisconfirm={() => setIsDeleteUserOpen(false)}
        isOpen={isDeleteUserOpen}
        handleClose={() => setIsDeleteUserOpen(false)}
        confirmButtonLabel={"Yes"}
        disconfirmButtonLabel={"No"}
        confirmButtonVariant={"contained"}
        disconfirmButtonVariant={"outlined"}
      />
      <AddEditUserDialog
        isOpen={isAddEditMemberOpen}
        handleClose={() => setIsAddEditMemberOpen(false)}
        userId={selectedUserId}
        isEditable={true}
      />
    </div>
  );
};

export default UserTable;
UserTable.propTypes = {
  searchValue: PropTypes.string,
};
