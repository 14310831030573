const initialState = {
  facilityList:
    JSON.parse(sessionStorage.getItem("facilityListSystemParameters")) || [],
  nvrList: JSON.parse(sessionStorage.getItem("nvrListSystemParameters")) || [],
  cameraList: JSON.parse(
    sessionStorage.getItem("cameraListSystemParameters")
  ) || { count: 0, data: [] },
};

export default function SystemParametersReducers(state = initialState, action) {
  switch (action.type) {
    case "SYSTEM_PARAMTER_ADD_FACILITY_LIST":
      return {
        ...state,
        facilityList: action.payload,
      };
    case "SYSTEM_PARAMTER_ADD_NVR_LIST":
      return {
        ...state,
        nvrList: action.payload,
      };
    case "SYSTEM_PARAMTER_ADD_CAMERA_LIST":
      return {
        ...state,
        cameraList: action.payload,
      };
    case "SYSTEM_PARAMTER_UPDATE_CAMERA_DETAIL": {
      const updatedCameraList = state.cameraList.data.map((camera) =>
        camera.id === action.payload.id
          ? { ...camera, ...action.payload }
          : camera
      );
      return {
        ...state,
        cameraList: {
          ...state.cameraList,
          data: updatedCameraList,
        },
      };
    }
    default:
      return state;
  }
}
