import React, { useEffect } from "react";
import { MenuItem, Select } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import AlertTableActions from "../../../redux/Action/AlertTableActions";
import AlertDashAction from "../../../redux/Action/AlertDashAction";
const inputselect = {
  boxShadow: "none",
  borderRadius: "8px",
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
  MenuProps: {
    PaperProps: {
      style: {
        backgroundColor: "var(--top-bar-bg-color)",
        maxHeight: 350,
        width: 50,
        overflowX: "auto",
      },
    },
  },
};
const priorityOption = [
  { value: "high", label: "High" },
  { value: "normal", label: "Normal" },
];
const PrioityFilter = (propTypes) => {
  var profile = JSON.parse(localStorage.getItem("profile"));
  const selectedPriority = useSelector(
    (state) => state.AlertTableStates.selectedPriority
  );
  const totalAlertEvent = useSelector(
    (state) => state.AlertTableStates.totalAlertEvent
  );
  const selectedAlertType = useSelector(
    (state) => state.AlertTableStates.selectedAlertType
  );
  const dispatch = useDispatch();

  const handelAlertTypeChange = (event) => {
    if (event.target.value == "all") {
      if (selectedAlertType.value == "all") {
        dispatch(AlertTableActions.updateAlertOrEvent("all"));
      }
      dispatch(AlertTableActions.updateAlertType({ value: "all" }));
      dispatch(AlertTableActions.updateAlertTypesSelected(totalAlertEvent));
    } else if (event.target.value == "high") {
      let highAlerts = totalAlertEvent.filter(
        (item) => item.alert_or_event === "Alert"
      );
      dispatch(AlertTableActions.updateAlertOrEvent("alert"));
      dispatch(AlertTableActions.updateAlertType({ value: "all" }));
      dispatch(AlertTableActions.updateAlertTypesSelected(highAlerts));
    } else {
      let normalAlerts = totalAlertEvent.filter(
        (item) => item.alert_or_event === "Event"
      );
      dispatch(AlertTableActions.updateAlertOrEvent("event"));
      dispatch(AlertTableActions.updateAlertTypesSelected(normalAlerts));
    }
    if (propTypes.type === "AlertTable") {
      dispatch(
        AlertTableActions.updateSelectedPriority({ value: event.target.value })
      );
      dispatch(AlertTableActions.updateAlertType({ value: "all" }));
    } else {
      dispatch(
        AlertDashAction.updateSelectedPriority({ value: event.target.value })
      );
    }
  };

  return (
    <div>
      <div className="select-input-lebel">Priority</div>
      <Select
        value={selectedPriority.value}
        sx={{
          color: "var(--card-text-color)",
          ...inputselect,
        }}
        className="select-input-field"
        MenuProps={inputselect.MenuProps}
        onChange={handelAlertTypeChange}
      >
        <MenuItem value="all" sx={{ color: "var(--card-text-color)" }}>
          All
        </MenuItem>
        {priorityOption.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={{
              paddingTop: "2px",
              paddingBottom: "2px",
              color: "var(--card-text-color)",
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default PrioityFilter;
