import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import CheckboxAutocomplete from "../CommonComponent/CheckboxAutoComplete";
import { useDispatch, useSelector } from "react-redux";
import AccessControlActions from "../../../../redux/Action/AccessControlAction";
import { notifiToasterSuccess } from "../../../../components/common/UseToast";

export default function ManageMembers({ isOpen, handleClose, userId }) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const userOptions = useSelector((state) => state.accessControl.userOptions);
  const profileList = useSelector((state) => state.accessControl.profileList);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen && userId !== -1) {
      const selectedUser =
        profileList.find((item) => item.id === userId)?.selected_user || [];
      setSelectedOptions(
        selectedUser.map(
          (userId) => userOptions.find((option) => option.id === userId) || {}
        )
      );
    }
  }, [isOpen, userId, profileList, userOptions]);

  const handleChange = (event, newValue) => {
    setSelectedOptions(newValue);
  };

  const handleModifyMembers = async () => {
    const updatedUserList = selectedOptions.map((item) => item.id);
    if (updatedUserList.length > 0) {
      try {
        setLoading(true); // If you wish to show loading state
        await dispatch(
          AccessControlActions.updateProfileToAccessControl(
            { selected_user: updatedUserList },
            userId,
            "patch"
          )
        );

        const userprofileId = JSON.parse(localStorage.getItem("profile")).id;
        await dispatch(
          AccessControlActions.getProfileListViaUserprofile(userprofileId)
        );

        handleClose();
      } catch (error) {
        // Handle any errors that occur during the update
        console.error("Failed to modify members:", error);
      } finally {
        setLoading(false); // Reset loading state in both success and error cases
      }
    } else {
      notifiToasterSuccess("Nothing to change");
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      maxWidth="sm"
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "sm",
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Manage Users
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500], // Changed to use theme for color
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ minWidth: "300px" }}>
        <CheckboxAutocomplete
          value={selectedOptions}
          onChange={handleChange}
          label="Update Members"
          options={userOptions}
          size="medium"
          error={false}
        />
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={handleModifyMembers}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? "Saving..." : "Add Users"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ManageMembers.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired, // Changed to expect a number since IDs are typically numeric
};
