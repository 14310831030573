exports.restructureResponse = (data) => {
  const newData = {
    received_at:
      data.notification_type === "alerts"
        ? data.alert_recieved_at
        : data.event_recieved_at,
    facility: data.facility,
    camera: data.camera,
    nvr: data.nvr,
    image:
      data.notification_type === "alerts" ? data.alert_image : data.event_image,
    type:
      data.notification_type === "alerts" ? data.alert_type : data.event_type,
    message:
      data.notification_type === "alerts"
        ? data.alert_message
        : data.event_message,
    priority: data.notification_type === "alerts" ? "High" : "Normal",
    id: data.id,
    remark: data.remark,
  };
  return newData;
};
