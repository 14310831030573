const initialState = {
  selectedMode: { value: "day-wise" },
  selectedFacility: { value: "all" },
  selectedDevice: { value: "all" },
  selectedAlertType: { value: "all" },
  time: [0, 23.99],
  timeRange: [0, 23.59],
  selectedClient: { value: "all" },
  searchValue: "",
  searchValueLength: 0,
  currentPage: 1,
  tabValue: [false, false],
  tabName: "alert",
  AlertTypes:
    JSON.parse(sessionStorage.getItem("selected_notification_type")) ?? [],
  AlertPlayback: [],
  loadingTableData: false,
  selectedUseCase: { value: "all" },
  useCaseList: [{ label: "All", value: "all" }],
  selectedPriority: { label: "All", value: "all" },
  alertOrEvent: "all",
  totalAlertEvent:
    JSON.parse(sessionStorage.getItem("selected_notification_type")) ?? [],
  isModelOpen: false,
  playbackModalStream: [],
};
export default function AlertTableReducer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_SELECTED_MODE":
      return {
        ...state,
        selectedMode: action.payload,
      };
    case "UPDATE_FACILITY":
      return {
        ...state,
        selectedFacility: action.payload,
      };
    case "UPDATE_DEVICE":
      return {
        ...state,
        selectedDevice: action.payload,
      };
    case "UPDATE_CAMERA":
      return {
        ...state,
        selectedCamera: action.payload,
      };
    case "UPDATE_SELECTED_PRIORITY":
      return {
        ...state,
        selectedPriority: action.payload,
      };
    case "UPDATE_ALERT_OR_EVENT":
      return {
        ...state,
        alertOrEvent: action.payload,
      };
    case "UPDATE_TOTAL_ALERT_AND_EVENT":
      return {
        ...state,
        totalAlertEvent: action.payload,
      };
    case "UPDATE_MODEL_STATUS":
      return {
        ...state,
        isModelOpen: action.payload,
      };
    case "UPDATE_CLIENT":
      return {
        ...state,
        selectedClient: action.payload,
      };
    case "UPDATE_ALERT_TYPE":
      return {
        ...state,
        selectedAlertType: action.payload,
      };
    case "UPDATE_SEARCH_VALUE":
      return {
        ...state,
        searchValue: action.payload,
      };
    case "UPDATE_SEARCH_VALUE_LENGTH":
      return {
        ...state,
        searchValueLength: action.payload,
      };
    case "UPDATE_TIME":
      return {
        ...state,
        timeRange: action.payload,
      };
    case "UPDATE_CURRENT_PAGE":
      return {
        ...state,
        currentPage: action.payload,
      };
    case "UPDATE_TAB_VALUE":
      return {
        ...state,
        tabValue: action.payload,
      };
    case "UPDATE_TAB_NAME":
      return {
        ...state,
        tabName: action.payload,
      };
    case "UPDATE_ALERT_TYPE_SELECTED":
      return {
        ...state,
        AlertTypes: action.payload,
      };
    case "UPDATE_ALERT_PLAYBACK_STREAM":
      return {
        ...state,
        AlertPlayback: action.payload,
      };
    case "UPDATE_LOADING_DATA_FOR_TABLE":
      return {
        ...state,
        loadingTableData: action.payload,
      };
    case "UPDATE_USECASE_SELECTED":
      return {
        ...state,
        selectedUseCase: action.payload,
      };
    case "GET_USE_CASE_LIST":
      return {
        ...state,
        useCaseList: action.payload,
      };
    case "CLEAR_TABLE_STATE":
      return {
        ...state,
        initialState,
      };
    case "UPDATE_ALERT_EVENT_PLAYBACK_STREAM":
      return {
        ...state,
        playbackModalStream: action.payload,
      };
    case "RESET_ALERT_TABLE_STATES":
      return {
        ...state,
        selectedMode: { value: "day-wise" },
        selectedFacility: { value: "all" },
        selectedDevice: { value: "all" },
        selectedAlertType: { value: "all" },
        time: [0, 23.99],
        timeRange: [0, 23.59],
        selectedClient: { value: "all" },
        searchValue: "",
        searchValueLength: 0,
        currentPage: 1,
        tabValue: [false, false],
        tabName: "alert",
        AlertTypes: [],
        AlertPlayback: [],
        loadingTableData: false,
        selectedUseCase: { value: "all" },
        useCaseList: [{ label: "All", value: "all" }],
        selectedPriority: { label: "All", value: "all" },
        alertOrEvent: "all",
        totalAlertEvent: [],
      };
    default:
      return state;
  }
}
