const initialState = {
  faceDetectedList: JSON.parse(sessionStorage.getItem("faceListData")) ?? [],
  personRecognized: "unmatched",
  currentPage: 1,
  selectedView: "grid",
  selectedTab: "all",
  pageSize: 9,
  userDetails: {
    id: 0,
    name: "",
    department: "",
    gender: "",
    age: "",
    teamId: "",
  },
  selectedFacility: JSON.parse(
    sessionStorage.getItem("face_rec_selected_facility")
  ) ?? { value: "all" },
  selectedCamera: { value: "all" },
  selectedMode: { value: "day-wise" },
  dateRange: {
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    key: "selection",
  },
  selectedDate: new Date().toISOString(),
  showDetailId: "",
  dataLoading: false,
  knownFaceList: [],
  knowFaceDataCount: 0,
  knownFaceLoading: false,
  currentPageKnown: 1,
};

export default function FaceRecognitionReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_FACE_DETECTED_LIST": {
      return { ...state, faceDetectedList: action.payload };
    }
    case "UPDATE_SELECTED_VIEW":
      return { ...state, selectedView: action.payload };
    case "UPDATE_CURRENT_PAGE_FACE_RECOGNITION":
      return { ...state, currentPage: action.payload };
    case "UPDATE_PAGE_SIZE_FACE_RECOGNITION":
      return { ...state, pageSize: action.payload };
    case "UPDATE_SELECTED_TAB_FACE_RECOGNITION":
      return { ...state, selectedTab: action.payload };
    case "SET_DETAILS":
      return { ...state, userDetails: action.payload };

    case "ALL_FACE_DETECTED":
      return { ...state, allFaceDetected: action.payload };

    case "UPDATE_FACE_RECOGNITION_SELECTED_FACILITY":
      return { ...state, selectedFacility: action.payload };

    case "UPDATE_FACE_RECOGNITION_SELECTED_CAMERA":
      return { ...state, selectedCamera: action.payload };

    case "SET_IS_PERSON_RECOGNIZED":
      return { ...state, personRecognized: action.payload };

    case "GET_SHOW_DETAIL_ID":
      return { ...state, showDetailId: action.payload };
    case "UPDATE_DATE_RANGE_FACE_RECOGNITION":
      return { ...state, dateRange: action.payload };
    case "UPDATE_DATE_FACE_RECOGNITION":
      return { ...state, selectedDate: action.payload };
    case "UPDATE_MODE_CHANGE_FACE_RECOGNITION":
      return { ...state, selectedMode: action.payload };
    case "UPDATE_LOADING_FOR_FETCH_DATA":
      return { ...state, dataLoading: action.payload };
    case "UPDATE_KNOWN_FACE_LIST":
      return { ...state, knownFaceList: action.payload };
    case "UPDATE_KNOWN_FACE_LOADING":
      return { ...state, knownFaceLoading: action.payload };
    case "UPDATE_KNOWN_FACE_DATA_COUNT":
      return { ...state, knowFaceDataCount: action.payload };
    case "UPDATE_KNOWN_FACE_CURRENT_PAGE":
      return { ...state, currentPageKnown: action.payload };
    default:
      return state;
  }
}
