import React from "react";
import classnames from "classnames";
import { usePagination, DOTS } from "./usePagination";
import "../../assets/css/pagination.scss";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useState } from "react";
import AlertTableActions from "../../redux/Action/AlertTableActions";
import { useDispatch } from "react-redux";
import { updateCurrentPage } from "../../redux/Action/RhmsAction";
import AnprActions from "../../redux/Action/AnprActions";
const Pagination = (propTypes) => {
  const [inputedPage, setInputPage] = useState("");
  const handleInputPageChange = (event) => {
    setInputPage(event.target.value);
  };
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
    usedIn,
  } = propTypes;
  const dispatch = useDispatch();
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };
  function handleGoToPage(lastPage) {
    if (inputedPage <= lastPage && inputedPage) {
      if (usedIn === "alerts") {
        dispatch(AlertTableActions.updateCurrentPage(Number(inputedPage)));
        setInputPage("");
      }
      if (usedIn === "rhms") {
        dispatch(updateCurrentPage(Number(inputedPage)));
        setInputPage("");
      }
      if (usedIn === "AnprDashboard") {
        dispatch(AnprActions.updateCurrentPage(Number(inputedPage)));
        setInputPage("");
      }
    }
  }
  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul
      className={classnames("pagination-container", { [className]: className })}
    >
      <div className="pagination-item-container">
        <li
          className={classnames("pagination-item", {
            disabled: currentPage === 1,
          })}
          onClick={onPrevious}
        >
          <div className="arrow left" />
        </li>
        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return (
              <li key={`dots${index}`} className="pagination-item dots">
                &#8230;
              </li>
            );
          }

          return (
            <li
              key={`page${pageNumber}`}
              className={classnames("pagination-item", {
                selected: pageNumber === currentPage,
              })}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </li>
          );
        })}
        <li
          className={classnames("pagination-item", {
            disabled: currentPage === lastPage,
          })}
          onClick={onNext}
        >
          <div className="arrow right" />
        </li>
      </div>
      {propTypes.usedIn !== "videowall" && (
        <div className="go-to-page-div">
          <input
            type="number"
            max={lastPage}
            min={1}
            value={inputedPage}
            className="input-inside-pagination"
            onChange={(e) => handleInputPageChange(e)}
            placeholder="Go to"
          />
          <button
            className="button-input-inside-pagination"
            disabled={inputedPage > lastPage || !inputedPage ? true : false}
            onClick={() => handleGoToPage(lastPage)}
          >
            <KeyboardDoubleArrowRightIcon />
          </button>
        </div>
      )}
    </ul>
  );
};

export default Pagination;
