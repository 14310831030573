import React from "react";
import Chart from "react-apexcharts";
export const AlertPiechart = (propTypes) => {
  const options = {
    series: propTypes.data[1].data,
    colors: ["#6C63F0", "#FB466C", "#ED589D", "#3AACFF", "#B750BE"],
    chart: {
      width: "100%",
      type: "pie",
    },
    labels: propTypes.data[0].data,
    legend: {
      show: true,
      labels: {
        colors: "var(--card-text-color)",
      },
      fontWeight: 400,
      formatter: function (val) {
        return val.replace(/_/g, " ").replace(/\b\w/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.slice(1);
        });
      },
      position: window.innerWidth < 520 ? "bottom" : "bottom",
    },
  };

  return (
    <div className="alertchartdiv">
      <div className="chart-div">
        <div
          style={{
            textAlign: "left",
            marginLeft: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <div className="chart-title">{propTypes.head}</div>
            <span className="alert-chart-text">Types</span>
          </div>
          {/* Uncomment the code below to add an icon */}
        </div>
        <div style={{ width: "100%", textAlign: "center" }}>
          <center>
            <Chart
              width={window.innerWidth < 520 ? "250" : "480"}
              height="350"
              options={options}
              series={options.series}
              type="pie"
              className="alert-pie-chart-text"
            />
          </center>
        </div>
      </div>
    </div>
  );
};
