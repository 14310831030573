import { useState } from "react";
import { useSelector } from "react-redux";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
} from "@mui/material";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import SidebarItem from "./SidebarItem";
import PropTypes from "prop-types";
const SidebarItemCollapse = ({ item }) => {
  const state = useSelector((state) => state);
  const toggle = state.commonData.toggleSidebar;
  const [open, setOpen] = useState(false);
  return item.sidebarProps ? (
    <>
      <ListItemButton
        onMouseUp={() => setOpen(!open)}
        sx={{
          "&: hover": {
            backgroundColor: "var(--top-bar-bg-color)",
          },
          borderRadius: "10px",
          paddingY: "12px",
          paddingX: "12px",
        }}
      >
        <ListItemIcon
          sx={{
            color: "var(--card-text-color)",
            margin: "0px",
          }}
          style={{ margin: 0 }}
        >
          {item.sidebarProps.icon && item.sidebarProps.icon}
        </ListItemIcon>
        {toggle ? (
          <div className="showIcon">
            {open ? (
              <ExpandLessOutlinedIcon
                sx={{
                  color: "var(--card-text-color)",
                }}
              />
            ) : (
              <ExpandMoreOutlinedIcon
                sx={{
                  color: "var(--card-text-color)",
                }}
              />
            )}
          </div>
        ) : null}

        {!toggle && (
          <ListItemText
            onDoubleClick={() => setOpen(!open)}
            disableTypography
            primary={
              <h5 style={{ margin: "0px", padding: "0px" }}>
                {item.sidebarProps.displayText}
              </h5>
            }
          />
        )}
        {toggle ? null : open ? (
          <ExpandLessOutlinedIcon
            sx={{
              color: "var(--card-text-color)",
            }}
          />
        ) : (
          <ExpandMoreOutlinedIcon
            sx={{
              color: "var(--card-text-color)",
            }}
          />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto">
        <List sx={{ paddingY: "0px", paddingX: toggle ? "0px" : "20px" }}>
          {item.child?.map((route, index) =>
            route.sidebarProps ? (
              route.child ? (
                <SidebarItemCollapse item={route} key={index} />
              ) : (
                <SidebarItem item={route} key={index} />
              )
            ) : null
          )}
        </List>
      </Collapse>
    </>
  ) : null;
};
SidebarItemCollapse.propTypes = {
  item: PropTypes.shape({
    sidebarProps: PropTypes.shape({
      icon: PropTypes.node,
      displayText: PropTypes.string.isRequired,
    }).isRequired,
    child: PropTypes.arrayOf(
      PropTypes.shape({
        sidebarProps: PropTypes.object,
        path: PropTypes.string,
      })
    ),
  }).isRequired,
};
export default SidebarItemCollapse;
