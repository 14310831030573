import React, { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import EmailIcon from "@mui/icons-material/Email";
import mainCss from "../../assets/css/mainCss";
import DownloadIcon from "@mui/icons-material/Download";
import { reportDownloadByMail } from "../../redux/Action/CommonAction";
import {
  getLocalDataDownloadAlertByAccount,
  getLocalDataDownloadAlertByStore,
  getLocalDataDownloadAlertByCamera,
} from "../../redux/Action/AlertDataActon";
import { ColorRing } from "react-loader-spinner";

const TableDownloadAndEmail = () => {
  const state = useSelector((state) => state);
  const [isLoadingg, setIsLoadingg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const profile = JSON.parse(localStorage.getItem("profile"));
  const handleLocalDownloadData = () => {
    setIsLoading(true);

    const date = moment(state.commonData.alerteventDate);
    const formattedDate = date.format("YYYY-MM-DD");

    try {
      if (state.AlertTableStates.selectedFacility.value === "all") {
        const obj = {
          id: profile.id,
          alert_type: state.AlertTableStates.selectedAlertType.value,
          date: formattedDate,
          priority: state.AlertTableStates.selectedPriority.value,
          alertOrEvent: state.AlertTableStates.alertOrEvent,
        };
        getLocalDataDownloadAlertByAccount(obj)
          .then((res) => {
            setIsLoading(false);
            downloadReportToSystem(makeDownloadableData(res.data.data));
          })
          .catch((error) => {
            setIsLoading(false);
            console.error(
              "Error in getLocalDataDownloadAlertByAccount:",
              error
            );
          });
      } else if (state.AlertTableStates.selectedDevice.value === "all") {
        const obj = {
          id: state.AlertTableStates.selectedFacility.value,
          alert_type: state.AlertTableStates.selectedAlertType.value,
          date: formattedDate,
          priority: state.AlertTableStates.selectedPriority.value,
          alertOrEvent: state.AlertTableStates.alertOrEvent,
        };
        getLocalDataDownloadAlertByStore(obj)
          .then((res) => {
            setIsLoading(false);
            downloadReportToSystem(makeDownloadableData(res.data.data));
          })
          .catch((error) => {
            setIsLoading(false);
            console.error("Error in getLocalDataDownloadAlertByStore:", error);
          });
      } else {
        const obj = {
          id: state.AlertTableStates.selectedDevice.value,
          alert_type: state.AlertTableStates.selectedAlertType.value,
          date: formattedDate,
          priority: state.AlertTableStates.selectedPriority.value,
          alertOrEvent: state.AlertTableStates.alertOrEvent,
        };
        getLocalDataDownloadAlertByCamera(obj)
          .then((res) => {
            setIsLoading(false);
            downloadReportToSystem(makeDownloadableData(res.data.data));
          })
          .catch((error) => {
            setIsLoading(false);
            console.error("Error in getLocalDataDownloadAlertByCamera:", error);
          });
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error in handleLocalDownloadData:", error);
    }
  };

  const convertJsonToCSV = (objArray) => {
    var array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    var str = "";

    for (var i = 0; i < array.length; i++) {
      var line = "";

      for (var index in array[i]) {
        if (line !== "") line += ",";

        // Escape the field value
        var fieldValue = array[i][index];
        if (typeof fieldValue === "string") {
          fieldValue = `"${fieldValue.replace(/"/g, '""')}"`;
        }

        line += fieldValue;
      }

      str += line + "\r\n";
    }

    return str;
  };

  const makeDownloadableData = (data) => {
    return data.map((item) => ({
      date: moment
        .utc(item.alert_recieved_at || item.event_recieved_at)
        .format("YYYY-MM-DD"),
      time: moment
        .utc(item.alert_recieved_at || item.event_recieved_at)
        .format("hh:mm A"),
      camera: item.camera[0],
      facility: item.facility[0],
      alert_message: (item.alert_message || item.event_message).replace(
        /_/g,
        " "
      ),
      alert_type: (item.alert_type || item.event_type).replace(/_/g, " "),
      alert_image: item.alert_image || item.event_image,
      remark: item.remark,
      is_false: item.is_false ? "Yes" : "No",
      is_resolved: item.is_resolved ? "Yes" : "No",
    }));
  };

  const downloadReportToSystem = (csvStr) => {
    const date = moment(state.commonData.alerteventDate);
    const formattedDate = date.format("YYYY-MM-DD");
    csvStr.unshift({
      // alert_recieved_at: "Date And Time",
      date: "Date",
      time: "Time",
      camera: "Camera",
      site: "Site",
      alert_message: "Message",
      alert_type: "Type",
      alert_image: "Image",
      remark: "Remarks",
      is_false: "Dismiss Alert",
      is_resolved: "Resolve Alert",
    });
    var downloadLink = document.createElement("a");
    var blob = new Blob(["\ufeff", convertJsonToCSV(csvStr)]);
    var url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = `Alerts Report ${formattedDate}.csv`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  const handleSendEmailReport = () => {
    setIsLoadingg(true);
    const type = "alerts";
    reportDownloadByMail(
      state.commonData.alerteventDate,
      type,
      state.AlertTableStates.selectedMode,
      {
        startDate: new Date(state.commonData.dateRange.startDate),
        endDate: new Date(state.commonData.dateRange.endDate),
        key: "selection",
      }
    )
      .then((res) => {
        setIsLoadingg(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  return (
    <div>
      {state.AlertTableStates.selectedFacility.value !== "all" && (
        <div className="col mt-4 d-flex justify-content-end">
          {state.AlertTableStates.selectedMode.value == "day-wise" && (
            <button
              className="btn btn-sm m-1 shadow-none"
              style={mainCss.downloadButton}
              onClick={!isLoading ? handleLocalDownloadData : null}
            >
              {!isLoading ? (
                <DownloadIcon sx={mainCss.downloadIcon} />
              ) : (
                <ColorRing
                  visible={true}
                  height="100"
                  width="100"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#ffff", "#ffff", "#ffff", "#ffff", "#ffff"]}
                />
              )}
            </button>
          )}
          <button
            className="btn  btn-sm m-1 shadow-none"
            style={mainCss.downloadButton}
            onClick={handleSendEmailReport}
          >
            {!isLoadingg ? (
              <EmailIcon sx={mainCss.downloadIcon} />
            ) : (
              <ColorRing
                visible={true}
                height="100"
                width="100"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#ffff", "#ffff", "#ffff", "#ffff", "#ffff"]}
              />
            )}
          </button>
        </div>
      )}

      {state.AlertTableStates.selectedFacility.value == "all" && (
        <div className="col mt-3 d-flex justify-content-end">
          {state.AlertTableStates.selectedMode.value == "day-wise" && (
            <div
              className="btn  btn-sm m-1 shadow-none download-btn"
              style={mainCss.downloadButton}
              title="Download data"
              onClick={!isLoading ? handleLocalDownloadData : null}
            >
              {!isLoading ? (
                <DownloadIcon sx={mainCss.downloadIcon} />
              ) : (
                <ColorRing
                  visible={true}
                  height="100"
                  width="100"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#ffff", "#ffff", "#ffff", "#ffff", "#ffff"]}
                />
              )}
            </div>
          )}
          <div
            className="btn  btn-sm m-1 shadow-none"
            style={mainCss.downloadButton}
            onClick={handleSendEmailReport}
          >
            {!isLoadingg ? (
              <EmailIcon sx={mainCss.downloadIcon} />
            ) : (
              <ColorRing
                visible={true}
                height="100"
                width="100"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#ffff", "#ffff", "#ffff", "#ffff", "#ffff"]}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TableDownloadAndEmail;
