import buildUrlWithParams from "./AddUrlsWIthParams";
import AnalyticsDashboardEndpoints from "../features/AnalyticsDashboardEndpoints";
import axios from "axios";
function getDataByKey(dataArray, key) {
  for (const item of dataArray) {
    if (item[key]) {
      return item[key];
    }
  }
  return null;
}
class DwellTimeActiosCombined {
  /**
   * Fetches the data for dwell dime for people count.
   * The parameters include user profile id, facility id, camera id, date, date
   * range,Weekly .
   * If an error occurs, or the response is not in the provisioned format, the function
   * stops the loading and dispatches an action with a predefined structure.
   *
   * @param {object} params - Parameters used to form the URL for the data request.
   * @return {function} - A dispatch function that performs async data fetch and state update
   */
  static getDwellTimeForAllTypes(params) {
    return async (dispatch) => {
      dispatch({ type: "UPDATE_LOADING_FOR_AGE_DWELL_TIME", payload: true });
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      const urls =
        AnalyticsDashboardEndpoints.getDwellTimeDataByUserProfileFacilityDevice();
      axios
        .get(buildUrlWithParams(params, urls), { headers })
        .then((response) => {
          if (params.start_date == params.end_date) {
            dispatch({
              type: params.weekly
                ? "DWELL_TIME_WEEKLY_DATA"
                : "DWELLTIME_COUNT_HOURLY_DATA",
              payload: response.data,
              date: params.start_date,
            });
          }
        })
        .catch(() => {
          dispatch({
            type: "UPDATE_LOADING_FOR_AGE_DWELL_TIME",
            payload: false,
          });
        })
        .finally(() => {
          dispatch({
            type: "UPDATE_LOADING_FOR_AGE_DWELL_TIME",
            payload: false,
          });
        });
    };
  }
  /**
   * Fetches the data for dwell dime for people count.
   * The parameters include user profile id, facility id, camera id, date, date
   * range for nicobar  .
   * If an error occurs, or the response is not in the provisioned format, the function
   * stops the loading and dispatches an action with a predefined structure.
   *
   * @param {object} params - Parameters used to form the URL for the data request.
   * @return {function} - A dispatch function that performs async data fetch and state update
   */
  static getNicobarDwellTimeCountHourlyByAccountFacilityDevice(params) {
    return async (dispatch) => {
      try {
        const token = localStorage.getItem("token");
        const headers = { Authorization: `Token ${token}` };

        dispatch({ type: "UPDATE_LOADING_FOR_AGE_DWELL_TIME", payload: true });
        const urls =
          AnalyticsDashboardEndpoints.getDwellTimeDataByUserProfileFacilityDeviceForNicobar();
        const response = await axios.get(buildUrlWithParams(params, urls), {
          headers,
        });

        const dataTemp = response.data.data.map((item) => ({
          average_dwell_time: parseFloat(item.average_dwell_time.toFixed(1)),
          recorded_at_hour: new Date(
            new Date(item.recorded_at_hour).toLocaleString("en-US", {
              timeZone: "GMT",
            })
          ).getHours(),
        }));

        dispatch({
          type: "NICOBAR_DWEEL_TIME",
          payload: dataTemp,
          date: params.start_date,
        });
      } catch (error) {
        console.error("Error fetching dwell time data:", error);
      } finally {
        dispatch({ type: "UPDATE_LOADING_FOR_AGE_DWELL_TIME", payload: false });
      }
    };
  }
}
export default DwellTimeActiosCombined;
