import * as React from "react";
import PropTypes from "prop-types"; // Import PropTypes
import Signup from "./../Signup";
import OnboardingOrganization from "../OnboardOrganization";
import OnboadingFacility from "../OnboardFacility";
import OnboardNVROrStandalone from "../OnboardNVROrStandalone";
import OnboardCameras from "../OnboardCameras";
import OnboardServices from "../OnboardServices";
import { Box } from "@mui/material";

const StepContent = ({ activeStep, isEditable }) => (
  <center>
    <Box
      sx={{
        padding: 3,
        border: "1px solid #e0e0e0",
        borderRadius: 2,
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        minHeight: "60vh",
      }}
      className="col-12 col-md-10 col-lg-8"
    >
      <div>
        {activeStep === 0 ? (
          <Signup isEditable={isEditable} />
        ) : activeStep === 1 ? (
          <OnboardingOrganization />
        ) : activeStep === 2 ? (
          <OnboadingFacility />
        ) : activeStep === 3 ? (
          <OnboardNVROrStandalone />
        ) : activeStep === 4 ? (
          <OnboardCameras />
        ) : (
          <OnboardServices />
        )}
      </div>
    </Box>
  </center>
);

StepContent.propTypes = {
  activeStep: PropTypes.number.isRequired, // activeStep must be a number and is required
  isEditable: PropTypes.bool, // isEditable must be a boolean, not required
};

export default StepContent;
