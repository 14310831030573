import { BACKEND_URL } from "../../routes/config";
class EventApiSpec {
  constructor() {
    /**
     * Returns the URL for getting the opening time by facility.
     * @param {string} cam_id - The camera ID.
     * @param {string} date - The date for the opening time.
     * @returns {string} The URL for fetching the opening time.
     */
    this.getOpeningTimeByFacility = (cam_id, date) => {
      return `${BACKEND_URL}/events/dash/opening/${cam_id}/${date}/`;
    };

    /**
     * Returns the URL for getting the closing time by facility.
     * @param {string} cam_id - The camera ID.
     * @param {string} date - The date for the closing time.
     * @returns {string} The URL for fetching the closing time.
     */
    this.getClosingTimeByFacility = (cam_id, date) => {
      return `${BACKEND_URL}/events/dash/closing/${cam_id}/${date}/`;
    };

    /**
     * Returns the URL for sending event notification.
     * @param {string} event_id - The event ID.
     * @returns {string} The URL for sending event notification.
     */
    this.sendEventNotification = (event_id) => {
      return `${BACKEND_URL}/events/sendnotification/${event_id}/`;
    };

    /**
     * Returns the URL for downloading alerts data by account.
     * @param {string} account_id - The account ID.
     * @param {string} type - The type of data.
     * @param {string} date - The date for the data.
     * @returns {string} The URL for downloading alerts data by account.
     */
    this.getAlertsDataDownloadByAccount = (account_id, type, date) => {
      return `${BACKEND_URL}/events/report-download/userprofile/${account_id}/${type}/${date}/`;
    };

    /**
     * Returns the URL for downloading alerts data by store.
     * @param {string} store_id - The store ID.
     * @param {string} type - The type of data.
     * @param {string} date - The date for the data.
     * @returns {string} The URL for downloading alerts data by store.
     */
    this.getAlertsDataDownloadByStore = (store_id, type, date) => {
      return `${BACKEND_URL}/events/report-download/facility/${store_id}/${type}/${date}/`;
    };

    /**
     * Returns the URL for downloading alerts data by camera.
     * @param {string} cam_id - The camera ID.
     * @param {string} type - The type of data.
     * @param {string} date - The date for the data.
     * @returns {string} The URL for downloading alerts data by camera.
     */
    this.getAlertsDataDownloadByCamera = (cam_id, type, date) => {
      return `${BACKEND_URL}/events/report-download/camera/${cam_id}/${type}/${date}/`;
    };
  }
}

export default new EventApiSpec();
