import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import HistoryIcon from "@mui/icons-material/History";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import { notifiToasterInfo } from "../../../components/common/UseToast";

const LiveViewTransitionTimer = () => {
  const dispatch = useDispatch();
  const isTimerEnabled = useSelector((state) => state.vmsHelper.timerEnabled);
  const transitionTime = useSelector(
    (state) => state.vmsHelper.TransitionTimer
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [timerInSeconds, setTimerInSeconds] = useState(120);
  const [timeFormat, setTimeFormat] = useState("Seconds");
  const [isTimerOn, setTimerOn] = useState(false);
  const open = Boolean(anchorEl);

  useEffect(() => {
    setTimerInSeconds(transitionTime);
  }, [transitionTime]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFormatChange = (event) => {
    const newFormat = event.target.value;
    setTimeFormat(newFormat);
    if (newFormat === "Minutes") {
      setTimerInSeconds(timerInSeconds * 60);
    } else {
      setTimerInSeconds(timerInSeconds / 60);
    }
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setTimerInSeconds(timeFormat === "Minutes" ? value * 60 : value);
  };

  const handleApply = () => {
    if (timerInSeconds < 60) {
      notifiToasterInfo("Transition Timer should not be less than 1 Minute");
      return;
    }

    dispatch({
      type: "UPDATE_TIMER_ENABLED_CAMERAS",
      payload: isTimerOn,
    });
    dispatch({ type: "UPDATE_TRANSITION_TIMER", payload: timerInSeconds });
    handleClose();
  };

  const handleTimerChange = (e) => {
    setTimerOn(e.target.checked);
  };

  const displayTimerValue =
    timeFormat === "Minutes" ? timerInSeconds / 60 : timerInSeconds;

  return (
    <div>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Tooltip title="Set Transition Timer" arrow>
          <IconButton color="primary" onClick={handleClick}>
            <HistoryIcon fontSize="large" />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="transition-timer-menu"
        open={open}
        onClose={handleClose}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
      >
        <Box sx={{ paddingX: "1rem", width: 300 }}>
          <Box
            component="div"
            display="flex"
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant="subtitle1" component="div" mr={2}>
              Transition Timer
            </Typography>
            <Switch
              color="primary"
              checked={isTimerOn}
              onChange={handleTimerChange}
            />
          </Box>
          <Box component="div" mt={2}>
            <FormControl fullWidth variant="outlined" size="small">
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    variant="outlined"
                    size="small"
                    value={displayTimerValue}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <Select
                    value={timeFormat}
                    onChange={handleFormatChange}
                    fullWidth
                    size="small"
                  >
                    <MenuItem value="Seconds">Seconds</MenuItem>
                    <MenuItem value="Minutes">Minutes</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </FormControl>
          </Box>

          <Box component="div" mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleApply}
              fullWidth
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Menu>
    </div>
  );
};

export default LiveViewTransitionTimer;
