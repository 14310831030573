export function extractDateTime(isoString) {
  const date = new Date(isoString);

  // Extracting and formatting the date in DD-MM-YYYY format
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // getMonth() is zero-based
  const year = date.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;

  // Extracting and formatting the time in hh:mm:ss AM/PM format
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const formattedTime = `${hours
    .toString()
    .padStart(2, "0")}:${minutes}:${seconds} ${ampm}`;

  return { date: formattedDate, time: formattedTime };
}
