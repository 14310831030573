import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import CheckboxAutocomplete from "../CommonComponent/CheckboxAutoComplete";
import { useDispatch } from "react-redux";
import AccessControlActions from "../../../../redux/Action/AccessControlAction";
function AddEditProfileDialog({
  isOpen,
  handleClose,
  isEditable,
  userId,
  profileList = [],
}) {
  const [profileName, setProfileName] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [permissions, setPermissions] = useState({
    dashboard: [],
    alerts: [],
    liveview: [],
    profile: [],
    remote_monitoring: [],
    heatmap: [],
    face_recognition: [],
    customization: [],
  });
  const permissionList = useSelector(
    (state) => state.accessControl.permissionList
  );
  let pagesAllow = useSelector((state) => state.accessControl.pagesToShow);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isEditable && userId !== -1 && isOpen && permissionList.length > 0) {
      const user = profileList.find((item) => item.id === userId);
      if (user) {
        setProfileName(user.profile_name || "");
        setSelectedUsers(user.selected_user || []);
        // Assuming you know all possible categories or have derived them
        const allCategories = [
          "dashboard",
          "alerts",
          "liveview",
          "profile",
          "remote_monitoring",
          "heatmap",
          "face_recognition",
          "customization",
        ]; // Add or remove categories as necessary

        // Initialize all categories with empty arrays
        const initializedSelectedOptions = allCategories.reduce(
          (acc, category) => {
            acc[category] = [];
            return acc;
          },
          {}
        );

        // Group permissions by module_name
        const groupedPermissions = permissionList.reduce((acc, item) => {
          if (!acc[item.module_name]) acc[item.module_name] = [];
          acc[item.module_name].push({
            id: item.id,
            value: item.permission_name,
          });
          return acc;
        }, {});

        // Merge with initializedSelectedOptions to ensure all categories are present
        const updatedSelectedOptions = { ...initializedSelectedOptions };

        Object.keys(groupedPermissions).forEach((category) => {
          updatedSelectedOptions[category] = groupedPermissions[
            category
          ].filter((item) => user.permission.includes(item.id));
        });
        console.log(updatedSelectedOptions);
        setSelectedOptions(updatedSelectedOptions);
      }
    }
  }, [isEditable, isOpen, userId, profileList, permissionList]);

  useEffect(() => {
    try {
      // Function to filter and map permissions
      const filterAndMapPermissions = (moduleName) => {
        if (
          pagesAllow.includes(
            moduleName
              .replace(/_/g, "") // Remove underscores
              .toLowerCase()
          ) ||
          moduleName == "profile"
        ) {
          return permissionList
            .filter((item) => item.module_name === moduleName)
            .map((item) => ({ id: item.id, value: item.permission_name }));
        }

        return [];
      };
      setPermissions({
        dashboard: filterAndMapPermissions("dashboard"),
        alerts: filterAndMapPermissions("alerts"),
        liveview: filterAndMapPermissions("liveview"),
        profile: filterAndMapPermissions("profile"),
        remote_monitoring: filterAndMapPermissions("remote_monitoring"),
        heatmap: filterAndMapPermissions("heatmap"),
        face_recognition: filterAndMapPermissions("face_recognition"),
        customization: filterAndMapPermissions("customization"),
      });
      setSelectedUsers;
    } catch (error) {
      console.error("Error processing permissions", error);
      // Handle error appropriately here (e.g., set error state, show notification)
    }
  }, [permissionList]);

  const collectSelectedPermissionIds = (selectedOptions) => {
    // Extract all values from the selectedOptions object; each is an array of selected items
    const allSelected = Object.values(selectedOptions);

    // Flatten the array of arrays and map each item to its id
    const allIds = allSelected.flat().map((item) => item.id);

    return allIds;
  };

  // Simplified handling for selected options with one state object
  const [selectedOptions, setSelectedOptions] = useState({
    dashboard: [],
    alerts: [],
    liveview: [],
    profile: [],
    remote_monitoring: [],
    face_recognition: [],
    customization: [],
  });

  const handleChangeSelectedOptions = (moduleName, newValue) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [moduleName]: newValue,
    }));
  };
  function handleSave() {
    const profileData = {
      profile_name: profileName,
      selected_user: selectedUsers,
      permission: collectSelectedPermissionIds(selectedOptions),
      account: JSON.parse(localStorage.getItem("profile")).account.account_id,
    };

    if (isEditable) {
      dispatch(
        AccessControlActions.updateProfileToAccessControl(profileData, userId)
      ).then(() => {
        let userprofileId = JSON.parse(localStorage.getItem("profile")).id;
        dispatch(
          AccessControlActions.getProfileListViaUserprofile(userprofileId)
        );
        handleClose();
      });
    } else {
      dispatch(
        AccessControlActions.addProfileToAccessControl(profileData)
      ).then(() => {
        let userprofileId = JSON.parse(localStorage.getItem("profile")).id;
        dispatch(
          AccessControlActions.getProfileListViaUserprofile(userprofileId)
        );
        handleClose();
      });
    }
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      maxWidth="md"
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "md",
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        {isEditable ? "Edit Profile" : "Create Profile"}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "theme.palette.grey[500]",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="mt-4">
        <Box>
          <TextField
            fullWidth
            name="name"
            label="Profile Name"
            variant="outlined"
            margin="normal"
            value={profileName}
            onChange={(event) => setProfileName(event.target.value)}
          />
          <div className="row">
            {Object.keys(permissions)
              .filter((key) => permissions[key].length > 0 || key == "profile") // Filter keys where options length is greater than zero
              .map((key) => (
                <div className="mt-3 col-12 col-md-6" key={key}>
                  <CheckboxAutocomplete
                    value={selectedOptions[key]}
                    onChange={(event, newValue) =>
                      handleChangeSelectedOptions(key, newValue)
                    }
                    label="Select Options"
                    heading={`${
                      key.charAt(0).toUpperCase() + key.slice(1)
                    } Permissions`}
                    options={permissions[key]}
                    size="medium"
                    error={false}
                  />
                </div>
              ))}
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave} variant="contained" color="primary">
          {isEditable ? "Save Changes" : "Create Profile"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AddEditProfileDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  isEditable: PropTypes.bool,
  userId: PropTypes.number,
  profileList: PropTypes.array,
};

export default AddEditProfileDialog;
