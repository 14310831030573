import axios from "axios";
import FaceRecEndpoints from "../features/FaceRecognitionEndPoints";
import { notifiToasterSuccess } from "../../components/common/UseToast";
import { notifiToasterWarn } from "../../components/common/UseToast";
function buildUrlWithParams(params, base_url) {
  // Initialize URL with the base part
  const url = new URL(base_url);

  // Iterate over the params object
  Object.keys(params).forEach((key) => {
    if (params[key] !== undefined && params[key] !== null) {
      // Append only if the parameter is not undefined or null
      url.searchParams.append(key, params[key]);
    }
  });

  // Return the final URL
  return url.toString();
}
class FaceRecognitionActions {
  // To upload the details
  static addFaceDetails(data) {
    return async (dispatch) => {
      try {
        const response = await axios.post(
          FaceRecEndpoints.uploadKnownFaceDetails(),
          data
        );

        if (response.data.message.includes("No face")) {
          notifiToasterWarn("No face detected");
        } else {
          console.log("Face detected");
          dispatch({ type: "SET_DETAILS", payload: response.data });
          notifiToasterSuccess("Details Saved");
        }
      } catch (error) {
        console.error("Update Details Error:", error);
        notifiToasterWarn("Not Saved");
      }
    };
  }

  static updateFacility(data) {
    return {
      type: "UPDATE_FACE_RECOGNITION_SELECTED_FACILITY",
      payload: data,
    };
  }
  static updateDevice(data) {
    return {
      type: "UPDATE_FACE_RECOGNITION_SELECTED_CAMERA",
      payload: data,
    };
  }
  static cancelTokenSource = null;
  static getFaceRecognitionDataTable(params) {
    return async (dispatch) => {
      // Check if there is an existing request and cancel it
      if (FaceRecognitionActions.cancelTokenSource) {
        FaceRecognitionActions.cancelTokenSource.cancel(
          "Operation canceled due to new request."
        );
      }

      // Create a new CancelToken
      FaceRecognitionActions.cancelTokenSource = axios.CancelToken.source();

      try {
        dispatch({ type: "UPDATE_LOADING_FOR_FETCH_DATA", payload: true });
        const baseUrl = FaceRecEndpoints.getFaceRecognitionDataTable();
        const urlWithParams = buildUrlWithParams(params, baseUrl);

        const response = await axios.get(urlWithParams, {
          cancelToken: FaceRecognitionActions.cancelTokenSource.token,
        });

        FaceRecognitionActions.cancelTokenSource = null;

        dispatch({
          type: "SET_FACE_DETECTED_LIST",
          payload: response.data,
        });
        sessionStorage.setItem("faceListData", JSON.stringify(response.data));
        dispatch({ type: "UPDATE_LOADING_FOR_FETCH_DATA", payload: false });
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else {
          console.error("Error fetching data:", error);
          dispatch({
            type: "SET_FACE_DETECTED_LIST",
            payload: [],
          });
        }
        // Only update loading state to false if this was not a cancellation
        if (!axios.isCancel(error)) {
          dispatch({ type: "UPDATE_LOADING_FOR_FETCH_DATA", payload: false });
        }
      }
    };
  }

  static updateDate(date) {
    return async (dispatch) => {
      dispatch({
        type: "UPDATE_DATE_FACE_RECOGNITION",
        payload: date.toISOString(),
      });
    };
  }

  static updateDateRange(dateRange) {
    return async (dispatch) => {
      dispatch({
        type: "UPDATE_DATE_RANGE_FACE_RECOGNITION",
        payload: dateRange,
      });
    };
  }
  static updateModeChange(mode) {
    return async (dispatch) => {
      dispatch({
        type: "UPDATE_MODE_CHANGE_FACE_RECOGNITION",
        payload: mode,
      });
    };
  }
  static uptaeSelectedTabFaceRec(value) {
    return async (dispatch) => {
      dispatch({
        type: "UPDATE_SELECTED_TAB_FACE_RECOGNITION",
        payload: value,
      });
    };
  }
  static async getTop5MatchedRecognized(uuid, facility_id) {
    try {
      const baseUrl = FaceRecEndpoints.getDataForTop5matchedRecognized(
        uuid,
        facility_id
      );
      const response = await axios.get(baseUrl);

      if (response.data) {
        return response.data;
      } else {
        throw new Error("No data received");
      }
    } catch (error) {
      return [];
    }
  }
  static async getTop5MatchedUnRecognized(uuid, facility_id) {
    try {
      const baseUrl = FaceRecEndpoints.getDataForTop5matchedUnRecognized(
        uuid,
        facility_id
      );
      const response = await axios.get(baseUrl);

      if (response.data) {
        return response.data;
      } else {
        throw new Error("No data received");
      }
    } catch (error) {
      return [];
    }
  }
  static getListOfKnownFaces(userprofileid, currentPage = 1, page_size) {
    return async (dispatch) => {
      dispatch({
        type: "UPDATE_KNOWN_FACE_LOADING",
        payload: true,
      });
      try {
        const baseUrl = FaceRecEndpoints.knownFacesDataByUserProfileId(
          userprofileid,
          currentPage,
          page_size
        );
        const response = await axios.get(baseUrl);

        if (response.status == 200) {
          dispatch({
            type: "UPDATE_KNOWN_FACE_LIST",
            payload: response.data.results,
          });
          dispatch({
            type: "UPDATE_KNOWN_FACE_DATA_COUNT",
            payload: response.data.count,
          });
        } else {
          dispatch({
            type: "UPDATE_KNOWN_FACE_LIST",
            payload: [],
          });
        }
      } catch (error) {
        dispatch({
          type: "UPDATE_KNOWN_FACE_LIST",
          payload: [],
        });
      } finally {
        dispatch({
          type: "UPDATE_KNOWN_FACE_LOADING",
          payload: false,
        });
      }
    };
  }
  static async deleteKnownFaceData(id) {
    try {
      const baseUrl = FaceRecEndpoints.knownFacesDataById(id); // Ensure this function returns a valid URL
      const response = await axios.delete(baseUrl);

      if (response.status === 204) {
        notifiToasterSuccess("Data deleted successfully");
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }
  static modifyFaceDetails(data, id) {
    return async (dispatch) => {
      try {
        const response = await axios.patch(
          FaceRecEndpoints.knownFacesDataById(id),
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data", // This is important when sending files
            },
          }
        );

        if (response.status === 200) {
          notifiToasterSuccess("Details Uploaded Successfully");
        } else {
          notifiToasterWarn("Failed To Update Details");
        }
      } catch (error) {
        notifiToasterWarn("Details Updated");
      }
    };
  }
}

export default FaceRecognitionActions;
