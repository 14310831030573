import React, { useEffect, useState, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  Typography,
  Button,
} from "@mui/material";
import { getEmailSettingList } from "../../../redux/Action/ProfileAction";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import AccessControlActions from "../../../redux/Action/AccessControlAction";
import CheckboxAutocomplete from "../AccessControl/CommonComponent/CheckboxAutoComplete";
import GroupedCheckboxAutocomplete from "../AccessControl/CommonComponent/GroupCheckboxAutoComplete";
import Account from "../../../redux/Action/CommonAction";
import { Bars } from "react-loader-spinner";
import EmailFreeSolo from "./utils/EmailFreeSolo";
import {
  addEmailCustomSetting,
  updateEmailSettingInstance,
} from "../../../redux/Action/ProfileAction";

export default function EmailSettingModal({
  isOpen,
  handleClose,
  isEditable,
  data,
}) {
  const [errors, setErrors] = useState({
    selectedFacilities: "",
    selectedNVRs: "",
    selectedCameras: "",
    selectedDevices: "",
    toList: "",
  });
  const [addLoading, setAddLoading] = useState(false);
  const [selectedFacilities, setSelectedFacilities] = useState([]);
  const [selectedNVRs, setSelectedNVRs] = useState([]);
  const [selectedCameras, setSelectedCameras] = useState([]);
  const [selectedAlert, setSelectedAlert] = useState([]);
  const [formattedFacilityList, setFromattedFacilityList] = useState([]);
  const profile = JSON.parse(localStorage.getItem("profile"));
  const dispatch = useDispatch();
  const nvrList = useSelector((state) =>
    state.accessControl.nvrList.filter((item) => item.value !== "All")
  );

  const cameraList = useSelector((state) =>
    state.accessControl.cameraList.filter((item) => item.value !== "All")
  );
  const facilitiesList = useSelector((state) => state.commonData.storeList);
  const alertList = useSelector((state) => state.AlertTableStates.AlertTypes);

  const [alertTypeOptions, setAlertOptions] = useState([]);
  const [toList, setToList] = useState([]);
  const [ccList, setCCList] = useState([]);
  const [loading, setLoading] = useState(false);

  // Ref to control the effect running only once per modal open
  const hasRunEffect = useRef(false);
  const NvrRunEffect = useRef(false);
  useEffect(() => {
    setAlertOptions(
      alertList.map((item) => ({
        id: item.notification_type,
        value: item.name,
      }))
    );
  }, [alertList]);

  useEffect(() => {
    const fetchData = async () => {
      if (!isEditable || !isOpen || formattedFacilityList.length === 0) return;

      const facilityIdsTemp = new Set(data.facilities.map((item) => item.id));
      const newSelectedFacilities = formattedFacilityList.filter((item) =>
        facilityIdsTemp.has(item.id)
      );

      setSelectedFacilities(newSelectedFacilities);

      setSelectedAlert(
        alertTypeOptions.filter((item) => data.alert_types.includes(item.id))
      );
      setCCList(data.cc_list);
      setToList(data.to_list);

      const facilitiesList = newSelectedFacilities.map(({ id }) => id);
      dispatch(
        AccessControlActions.replaceDataFromFacility(facilitiesList, "nvr")
      );
    };

    fetchData();
  }, [isEditable, isOpen, data, alertTypeOptions, dispatch]);

  useEffect(() => {
    if (
      nvrList.length > 0 &&
      selectedNVRs.length === 0 &&
      data &&
      isOpen &&
      !NvrRunEffect.current
    ) {
      const nvrIdsTemp = new Set(data.nvrs.map((item) => item.id));
      const newSelectedNVRs = nvrList.filter((item) => nvrIdsTemp.has(item.id));
      setSelectedNVRs(newSelectedNVRs);
      NvrRunEffect.current = true;
      dispatch(
        AccessControlActions.replaceCameraFromNVR(
          newSelectedNVRs.map(({ id }) => id)
        )
      );
    }
  }, [nvrList, isOpen]);

  useEffect(() => {
    if (
      cameraList.length > 0 &&
      selectedCameras.length === 0 &&
      data &&
      isOpen &&
      !hasRunEffect.current
    ) {
      const cameraIdsTemp = new Set(data.cameras.map((item) => item.id));
      const newSelectedCameras = cameraList.filter(
        (item) => cameraIdsTemp.has(item.id) && selectedNVRs.includes
      );
      setSelectedCameras(newSelectedCameras);
      hasRunEffect.current = true; // Mark it as run
    }
  }, [cameraList, isOpen]);

  const handleCcChange = (newValue) => {
    setCCList(newValue);
  };

  const handleToChange = (newValue) => {
    setToList(newValue);
  };

  useEffect(() => {
    if (isOpen) {
      Account.getStoresByAccount(profile.id)(dispatch);
    }
  }, [isOpen, dispatch, profile.id]);

  useEffect(() => {
    const restructureFacility = facilitiesList
      .filter((item) => !item.text.toLowerCase().includes("all"))
      .map((item) => ({
        id: item.value,
        value: item.text,
      }));

    setFromattedFacilityList(restructureFacility);
  }, [facilitiesList]);

  function handleFacilityChange(event, newValue) {
    const containsAll = newValue.some(({ value }) => value === "All");
    let newFacilities = newValue;

    if (containsAll) {
      const allInstalledAtIds = new Set(
        newValue
          .filter(({ value }) => value === "All")
          .map(({ installed_at_id }) => installed_at_id)
      );

      newFacilities = formattedFacilityList.filter(({ installed_at_id }) =>
        allInstalledAtIds.has(installed_at_id)
      );
    }

    setSelectedFacilities(newFacilities.filter((item) => item.value != "All"));

    const newIdToAdd = newFacilities.filter(
      ({ id }) => !selectedFacilities.some((facility) => facility.id === id)
    );
    const idToRemove = selectedFacilities.filter(
      ({ id }) => !newFacilities.some((facility) => facility.id === id)
    );

    if (newIdToAdd.length > 0) {
      const facilitiesList = newIdToAdd.map(({ id }) => id);
      dispatch(AccessControlActions.getDataFromFacility(facilitiesList, "nvr"));
      dispatch(
        AccessControlActions.getDataFromFacility(facilitiesList, "device")
      );
    }

    if (idToRemove.length > 0) {
      const facilitiesList = idToRemove.map(({ id }) => id);
      const newNvrList = nvrList.filter(
        ({ installed_at_id }) => !facilitiesList.includes(installed_at_id)
      );

      let removedNvr = selectedNVRs.filter(({ installed_at_id }) =>
        facilitiesList.includes(installed_at_id)
      );
      let removedNvrId = removedNvr.map((item) => item.id);

      setSelectedCameras(
        selectedCameras.filter(
          ({ installed_at_id }) => !removedNvrId.includes(installed_at_id)
        )
      );

      const newCameraList = cameraList.filter(
        ({ installed_at_id }) => !removedNvrId.includes(installed_at_id)
      );
      dispatch({
        type: "SET_CAMERA_FROM_ACCESS_CONTROL",
        payload: newCameraList,
      });

      dispatch({
        type: "SET_NVR_FROM_FACILITY_ACCESS_CONTROL",
        payload: newNvrList,
      });
      setSelectedNVRs(
        selectedNVRs.filter(
          ({ installed_at_id }) => !facilitiesList.includes(installed_at_id)
        )
      );
    }
  }

  function handleNvrChange(event, newValue) {
    const containsAll = newValue.some(({ value }) => value === "All");

    if (containsAll) {
      const uniqueNvrIds = new Set();

      newValue.forEach(({ value, installed_at_id }) => {
        if (value === "All") {
          nvrList.forEach(({ id, installed_at_id: nvrInstalledAtId }) => {
            if (nvrInstalledAtId === installed_at_id) {
              uniqueNvrIds.add(id);
            }
          });
        }
      });

      const selectedNvrsTemp = nvrList.filter(({ id }) => uniqueNvrIds.has(id));

      setSelectedNVRs(selectedNvrsTemp.filter((item) => item.value != "All"));
    } else {
      setSelectedNVRs(newValue);
    }

    const newIdsToAdd = newValue.filter(
      ({ id }) => !selectedNVRs.some((nvr) => nvr.id === id)
    );

    const idsToRemove = selectedNVRs.filter(
      ({ id }) => !newValue.some((nvr) => nvr.id === id)
    );

    if (newIdsToAdd.length > 0) {
      const nvrListTemp = newIdsToAdd.map(({ id }) => id);
      dispatch(AccessControlActions.getCameraFromNVR(nvrListTemp));
    }

    if (idsToRemove.length > 0) {
      const nvrListTemp = idsToRemove.map(({ id }) => id);
      const newCameraList = cameraList.filter(
        ({ installed_at_id }) => !nvrListTemp.includes(installed_at_id)
      );
      dispatch({
        type: "SET_CAMERA_FROM_ACCESS_CONTROL",
        payload: newCameraList,
      });
      setSelectedCameras(
        selectedCameras.filter(
          ({ installed_at_id }) => !nvrListTemp.includes(installed_at_id)
        )
      );
    }
  }

  function handleCameraChange(event, newValue) {
    const containsAll = newValue.some((item) => item.value === "All");
    console.log(newValue, containsAll);
    if (containsAll) {
      const allItems = newValue.filter((item) => item.value === "All");
      let selectedCameras = [];
      allItems.forEach((allItem) => {
        const relatedCameras = cameraList.filter(
          (camera) => camera.installed_at_id === allItem.installed_at_id
        );
        selectedCameras = [...selectedCameras, ...relatedCameras];
      });
      setSelectedCameras(selectedCameras);
    } else {
      setSelectedCameras(newValue);
    }
  }

  async function handleSave() {
    // Initialize error object
    let newErrors = {
      selectedFacilities: "",
      selectedNVRs: "",
      selectedCameras: "",
      selectedDevices: "",
    };

    const requiredFields = [
      {
        field: selectedFacilities,
        key: "selectedFacilities",
        message: "Please select at least one facility.",
      },
      {
        field: selectedCameras,
        key: "selectedCameras",
        message: "Please select at least one camera.",
      },
      {
        field: selectedNVRs,
        key: "selectedNVRs",
        message: "Please select at least one NVR.",
      },
      {
        field: toList,
        key: "toList",
        message: "Please enter atleast One email in To List.",
      },
      {
        field: selectedAlert,
        key: "selectedAlert",
        message: "Please select atleast one alert type",
      },
    ];

    // Check for empty required fields
    requiredFields.forEach(({ field, key, message }) => {
      if (field.length === 0) {
        newErrors[key] = message;
      }
    });

    setErrors(newErrors);

    // If there are errors, do not proceed
    if (Object.values(newErrors).some((error) => error !== "")) {
      return;
    }

    const generatePostData = (items) =>
      items.filter((item) => item.value !== "All").map((item) => item.id);

    const post_data = {
      facilities: generatePostData(selectedFacilities),
      cameras: generatePostData(selectedCameras),
      nvrs: generatePostData(selectedNVRs),
      alert_types: generatePostData(selectedAlert),
      to_list: toList,
      cc_list: ccList,
      user_profile_id: profile.id,
    };

    setAddLoading(true);

    try {
      if (isEditable) {
        await updateEmailSettingInstance(data.id, post_data);
      } else {
        dispatch(addEmailCustomSetting(post_data));
      }

      dispatch(getEmailSettingList(profile.id));
      handleClose();
    } finally {
      setAddLoading(false);
    }
  }

  return (
    <React.Fragment>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        maxWidth="md"
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "md",
          },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {isEditable ? "Edit Mapping" : "Create Mapping"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "300px" }}
          >
            <Bars
              height="80"
              width="80"
              color="var(--card-text-color)"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          <>
            <DialogContent>
              <Box
                component="form"
                noValidate
                autoComplete="off"
                sx={{ mt: 1 }}
              >
                <div className="mt-2">
                  <Typography variant="h6" gutterBottom>
                    {"Email Notifications Details"}
                  </Typography>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 mt-2">
                    <CheckboxAutocomplete
                      value={selectedFacilities}
                      onChange={handleFacilityChange}
                      label="Select Facility"
                      helperText={errors.selectedFacilities}
                      size="large"
                      options={formattedFacilityList}
                      heading={""}
                      error={Boolean(errors.selectedFacilities)}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-3">
                    <GroupedCheckboxAutocomplete
                      value={selectedNVRs}
                      onChange={handleNvrChange}
                      label="Select NVRs"
                      size="large"
                      options={nvrList}
                      groupBy="installed_at_name"
                      loading={false}
                      error={Boolean(errors.selectedNVRs)}
                      helperText={errors.selectedNVRs}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-3">
                    <GroupedCheckboxAutocomplete
                      value={selectedCameras}
                      onChange={handleCameraChange}
                      label="Select Cameras"
                      size="large"
                      options={cameraList}
                      groupBy="Facility_name"
                      loading={false}
                      error={Boolean(errors.selectedCameras)}
                      helperText={errors.selectedCameras}
                    />
                  </div>

                  <div className="col-12 col-md-6 mt-3">
                    <CheckboxAutocomplete
                      value={selectedAlert}
                      onChange={(event, newValue) => setSelectedAlert(newValue)}
                      label="Alert Types"
                      heading={""}
                      options={alertTypeOptions}
                      size={"large"}
                      error={Boolean(errors.selectedAlert)}
                      helperText={errors.selectedAlert}
                    />
                  </div>

                  <div className="col-12 col-md-12 mt-3">
                    <EmailFreeSolo
                      value={toList}
                      onChangeFunc={handleToChange}
                      label={"Email To List "}
                      placeholder={"add new Email"}
                      helperText={errors.toList}
                    />
                  </div>

                  <div className="col-12 col-md-12 mt-4">
                    <EmailFreeSolo
                      value={ccList}
                      onChangeFunc={handleCcChange}
                      label={"Email CC List"}
                      placeholder={"add new Email"}
                      helperText={""}
                    />
                  </div>
                </div>
              </Box>
            </DialogContent>

            <DialogActions>
              <Button
                autoFocus
                onClick={handleSave}
                variant="contained"
                color="primary"
              >
                {addLoading
                  ? "Saving ..."
                  : isEditable
                  ? "Save Changes"
                  : "Create Mapping"}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </React.Fragment>
  );
}

EmailSettingModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  data: PropTypes.object,
};
