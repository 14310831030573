import ReactApexCharts from "react-apexcharts";
import { useSelector } from "react-redux";
const AlertBarChart = (propTypes) => {
  const state = useSelector((state) => state);
  const size = propTypes.data[0].data.length;
  const chartOptions = {
    chart: {
      id: "bar-chart",
      background: "transparent",
      toolbar: {
        show: false,
      },
    },
    colors: ["#4339F2"],

    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        columnWidth: size == 1 ? "5%" : size == 2 ? "10%" : "15%",
        columnHeight: "80%",
        // borderRadius: 10,
      },
    },

    xaxis: {
      categories: propTypes.data[0].data,
      labels: {
        show: true,
        style: {
          colors: "var(--card-text-color)", // Change text color to var(--card-text-color)
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          colors: "var(--card-text-color)", // Change text color to var(--card-text-color)
        },
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
  };

  // Define the series data for the bar chart
  const chartSeries = [
    {
      name: propTypes.title,
      data: propTypes.data[1].data,
    },
  ];

  return (
    <div>
      <div className="chart-container">
        <div className="chart-div">
          <div
            style={{
              textAlign: "left",
              marginLeft: "20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <div className="chart-title">
                Top{" "}
                {state.commonData.storeList.length <= 8
                  ? state.commonData.storeList.length - 1
                  : 7}
              </div>
              <span className="alert-chart-text">Sites</span>
            </div>
          </div>
          <div
            style={{ width: "100%", textAlign: "center", overflow: "hidden" }}
          >
            {/* Render the bar chart using ReactApexCharts */}
            <ReactApexCharts
              className="d-flex justify-content-center"
              id="myChart"
              options={chartOptions}
              series={chartSeries}
              type="bar"
              height={330}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertBarChart;
