const initialState = {
  rhmsData: [],
  nvrList: [{ text: "All", value: "all" }],
  totalOnlineNvrCount: 0,
  totalNumberOfNvr: 0,
  totalPages: 0,
  nvrDataForDownload: [],
  isLoading: false,
  heatmapData: [],
  nvr_uptime: 1,
  currentPage: 1,
  totalCameraCount: 0,
  onlineCameraCount: 0,
  offlineCameraCount: 0,
  cameraStatsLoading: false,
};

export default function RhmsReducer(state = initialState, action) {
  switch (action.type) {
    case "RHMS_STATUS_DATA":
      return {
        ...state,
        rhmsData: action.payload.data,
        totalPages: action.payload.count || 0,
      };
    case "TOTAL_NVR_COUNT":
      return {
        ...state,
        totalNumberOfNvr: action.payload.count,
      };
    case "NVR_BY_STORE":
      return {
        ...state,
        nvrList: action.payload,
      };
    case "TOTAL_NVR_ONLINE":
      return {
        ...state,
        totalOnlineNvrCount: action.payload,
        // totalPages: action.payload,
      };
    case "nvrDataForDownload":
      return { ...state, nvrDataForDownload: action.payload };
    case "LOADING":
      return { ...state, isLoading: action.payload };
    case "HEATMAP_DATA":
      return { ...state, heatmapData: action.payload };
    case "RESET_RHMS_REDUCER":
      return initialState;
    case "UPDATE_CURRENT_PAGE":
      return { ...state, currentPage: action.payload };
    case "UPDATE_NVR_UPTIME":
      return { ...state, nvr_uptime: action.payload };
    case "UPDATE_CAMERA_COUNT_DATA":
      return {
        ...state,
        totalCameraCount: action.payload.total,
        onlineCameraCount: action.payload.online,
        offlineCameraCount: action.payload.offline,
      };
    case "CAMERA_STATS_LOADING":
      return { ...state, cameraStatsLoading: action.payload };
    default:
      return state;
  }
}
