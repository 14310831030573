import React from "react";
import StackedBarchart from "../../components/charts/StackedBarchart";

const WeeklyStaticsChart = (propTypes) => {
  return (
    <div className="alertchartdiv">
      <div className="chart-div">
        <div
          style={{
            textAlign: "left",
            marginLeft: "20px",

            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <div className="chart-title">{propTypes.title}</div>
            <span className="alert-chart-text"> </span>
          </div>
        </div>

        <div>
          <StackedBarchart data={propTypes.data} />
        </div>
      </div>
    </div>
  );
};

export default WeeklyStaticsChart;
