import React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import mainCss from "../../assets/css/mainCss";
import "../../assets/css/main.css";
import Pagination from "../../components/common/Pagination";
import { useSelector } from "react-redux";
import { MenuItem, Select } from "@mui/material";
import { changeTopbarTitle } from "../../redux/Action/ChangeTopbarTitle";
import Account from "../../redux/Action/CommonAction";
import { getProfileIdByClient } from "../../redux/Action/CommonAction";
import {
  getNVRStatusByAccount,
  getNVRStatusByStore,
  getNVRStatusByNVR,
  getNVRsByStore,
  getNVRStatusSearchByAccount,
  getNVRStatusSearchByStore,
  getNVROnlineStatusByAccount,
  getNVROnlineStatusByStore,
  getNVRStatusByStoreByType,
  getCameraStats,
} from "../../redux/Action/RhmsAction";
import { updateCurrentPage } from "../../redux/Action/RhmsAction";
import RemoteMonitoringTable from "./RhmsTable/RemoteMonitoringTable";
import RemoteMonitoringStatitics from "./RemoteMonitoringStatitics";
import CameraMonitoringStatistics from "./CameraMonitoringStatistic";
import { useNavigate } from "react-router-dom";
import DownloadAndEmailReport from "./DownloadEmailModal";
const inputselect = {
  boxShadow: "none",
  borderRadius: "8px",
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
  MenuProps: {
    PaperProps: {
      style: {
        backgroundColor: "var(--top-bar-bg-color)",
        maxHeight: 350,
        width: 50,
        overflowX: "auto",
      },
    },
  },
};
let PageSize = 50;
const RemoteMonitoringDash = () => {
  // hooks of this component

  const rhmsData = useSelector((state) => state.rhmsData);
  const rhmsDataLoading = useSelector((state) => state.rhmsData.isLoading);
  const clientList = useSelector((state) => state.commonData.clientList);
  const storeList = useSelector((state) => state.commonData.storeList);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedType, setSelectedType] = useState({ value: "all" });
  const [selectedStore, setSelectedStore] = useState({ value: "all" });
  const [selectedNvr, setSelectedNvr] = useState({ value: "all" });
  const [searchValue, setSearchValue] = useState("");
  const [searchValueLength, setSearchValueLength] = useState(0);
  const [selectedClient, setselectedClient] = useState({ value: "all" });
  var profile = JSON.parse(localStorage.getItem("profile"));
  var profile_id = JSON.parse(localStorage.getItem("profile_id"));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (profile.remote_monitoring !== true) {
      navigate("/unauthorized");
    }
    dispatch(changeTopbarTitle("Remote Monitoring"));
    if (profile.account.config["client"] == "True") {
      var obj = JSON.parse(localStorage.getItem("profile"));
      obj.id = localStorage.getItem("profile_id");
      localStorage.setItem("profile", JSON.stringify(obj));
      Account.getClientList(profile_id)(dispatch);
      Account.getStoresByAccount(profile.id)(dispatch);
    } else {
      Account.getStoresByAccount(profile.id)(dispatch);
    }
  }, []);
  useEffect(() => {
    setCurrentPage(rhmsData.currentPage);
  }, [rhmsData.currentPage]);
  useEffect(() => {
    if (searchValueLength >= 3) {
      getStatus(selectedStore, selectedNvr);
    }
    if (searchValueLength == 0) {
      getStatus(selectedType, selectedStore, selectedNvr, currentPage);
    }
  }, [searchValue, currentPage]);

  useEffect(() => {
    var profile = JSON.parse(localStorage.getItem("profile"));
    profile.id = JSON.parse(localStorage.getItem("profile_id"));
    localStorage.setItem("profile", JSON.stringify(profile));
  }, []);

  // handel type change
  const handleTypeChange = (event) => {
    setSelectedType({ value: event.target.value });
    setCurrentPage(1);
    getStatus({ value: event.target.value }, selectedStore, selectedNvr, 1);
  };

  // handle Store Change
  const handleStoreChange = (event) => {
    setSelectedStore({ value: event.target.value });
    setCurrentPage(1);
    if (event.target.value != "all") {
      setSelectedNvr({ value: "all" });
      dispatch(getNVRsByStore(event.target.value));
      getStatus(
        selectedType,
        { value: event.target.value },
        { value: "all" },
        1
      );
    } else {
      getStatus(selectedType, { value: "all" }, { value: "all" }, 1);
    }
  };

  const handleClientChange = (event) => {
    setselectedClient({ value: event.target.value });
    setSelectedStore({ value: "all" });
    setSelectedNvr({ value: "all" });
    setCurrentPage(1);
    const store = { value: "all" };
    const device = { value: "all" };
    if (event.target.value !== "all") {
      getProfileIdByClient(event.target.value).then((res) => {
        var profile = JSON.parse(localStorage.getItem("profile"));
        profile.id = res.data[0].account_owner;
        localStorage.setItem("profile", JSON.stringify(profile));
        Account.getStoresByAccount(res.data[0].account_owner)(dispatch);
        getStatus(selectedType, store, device, 1);
      });
    } else {
      var obj = JSON.parse(localStorage.getItem("profile"));
      obj.id = localStorage.getItem("profile_id");
      localStorage.setItem("profile", JSON.stringify(obj));
      Account.getStoresByAccount(obj.id)(dispatch);
      getStatus(selectedType, store, device, 1);
    }
  };

  // handle Nvr change

  const handleNvrChange = (event) => {
    const value = event.target;
    setSelectedNvr({ value: event.target.value });
    setCurrentPage(1);
    if (value !== "all") {
      getStatus(selectedType, selectedStore, { value: event.target.value }, 1);
    } else {
      getStatus(selectedType, selectedStore, { value: event.target.value }, 1);
    }
  };

  // handle Searvh on chnage event
  const handleSearchChange = (event, setSearchValue) => {
    setSearchValue(event.target.value);
    setSearchValueLength(event.target.value.length);
  };
  const handleKeyPress = (event, setSearchValue) => {
    if (event.key === "Enter") {
      setSearchValue(event.target.value);
      // Assuming setSearchValueLength is meant to update state based on the length of the search input
      setSearchValueLength(event.target.value.length);
    }
  };
  // handle pagination handlePagination
  const handlePagination = (page) => {
    setCurrentPage(page);
    dispatch(updateCurrentPage(Number(page)));
  };

  function getStatus(type, store, nvr, page) {
    let id = JSON.parse(localStorage.getItem("profile")).id;
    if (searchValueLength != 0) {
      getStatusViaSearch(store, nvr, page);
      return;
    }

    if (type.value == "all") {
      //  if selected type is all then get nvr status of all category
      if (store.value == "all") {
        dispatch(getNVRStatusByAccount(id, page));
        dispatch(getCameraStats(id, null, null));
      } else if (nvr.value == "all") {
        dispatch(getNVRStatusByStore(store.value, page));
        dispatch(getCameraStats(id, store.value, null));
      } else {
        dispatch(getNVRStatusByNVR(nvr.value));
        dispatch(getCameraStats(id, null, nvr.value));
      }
    } else {
      //  if selected type is not all then get nvr status of only selected category
      if (store.value == "all") {
        // dispatch(getNVRStatusByAccount(id, page));
        dispatch(getNVROnlineStatusByAccount(id, page, type.value));
        dispatch(getCameraStats(id, null, null));
      } else if (nvr.value == "all") {
        dispatch(getNVRStatusByStoreByType(store.value, page));
        dispatch(getNVROnlineStatusByStore(store.value, page, type.value));
        dispatch(getCameraStats(id, store.value, null));
      } else {
        dispatch(getCameraStats(id, null, nvr.value));
      }
    }
  }

  function getStatusViaSearch() {
    if (selectedStore.value == "all") {
      let id = JSON.parse(localStorage.getItem("profile")).id;
      dispatch(getNVRStatusSearchByAccount(id, currentPage, searchValue));
    } else if (selectedNvr.value == "all") {
      dispatch(
        getNVRStatusSearchByStore(selectedStore.value, currentPage, searchValue)
      );
    }
  }

  return (
    <div style={mainCss.divBelowTopbar}>
      <div className="select-input-div card-custom card-stretch gutter-b">
        <div className="card-body-div">
          <div className="row">
            {profile.account.config["client"] == "True" && (
              <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 mt-2">
                <div className="select-input-lebel">Clients</div>
                <Select
                  value={selectedClient.value}
                  onChange={handleClientChange}
                  sx={{
                    color: "var(--card-text-color)",
                    ...inputselect,
                  }}
                  className="select-input-field"
                  MenuProps={inputselect.MenuProps}
                >
                  {clientList.map((item) => {
                    return (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        sx={{
                          paddingTop: "2px",
                          paddingBottom: "2px",
                          color: "var(--card-text-color)",
                        }}
                      >
                        {item.text}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
            )}
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 mt-2">
              <div className="select-input-lebel">Status</div>
              <Select
                value={selectedType.value}
                sx={{
                  color: "var(--card-text-color)",
                  ...inputselect,
                }}
                className="select-input-field"
                MenuProps={inputselect.MenuProps}
                onChange={handleTypeChange}
              >
                <MenuItem
                  value="all"
                  sx={{
                    color: "var(--card-text-color)",
                  }}
                >
                  All
                </MenuItem>
                <MenuItem
                  value="online"
                  sx={{
                    color: "var(--card-text-color)",
                  }}
                >
                  ONLINE
                </MenuItem>
                <MenuItem
                  value="offline"
                  sx={{
                    color: "var(--card-text-color)",
                  }}
                >
                  OFFLINE
                </MenuItem>
                <MenuItem
                  value="hddabnormal"
                  sx={{
                    color: "var(--card-text-color)",
                  }}
                >
                  ABNORMAL
                </MenuItem>
              </Select>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 mt-2">
              <div className="select-input-lebel">Facility</div>
              <Select
                value={selectedStore.value}
                onChange={handleStoreChange}
                sx={{
                  color: "var(--card-text-color)",
                  ...inputselect,
                }}
                className="select-input-field"
                MenuProps={inputselect.MenuProps}
              >
                {storeList.map((item) => {
                  return (
                    <MenuItem
                      key={item.value}
                      value={item.value}
                      sx={{
                        paddingTop: "2px",
                        paddingBottom: "2px",
                        color: "var(--card-text-color)",
                      }}
                    >
                      {item.text}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            {selectedStore.value !== "all" && (
              <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 mt-2">
                <div className="select-input-lebel">NVR</div>
                <Select
                  value={selectedNvr.value}
                  onChange={handleNvrChange}
                  sx={{
                    color: "var(--card-text-color)",
                    ...inputselect,
                  }}
                  className="select-input-field"
                  MenuProps={inputselect.MenuProps}
                >
                  {rhmsData.nvrList.map((item) => {
                    return (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        sx={{
                          paddingTop: "2px",
                          paddingBottom: "2px",
                          color: "var(--card-text-color)",
                        }}
                      >
                        {item.text}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
            )}

            <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 mt-2">
              <div className="select-input-lebel">Search</div>
              <input
                type="search"
                placeholder="Search..."
                className="search-input-field"
                onKeyDown={(e) => handleKeyPress(e, setSearchValue)}
                style={{
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  color: "var(--card-text-color)",
                }}
              />
            </div>
            <DownloadAndEmailReport />
          </div>
        </div>
      </div>
      <RemoteMonitoringStatitics loading={rhmsDataLoading} />
      <CameraMonitoringStatistics loading={rhmsDataLoading} />
      <RemoteMonitoringTable loading={rhmsDataLoading} />
      <div className="pagination-div">
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={rhmsData.totalPages}
          pageSize={PageSize}
          onPageChange={(page) => handlePagination(page)}
          usedIn="rhms"
        />
      </div>
    </div>
  );
};
export default RemoteMonitoringDash;
