import React, { useState } from "react";
import moment from "moment";
import { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
const HeatmapCard = (propType) => {
  const [show, setShow] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setmodalData] = useState({});
  const handleOpenModal = () => {
    // Step 3
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    // Step 4
    setIsModalOpen(false);
  };

  useEffect(() => {
    setmodalData(propType.item);
  }, [propType.item]);

  return (
    <div>
      <div className="heatmap-image-div mb-4" onClick={handleOpenModal}>
        <img src={propType.item.heatmap_image} />
        <div className="d-flex justify-content-between pt-3 px-2">
          <div>
            <p className="p-tag-heatmap">
              Time:{moment.utc(propType.item.recorded_at).format("h A")}
            </p>
          </div>
          <div>
            <p className="p-tag-heatmap">View</p>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <Dialog
          open={isModalOpen}
          onClose={handleCloseModal}
          maxWidth={"md"}
          PaperProps={{
            sx: {
              width: "100%",
              maxWidth: "md",
            },
          }}
        >
          <DialogTitle
            sx={{ m: 0, p: 4 }}
            id="customized-dialog-title"
            className="header-background modal-title-color"
            style={{
              backgroundColor: "#4D3EF8",
              color: "white",
            }}
          >
            {/* <h1>alert</h1> */}
            <IconButton
              aria-label="close"
              onClick={handleCloseModal}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "white",
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {/* <div className="alertacard-close-button-heatmap">
              {" "}
              <img
                src={cancel_icon}
                alt="cancel-icon"
                onClick={handleCloseModal}
              />
            </div> */}
            <img
              style={{ height: "500px", width: "100%" }}
              src={modalData.heatmap_image}
            ></img>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default HeatmapCard;
