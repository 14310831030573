import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import DashboardCard from "../DashboardCard";
import SentimentSatisfiedOutlinedIcon from "@mui/icons-material/SentimentSatisfiedOutlined";
import TimelineIcon from "@mui/icons-material/Timeline";
import EqualizerRoundedIcon from "@mui/icons-material/EqualizerRounded";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import GroupsIcon from "@mui/icons-material/Groups";
import dashboardFunction from "../DashboardFunctions";

const iconFont = {
  fontSize: "50px",
  color: "#4318FF",
};

const getPeopleCountPercent = (pc, ps) => {
  if (!ps) return "0%"; // Return '0%' if ps is 0 to avoid division by zero
  return ((pc / ps) * 100).toFixed(2) + "%";
};

const PeopleCountAndPasserbyCards = ({ selectedMode }) => {
  const profile = JSON.parse(localStorage.getItem("profile") || "{}");
  const accessPermissions =
    JSON.parse(localStorage.getItem("agx_permission") || "{}").Dashboard || [];
  const { retailData, loading } = useSelector((state) => state);
  const hasPasserbyPermission =
    profile.services_selected?.Dashboard.includes("passer_by") &&
    accessPermissions.includes("view_passer_by_analytics");
  const hasPeopleCountPermission =
    profile.services_selected?.Dashboard.includes("people_count") &&
    accessPermissions.includes("view_people_count_analytics");
  const hasOccupancyPermission =
    profile.services_selected?.Dashboard.includes("Occupancy") &&
    accessPermissions.includes("view_occupancy_analytics");
  const hasGroupCountPermission =
    profile.services_selected?.Dashboard.includes("group_count") &&
    accessPermissions.includes("view_people_count_analytics");
  return (
    <>
      {hasPasserbyPermission && (
        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-2">
          <DashboardCard
            icon={<EqualizerRoundedIcon style={iconFont} />}
            headText={dashboardFunction.totalPasserCount(
              retailData.passerCountHourly
            )}
            isLoading={loading.passerbyLoading}
            paraText="Total Passer By Count"
          />
        </div>
      )}

      {hasPeopleCountPermission && (
        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-2">
          <DashboardCard
            icon={<PeopleAltOutlinedIcon style={iconFont} />}
            headText={dashboardFunction.totalPeopleCount(
              retailData.peopleCountHourly
            )}
            paraText="Total People Count"
            isLoading={loading.peopleCountLoading}
          />
        </div>
      )}

      {hasOccupancyPermission && (
        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-2">
          <DashboardCard
            icon={<PeopleAltOutlinedIcon style={iconFont} />}
            headText={dashboardFunction.totalPeopleCountOut(
              retailData.peopleCountHourly
            )}
            paraText="Total People Count Out"
            isLoading={loading.peopleCountLoading}
          />
        </div>
      )}

      {hasPasserbyPermission && hasPeopleCountPermission && (
        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-2">
          <DashboardCard
            icon={<TimelineIcon style={iconFont} />}
            headText={getPeopleCountPercent(
              dashboardFunction.totalPeopleCount(retailData.peopleCountHourly),
              dashboardFunction.totalPasserCount(retailData.passerCountHourly)
            )}
            paraText="Total People Count Percentage"
            isLoading={loading.peopleCountLoading || loading.passerbyLoading}
          />
        </div>
      )}

      {hasOccupancyPermission && (
        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-2">
          <DashboardCard
            icon={<TimelineIcon style={iconFont} />}
            headText={dashboardFunction.calculateOccupancy(
              retailData.peopleCountHourly
            )}
            paraText="Total Occupancy"
            isLoading={loading.peopleCountLoading}
          />
        </div>
      )}

      {hasGroupCountPermission && selectedMode.value === "day-wise" && (
        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-2">
          <DashboardCard
            icon={<GroupsIcon style={iconFont} />}
            headText={`${retailData.groupCountData[0]?.Individual || 0} / ${
              retailData.groupCountData[0]?.Groups || 0
            }`}
            paraText="Individuals/Groups"
            isLoading={loading.groupDataLoading}
          />
        </div>
      )}
    </>
  );
};

PeopleCountAndPasserbyCards.propTypes = {
  selectedMode: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }).isRequired,
};

export default PeopleCountAndPasserbyCards;
