// AddGroupDialog.jsx
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import CheckboxAutocomplete from "../CommonComponent/CheckboxAutoComplete";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import GroupedCheckboxAutocomplete from "../CommonComponent/GroupCheckboxAutoComplete";
import AccessControlActions from "../../../../redux/Action/AccessControlAction";
import { useDispatch, useSelector } from "react-redux";
import Account from "../../../../redux/Action/CommonAction";
import UniqueRingLoader from "../../../../components/common/Loader/RingLoader";
import { notifiToasterWarn } from "../../../../components/common/UseToast";
import { Bars } from "react-loader-spinner";

export default function AddGroupDialog({
  isOpen,
  handleClose,
  userId,
  isEditable,
  groupList = [],
}) {
  const [groupName, setGroupName] = useState("");
  const [errors, setErrors] = useState({
    groupName: "",
    selectedFacilities: "",
    selectedNVRs: "",
    selectedCameras: "",
    selectedDevices: "",
    selectedAlertTypes: "",
  });
  const [addLoading, setAddLoading] = useState(false);
  const [selectedFacilities, setSelectedFacilities] = useState([]);
  const [selectedNVRs, setSelectedNVRs] = useState([]);
  const [selectedCameras, setSelectedCameras] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [formattedFacilityList, setFromattedFacilityList] = useState([]);
  const [alert_list, setALertList] = useState([]);
  // const [previousFacilityList, setPreviousFacilityList] = useState([]);
  const profile = JSON.parse(localStorage.getItem("profile"));
  const userOptions = useSelector((state) => state.accessControl.userOptions);
  const dispatch = useDispatch();
  const nvrList = useSelector((state) => state.accessControl.nvrList);
  const deviceList = useSelector((state) => state.accessControl.deviceList);
  const cameraList = useSelector((state) => state.accessControl.cameraList);
  const facilitiesList = useSelector((state) => state.commonData.storeList);
  const userList = useSelector((state) => state.accessControl.userList);
  const [loading, setLoading] = useState(false);
  const alert_event_list = useSelector((state) =>
    state.accessControl.alert_event_list.map((item) => ({
      value: item.name,
      id: item.notification_type,
    }))
  );

  useEffect(() => {
    const fetchData = async () => {
      if (isEditable && isOpen) {
        const grp = groupList.find((item) => item.id == userId);
        let selected_users = userOptions.filter((item) =>
          grp.user_list.includes(Number(item.id))
        );
        setSelectedUsers(selected_users);
        setGroupName(grp.group_name);
        setLoading(true);
        try {
          let grpDetails = await AccessControlActions.getGroupDetailsById(
            userId
          );
          if (grpDetails) {
            setLoading(false);
            setSelectedCameras(grpDetails.cameras);
            setSelectedDevices(grpDetails.devices);
            setSelectedNVRs(grpDetails.nvr);
            setSelectedFacilities(grpDetails.facility);
            if (grp.alert_list) {
              let usecase = grp.alert_list.split(",");
              let selected_useCase = alert_event_list.filter((item) =>
                usecase.includes(item.id)
              );
              console.log(usecase, selected_useCase);
              setALertList(selected_useCase);
            } else {
              setALertList([]);
            }
          }
        } catch (error) {
          console.error("Failed to fetch group details:", error);
          notifiToasterWarn("Failed to fetch the details");
          setSelectedFacilities([]);
          setSelectedCameras([]);
          setSelectedDevices([]);
          setSelectedNVRs([]);

          handleClose();
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [isEditable, isOpen, userId, groupList]);

  //useeffect to get nvr and device list from faciities
  useEffect(() => {
    if (isEditable && nvrList.length < 1) {
      if (selectedFacilities.length == 0) return;
      let facilitiesList = selectedFacilities.map((item) => item.id);
      dispatch(AccessControlActions.getDataFromFacility(facilitiesList, "nvr"));
      dispatch(
        AccessControlActions.getDataFromFacility(facilitiesList, "device")
      );
    }
  }, [selectedFacilities, isEditable]);

  //useeffect to get list of all faciities
  useEffect(() => {
    if (isOpen) {
      Account.getStoresByAccount(profile.id)(dispatch);
    }
  }, [isOpen]);

  //useeffect to restructre the faciities list to desired format
  useEffect(() => {
    const restructureFacility = facilitiesList.map((item) => ({
      id: item.value,
      value: item.text,
    }));
    setFromattedFacilityList(restructureFacility);
  }, [facilitiesList]);

  //useeffect to get camera list from NVR
  useEffect(() => {
    if (isEditable && cameraList.length < 1) {
      if (selectedNVRs.length == 0) return;
      let nvrList = selectedNVRs.map((item) => item.id);
      dispatch(AccessControlActions.getCameraFromNVR(nvrList));
    }
  }, [selectedNVRs, isEditable]);
  function handleAlertTypeChange(event, newValue) {
    setALertList(newValue);
    const containsAll = newValue.some(({ value }) => value === "All");
    if (containsAll) {
      setALertList([...alert_event_list]);
    }
  }

  //function to handle the chnages of facility selection
  function handleFacilityChange(event, newValue) {
    const containsAll = newValue.some(({ value }) => value === "All");
    let newFacilities = newValue;

    if (containsAll) {
      const allInstalledAtIds = new Set(
        newValue
          .filter(({ value }) => value === "All")
          .map(({ installed_at_id }) => installed_at_id)
      );

      newFacilities = formattedFacilityList.filter(({ installed_at_id }) =>
        allInstalledAtIds.has(installed_at_id)
      );
    }

    setSelectedFacilities(newFacilities.filter((item) => item.value !== "All"));

    const newIdsToAdd = newFacilities.filter(
      ({ id }) => !selectedFacilities.some((facility) => facility.id === id)
    );
    const idsToRemove = selectedFacilities.filter(
      ({ id }) => !newFacilities.some((facility) => facility.id === id)
    );

    if (newIdsToAdd.length > 0) {
      const facilitiesList = newIdsToAdd.map(({ id }) => id);
      dispatch(AccessControlActions.getDataFromFacility(facilitiesList, "nvr"));
      dispatch(
        AccessControlActions.getDataFromFacility(facilitiesList, "device")
      );
    }

    if (idsToRemove.length > 0) {
      const facilitiesList = idsToRemove.map(({ id }) => id);

      const newNvrList = nvrList.filter(
        ({ installed_at_id }) => !facilitiesList.includes(installed_at_id)
      );

      const removedNvrList = selectedNVRs.filter(({ installed_at_id }) =>
        facilitiesList.includes(installed_at_id)
      );

      const removedNvrIds = removedNvrList.map((item) => item.id);

      // Log the necessary variables to debug
      console.log("Facilities to remove:", facilitiesList);
      console.log("Removed NVR IDs:", removedNvrIds);

      // Filter out cameras associated with the removed NVRs
      setSelectedCameras(
        selectedCameras.filter(
          (camera) => !removedNvrIds.includes(camera.nvr_id)
        )
      );

      const newCameraList = cameraList.filter(
        (camera) => !removedNvrIds.includes(camera.nvr_id)
      );

      dispatch({
        type: "SET_CAMERA_FROM_ACCESS_CONTROL",
        payload: newCameraList,
      });

      dispatch({
        type: "SET_NVR_FROM_FACILITY_ACCESS_CONTROL",
        payload: newNvrList,
      });

      setSelectedNVRs(
        selectedNVRs.filter(
          ({ installed_at_id }) => !facilitiesList.includes(installed_at_id)
        )
      );
    }
  }

  function handleNvrChange(event, newValue) {
    const containsAll = newValue.some(({ value }) => value === "All");

    if (containsAll) {
      const uniqueNvrIds = new Set();

      newValue.forEach(({ value, installed_at_id }) => {
        if (value === "All") {
          nvrList.forEach(({ id, installed_at_id: nvrInstalledAtId }) => {
            if (nvrInstalledAtId === installed_at_id) {
              uniqueNvrIds.add(id);
            }
          });
        }
      });

      const selectedNvrsTemp = nvrList.filter(
        ({ id, value }) => uniqueNvrIds.has(id) && value != "All"
      );

      setSelectedNVRs([...selectedNVRs, ...selectedNvrsTemp]);
    } else {
      setSelectedNVRs(newValue);
    }

    const newIdsToAdd = newValue.filter(
      ({ id, value }) =>
        !selectedNVRs.some((nvr) => nvr.id === id && value != "All")
    );

    const idsToRemove = selectedNVRs.filter(
      ({ id }) => !newValue.some((nvr) => nvr.id === id)
    );

    if (newIdsToAdd.length > 0) {
      const nvrListTemp = newIdsToAdd.map(({ id }) => id);
      dispatch(AccessControlActions.getCameraFromNVR(nvrListTemp));
    }

    if (idsToRemove.length > 0) {
      const nvrListTemp = idsToRemove.map(({ id }) => id);
      const newCameraList = cameraList.filter(
        ({ installed_at_id }) => !nvrListTemp.includes(installed_at_id)
      );
      dispatch({
        type: "SET_CAMERA_FROM_ACCESS_CONTROL",
        payload: newCameraList,
      });
      setSelectedCameras(
        selectedCameras.filter(
          ({ installed_at_id }) => !nvrListTemp.includes(installed_at_id)
        )
      );
    }
  }
  function handleCameraChange(event, newValue) {
    // Check if "All" is among the new values
    const containsAll = newValue.some((item) => item.value === "All");
    if (containsAll) {
      // For each "All" selected, find other cameras with the same grouping attribute (e.g., installed_at_id)
      const allItems = newValue.filter((item) => item.value === "All");
      let selectedCameras = [];
      allItems.forEach((allItem) => {
        const relatedCameras = cameraList.filter(
          (camera) => camera.installed_at_id === allItem.installed_at_id
        );
        selectedCameras = [...selectedCameras, ...relatedCameras];
      });
      setSelectedCameras(selectedCameras);
    } else {
      setSelectedCameras(newValue);
    }
  }

  function handleDeviceChange(event, newValue) {
    // Check if "All" is among the new values
    const containsAll = newValue.some((item) => item.value === "All");
    if (containsAll) {
      // For each "All" selected, find other devices with the same grouping attribute (e.g., installed_at_id)
      const allItems = newValue.filter((item) => item.value === "All");
      let selectedDevices = [];
      allItems.forEach((allItem) => {
        const relatedDevices = deviceList.filter(
          (device) => device.installed_at_id === allItem.installed_at_id
        );
        selectedDevices = [...selectedDevices, ...relatedDevices];
      });
      setSelectedDevices(selectedDevices);
    } else {
      setSelectedDevices(newValue);
    }
  }

  // console.log(facilitiesList);
  function handleSave() {
    let newErrors = {
      groupName: "",
      selectedFacilities: "",
      selectedNVRs: "",
      selectedCameras: "",
      selectedDevices: "",
    };

    if (!groupName.trim()) {
      newErrors.groupName = "Please enter a group name.";
    }
    if (selectedFacilities.length === 0) {
      newErrors.selectedFacilities = "Please select at least one facility.";
    }
    if (selectedCameras.length === 0) {
      newErrors.selectedCameras = "Please select at least one camera.";
    }
    if (selectedNVRs.length === 0) {
      newErrors.selectedNVRs = "Please select at least one NVR.";
    }
    if (alert_list.length == 0) {
      newErrors.alert_list = "Please select at least one Alert Type.";
    }
    setErrors(newErrors);

    // Check if there are any errors before proceeding
    const hasErrors = Object.values(newErrors).some((error) => error !== "");
    if (hasErrors) {
      return;
    }
    const post_data = {
      group_name: groupName,
      selected_facility: selectedFacilities
        .filter((item) => item.value !== "All")
        .map((item) => item.id),
      selected_device: selectedDevices
        .filter((item) => item.value !== "All")
        .map((item) => item.id),
      selected_camera: selectedCameras
        .filter((item) => item.value !== "All")
        .map((item) => item.id),
      selected_nvr: selectedNVRs
        .filter((item) => item.value !== "All")
        .map((item) => item.id),
      user_list: selectedUsers
        .filter((item) => item.value !== "All")
        .map((item) => item.id),
      account: profile.account.account_id,
      alert_list: alert_list
        .filter((item) => item.value !== "All")
        .map((item) => item.id)
        .join(","),
    };
    if (isEditable) {
      dispatch(
        AccessControlActions.updateGroupInfoForAccessControl(userId, post_data)
      ).then(() => {
        setAddLoading(false);
        dispatch(AccessControlActions.getGroupList(profile.id));
        handleClose();
      });
    } else {
      setAddLoading(true);
      dispatch(AccessControlActions.addGroupToAccessControl(post_data)).then(
        () => {
          setAddLoading(false);
          dispatch(AccessControlActions.getGroupList(profile.id));
          handleClose();
        }
      );
    }
  }
  return (
    <React.Fragment>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        maxWidth="md"
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "md",
          },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {isEditable ? "Edit Group" : "Create Group"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "300px" }}
          >
            <Bars
              height="80"
              width="80"
              color="var(--card-text-color)"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          <>
            <DialogContent>
              <Box
                component="form"
                noValidate
                autoComplete="off"
                sx={{ mt: 1 }}
              >
                <div className="mt-2">
                  <Typography variant="h6" gutterBottom>
                    {"Group Details"}
                  </Typography>
                </div>
                <div className="col-4">
                  <TextField
                    fullWidth
                    error={!!errors.groupName}
                    helperText={errors.groupName}
                    id="group-name"
                    label="Group Name"
                    variant="outlined"
                    margin="normal"
                    size="large"
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}
                  />
                </div>
                <div className="mt-2">
                  <Typography variant="h6" gutterBottom>
                    {"Group Mapping"}
                  </Typography>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 mt-2">
                    <CheckboxAutocomplete
                      value={selectedFacilities}
                      onChange={handleFacilityChange}
                      label="Select Facility"
                      helperText={errors.selectedFacilities}
                      size="large"
                      options={formattedFacilityList}
                      heading={""}
                      error={errors.selectedFacilities}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-3">
                    <GroupedCheckboxAutocomplete
                      value={selectedNVRs}
                      onChange={handleNvrChange}
                      label="Select NVRs"
                      size="large"
                      options={nvrList}
                      groupBy="installed_at_name"
                      loading={false}
                      error={Boolean(errors.selectedNVRs)}
                      helperText={errors.selectedNVRs}
                    />
                  </div>

                  <div className="col-12 col-md-6 mt-3">
                    <GroupedCheckboxAutocomplete
                      value={selectedCameras}
                      onChange={handleCameraChange}
                      label="Select Cameras"
                      size="large"
                      options={cameraList}
                      groupBy="Facility_name"
                      loading={false}
                      error={Boolean(errors.selectedCameras)}
                      helperText={errors.selectedCameras}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-2">
                    <CheckboxAutocomplete
                      value={alert_list}
                      onChange={handleAlertTypeChange}
                      label="Select Usecases"
                      helperText={errors.alert_list}
                      size="large"
                      options={[
                        { id: "All", value: "All" },
                        ...alert_event_list,
                      ]}
                      heading={""}
                      error={errors.alert_list}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-3">
                    <GroupedCheckboxAutocomplete
                      value={selectedDevices}
                      onChange={handleDeviceChange}
                      label="Select Devices"
                      size="large"
                      options={deviceList}
                      groupBy="Facility_name"
                      loading={false}
                      error={Boolean(errors.selectedDevices)}
                      helperText={errors.selectedDevices}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12 col-md-6 mt-3">
                    <CheckboxAutocomplete
                      value={selectedUsers}
                      onChange={(event, newValue) => setSelectedUsers(newValue)}
                      label="Select Users"
                      heading={"Users"}
                      options={userOptions}
                      size={"large"}
                      error={false}
                    />
                  </div>
                </div>
              </Box>
            </DialogContent>

            <DialogActions>
              <Button
                autoFocus
                onClick={handleSave}
                variant="contained"
                color="primary"
              >
                {addLoading
                  ? "Saving ..."
                  : isEditable
                  ? "Save Changes"
                  : "Create Group"}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </React.Fragment>
  );
}

AddGroupDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
  groupList: PropTypes.array,
};
