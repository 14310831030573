import React, { useEffect, useState, useCallback } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Button,
  TableBody,
  IconButton,
  Chip,
} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationDialog from "../../../components/common/ConfirmationDailog";
import EmailSettingModal from "./EmailSettingModal";
import {
  deleteEmailSettingList,
  getEmailSettingList,
} from "../../../redux/Action/ProfileAction";
import { useDispatch, useSelector } from "react-redux";
function generateUUID() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}
const access_permission = ["edit_groups", "delete_groups"];

const EmailCustomMainPage = () => {
  const [isDeleteUserOpen, setIsDeleteUserOpen] = useState(false);
  const [isAddEditEmailOpen, setIsAddEditMailOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [rowData, setRowData] = useState(null);

  const dispatch = useDispatch();
  const emailList = useSelector((state) => state.profile.emailMapping);

  // Fetch Email Settings
  const fetchEmailSettings = async () => {
    const profile = JSON.parse(localStorage.getItem("profile"));
    const profile_id = profile?.id;
    setIsLoading(true);
    dispatch(getEmailSettingList(profile_id))
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    // Fetch NVR, Camera, Facility Details

    fetchEmailSettings();
  }, []);

  const handleAddNewEmail = useCallback(() => {
    setRowData(null);
    setIsEditable(false);
    setIsAddEditMailOpen(true);
  }, []);

  const handleAddEditGroup = useCallback((rowData) => {
    setRowData(rowData);
    setIsEditable(true);
    setIsAddEditMailOpen(true);
  }, []);

  const handleDeleteUser = useCallback((userId) => {
    setSelectedUserId(userId);
    setIsDeleteUserOpen(true);
  }, []);

  const confirmDelete = async () => {
    await deleteEmailSettingList(selectedUserId);
    setIsDeleteUserOpen(false);
    await fetchEmailSettings();
  };

  const renderLimitedData = (data) => {
    const displayData = data.slice(0, 4);
    const remainingCount = data.length - displayData.length;

    return (
      <>
        {displayData.join(", ")}
        {remainingCount > 0 && <Chip label={`+${remainingCount}`} />}
      </>
    );
  };
  function handleClose() {
    dispatch({
      type: "SET_CAMERA_FROM_ACCESS_CONTROL",
      payload: [],
    });
    dispatch({
      type: "SET_NVR_FROM_FACILITY_ACCESS_CONTROL",
      payload: [],
    });
    dispatch({
      type: "SET_DEVICES_FROM_FACILITY_ACCESS_CONTROL",
      payload: [],
    });
    setIsAddEditMailOpen(false);
  }
  return (
    <div>
      <div className="d-flex justify-content-end mx-2">
        <Button onClick={handleAddNewEmail} variant="contained">
          Add new Mapping
        </Button>
      </div>
      <div className="px-2">
        <TableContainer component={Paper} className="mt-3">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>S.No.</TableCell>
                <TableCell>Facility</TableCell>
                <TableCell>Cameras</TableCell>
                <TableCell>Alert Types</TableCell>
                <TableCell>To List</TableCell>
                <TableCell>CC List</TableCell>
                {(access_permission.includes("edit_groups") ||
                  access_permission.includes("delete_groups")) && (
                  <TableCell align="center">Actions</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    Loading Data ...
                  </TableCell>
                </TableRow>
              ) : emailList.length == 0 ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No Data Found
                  </TableCell>
                </TableRow>
              ) : (
                emailList.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      {renderLimitedData(
                        item.facilities.map(
                          (facility) => facility.facility_name
                        )
                      )}
                    </TableCell>
                    <TableCell>
                      {renderLimitedData(
                        item.cameras.map((camera) => camera.camera_name)
                      )}
                    </TableCell>
                    <TableCell>{renderLimitedData(item.alert_types)}</TableCell>
                    <TableCell>{renderLimitedData(item.to_list)}</TableCell>
                    <TableCell>{renderLimitedData(item.cc_list)}</TableCell>
                    <TableCell align="center" sx={{ padding: 0, margin: 0 }}>
                      {access_permission.includes("edit_groups") && (
                        <IconButton
                          aria-label="edit"
                          onClick={() => handleAddEditGroup(item)}
                        >
                          <ModeEditIcon />
                        </IconButton>
                      )}
                      {access_permission.includes("delete_groups") && (
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleDeleteUser(item.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <ConfirmationDialog
        title="Delete Group"
        dialogContent="Are you sure to delete the group"
        handleConfirm={confirmDelete}
        handleDisconfirm={() => setIsDeleteUserOpen(false)}
        isOpen={isDeleteUserOpen}
        handleClose={() => setIsDeleteUserOpen(false)}
        confirmButtonLabel="Yes"
        disconfirmButtonLabel="No"
        confirmButtonVariant="contained"
        disconfirmButtonVariant="outlined"
      />

      {isAddEditEmailOpen && (
        <EmailSettingModal
          isOpen={isAddEditEmailOpen}
          handleClose={handleClose}
          isEditable={isEditable}
          data={rowData}
          key={generateUUID()}
        />
      )}
    </div>
  );
};

export default EmailCustomMainPage;
