import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationDialog from "../../../components/common/ConfirmationDailog";
import FaceRecognitionActions from "../../../redux/Action/FaceRegnitionAction";
import { useDispatch } from "react-redux";
import { propTypes } from "react-bootstrap/esm/Image";
const DeleteKnownFace = ({ userId, handleClose }) => {
  const [isDeleteUserOpen, setIsDeleteUserOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleDeleteUserClick = (userId) => {
    setSelectedUserId(userId);
    setIsDeleteUserOpen(true);
  };
  function getKnownFacesData() {
    let userprofileid = JSON.parse(localStorage.getItem("profile")).id;
    dispatch(FaceRecognitionActions.getListOfKnownFaces(userprofileid, 1, 20));
  }
  const deleteUserConfirm = async (userId) => {
    setLoading(true);
    let flag = await FaceRecognitionActions.deleteKnownFaceData(userId);
    if (flag) {
      getKnownFacesData();
      handleClose();
    }
    setIsDeleteUserOpen(false);
    setLoading(false);
  };

  return (
    <div>
      <Button
        onClick={() => handleDeleteUserClick(userId)}
        variant="outlined"
        color="primary"
        disabled={loading}
        startIcon={<DeleteIcon />}
      >
        {loading ? (
          <>
            <CircularProgress size={24} color="inherit" />
            {" Deleting..."}
          </>
        ) : (
          "Delete User"
        )}
      </Button>
      <ConfirmationDialog
        title="Delete Person Data"
        dialogContent="Are you sure you want to delete this face data?"
        handleConfirm={() => deleteUserConfirm(selectedUserId)}
        handleDisconfirm={() => setIsDeleteUserOpen(false)}
        isOpen={isDeleteUserOpen}
        handleClose={() => setIsDeleteUserOpen(false)}
        confirmButtonLabel="Yes"
        disconfirmButtonLabel="No"
        confirmButtonVariant="contained"
        disconfirmButtonVariant="outlined"
      />
    </div>
  );
};

DeleteKnownFace.propTypes = {
  userId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default DeleteKnownFace;
