import { configureStore } from "@reduxjs/toolkit";
import dataReducer from "../redux/Reducer/LoginReducer";
import titleReducer from "../redux/Reducer/ChangeTopbarTiitleReducer";
import ProfileReducer from "../redux/Reducer/ProfileReducer";
import DeviceControlReducer from "../redux/Reducer/DeviceControlReducer";
import DeviceStatusReducer from "../redux/Reducer/DeviceStatusReducer";
import RhmsReducer from "../redux/Reducer/RhmsReducer";
import CommonReducer from "../redux/Reducer/CommonReducer";
import DashMixinsReducer from "../redux/Reducer/DashMixinsActionsReducer";
import MaleFemaleReducer from "../redux/Reducer/MaleFemaleReducer";
import AgeDistReducer from "../redux/Reducer/AgeDistReducer";
import EmotionReducer from "../redux/Reducer/EmotionReducer";
import AlertReducer from "./Reducer/AlertChartReducer";
import EventReducer from "./Reducer/EventChartReducer";
import HeatMapReducer from "./Reducer/HeatMapReducer";
import LiveReducer from "./Reducer/LiveViewReducer";
import AlertTableReducer from "./Reducer/AlertTableReducer";
import EventTableReducer from "./Reducer/EventTableReducer";
import OperationModeReducer from "./Reducer/OperationModeReducer";
import VmsHelperReducer from "./Reducer/VideoWallReducer";
import AlertDashReducer from "./Reducer/AlertDashReducer";
import LoadingReducer from "./Reducer/LoadingReducers";
import AccessControlReducer from "./Reducer/AccessControlReducer";
import OnboardingReducer from "./Reducer/OnboardingReducer";
import FaceRecognitionReducer from "./Reducer/FaceRecognitionReducer";
import RuleBasedAlertsReducers from "./Reducer/RuleBasedAlertsReducer";
import VmsReducer from "./Reducer/VmsReducer";
import SystemParametersReducers from "./Reducer/SystemParametersReducers";
import AnprReducer from "./Reducer/AnprReducer";
export const store = configureStore({
  reducer: {
    login: dataReducer,
    title: titleReducer,
    profile: ProfileReducer,
    deviceControlData: DeviceControlReducer,
    deviceStatusData: DeviceStatusReducer,
    rhmsData: RhmsReducer,
    commonData: CommonReducer,
    retailData: DashMixinsReducer,
    gender: MaleFemaleReducer,
    age: AgeDistReducer,
    emotion: EmotionReducer,
    AlertData: AlertReducer,
    AlertTableStates: AlertTableReducer,
    EventTableStates: EventTableReducer,
    EventData: EventReducer,
    heatMapData: HeatMapReducer,
    liveview: LiveReducer,
    modalData: OperationModeReducer,
    AlertDashboardStates: AlertDashReducer,
    loading: LoadingReducer,
    accessControl: AccessControlReducer,
    onbaording: OnboardingReducer,
    faceRecognition: FaceRecognitionReducer,
    ruleBasedAlerts: RuleBasedAlertsReducers,
    vms: VmsReducer,
    vmsHelper: VmsHelperReducer,
    systemParameters: SystemParametersReducers,
    anpr: AnprReducer,
  },
  devTools: true,
});
