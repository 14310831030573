import axios from "axios";
import ApiSpec from "../features/EndPoints";
import { notifiToasterWarn } from "../../components/common/UseToast";

class VmsHelperActions {
  //Action to remove some selected Ips from redux state
  static updateCameraSelected(data) {
    return async (dispatch) => {
      dispatch({
        type: "UPDATE_SELECTED_CAMERAS",
        payload: data,
      });
    };
  }
  static toggleTimerEnable(data) {
    return async (dispatch) => {
      dispatch({
        type: "UPDATE_TIMER_ENABLED_CAMERAS",
        payload: data,
      });
    };
  }
  static RemoveCameraSelected(data) {
    return async (dispatch) => {
      dispatch({
        type: "REMOVE_SELECTED_CAMERAS_FROM_STATE",
        payload: data,
      });
    };
  }
  static updateSelectedGrid(data) {
    return async (dispatch) => {
      dispatch({
        type: "UPDATE_SELECTED_GRID",
        payload: data,
      });
    };
  }
  static updateTransitionTimer(data) {
    return async (dispatch) => {
      dispatch({
        type: "UPDATE_TRANSITION_TIMER",
        payload: data,
      });
    };
  }
  static removeAllSelectedCamera(data) {
    return async (dispatch) => {
      dispatch({
        type: "REMOVE_ALL_SELECTED_CAMERAS",
      });
    };
  }
  static increaseLoadingCameras(data) {
    return async (dispatch) => {
      dispatch({
        type: "INCREASE_LOADING_CAMERAS",
        payload: data,
      });
    };
  }
  static decreaseLoadingCameras(data) {
    return async (dispatch) => {
      dispatch({
        type: "DECREASE_LOADING_CAMERAS",
        payload: data,
      });
    };
  }
  static updateLoadingCameraGrid(data) {
    return async (dispatch) => {
      dispatch({
        type: "UPDATE_LOADING_CAMERAS_GRID_SIZE",
        payload: data,
      });
    };
  }
  static UpdatePlayList(data) {
    return async (dispatch) => {
      dispatch({
        type: "UPDATE_PLAYLIST",
        payload: data,
      });
    };
  }
  static UpdateCurrentPage(data) {
    return async (dispatch) => {
      dispatch({
        type: "UPDATE_CURRENT_PAGE",
        payload: data,
      });
    };
  }
  static UpdatePlaybackStream(data) {
    return async (dispatch) => {
      dispatch({
        type: "UPDATE_PLAYBACK_STREAM",
        payload: data,
      });
    };
  }

  static UpdateLiveviewFeature(data) {
    return async (dispatch) => {
      dispatch({
        type: "UPDATE_LIVEVIEW_FEATURE",
        payload: data,
      });
    };
  }

  static updatePlaybackCamera(data) {
    return async (dispatch) => {
      dispatch({
        type: "UPDATE_PLAYBACK_CAMERA",
        payload: data,
      });
    };
  }
  static removeAllPlaybackCamera(data) {
    return async (dispatch) => {
      dispatch({
        type: "REMOVE_ALL_PLAYBACK_CAMERAS",
      });
    };
  }
  static removeSelecetedPlaybackCamera(data) {
    return async (dispatch) => {
      dispatch({
        type: "REMOVE_SELECTED_CAMERA_FROM_PLAYBACK_LIST",
        payload: data,
      });
    };
  }
  static updateLoadingForFacilityList(data) {
    return async (dispatch) => {
      dispatch({
        type: "UPDATE_LOADING_FOR_FACILITY",
        payload: data,
      });
    };
  }
  static updateLoadingForPlayback(data) {
    return async (dispatch) => {
      dispatch({
        type: "UPDATE_LOADING_FOR_PLAYBACK",
        payload: data,
      });
    };
  }
  static updateSelectedPage(data) {
    return async (dispatch) => {
      dispatch({
        type: "UPDATE_SELECETED_DATE_FOR_PLAYBACK",
        payload: data,
      });
    };
  }
  static getAllFacilitiesCameraLiveview(data) {
    // var profile = JSON.parse(localStorage.getItem("profile"));
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      await axios
        .get(ApiSpec.getAllFacilityCamera(data), { headers })
        .then((response) => {
          dispatch({
            type: "UPDATE_CAMERA_LIST_ALL_FACILITY",
            payload: response.data,
          });
        });
    };
  }
  static extractCameraIds(data) {
    let cameraIds = [];

    // Check if the input data is an array and has at least one element
    if (Array.isArray(data) && data.length > 0) {
      // Iterate through each element in the data array
      data.forEach((item) => {
        // Check if the element has the expected structure with a 'camera' property
        if (item && item.camera && Array.isArray(item.camera)) {
          // Iterate through each camera object in the 'camera' array
          item.camera.forEach((camera) => {
            // Check if the camera object has the 'id' property
            if (camera && camera.id !== undefined) {
              // Push the camera ID to the cameraIds array
              cameraIds.push(camera.id);
            }
          });
        }
      });
    }

    return cameraIds;
  }

  static getAllFacilitiesCameraForPlayback(data) {
    // var profile = JSON.parse(localStorage.getItem("profile"));
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      await axios
        .get(ApiSpec.getAllFacilityCameraPlayBack(data), { headers })
        .then((response) => {
          dispatch({
            type: "UPDATE_FACILITY_LIST_FOR_PLAYBACK_CAMERAS",
            payload: response.data,
          });
          dispatch({
            type: "UPDATE_CAMERA_LIST_FOR_PLAYBACK_CAMERAS",
            payload: VmsHelperActions.extractCameraIds(response.data),
          });
        });
    };
  }
  static getMonthlyAvailableRecording(data) {
    // var profile = JSON.parse(localStorage.getItem("profile"));
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      await axios
        .get(
          ApiSpec.getMonthlyAvailableRecording(
            data.cam_id,
            data.year,
            data.month
          ),
          { headers }
        )
        .then((response) => {
          dispatch({
            type: "UPDATE_MONTHLY_AVALIAVLE_RECORDING",
            payload: response.data,
          });
        });
    };
  }
  static getPlaybackStream(data) {
    // var profile = JSON.parse(localStorage.getItem("profile"));
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      await axios
        .get(
          ApiSpec.getPlaybackStream(
            data.cam_id,
            data.date,
            data.startTime,
            data.endTime
          ),
          { headers }
        )
        .then((response) => {
          if (response.data.length == 0) {
            notifiToasterWarn(
              "Sorry, there is no recording available for the selected time."
            );
          } else {
            dispatch({
              type: "UPDATE_PLAYBACK_STREAM",
              payload: response.data,
            });
          }
        })
        .catch(() => {
          dispatch({
            type: "UPDATE_PLAYBACK_STREAM",
            payload: [],
          });
        });
    };
  }
  static updateSelectedQuality(data) {
    return async (dispatch) => {
      dispatch({
        type: "UPDATE_SELECTED_QUALITY",
        payload: data,
      });
    };
  }
}

export default VmsHelperActions;
