import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import DeviceUptime from "../RhmsModals/DeviceUptime";
import Modal from "react-bootstrap/Modal";
import GraphTab from "../RhmsModals/GraphTab";
import PropTypes from "prop-types";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import LastOnlineModal from "../ConfigurableRhms/LastOnlineModal";
import { nvrWithApi } from "../../../configs/dataConfig";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { checkProblemInNvr } from "../../../redux/Action/RhmsAction";
import { useDispatch } from "react-redux";
import Alert from "@mui/material/Alert";
const RemoteMonitoringRowDetails = ({ row, open }) => {
  const dispatch = useDispatch();
  const [showOffCam, setShowOffcam] = useState(false);
  const [lastRecordingModalData, setlastRecordingModalData] = useState([]);
  const [modalDataOfflineCam, setmodalDataOfflineCam] = useState([]);
  const [modalData, setmodalDAta] = useState({});
  const [show, setShow] = useState(false);
  const [showlastRecording, setshowlastRecording] = useState(false);
  const [showLastOnline, setShowLastOnline] = useState(false);
  const [diagnosError, setDiagError] = useState("");
  const handleShow = (item) => {
    setmodalDAta(item);
    setShow(true);
  };

  const handleShowOfflineCam = (item) => {
    setmodalDataOfflineCam(item.cameras_offline);
    setShowOffcam(true);
  };
  var formattedBrand = row.brand
    .replace(/[^\w\s]/gi, "")
    .replace(/\s+/g, "")
    .toLowerCase();
  const isApiAvailable = nvrWithApi.includes(formattedBrand);
  const handleLastRecordingObj = (item) => {
    let notEzviz =
      row.last_recording_available &&
      row.last_recording_available[0] &&
      row.brand !== "ezviz";

    setlastRecordingModalData(notEzviz ? item.last_recording_available : []);
    setshowlastRecording(notEzviz);
  };
  const handleCloseOfflineCam = () => setShowOffcam(false);
  const handleCloseOfflineCamRecording = () => setshowlastRecording(false);
  const handleClose = () => {
    setShow(false);
    setDiagError("");
  };
  function ShowLastOnline() {
    setShowLastOnline(true);
  }
  function hideLastOnline() {
    setShowLastOnline(false);
  }
  async function runDiagonistic(nvr_id) {
    let error = await checkProblemInNvr(nvr_id);
    setDiagError(error);
  }
  return (
    <>
      <TableRow style={{ border: "none" }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small">
                <TableHead
                  sx={{
                    backgroundColor: "var( --side-bar-bg-color)",
                    boxShadow: "none",
                  }}
                >
                  <TableRow>
                    <TableCell
                      align="center"
                      style={{
                        color: "var(--chatbot-welcome-text-color)",
                      }}
                      sx={{ borderBottom: "none" }}
                    >
                      Camera Connected
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ color: "var(--chatbot-welcome-text-color)" }}
                    >
                      Last online
                    </TableCell>
                    {isApiAvailable && (
                      <TableCell
                        align="center"
                        style={{ color: "var(--chatbot-welcome-text-color)" }}
                      >
                        Last Available Recording
                      </TableCell>
                    )}
                    <TableCell
                      align="center"
                      style={{ color: "var(--chatbot-welcome-text-color)" }}
                    >
                      Details
                    </TableCell>
                    {isApiAvailable && (
                      <TableCell
                        align="center"
                        style={{ color: "var(--chatbot-welcome-text-color)" }}
                      >
                        Camera Offline
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      style={{ color: "var(--chatbot-welcome-text-color)" }}
                    >
                      {row.total_cameras}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ color: "var(--chatbot-welcome-text-color)" }}
                    >
                      {row.nvr_ip2 ? (
                        <button
                          onClick={() => ShowLastOnline()}
                          className="showDetailsNvrButton remote-table-details"
                        >
                          Show
                        </button>
                      ) : (
                        <span>{row.last_online}</span>
                      )}
                    </TableCell>
                    {isApiAvailable && (
                      <TableCell align="center">
                        <button
                          onClick={() => handleLastRecordingObj(row)}
                          className="showDetailsNvrButton remote-table-details"
                        >
                          {row.last_recording_available &&
                          row.last_recording_available[0] &&
                          row.brand !== "ezviz"
                            ? row.last_recording_available[0]
                            : "N/A"}
                        </button>
                      </TableCell>
                    )}
                    <TableCell align="center">
                      <button
                        onClick={() => handleShow(row)}
                        className="showDetailsNvrButton remote-table-details"
                      >
                        Show Details
                      </button>
                    </TableCell>
                    {isApiAvailable && (
                      <TableCell align="center">
                        {row.nvr_online == "OFFLINE" ? (
                          row.total_cameras
                        ) : (
                          <button
                            onClick={() => handleShowOfflineCam(row)}
                            className="showDetailsNvrButton remote-table-camera-offline"
                          >
                            Show
                            <span className="cam-offline-btn-rhms">
                              {row.cameras_offline.length}
                            </span>
                          </button>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {showLastOnline && (
        <LastOnlineModal
          NvrData={row}
          showLastOnline={showLastOnline}
          hideLastOnline={hideLastOnline}
        />
      )}
      {/* =============== Modal For Last Available Recordings =================== */}
      <Modal
        centered
        show={showlastRecording}
        onHide={handleCloseOfflineCamRecording}
        style={{ zIndex: 9999 }}
      >
        <Modal.Header className="header-background" closeButton>
          <Modal.Title className="modal-title-color">
            Last Available Recordings
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "500px", overflowY: "auto" }}>
          <table className="custom-table">
            <thead>
              <tr>
                <th colSpan="2" className="available-days-header">
                  Last Available Recording Days : {lastRecordingModalData[0]}
                </th>
              </tr>
              {lastRecordingModalData.length > 1 && (
                <tr>
                  <th>Unavailable Recording Dates</th>
                  <th>Status</th>
                </tr>
              )}
            </thead>
            <tbody>
              {lastRecordingModalData.slice(1).map((item, index) => (
                <tr key={index}>
                  <td>{item}</td>
                  <td className="status-not-available">Not Available</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
      <Modal
        centered
        show={showOffCam}
        onHide={handleCloseOfflineCam}
        style={{ zIndex: 9999 }}
      >
        <Modal.Header className="header-background" closeButton>
          <Modal.Title className="modal-title-color">
            Cameras Offline{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "var(--side-bar-bg-color)" }}>
          {Array.isArray(modalDataOfflineCam) ? (
            <table className="rhms-modal-table " style={{ width: "100%" }}>
              <tbody>
                {modalDataOfflineCam.map((item, index) => (
                  <tr key={`row-modal-offline-${PropTypes.index}`}>
                    <td>{item}</td>
                    <td className="rhms-table-text-modal-details">
                      <span style={{ color: "#F32840" }}>Offline</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div>No data to display</div>
          )}
        </Modal.Body>
      </Modal>
      <Modal
        size="xl"
        show={show}
        onHide={handleClose}
        style={{ zIndex: 9999 }}
        className="remote-monitoring-modal-parent"
      >
        <Modal.Header className="header-background" closeButton>
          <Modal.Title className="modal-title-color">Details </Modal.Title>
        </Modal.Header>
        <div
          className="row heatmap-chart-container"
          style={{ marginRight: "1px", marginLeft: "1px" }}
        >
          <div className="col-12 col-lg-5 ">
            <table className="rhms-modal-table remote-monitoring-detail-modal mt-2">
              <tbody>
                {modalData.nvr && (
                  <tr>
                    <td className="rhms-table-text-modal-details-label">
                      NVR/DVR Name
                    </td>
                    <td className="rhms-table-text-modal-details">
                      {modalData.nvr.replace(/[_-]/g, " ")}
                      {modalData.nvr_ip2 && (
                        <sup>
                          <AutorenewIcon
                            style={{ fontSize: "16px", fontWeight: "bolder" }}
                          />
                          Switch over{" "}
                        </sup>
                      )}
                    </td>
                  </tr>
                )}
                {modalData.facility && (
                  <tr>
                    <td className="rhms-table-text-modal-details-label">
                      Store Name
                    </td>
                    <td className="rhms-table-text-modal-details">
                      {modalData.facility
                        .replace(/_/g, " ")
                        .replace(/\b\w/g, (c) => c.toUpperCase())}
                    </td>
                  </tr>
                )}
                {modalData.brand && (
                  <tr>
                    <td className="rhms-table-text-modal-details-label">
                      Brand
                    </td>
                    <td className="rhms-table-text-modal-details">
                      {modalData.brand}
                    </td>
                  </tr>
                )}
                {modalData.contact_person && (
                  <tr>
                    <td className="rhms-table-text-modal-details-label">
                      Contact Person
                    </td>
                    <td className="rhms-table-text-modal-details">
                      {modalData.contact_person}
                    </td>
                  </tr>
                )}

                {modalData.contact_number && (
                  <tr>
                    <td className="rhms-table-text-modal-details-label">
                      Contact Number
                    </td>
                    <td className="rhms-table-text-modal-details">
                      {modalData.contact_number}
                    </td>
                  </tr>
                )}

                {modalData.facility_address && (
                  <tr>
                    <td className="rhms-table-text-modal-details-label">
                      Store Address
                    </td>
                    <td
                      className="rhms-table-text-modal-details"
                      style={{ lineHeight: "1.4" }}
                    >
                      {modalData.facility_address}
                    </td>
                  </tr>
                )}
                {modalData.last_online && (
                  <tr>
                    <td className="rhms-table-text-modal-details-label">
                      Last Online
                    </td>
                    <td className="rhms-table-text-modal-details">
                      {modalData.last_online}
                    </td>
                  </tr>
                )}
                {modalData.last_available_recording && (
                  <tr>
                    <td className="rhms-table-text-modal-details-label">
                      LAR Date
                    </td>
                    <td className="rhms-table-text-modal-details">
                      {modalData.last_available_recording}
                    </td>
                  </tr>
                )}
                {modalData.nvr_ip && (
                  <tr>
                    <td className="rhms-table-text-modal-details-label">
                      Primary IP
                    </td>
                    <td className="rhms-table-text-modal-details">
                      {modalData.nvr_ip}
                    </td>
                  </tr>
                )}
                {modalData.nvr_http && (
                  <tr>
                    <td className="rhms-table-text-modal-details-label">
                      Primary HTTP Port
                    </td>
                    <td className="rhms-table-text-modal-details">
                      {modalData.nvr_http}
                    </td>
                  </tr>
                )}

                {modalData.nvr_ip2 && (
                  <tr>
                    <td className="rhms-table-text-modal-details-label">
                      Secondary IP
                    </td>
                    <td className="rhms-table-text-modal-details">
                      {modalData.nvr_ip2}
                    </td>
                  </tr>
                )}
                {modalData.nvr_http2 && (
                  <tr>
                    <td className="rhms-table-text-modal-details-label">
                      Secondary HTTP Port
                    </td>
                    <td className="rhms-table-text-modal-details">
                      {modalData.nvr_http}
                    </td>
                  </tr>
                )}
                {modalData.nvr_https && (
                  <tr>
                    <td className="rhms-table-text-modal-details-label">
                      HTTPS Port
                    </td>
                    <td className="rhms-table-text-modal-details">
                      {modalData.nvr_https}
                    </td>
                  </tr>
                )}
                {modalData.nvr_rtsp && (
                  <tr>
                    <td className="rhms-table-text-modal-details-label">
                      RTSP Port
                    </td>
                    <td className="rhms-table-text-modal-details">
                      {modalData.nvr_rtsp}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="col-12 col-lg-7 heatmap-chart-container">
            {modalData.nvr_online == "OFFLINE" && (
              <>
                <div className="d-flex justify-content-end m-3">
                  <Button
                    variant="contained"
                    color="warning"
                    title="Check the problem causing the NVR/DVR to be offline"
                    onClick={() => runDiagonistic(modalData.nvr_id)}
                  >
                    Run Diagnostic
                  </Button>
                </div>
                {diagnosError.length > 0 && (
                  <div>
                    <Alert severity="info">{diagnosError}</Alert>
                  </div>
                )}
              </>
            )}
            {modalData.brand === "Hikvision" ? (
              <GraphTab nvr_id={modalData.nvr_id} />
            ) : (
              <DeviceUptime nvr_id={modalData.nvr_id} />
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};
RemoteMonitoringRowDetails.propTypes = {
  row: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
};
export default RemoteMonitoringRowDetails;
