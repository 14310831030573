import { React, useEffect, useState } from "react";
import moment from "moment";
import Chart from "react-apexcharts";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 15,
  },
}));

const DashLineChart = (propTypes) => {
  const [tickAmount, settickAmount] = useState(0);
  const [series, setseries] = useState([]);
  const [selectedMode] = useState("hourly");
  const [dataLabelsEnabled, setDataLabelsEnabled] = useState(false);
  const [dataPointTooltipText, setDataPointTooltipText] =
    useState("Show data points");
  const dataPointsToggle = (ev) => {
    const check = ev.currentTarget.checked;
    setDataLabelsEnabled(check);
    setDataPointTooltipText(check ? "Hide data points" : "Show data points");
  };

  useEffect(() => {
    if (
      propTypes.data.length > 0 &&
      Object.prototype.hasOwnProperty.call(propTypes.data[0], "data")
    ) {
      assignData(propTypes.data);
    }
  }, [propTypes.data]);

  const assignData = (val) => {
    settickAmount(val[0].data.length);
    let seriesTemp = [];
    for (let i = 0; i < val.length; i++) {
      seriesTemp.push({
        name: val[i].name,
        data: val[i].data,
      });
      if (tickAmount < val[i].data.length) {
        settickAmount(val[i].data.length);
      }
    }
    setseries(seriesTemp);
  };

  function toolTipObj() {
    if (propTypes.type === "week") {
      return {
        shared: true,
        intersect: false,
        followCursor: true,
        x: {
          formatter: (val, { seriesIndex, dataPointIndex, w }) => {
            return moment(
              w.config.series[seriesIndex].data[dataPointIndex].x
            ).format("dddd, MMMM D YYYY");
          },
        },
        y: {
          formatter: (value, { series, seriesIndex }) => {
            const total = series[seriesIndex].reduce(
              (acc, cur) => acc + cur,
              0
            );
            return `${value} (${((value / total) * 100).toFixed(2)}%)`;
          },
        },
      };
    } else {
      return {
        shared: true,
        intersect: false,
        followCursor: true,
        theme: false,
        x: {
          formatter: (val) => {
            return moment(val).format("MMMM D YYYY, h:mm A");
          },
        },
        y: {
          formatter: (value, { series, seriesIndex }) => {
            const total = series[seriesIndex].reduce(
              (acc, cur) => acc + cur,
              0
            );
            return `${value} (${((value / total) * 100).toFixed(2)}%)`;
          },
        },
      };
    }
  }

  function xaxisForChart() {
    if (propTypes.type === "week") {
      return {
        type: "date",
        labels: {
          formatter: (val) => {
            if (selectedMode === "hourly") {
              return moment(new Date(val)).format("ddd");
            }
            return moment(new Date(val)).format("DD-MM-YY");
          },
          style: {
            colors: "var(--card-text-color)", //change text color to var(--card-text-color)
          },
        },
        title: {
          text: "Day",
        },
        tooltip: {
          enabled: false,
        },
      };
    } else {
      return {
        type: "datetime",
        tickAmount:
          tickAmount === 0 ? 0 : tickAmount === 1 ? 2 : tickAmount - 1,
        labels: {
          formatter: (val) => {
            if (selectedMode === "hourly") {
              return moment(new Date(val)).format("h A");
            }
            return moment(new Date(val)).format("DD-MM-YY");
          },
          style: {
            colors: "var(--card-text-color)", //change text color to var(--card-text-color)
          },
        },
        title: {
          text: dataLabelsEnabled ? "Time" : "",
          style: {
            fontSize: "16px", // Set the font size
            fontFamily: "Roboto", // Set the font family
            fontWeight: "bold", // Set the font weight
            color: "var(--card-text-color)", // Set the font color
          },
        },
        tooltip: {
          enabled: false,
        },
      };
    }
  }
  const options = {
    chart: {
      id: "line-chart",
      height: 350,
      type: "line",
      stacked: false,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    xaxis: xaxisForChart(),
    // tooltip: toolTipObj(),
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    yaxis: {
      title: {
        text: dataLabelsEnabled ? "Group Count" : "",
        style: {
          fontSize: "16px", // Set the font size
          fontFamily: "Roboto", // Set the font family
          fontWeight: "bold", // Set the font weight
          color: "var(--card-text-color)", // Set the font color
        },
      },
      labels: {
        show: true,
        style: {
          colors: "var(--card-text-color)", // Change text color to var(--card-text-color)
        },
      },
    },
    stroke: {
      curve: "smooth",
    },
    legend: {
      position: "top",
    },
    colors: ["#4339F2", "#DAD7FE"],
  };
  return (
    <div className={propTypes.classNameActive ? "chart-container" : null}>
      <div className={propTypes.classNameActive ? "chart-div" : null}>
        <div className="chart-div">
          <p
            className="chart-title"
            style={{ textAlign: "left", marginLeft: "20px" }}
          >
            {propTypes.title}
          </p>{" "}
          <div
            className="chart-title"
            style={{ textAlign: "right", marginLeft: "20px" }}
          >
            <span className="d-block text-dark font-weight-bolder">
              <label className="checkbox checkbox-lg checkbox-single flex-shrink-0 m-0 mx-4 checkbox-light-secondary">
                <LightTooltip placement="top" title={dataPointTooltipText}>
                  <input
                    className="chart-checkbox"
                    type="checkbox"
                    onChange={dataPointsToggle}
                  />
                </LightTooltip>

                <span className="checkbox-icon"></span>
              </label>
            </span>
          </div>
          <div style={{ overflow: "hidden" }}>
            <Chart options={options} series={series} type="line" height={300} />{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashLineChart;
