import moment from "moment";
import * as DateFns from "date-fns";
const initialState = {
  totalEventHourly: [],
  resolvedEventHourly: [],
  unresolvedEventHourly: [],
  totalWeeklyEvents: [],
  topSevenSiteEventData: [],
  totalEventTypeData: [],
  eventtTableData: [],
  tableEventCount: 0,
};

export default function EventReducer(state = initialState, action) {
  switch (action.type) {
    case "TOTAL_EVENT_HOURLY":
      return {
        ...state,
        totalEventHourly: action.payload.map((datapoint) => {
          let dater = DateFns.setHours(
            new Date(action.date),
            new Date(datapoint.event_recieved_at__hour)
          );
          datapoint.date = moment(dater)
            .subtract(30, "minutes")
            .toLocaleString();
          delete datapoint.event_recieved_at__hour;
          return datapoint;
        }),
      };
    case "RESOLVED_EVENT_HOURLY":
      return {
        ...state,
        resolvedEventHourly: action.payload.map((datapoint) => {
          let dater = DateFns.setHours(
            new Date(action.date),
            new Date(datapoint.event_recieved_at__hour)
          );
          datapoint.date = moment(dater)
            .subtract(30, "minutes")
            .toLocaleString();
          delete datapoint.event_recieved_at__hour;
          return datapoint;
        }),
      };
    case "UNRESOLVED_EVENT_HOURLY":
      return {
        ...state,
        unresolvedEventHourly: action.payload.map((datapoint) => {
          let dater = DateFns.setHours(
            new Date(action.date),
            new Date(datapoint.event_recieved_at__hour)
          );
          datapoint.date = moment(dater)
            .subtract(30, "minutes")
            .toLocaleString();
          delete datapoint.event_recieved_at__hour;
          return datapoint;
        }),
      };
    case "TOTAL_WEEKLY_EVENETS":
      return {
        ...state,
        totalWeeklyEvents: action.payload.map((datapoint) => {
          datapoint.date = new Date(
            datapoint.event_recieved_at__date
          ).toISOString();
          return datapoint;
        }),
      };
    case "TOP_SEVEN_SITE_EVENTS":
      return {
        ...state,
        topSevenSiteEventData: action.payload,
      };
    case "TOTAL_EVENTS_TYPE_DATA":
      return {
        ...state,
        totalEventTypeData: action.payload,
      };

    case "TABLE_DATA_EVENT":
      return {
        ...state,
        eventtTableData: action.payload.data,
        tableEventCount: action.payload.count,
      };

    default:
      return state;
  }
}
