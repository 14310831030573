import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import DashboardCard from "../DashboardCard"; // Ensure correct import path
import Diversity3Icon from "@mui/icons-material/Diversity3"; // Ensure correct import
import TimelineIcon from "@mui/icons-material/Timeline";
const iconFont = {
  fontSize: "50px",
  color: "#4318FF",
};
function getMaxAgeGroup(data) {
  let maxKey = "";
  let maxValue = -1;
  let total = 0;
  let maxPercentage = 0;

  // Calculate the total count of all age groups
  for (let key in data) {
    total += data[key];
  }
  if (total === 0) {
    return {
      text: "Max Age Group",
      ageGroup: "",
      percentage: 0,
    };
  }

  // Find the age group with the highest count and its percentage
  for (let key in data) {
    let percentage = (data[key] / total) * 100;
    if (data[key] > maxValue) {
      maxValue = data[key];
      maxKey = key;
      maxPercentage = percentage;
    }
  }

  // Convert age group key to "start-end" format
  const ageGroupParts = maxKey.split("_");
  const startAge = ageGroupParts[1];
  const endAge = ageGroupParts[2];
  const ageGroupLabel = `${startAge}-${endAge}`;

  return {
    text: "Max Age Group",
    ageGroup: ageGroupLabel,
    percentage: maxPercentage.toFixed(1, 2),
  };
}
const AgeGroupDistributionCard = ({ selectedMode }) => {
  const profile = JSON.parse(localStorage.getItem("profile") || "{}");
  const accessPermissions =
    JSON.parse(localStorage.getItem("agx_permission") || "{}").Dashboard || [];
  const age = useSelector((state) => state.age);
  const maxAgeGroup = getMaxAgeGroup(age.ageDistribution);
  const ageGroups = [
    { ageRange: "age_0_12", text: "Age group (0-12)" },
    { ageRange: "age_13_18", text: "Age group (13-18)" },
    { ageRange: "age_19_25", text: "Age group (19-25)" },
    { ageRange: "age_26_35", text: "Age group (26-35)" },
    { ageRange: "age_36_45", text: "Age group (36-45)" },
    { ageRange: "age_46_60", text: "Age group (46-60)" },
    { ageRange: "age_60_ahead", text: "Age group (60+)" },
  ];

  const canViewDemographics =
    profile.services_selected?.Dashboard.includes("age_demographics_cards") &&
    accessPermissions.includes("view_demographics_analytics") &&
    selectedMode.value === "day-wise";
  const PeopleDemographicsLoading = useSelector(
    (state) => state.loading.demographicsLoadingPeopleCount
  );
  return (
    <>
      {profile.services_selected.Dashboard.includes("demographics") &&
        accessPermissions.includes("view_demographics_analytics") &&
        selectedMode.value === "day-wise" && (
          <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-2">
            <DashboardCard
              icon={<TimelineIcon sx={iconFont} />}
              headText={maxAgeGroup.percentage + "%"}
              paraText={`${maxAgeGroup.text} ${maxAgeGroup.ageGroup}`}
              isLoading={PeopleDemographicsLoading}
            />
          </div>
        )}
      {canViewDemographics &&
        ageGroups.map(({ ageRange, text }) => (
          <div
            key={ageRange}
            className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-2"
          >
            <DashboardCard
              icon={<Diversity3Icon style={iconFont} />}
              headText={age.ageDistribution[ageRange]}
              paraText={text}
              isLoading={PeopleDemographicsLoading}
            />
          </div>
        ))}
    </>
  );
};

AgeGroupDistributionCard.propTypes = {
  selectedMode: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }).isRequired,
};

export default AgeGroupDistributionCard;
