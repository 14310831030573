import React from "react";
import smallLogo from "../../assets/images/agrexSmallLogo.png";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import PropTypes from "prop-types";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import PauseIcon from "@mui/icons-material/Pause";
import { Button } from "@mui/material";
import ChatInput from "./ChatInput";
const ChatInterface = ({
  messages,
  loading,
  handleInputChange,
  handleClearChat,
}) => {
  const [phone] = useState(window.innerWidth > 500 ? false : true);
  var profile = JSON.parse(localStorage.getItem("profile"));
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    scrollToBottom();
    sessionStorage.setItem("agx_chat_bot_chat", JSON.stringify(messages));
  }, [messages, loading]);

  return (
    <div>
      <div className="container-fluid">
        <div className="col-12 chatbox-mainframe">
          <div className="col-12 chatting-box">
            {messages.map((msg, index, messages) => {
              return msg.role === "user" ? (
                <div
                  key={index}
                  className="d-flex user-sent-message col-11 col-sm-11 col-md-11"
                  ref={index + 1 == messages.length ? messagesEndRef : null}
                >
                  <div className="user-message-text">
                    <p id="chat-msg-text">{msg.content}</p>
                  </div>
                  <div className="user-text-profile-icon d-flex">
                    <IconButton>
                      <Avatar
                        sx={{
                          width: phone ? "25px" : "30px",
                          height: phone ? "25px" : "30px",
                          fontSize: phone ? "16px" : "20px",
                        }}
                        alt={profile.name}
                        src="#"
                      />
                    </IconButton>
                  </div>
                </div>
              ) : msg.role === "assistant" ? (
                <div
                  key={index}
                  className="d-flex server-sent-message col-11 col-sm-11 col-md-11"
                  ref={index + 1 == messages.length ? messagesEndRef : null}
                >
                  <div className="server-text-profile-icon">
                    <img src={smallLogo} />
                  </div>
                  {msg.content.length > 10000 ? (
                    <div className="server-message-text">
                      <img
                        src={msg.content}
                        id="imageTag"
                        className="image-div-from-camera"
                      />
                    </div>
                  ) : (
                    <div className="server-message-text">
                      {msg.content.split("\n").map((line, ind) => {
                        return (
                          <p id="chat-msg-text" key={ind}>
                            {line}
                          </p>
                        );
                      })}
                    </div>
                  )}
                </div>
              ) : null;
            })}
            {loading ? (
              <div
                className=" d-flex server-sent-message col-12"
                ref={messagesEndRef}
              >
                <div className="server-text-profile-icon">
                  <img src={smallLogo} />
                </div>
                <div className="server-message-text" style={{ border: "none" }}>
                  <ThreeDots
                    height="30"
                    width="40"
                    radius="9"
                    color="gray"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              </div>
            ) : null}
          </div>

          <div className="row px-2 bottom-search-input-bar-new">
            <div className="col mt-1">
              {loading ? (
                <Button
                  variant="contained"
                  size="medium"
                  startIcon={<PauseIcon />}
                >
                  Stop
                </Button>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  sx={{
                    minWidth: "40px",
                    height: "40px",
                    padding: 0,
                    "& .MuiButton-startIcon": {
                      margin: 0,
                    },
                  }}
                  onClick={handleClearChat}
                  disabled={loading}
                >
                  <DeleteOutlineIcon sx={{ fontSize: "25px" }} />
                </Button>
              )}
            </div>

            <div className="col-8 col-sm-8 col-md-10 col-lg-10 col-xl-11">
              <ChatInput
                loading={loading}
                handleInputChange={handleInputChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
ChatInterface.propTypes = {
  messages: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleClearChat: PropTypes.func.isRequired,
};
export default ChatInterface;
