import AlertAction from "../../redux/Action/AlertChartAction";
import AlertTableDataAction from "../../redux/Action/AlertTableDataAction";
import moment from "moment";
import AlertEventChartActions from "../../redux/Action/AlertEventChartActions";
import AlertEventTableActions from "../../redux/Action/AlertEventTableActions";
const getChartData = (
  dateValue,
  store,
  device,
  alert_type,
  priority,
  alertOrEvent,
  time,
  dispatch
) => {
  const date = moment(dateValue);
  const formattedDate = date.format("YYYY-MM-DD");
  const profile = JSON.parse(localStorage.getItem("profile"));
  const formatTime = (time) => {
    const [hours, minutes] = time
      .toString()
      .split(".")
      .map((num) => num.padStart(2, "0"));
    return `${hours}:${minutes || "00"}:00`;
  };

  // Format start and end time
  const [startTime, endTime] = time.map(formatTime);
  console.log(startTime, endTime);
  getChartDataMerged(
    dateValue,
    store,
    device,
    alert_type,
    priority,
    alertOrEvent,
    dateValue,
    dateValue,
    startTime,
    endTime,
    dispatch
  );
  const obj = {
    id: profile.id,
    alert_type: alert_type,
    date: formattedDate,
    priority: priority,
    alertOrEvent:
      priority == "all" ? "all" : priority == "high" ? "alert" : "event",
    start_time: startTime,
    end_time: endTime,
  };
  if (obj.id == 174) {
    //only for yumfoods
    AlertAction.yumfoodsChartAnalysis(obj.id, obj.date, obj.date)(dispatch);
  }
  if (store == "all") {
    const obj = {
      id: profile.id,
      alert_type: alert_type,
      date: formattedDate,
      priority: priority,
      alertOrEvent: alertOrEvent,
      start_time: startTime,
      end_time: endTime,
    };
    AlertAction.getTopTenAlertsSites(obj)(dispatch);
    AlertAction.getAlertTypeStaticsHourlyByAccount(obj)(dispatch);
    if (obj.id == 174) {
      //only for yumfoods
      AlertAction.yumfoodsChartAnalysis(obj.id, obj.date, obj.date)(dispatch);
    }
  } else if (device == "all") {
    const obj = {
      id: store,
      alert_type: alert_type,
      date: formattedDate,
      priority: priority,
      alertOrEvent: alertOrEvent,
      start_time: startTime,
      end_time: endTime,
    };
    AlertAction.getAlertTypeStaticsHourlyByFacility(obj)(dispatch);
  } else {
    const obj = {
      id: device,
      alert_type: alert_type,
      date: formattedDate,
      priority: priority,
      alertOrEvent: alertOrEvent,
      start_time: startTime,
      end_time: endTime,
    };

    AlertAction.getAlertTypeStaticsHourlyByCamera(obj)(dispatch);
  }
};
export const getChartDataByDateRange = (
  startDate,
  endDate,
  store,
  device,
  alert_type,
  priority,
  alertOrEvent,
  dispatch
) => {
  const dateStart = moment(startDate);
  const dateEnd = moment(endDate);
  const startDate2 = dateStart.format("YYYY-MM-DD");
  const endDate2 = dateEnd.format("YYYY-MM-DD");
  const profile = JSON.parse(localStorage.getItem("profile"));
  getChartDataMerged(
    startDate,
    store,
    device,
    alert_type,
    priority,
    alertOrEvent,
    startDate,
    endDate,
    null,
    null,
    dispatch
  );
  if (store == "all") {
    const obj = {
      id: profile.id,
      alert_type: alert_type,
      start_date: startDate2,
      end_date: endDate2,
      priority: priority,
      alertOrEvent: alertOrEvent,
    };

    if (obj.id == 174) {
      //only for yumfoods
      AlertAction.yumfoodsChartAnalysis(
        obj.id,
        obj.start_date,
        obj.end_date
      )(dispatch);
    }
  }
};

export default getChartData;

export const getTenSitesDataAction = (
  account_id,
  obj,
  dates,
  priority,
  alertOrEvent,
  time,
  dispatch
) => {
  // Function to correctly format time for startTime and endTime
  const formatTime = (time) => {
    const [hours, minutes] = time
      .toString()
      .split(".")
      .map((num) => num.padStart(2, "0"));
    return `${hours}:${minutes || "00"}:00`;
  };

  // Format start and end time
  const [startTime, endTime] = time.map(formatTime);
  const date = moment(dates);
  const formattedDate = date.format("YYYY-MM-DD");
  AlertAction.getTopTenAlertsSitesBySites(
    account_id,
    obj,
    formattedDate,
    priority,
    alertOrEvent,
    startTime,
    endTime
  )(dispatch);
};

/**
 * Fetches the data for a table based on various parameters such as dates, mode, alert type,
 * store, device, time, date range, page, tab value, search item, page size, selected use
 * case, priority, and alert or event. It has an optional parameter for loading status.
 *
 * @param {Date} dateValue - The selected date.
 * @param {string} mode - The selected mode, either 'day-wise' or not.
 * @param {string} alert_type - The type of alert.
 * @param {string} store - The id of the store to fetch data from.
 * @param {string} device - The id of the device to fetch data from.
 * @param {Array} time - Array containing the start and end time.
 * @param {Object} dtRange - The range of dates.
 * @param {number} page - The current page.
 * @param {Array} tabValue - Array containing boolean parameters for the tabs.
 * @param {string} searchItem - The search item.
 * @param {number} pageSize - The size of the page.
 * @param {Object} selectedUseCase - The selected use case.
 * @param {string} priority - The priority of the alert or event.
 * @param {string} alertOrEvent - The value could either be 'alert' or 'event'.
 * @param {function} dispatch - The dispatch function of the redux store.
 * @param {boolean} showLoading - Indicates whether to display loading state.
 */
export const getTableData = (
  dateValue,
  mode,
  alert_type,
  store,
  device,
  time,
  dtRange,
  page,
  tabValue,
  searchItem,
  pageSize,
  selectedUseCase,
  priority,
  alertOrEvent,
  dispatch,
  showLoading = true
) => {
  // Function to correctly format time for startTime and endTime
  const formatTime = (time) => {
    const [hours, minutes] = time
      .toString()
      .split(".")
      .map((num) => num.padStart(2, "0"));
    return `${hours}:${minutes || "00"}:00`;
  };

  // Format start and end time
  const [startTime, endTime] = time.map(formatTime);

  // Create start and end dates based on mode
  const startDate =
    mode === "day-wise"
      ? moment(dateValue).format("YYYY-MM-DD")
      : moment(dtRange.startDate).format("YYYY-MM-DD");
  const endDate =
    mode === "day-wise"
      ? startDate
      : moment(dtRange.endDate).format("YYYY-MM-DD");

  // Get profile from local storage
  const profile = JSON.parse(localStorage.getItem("profile"));

  // Check operation mode
  const opMode =
    JSON.parse(localStorage.getItem("profile")).account.config["Operation"] ==
    "True";
  if (priority == "all") {
    alertOrEvent = "all";
  } else if (priority == "high") {
    alertOrEvent = "alert";
  } else {
    alertOrEvent = "event";
  }
  // Check if selected use case is not 'all' and operation mode is true
  if (selectedUseCase.value != "all" && opMode) {
    const obj = {
      selectedUseCase: selectedUseCase.value,
      date: moment(dateValue).format("YYYY-MM-DD"),
      start_time: startTime,
      end_time: endTime,
      type: alertOrEvent,
      page_no: page,
      page_size: pageSize,
    };
    // Dispatch action to alert or events via usecase for operation uses
    AlertTableDataAction.getAlertViaUSecase(obj)(dispatch);
  } else {
    // Create parameters for fetching data
    const params = {
      notification_type: alert_type,
      alert_or_event: alertOrEvent,
      priority: priority,
      start_date: startDate,
      end_date: endDate,
      start_time: startTime,
      end_time: endTime,
      is_false: tabValue[0],
      is_resolved: tabValue[1],
      page_size: pageSize,
      page_no: page,
      user_profile_id: profile.id,
      // Check store, device and length of search item to add properties to params
      ...(store !== "all" && device === "all" && { facility_id: store }),
      ...(device !== "all" && { camera_id: device }),
      ...(searchItem.length >= 3 && { searchitem: searchItem }),
    };

    dispatch(
      AlertEventTableActions.AlertEventMergedAction(params, showLoading)
    );
  }
};

/**
 * Constructs query params for dispatching actions
 * and dispatches alert event actions for a date range.
 *
 * @params {Object} dateValue - The date for which to fetch data.
 * @params {String} store - The store id.
 * @params {String} device - The device id(camera).
 * @params {String} alert_type - The type of alert "alert" and "event", i,e human_alerts.
 * @params {String} priority - The priority level of the alert "high", "normal" or "all".
 * @params {String} alertOrEvent - To differentiate between alert and event(alert or event)
 * @params {Object} startDate - Start date of the range.
 * @params {Object} endDate - End date of the range.
 * @params {Function} dispatch - The dispatch function from Redux.
 * @return {Object} paramsBase - The base parameters used for fetching data.
 *
 */
export const getChartDataMerged = (
  dateValue,
  store,
  device,
  alert_type,
  priority,
  alertOrEvent,
  startDate,
  endDate,
  startTime,
  endTime,
  dispatch
) => {
  // Formatting date and fetching profile data
  console.log(
    dateValue,
    store,
    device,
    alert_type,
    priority,
    alertOrEvent,
    startDate,
    endDate,
    startTime,
    endTime,
    dispatch
  );
  const formattedDate = moment(dateValue).format("YYYY-MM-DD");
  const profile = JSON.parse(localStorage.getItem("profile"));

  // Formatting start and end date
  const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
  const formattedEndDate = moment(endDate).format("YYYY-MM-DD");
  if (priority == "all") {
    alertOrEvent = "all";
  } else if (priority == "high") {
    alertOrEvent = "alert";
  } else {
    alertOrEvent = "event";
  }
  // Creating base parameters for API calls
  const paramsBase = {
    user_profile_id: profile.id,
    alertOrEvent,
    priority,
    start_date: formattedStartDate,
    end_date: formattedEndDate,

    ...(alertOrEvent === "all"
      ? { alert_type: alert_type }
      : alertOrEvent === "alert"
      ? { alert_type: alert_type }
      : { event_type: alert_type }),
    ...(store !== "all" && device === "all" ? { facility_id: store } : {}),
    ...(device !== "all" ? { camera_id: device } : {}),
  };

  if (endDate === startDate) {
    paramsBase.start_time = startTime;
    paramsBase.end_time = endTime;
  }
  // Dispatching actions for different types depending on the graph type
  ["total", "resolved", "unresolved", "dismissed"].forEach((graph_type) => {
    dispatch(
      AlertEventChartActions.AlertEventMergedActionForDashboard({
        ...paramsBase,
        graph_type,
      })
    );
  });

  // Conditionally dispatching additional actions based on priority and date range
  //if priority is equal to all then calling a api for fetching it
  if (priority === "all") {
    dispatch(AlertEventChartActions.getWeeklyAlertEvent(paramsBase));
  }
  //if priority is equal to high or normal then calling a consolidate api
  else if (priority === "high" && formattedStartDate === formattedEndDate) {
    dispatch(
      AlertEventChartActions.AlertEventMergedActionForDashboard({
        ...paramsBase,
        alert_weekly: true,
        graph_type: "total",
      })
    );
  } else if (priority === "normal" && formattedStartDate === formattedEndDate) {
    dispatch(
      AlertEventChartActions.AlertEventMergedActionForDashboard({
        ...paramsBase,
        event_weekly: true,
        graph_type: "total",
      })
    );
  }

  // Dispatching action to fetch top 7 site alert events
  dispatch(
    AlertEventChartActions.getTop7SiteAlertEvent({
      user_profile_id: paramsBase.user_profile_id,
      priority: paramsBase.priority,
      start_date: paramsBase.start_date,
      end_date: paramsBase.end_date,
      start_time: paramsBase.start_time,
      end_time: paramsBase.end_time,
      alert_type: alert_type,
    })
  );

  // Dispatching action to fetch alert event classifications
  dispatch(
    AlertEventChartActions.getAlertEventClassificationMerged(paramsBase)
  );
  let count_name = profile.account.config["count"];
  if (count_name) {
    dispatch(
      AlertEventChartActions.getPizzaCountUserFacilityCamera({
        ...paramsBase,
        count_name: count_name,
      })
    );
  }

  return paramsBase;
};
