import React from "react";
import EmailIcon from "@mui/icons-material/Email";
import DownloadIcon from "@mui/icons-material/Download";
import { ColorRing } from "react-loader-spinner";
import { reportDownloadByMail } from "../../redux/Action/CommonAction";
import { totalNvrDataForLocalDownload } from "../../redux/Action/RhmsAction";
import { useState } from "react";
import mainCss from "../../assets/css/mainCss";
const DownloadAndEmailReport = () => {
  const [access_permission] = useState(
    JSON.parse(localStorage.getItem("agx_permission")).Remote_monitoring ?? []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingg, setIsLoadingg] = useState(false);

  /**
   * Sets the loading state to true, fetches total Nvr (Network Video Recorder) data and downloads it as a report.
   * If there is an error while fetching the data, the loading state is set to false.
   */
  const handleLocalDownloadData = () => {
    setIsLoading(true);
    totalNvrDataForLocalDownload()
      .then((res) => {
        downloadReportToSystem(makeDownloadableData(res.data));
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  /**
   * Converts a JSON object array to CSV format.
   * @param {object} objArray - A JSON object array.
   * @return {string} The CSV string.
   */
  const convertJsonToCSV = (objArray) => {
    var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    var str = "";
    for (var i = 0; i < array.length; i++) {
      var line = "";
      for (var index in array[i]) {
        if (line != "") line += ",";
        line += array[i][index];
      }
      str += line + "\r\n";
    }
    return str;
  };
  /**
   * Formats given data, mainly by combining the 'cameras_offline' array into a string and selecting the first element from 'last_recording_available'.
   * @param {object} data - The data to be formatted.
   * @return {object} The formatted data.
   */
  const makeDownloadableData = (data) => {
    var arr = data.map(
      ({
        nvr,
        facility,
        city,
        state,
        address,
        zone,
        total_cameras,
        nvr_online,
        hdd_working,
        is_recording,
        cameras_offline,
        last_online,
        last_recording_available,
        reason,
      }) => {
        last_recording_available = last_recording_available[0];
        cameras_offline = cameras_offline.join("/");
        address = address.replace(/[^a-zA-Z0-9\s]/g, " ").replace(/\s+/g, " ");
        return {
          nvr,
          facility,
          city,
          state,
          address,
          zone,
          total_cameras,
          nvr_online,
          hdd_working,
          is_recording,
          cameras_offline,
          last_online,
          last_recording_available,
          reason,
        };
      }
    );
    return arr;
  };

  /**
   * Downloads a formed report to the user's system in a CSV format.
   * @param {string} csvStr - The report data in a CSV string format.
   */
  const downloadReportToSystem = (csvStr) => {
    try {
      csvStr.unshift({
        nvr: "NVR/DVR",
        facility: "Store",
        city: "City",
        state: "State",
        address: "Address",
        zone: "Zone",
        total_cameras: "Total Cameras",
        nvr_online: "NVR ONLINE",
        hdd_working: "HDD Working",
        is_recording: "Recording",
        cameras_offline: "Offline Camera",
        last_online: "Last Online",
        last_recording_available: "Last Recording Available",
        reason: "Offline Reason",
      });
      var downloadLink = document.createElement("a");
      var blob = new Blob(["\ufeff", convertJsonToCSV(csvStr)]);
      var url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = `NVR_Health_Report.csv`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (e) {
      console.log(e);
    }
  };
  /**
   * Sends an email report based on given parameters, setting/loading states appropriately.
   */
  const handleSendEmailReport = () => {
    setIsLoadingg(true);
    const type = "remote-monitoring";
    reportDownloadByMail(Date.now(), type, "day-wise", Date.now())
      .then(() => {
        setIsLoadingg(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <div>
      {access_permission.includes("nvr_email_download_report") && (
        <div className="col mt-4">
          <div className="d-flex justify-content-end">
            <div
              className="btn  btn-sm m-1 shadow-none"
              style={mainCss.downloadButton}
              onClick={handleLocalDownloadData}
            >
              {!isLoading ? (
                <DownloadIcon sx={mainCss.downloadIcon} />
              ) : (
                <ColorRing
                  visible={true}
                  height="40"
                  width="40"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#ffff", "#ffff", "#ffff", "#ffff", "#ffff"]}
                />
              )}
            </div>

            <div
              className="btn  btn-sm m-1 shadow-none"
              style={mainCss.downloadButton}
              onClick={handleSendEmailReport}
            >
              {!isLoadingg ? (
                <EmailIcon sx={mainCss.downloadIcon} />
              ) : (
                <ColorRing
                  visible={true}
                  height="40"
                  width="40"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#ffff", "#ffff", "#ffff", "#ffff", "#ffff"]}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DownloadAndEmailReport;
