import axios from "axios";
import AlertApiSpec from "../features/AlertEndPoints";
import {
  notifiToasterSuccess,
  notifiToasterWarn,
  notifiToasterInfo,
} from "../../components/common/UseToast";

/**
 * getLocalDataDownloadAlertByAccount function
 *
 * This function is used to get data for downloading the alerts and events table based on user profile
 * including alert type, date, and priority.
 *
 * @param {Object} data - It should include id, alert_type, date, priority, and alertOrEvent.
 * @returns {Promise} this promise will resolve to the response from the API call, or reject with an error
 */
export const getLocalDataDownloadAlertByAccount = (data) => {
  return new Promise((resolve, reject) => {
    // Get token from local storage
    var token = localStorage.getItem("token");

    // Form the headers for the request
    let headers = {
      Authorization: `Token ${token}`,
    };

    // Make the GET request to API endpoint using axios
    axios
      .get(
        AlertApiSpec.getAlertsDataDownloadByAccount(
          data.id,
          data.alert_type,
          data.date,
          data.priority,
          data.alertOrEvent
        ),
        {
          headers,
        }
      )
      .then((response) => {
        // If the request is successful, show a success notification and resolve the promise with the response
        notifiToasterSuccess("Alert Report Downloaded Successfully");
        resolve(response);
      })
      .catch((error) => {
        // If request fails, show a warning notification and reject the promise with the error
        notifiToasterWarn("Failed To Download Report");
        reject(error);
      });
  });
};

/**
 * getLocalDataDownloadAlertByStore function
 *
 * This function is used to download the alerts and events data for a selected store
 * based on user profile, alert type, date, priority, etc
 *
 * @param {Object} data - It should include id, alert_type, date, priority, and alertOrEvent.
 * @returns {Promise} this promise will resolve to the response from the API call, or reject with an error
 */
export const getLocalDataDownloadAlertByStore = (data) => {
  return new Promise((resolve, reject) => {
    // Get token from local storage
    var token = localStorage.getItem("token");

    // Form headers for the request
    let headers = {
      Authorization: `Token ${token}`,
    };

    // Make a GET request to API using axios
    axios
      .get(
        AlertApiSpec.getAlertsDataDownloadByStore(
          data.id,
          data.alert_type,
          data.date,
          data.priority,
          data.alertOrEvent
        ),
        {
          headers,
        }
      )
      .then((response) => {
        // If the request is successful, show a success notification and resolve promise with the response
        notifiToasterSuccess("Alert Report Downloaded Successfully");
        resolve(response);
      })
      .catch((error) => {
        // If the request fails, show a warning notification and reject the promise with the error
        notifiToasterWarn("Failed To Download Report");
        reject(error);
      });
  });
};
/**
 * getLocalDataDownloadAlertByCamera function
 *
 * This function is used to retrieve alerts and events data for a specific camera based on user profile,
 * alert type, date, and priority etc.
 *
 * @param {Object} data - An object representing the user's data.
 * Should include id, alert_type, date, priority, and alertOrEvent.
 *
 * @returns {Promise} A promise that resolves to the response from the API call,
 * or rejects with an error if the request fails.
 */
export const getLocalDataDownloadAlertByCamera = (data) => {
  return new Promise((resolve, reject) => {
    // Retrieve token and profile from local storage
    var token = localStorage.getItem("token");
    const profile = JSON.parse(localStorage.getItem("profile"));

    // Construct the headers for the request
    let headers = {
      Authorization: `Token ${token}`,
    };

    // Make a GET request to the alerts data download by camera API endpoint using axios
    axios
      .get(
        AlertApiSpec.getAlertsDataDownloadByCamera(
          data.id,
          data.alert_type,
          data.date,
          data.priority,
          data.alertOrEvent
        ),
        {
          headers,
        }
      )
      .then((response) => {
        // If the request is successful, display a success notification
        notifiToasterSuccess("Alert Report Downloaded Successfully");
        // Resolve the promise with the response
        resolve(response);
      })
      .catch((error) => {
        // If the request fails, display a warning notification
        notifiToasterWarn("Failed To Download Report");
        // Reject the promise with the error
        reject(error);
      });
  });
};
export const generateEnjayTicket = (id) => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("token");

    const headers = {
      Authorization: `Token ${token}`,
    };

    axios
      .get(AlertApiSpec.generateEnjayTicket(id), { headers })
      .then((response) => {
        if (response.status === 301) {
          notifiToasterInfo("Ticket already generated");
        } else if (response.status === 200) {
          notifiToasterSuccess("Ticket generated successfully");
        } else {
          notifiToasterWarn(`Unexpected status code`);
        }

        resolve(response);
      })
      .catch((error) => {
        // Check for a response object (meaning the server responded)
        if (error.response) {
          const { status, data } = error.response;

          if (status === 400 && data && data.message) {
            notifiToasterWarn(data.message);
          } else {
            notifiToasterWarn(`Unable to generate ticket`);
          }
        } else {
          notifiToasterWarn("Network error: Unable to generate ticket");
        }

        // Reject the promise with the error object
        reject(error);
      });
  });
};
