import { React, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import moment from "moment";
import { styled } from "@mui/material/styles";
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 15,
  },
}));

const DashAreaChart = (propTypes) => {
  const [tickAmount, settickAmount] = useState(0);
  const [series, setseries] = useState([]);
  const [selectedMode, setselectedMode] = useState("hourly");
  const [dataLabelsEnabled, setDataLabelsEnabled] = useState(false);
  const [dataPointTooltipText, setDataPointTooltipText] =
    useState("Show data points");
  useEffect(() => {
    if (
      propTypes.data.length > 0 &&
      Object.prototype.hasOwnProperty.call(propTypes.data[0], "data")
    ) {
      assignData(propTypes.data);
    }
  }, [propTypes.data]);

  const assignData = (val) => {
    settickAmount(val[0].data.length);
    let seriesTemp = [];
    for (let i = 0; i < val.length; i++) {
      seriesTemp.push({
        name: val[i].name,
        data: val[i].data,
      });
      if (tickAmount < val[i].data.length) {
        settickAmount(val[i].data.length);
      }
    }
    setseries(seriesTemp);
  };

  function xaxisForChart() {
    if (propTypes.type === "week") {
      return {
        type: "date",
        labels: {
          formatter: (val) => {
            if (selectedMode === "hourly") {
              return moment(new Date(val)).format("ddd");
            }
            return moment(new Date(val)).format("DD-MM-YY");
          },
          style: {
            colors: "var(--card-text-color)", //change text color to var(--card-text-color)
          },
        },
        title: {
          text: dataLabelsEnabled ? "Day" : "",
          style: {
            fontSize: "16px", // Set the font size
            fontFamily: "Roboto", // Set the font family
            fontWeight: "bold", // Set the font weight
            color: "var(--card-text-color)", // Set the font color
          },
        },
        tooltip: {
          enabled: false,
        },
      };
    } else {
      return {
        type: "datetime",
        tickAmount: undefined,
        labels: {
          formatter: (val) => {
            if (propTypes.selectedModeBypage === "dateRange") {
              return moment(new Date(val)).format("DD-MM-YY");
            } else if (selectedMode === "hourly") {
              return moment(new Date(val)).format("h A");
            } else return moment(new Date(val)).format("DD-MM-YY");
          },
          style: {
            colors: "var(--card-text-color)", //change text color to var(--card-text-color)
          },
        },
        title: {
          text: dataLabelsEnabled
            ? propTypes.type === "dateRange"
              ? "Date"
              : "Time"
            : "",
          style: {
            fontSize: "16px", // Set the font size
            fontFamily: "Roboto", // Set the font family
            fontWeight: "bold", // Set the font weight
            color: "var(--card-text-color)", // Set the font color
          },
        },
        tooltip: {
          enabled: false,
        },
      };
    }
  }

  function toolTipObj() {
    if (propTypes.type === "week") {
      return {
        shared: true,
        intersect: false,
        followCursor: true,
        x: {
          formatter: (val, { seriesIndex, dataPointIndex, w }) => {
            return moment(
              w.config.series[seriesIndex].data[dataPointIndex].x
            ).format("dddd, MMMM D YYYY");
          },
        },
        y: {
          formatter: (value, { series, seriesIndex }) => {
            const total = series[seriesIndex].reduce(
              (acc, cur) => acc + cur,
              0
            );
            // return `${value} (${((value / total) * 100).toFixed(2)}%)`;
            var percent = ((value / total) * 100).toFixed(2);
            if (isNaN(percent)) {
              percent = (0).toFixed(2);
            }
            return `${value} (${percent}%)`;
          },
        },
      };
    } else {
      return {
        shared: true,
        intersect: false,
        followCursor: true,

        x: {
          formatter: (val, { seriesIndex, dataPointIndex, w }) => {
            if (propTypes.selectedModeBypage === "dateRange") {
              return moment(
                w.config.series[seriesIndex].data[dataPointIndex].x
              ).format("dddd, MMMM D YYYY");
            } else {
              return moment(val).format("MMMM D YYYY, h:mm A");
            }
          },
        },
        y: {
          formatter: (value, { series, seriesIndex }) => {
            const total = series[seriesIndex].reduce(
              (acc, cur) => acc + cur,
              0
            );
            var percent = ((value / total) * 100).toFixed(2);
            if (isNaN(percent)) {
              percent = (0).toFixed(2);
            }
            return `${value} (${percent}%)`;
          },
        },
      };
    }
  }
  const dataPointsToggle = (ev) => {
    const check = ev.currentTarget.checked;
    setDataLabelsEnabled(check);
    setDataPointTooltipText(check ? "Hide data points" : "Show data points");
  };

  const options = {
    chart: {
      height: 350,
      type: "area",
      stacked: false,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    dataLabels: {
      enabled: dataLabelsEnabled,
    },
    stroke: {
      curve: "straight",
    },
    tooltip: toolTipObj(),
    xaxis: xaxisForChart(),
    yaxis: {
      labels: {
        style: {
          colors: "var(--card-text-color)", //change text color to var(--card-text-color)
        },
      },
      title: {
        text: dataLabelsEnabled ? propTypes.labelToShowY : "",
        style: {
          fontSize: "16px", // Set the font size
          fontFamily: "Roboto", // Set the font family
          fontWeight: "bold", // Set the font weight
          color: "var(--card-text-color)", // Set the font color
        },
      },
    },
    legend: {
      position: "top",
      labels: {
        colors: "var(--card-text-color)",
      },
    },
    plotOptions: {
      area: {
        horizontal: false,
        dataLabels: {
          position: "top",
        },
      },
    },
    colors: ["#405DF9", "#ED589D", "#3AACFF", "#ebc334", "#9C27B0", "#00BCD4"],
  };

  return (
    <div className={propTypes.classNameActive ? "chart-container" : null}>
      <div className={propTypes.classNameActive ? "chart-div" : null}>
        <div className="chart-div">
          <p
            className="chart-title"
            style={{ textAlign: "left", marginLeft: "20px" }}
          >
            {propTypes.title}
          </p>{" "}
          <div
            className="chart-title"
            style={{ textAlign: "right", marginLeft: "20px" }}
          >
            <span className="d-block text-dark font-weight-bolder">
              <label className="checkbox checkbox-lg checkbox-single flex-shrink-0 m-0 mx-4 checkbox-light-secondary">
                <LightTooltip placement="top" title={dataPointTooltipText}>
                  <input
                    className="chart-checkbox"
                    type="checkbox"
                    onChange={dataPointsToggle}
                  />
                </LightTooltip>

                <span className="checkbox-icon"></span>
              </label>
            </span>
          </div>
          <div style={{ overflow: "hidden" }}>
            <Chart options={options} series={series} type="area" height={400} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashAreaChart;
