import React, { useState, useEffect } from "react";
import {
  Button,
  Typography,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Autocomplete,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  Alert,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LanguageIcon from "@mui/icons-material/Language";
import VmsLiveviewActions from "../../../redux/Action/VmsActions";
import { useSelector, useDispatch } from "react-redux";
import RecordingScheduleModal from "./AddOrEditScheduleComponets";
import { useParams } from "react-router-dom";
import { changeTopbarTitle } from "../../../redux/Action/ChangeTopbarTitle";
import SystemParametersActions from "../../../redux/Action/SystemParameterActions";
import { resolutions, days, default_empty_schedule } from "../utils/data";
import CopyCameraSettingToOtherCameras from "./CopyCameraSettingToOtherCameras";
const timeSlots = Array.from({ length: 24 }, (_, i) => i);

const initialCameraState = {
  camera_name: "",
  channel_number: "",
  connected_to: null,
  main_rtsp_url: "",
  sub_rtsp_url: "",
  camera_ip: "",
  camera_http_port: "",
};

/**
 * Utility function to capitalize the first letter of a given word.
 *
 * @param {string} word - The word to capitalize.
 * @returns {string} The word with the first letter capitalized.
 */
function capitalizeFirstLetter(word) {
  if (!word) return "";
  return word.charAt(0).toUpperCase() + word.slice(1);
}

function getResolutionByPixels(pixels) {
  return resolutions.find((item) => item.value === pixels);
}

const CameraAddEditPage = () => {
  const navigate = useNavigate();
  const { cam_id } = useParams();
  const dispatch = useDispatch();
  const [access_permission] = useState(
    JSON.parse(localStorage.getItem("agx_permission")).Liveview ?? []
  );
  const cameraList = useSelector((state) => state.systemParameters.cameraList);
  const nvrList = useSelector((state) => state.systemParameters.nvrList);
  const reduxIntervals = useSelector((state) => state.vms.recordingSchedule);
  const [camera, setCamera] = useState(initialCameraState);
  const [errors, setErrors] = useState({});
  const [isLiveViewEnable, setIsLiveViewEnable] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const [isEditScheduleOpen, setIsEditScheduleOpen] = useState(false);
  const [intervals, setIntervals] = useState({});
  const [enableRecording, setEnableRecording] = useState("no");
  const [streamToRecord, setStreamToRecord] = useState("sub");
  const [selectedResolution, setSelectedResolution] = useState(resolutions[6]);

  useEffect(() => {
    if (cam_id == -1) {
      setIntervals(default_empty_schedule);
    }
    return () => {
      dispatch({
        type: "UPDATE_DAY_WISE_RECORDING_SCHEDULE",
        payload: default_empty_schedule,
      });
    };
  }, []);

  useEffect(() => {
    setIntervals(reduxIntervals);
  }, [reduxIntervals]);

  // This useEffect is responsible for fetching the camera details for editing the camera if cam_id is not -1,
  // else it will update the top bar.
  useEffect(() => {
    if (cam_id != -1) {
      dispatch(changeTopbarTitle("Camera / Edit"));
      const camera = cameraList.data.find((item) => item.id == Number(cam_id));
      dispatch(VmsLiveviewActions.fetchRecordingScheduleCameraWise(cam_id));
      setCamera(camera);
      if (camera && camera.recording_resolution) {
        setSelectedResolution(
          getResolutionByPixels(camera.recording_resolution)
        );
        setEnableRecording(camera.is_recording_cloud ? "yes" : "no");
        setStreamToRecord(
          camera.stream_to_record === "sub_stream" ? "sub" : "main"
        );
      } else {
        setSelectedResolution(resolutions[6]);
        setEnableRecording("no");
        setStreamToRecord("sub");
      }
    } else {
      dispatch(changeTopbarTitle("Camera / Add"));
    }
  }, [cam_id, cameraList.count]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCamera({ ...camera, [name]: value });

    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
  };

  const handleAutocompleteChange = (event, newValue) => {
    setCamera({ ...camera, connected_to: newValue ? newValue.value : null });

    if (errors.connected_to) {
      setErrors({ ...errors, connected_to: null });
    }
  };

  const validate = () => {
    let tempErrors = {};
    if (!camera.camera_name) {
      tempErrors.camera_name = "Camera name is required";
    }
    if (!camera.channel_number) {
      tempErrors.channel_number = "Channel number is required";
    }
    if (!camera.connected_to) {
      tempErrors.connected_to = "Connected NVR is required";
    }
    if (enableRecording === "yes") {
      const allIntervalsEmpty = Object.values(intervals).every(
        (dayIntervals) => dayIntervals.length === 0
      );
      if (allIntervalsEmpty) {
        tempErrors.intervals =
          "Recording schedule must have at least one interval when recording is enabled.";
      }
    }
    setErrors(tempErrors);

    return Object.keys(tempErrors).length === 0;
  };

  // This function will create a new camera if cam_id == -1, else update the previous camera details
  const handleSaveConfigurations = () => {
    if (!validate()) return;

    const cameraData = {
      recording_resolution: selectedResolution.value,
      stream_to_record: streamToRecord === "sub" ? "sub_stream" : "main_stream",
      is_recording_cloud: enableRecording === "yes",
      camera_name: camera.camera_name,
      connected_to: camera.connected_to,
      main_rtsp_url: camera.main_rtsp_url,
      sub_rtsp_url: camera.sub_rtsp_url,
      channel_number: camera.channel_number,
      camera_ip: camera.camera_ip,
      camera_http_port: camera.camera_http_port,
      recording_schedule: intervals,
    };

    const action =
      cam_id == -1
        ? SystemParametersActions.addCamera(cameraData, 25)
        : VmsLiveviewActions.patchCamera(camera.id, cameraData);

    dispatch(action).then(() => {
      if (cam_id == -1) {
        navigate("/Customization/camera");
      } else {
        dispatch(
          VmsLiveviewActions.fetchRecordingScheduleCameraWise(camera.id)
        );
      }
    });
  };

  //this function will generate the heatmap of time selected for recording and not recording based on schedule saved
  const renderTimeSlots = (day) => {
    const dayIntervals = intervals[day] || [];

    return timeSlots.map((slot) => {
      const slotStart = `${slot.toString().padStart(2, "0")}:00`;
      const slotEnd = `${(slot + 1).toString().padStart(2, "0")}:00`;
      let backgroundColor = "#C42E2F"; // Default to 'Not Recording' color

      dayIntervals.forEach((interval) => {
        const { start_time, end_time, recording } = interval;
        if (
          (slotStart >= start_time && slotStart < end_time) ||
          (slotEnd > start_time && slotEnd <= end_time) ||
          (start_time <= slotStart && end_time >= slotEnd)
        ) {
          backgroundColor = recording ? "#0BC80B" : "#C42E2F";
        }
      });

      return (
        <Box key={slot} sx={{ flexGrow: 1, backgroundColor, height: "20px" }}>
          <div style={{ borderRight: "1px solid #ddd" }} />
        </Box>
      );
    });
  };

  const handleNavigateToCameraPage = () => {
    if (camera.camera_ip && camera.camera_http_port) {
      window.open(
        `http://${camera.camera_ip}:${camera.camera_http_port}`,
        "_blank"
      );
    } else {
      alert("Please enter camera IP and port to go to camera page");
    }
  };

  return (
    camera && (
      <Box sx={{ backgroundColor: "var(--side-bar-bg-color)", px: 2 }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          pt={2}
          pb={2}
        >
          <Button
            component={Link}
            to="/Customization/camera"
            color="primary"
            variant="outlined"
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
          <Button
            color="primary"
            variant="outlined"
            startIcon={<LanguageIcon />}
            onClick={handleNavigateToCameraPage}
          >
            To Camera Page
          </Button>
        </Box>
        <Box mb={2}>
          <TextField
            margin="dense"
            label="Camera Name"
            name="camera_name"
            value={camera.camera_name}
            onChange={handleChange}
            fullWidth
            error={Boolean(errors.camera_name)}
            helperText={errors.camera_name}
          />
        </Box>
        <Box mb={2}>
          <TextField
            margin="dense"
            label="Channel Number"
            name="channel_number"
            type="number"
            value={camera.channel_number}
            onChange={handleChange}
            fullWidth
            error={Boolean(errors.channel_number)}
            helperText={errors.channel_number}
          />
        </Box>
        <Box mb={2}>
          <Autocomplete
            options={nvrList ? nvrList.slice(1) : []}
            getOptionLabel={(option) => option.label}
            onChange={handleAutocompleteChange}
            value={
              nvrList
                ? nvrList.find((item) => item.value === camera.connected_to) ||
                  null
                : null
            }
            renderInput={(params) => (
              <TextField
                {...params}
                margin="dense"
                label="Connected NVR"
                fullWidth
                error={Boolean(errors.connected_to)}
                helperText={errors.connected_to}
              />
            )}
          />
        </Box>
        {access_permission.includes("enable_disable_liveview") && (
          <FormControl component="fieldset">
            <FormLabel component="legend">Enable Live View</FormLabel>
            <RadioGroup
              row
              aria-label="enable-live-view"
              name="enable-live-view"
              value={isLiveViewEnable}
              onChange={(e) => setIsLiveViewEnable(e.target.value)}
            >
              <FormControlLabel value="true" control={<Radio />} label="Yes" />
              <FormControlLabel value="false" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        )}
        <Box mb={2} mt={2}>
          <TextField
            margin="dense"
            label="Main RTSP URL"
            name="main_rtsp_url"
            value={camera.main_rtsp_url}
            onChange={handleChange}
            fullWidth
          />
        </Box>
        <Box mb={2}>
          <TextField
            margin="dense"
            label="Sub RTSP URL"
            name="sub_rtsp_url"
            value={camera.sub_rtsp_url}
            onChange={handleChange}
            fullWidth
          />
        </Box>
        <Box mb={2}>
          <TextField
            margin="dense"
            label="Camera IP"
            name="camera_ip"
            value={camera.camera_ip}
            onChange={handleChange}
            fullWidth
            helperText={"Enter the Public IP of current camera"}
          />
        </Box>
        <Box mb={2}>
          <TextField
            margin="dense"
            label="Camera HTTP Port"
            name="camera_http_port"
            type="number"
            value={camera.camera_http_port}
            onChange={handleChange}
            fullWidth
            helperText={"Enter the Public HTTP port of current camera"}
          />
        </Box>

        {access_permission.includes("configure_playback_settings") && (
          <FormControl component="fieldset">
            <FormLabel component="legend">Enable Recording</FormLabel>
            <RadioGroup
              row
              aria-label="enable-recording"
              name="enable-recording"
              value={enableRecording}
              onChange={(e) => setEnableRecording(e.target.value)}
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        )}
        {enableRecording === "yes" &&
          access_permission.includes("configure_playback_settings") && (
            <>
              <Autocomplete
                options={resolutions}
                getOptionLabel={(option) =>
                  `${option.label} ( ${option.value} )`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Resolution"
                    helperText="Choose the resolution your camera supports. Higher resolutions require more bandwidth and storage."
                  />
                )}
                value={selectedResolution}
                onChange={(e, v) => setSelectedResolution(v)}
                sx={{ mt: 2 }}
              />

              <FormControl fullWidth sx={{ marginTop: "20px" }}>
                <FormLabel component="legend">Stream to Record</FormLabel>
                <Select
                  value={streamToRecord}
                  onChange={(e) => setStreamToRecord(e.target.value)}
                >
                  <MenuItem value="main">Main Stream</MenuItem>
                  <MenuItem value="sub">Sub Stream</MenuItem>
                </Select>
              </FormControl>
              {errors.intervals && (
                <Alert
                  severity="error"
                  sx={{ marginBottom: "20px", marginTop: 5 }}
                >
                  {errors.intervals}
                </Alert>
              )}
              <Grid
                item
                xs={12}
                md={12}
                mt={3}
                display="flex"
                justifyContent="center"
              >
                <Typography variant="body2">
                  <Box display="flex" alignItems="center" mb={1} px={1}>
                    <Box width="20px" height="20px" bgcolor="#0BC80B" mr={1} />
                    Recording
                  </Box>
                </Typography>
                <Typography variant="body2">
                  <Box display="flex" alignItems="center" mb={1}>
                    <Box width="20px" height="20px" bgcolor="#C42E2F" mr={1} />
                    Not Recording
                  </Box>
                </Typography>
              </Grid>

              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={12}>
                  <Table>
                    <TableHead
                      sx={{ backgroundColor: "transparent", boxShadow: 0 }}
                    >
                      <TableRow>
                        <TableCell />
                        <TableCell>
                          <Box sx={{ display: "flex" }}>
                            {timeSlots.map((hour) => (
                              <Box
                                key={hour}
                                sx={{
                                  flexGrow: 1,
                                  textAlign: "center",
                                  height: "20px",
                                  color: "black",
                                  position: "relative",
                                }}
                              >
                                {hour + 1}
                              </Box>
                            ))}
                          </Box>
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {days.map((day) => (
                        <TableRow key={day}>
                          <TableCell>
                            <Typography sx={{ color: "black" }}>
                              {day.toLocaleUpperCase()}
                            </Typography>
                          </TableCell>
                          <TableCell width="100%">
                            <Box sx={{ display: "flex" }}>
                              {renderTimeSlots(day)}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              onClick={() => {
                                setIsEditScheduleOpen(true);
                                setSelectedDay(capitalizeFirstLetter(day));
                              }}
                            >
                              Settings
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </>
          )}
        <div className="col-12 d-flex justify-content-between mt-4 pb-2">
          {cam_id != -1 &&
            access_permission.includes("configure_playback_settings") && (
              <CopyCameraSettingToOtherCameras
                cameraList={cameraList.data}
                currentCameraId={cam_id}
              />
            )}
          <Button onClick={handleSaveConfigurations} variant="outlined">
            Save Details
          </Button>
        </div>
        {isEditScheduleOpen && (
          <RecordingScheduleModal
            open={isEditScheduleOpen}
            data={intervals}
            cam_id={cam_id}
            selectedDay={selectedDay}
            handleClose={() => setIsEditScheduleOpen(false)}
          />
        )}
      </Box>
    )
  );
};

export default CameraAddEditPage;
