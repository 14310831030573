import React from "react";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import PropTypes from "prop-types";
import { useState } from "react";

function findInvalidEmails(emailArray) {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailArray.filter((email) => !emailRegex.test(email));
}

const EmailFreeSolo = ({
  value,
  onChangeFunc,
  label,
  placeholder,
  helperText,
}) => {
  const [error, setError] = useState("");
  function handleChangeLocal(e, val) {
    let wrongEmails = findInvalidEmails(val);
    if (wrongEmails.length > 0) {
      let errorWrongEmails = `${wrongEmails.map(
        (item) => item
      )} is invalid email`;
      setError(errorWrongEmails);
    } else {
      setError("");
      onChangeFunc(val);
    }
  }
  return (
    <div>
      <div className="message-select-field">
        <Autocomplete
          multiple
          disableClearable
          disableCloseOnSelect={true}
          id="tags-filled-facility"
          options={[]}
          value={value}
          onChange={handleChangeLocal}
          freeSolo
          renderTags={(value, getTagProps) => (
            <div
              style={{
                maxHeight: "200px",
                overflowY: "auto",
                overflowX: "auto",
              }}
            >
              {value.map((option, index) => (
                <Chip
                  key={index}
                  variant="standard"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))}
            </div>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!(error ? error : helperText ? helperText : "")}
              helperText={
                error
                  ? error
                  : helperText
                  ? helperText
                  : "Type email and press enter to add email"
              }
              variant="outlined"
              label={label}
              placeholder={placeholder}
            />
          )}
        />
      </div>
    </div>
  );
};

EmailFreeSolo.propTypes = {
  value: PropTypes.array.isRequired,
  onChangeFunc: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  helperText: PropTypes.string.isRequired,
};

export default EmailFreeSolo;
