import Button from "@mui/material/Button";
import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import { notifiToasterWarn } from "./../../../components/common/UseToast";
import OperationModeAction from "../../../redux/Action/OperationModeAction";
import PropTypes from "prop-types";
import Account from "../../../redux/Action/CommonAction";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
const inputselect = {
  boxShadow: "none",
  borderRadius: "4px",
  marginLeft: "10px",
  height: "50px",
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
  MenuProps: {
    PaperProps: {
      style: {
        backgroundColor: "var(--top-bar-bg-color)",
        maxHeight: 150,
        width: 50,
        overflowX: "auto",
      },
    },
  },
};

function TransferEventModal({ item, show, incrementcount, handleClose }) {
  TransferEventModal.propTypes = {
    incrementcount: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    item: PropTypes.object.isRequired,
  };
  const [newEventType, setNewEventType] = useState(item.event_type);
  const [selectedFacility, setSelectedFacility] = useState(item.facility[1]);
  // Ensure 'item' is the correct dependency
  const [destination, setDestination] = useState("event");
  const [deleteSource, setDeleteSource] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    new Date(
      new Date(item.event_recieved_at).toLocaleString("en-US", {
        timeZone: "GMT",
      })
    )
  );

  const isOptionEqualToValue = (option, value) => {
    return option.value === value?.value;
  };
  function extractTime(timestamp) {
    // Split the timestamp into date and time parts
    const [datePart, timePart] = timestamp.split("T");

    // Split the time part into hours, minutes, and seconds
    const [hours, minutes] = timePart.split(":");

    // Return the time in hh:mm format
    return `${hours}:${minutes}`;
  }
  const [time, setTime] = useState(extractTime(item.event_recieved_at));
  function handleDeleteSource(e) {
    setDeleteSource(e.target.value);
  }
  function handleDestination(e) {
    setDestination(e.target.value);
  }
  function modifyDate(dateString, timeString) {
    // Create a new Date object using the date string
    const dateObj = new Date(dateString);

    // Extract the individual time components
    const [hours, minutes] = timeString.split(":").map(Number); // convert to numbers

    // Apply timezone offset
    const offset = dateObj.getTimezoneOffset();
    const hourOffset = Math.floor(offset / 60);
    const minuteOffset = offset % 60;
    const localHours = hours - hourOffset - 1;
    const localMinutes = minutes - minuteOffset;

    // Set the extracted time components to the date object
    dateObj.setHours(localHours, localMinutes);

    // Convert the date object to ISO 8601 format
    const isoString = dateObj.toISOString();

    return isoString;
  }

  const profile_id = localStorage.getItem("profile_id");
  const dispatch = useDispatch();
  const [selectedCamera, setSelectedCamera] = useState(item.camera[1]);
  const [account, setAccount] = useState(
    JSON.parse(localStorage.getItem("profile")).account.account_id
  );
  const handleAccountChange = (event, value) => {
    if (value) {
      setAccount(value.value);
      OperationModeAction.getFacilitiesByAccountId(value.value)(dispatch);
    }
  };
  const [event_message, setEventMessage] = useState(item.event_message);
  const [itemRemark, setItemRemark] = useState(item.remark);
  const state = useSelector((state) => state);
  const FacilityOptions = state.modalData.facilityByAccountList;

  const CameraOptions = state.modalData.cameraList;
  const AccountOptions = state.modalData.accountList;
  const handleSelectEventType = (e) => {
    setNewEventType(e.target.value);
  };
  const handleStoreChange = (e, value) => {
    if (value) {
      setSelectedFacility(value.value);
      OperationModeAction.getCamerasByStore(value.value)(dispatch);
    }
  };
  const handleCameraChange = (e, value) => {
    if (value) {
      setSelectedCamera(value.value);
    }
  };

  const handleEventMessageChange = (e) => {
    setEventMessage(e.target.value);
  };

  // Example usage
  function isCameraPresent() {
    let flag = state.modalData.cameraList.some(
      (item) => item.value === selectedCamera
    );

    return flag;
  }
  function isFacilityPresent() {
    let flag = state.modalData.facilityByAccountList.some(
      (item) => item.value === selectedFacility
    );

    return flag;
  }
  function isAccountPresent() {
    let flag = state.modalData.accountList.some(
      (item) => item.value === account
    );
    return flag;
  }
  const handleSave = (e) => {
    const changedDate = modifyDate(selectedDate, time);
    if (!event_message) {
      notifiToasterWarn("Message is required");
      setEventMessage(item.event_message);
      return;
    }

    if (isCameraPresent() == false) {
      notifiToasterWarn("Camera and facility is not matched");
      return;
    }
    if (isFacilityPresent() == false) {
      notifiToasterWarn("Camera and facility is not matched");
      return;
    }
    if (deleteSource) {
      dispatch(OperationModeAction.deleteEvents(item.id));
      const eventData = {
        event_image_url: item.event_image,
        event_recieved_at: changedDate,
        camera: selectedCamera,
        facility: selectedFacility,
        event_message: event_message,
        event_type: newEventType,
        remark: itemRemark,
        event_image_url2: item.event_image2,
        source: "event",
      };
      if (destination == "alert") {
        eventData.destination = "alert";
        OperationModeAction.transferAlertIntoEvent(eventData)(dispatch);
      } else {
        OperationModeAction.transferEvent(eventData)(dispatch);
      }
    } else {
      const eventData = {
        event_image_url: item.event_image,
        event_recieved_at: changedDate,
        camera: selectedCamera,
        facility: selectedFacility,
        event_message: event_message,
        event_type: newEventType,
        remark: itemRemark,
        event_image_url2: item.event_image2,
        source: "event",
      };
      if (destination == "alert") {
        eventData.destination = "alert";
        OperationModeAction.transferAlertIntoEvent(eventData)(dispatch);
      } else {
        OperationModeAction.transferEvent(eventData)(dispatch);
      }
    }
    handleClose();
  };
  const handleRemarkChange = (e) => {
    setItemRemark(e.target.value);
  };
  const handleDateChange = (date) => {
    setSelectedDate(date); // update local state
  };
  const handleTimeChange = (e) => {
    setTime(e.target.value);
  };
  useEffect(() => {
    if (show) {
      OperationModeAction.getCamerasByStore(selectedFacility)(dispatch);
      OperationModeAction.getFacilitiesByAccountId(account)(dispatch);
    }
    Account.toglleSidebar(true)(dispatch);
    return () => {
      Account.toglleSidebar(false)(dispatch);
    };
  }, [show]);
  return (
    <Dialog
      open={show}
      onClose={handleClose}
      size="lg"
      aria-labelledby="customized-dialog-title"
      className="editTransferModal"
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "md",
        },
      }}
    >
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        id="customized-dialog-title"
        className="header-background modal-title-color"
        style={{
          backgroundColor: "#4D3EF8",
          color: "white",
        }}
      >
        Transfer Event
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        style={{
          height: "60vh",
          overflowY: "scroll",
          backgroundColor: " var( --side-bar-bg-color)",
        }}
      >
        <div className="profile-detail-form">
          <div className="d-lg-flex">
            <div className="col-12 col-lg-6">
              <div className="form-group-edit-card ">
                <div className="row">
                  <div className="row form-content-edit-data">
                    <label className="col form-label" htmlFor="email">
                      Date:
                    </label>
                    <div style={{ width: "190px" }}>
                      <DatePicker
                        className="form-control col"
                        // customInput={<input className="custom-input-date-text" />}
                        selected={selectedDate}
                        onChange={handleDateChange}
                        dateFormat="dd/MM/yyyy"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div
                    style={{ padding: "10px" }}
                    className="form-content-edit-data row"
                  >
                    <label className="form-label col-4 col-lg" htmlFor="email">
                      Time:
                    </label>
                    <input
                      type="time"
                      className="form-control col"
                      id="event_time"
                      name="event_time"
                      placeholder="Event Time"
                      value={time}
                      onChange={handleTimeChange}
                      style={{
                        backgroundColor: "var(--sidebar-bg-color)",
                        color: "var(--card-text-color)",
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* Email Address onSubmit={handleSubmit*/}
              <div className="form-group">
                <div className="row">
                  <div
                    style={{ padding: "10px" }}
                    className="row form-content-edit-data"
                  >
                    <label className="form-label col-4 col-lg" htmlFor="email">
                      Remark:
                    </label>
                    <input
                      type="text"
                      className="form-control col"
                      id="event_message"
                      name="event_message"
                      value={itemRemark}
                      onChange={handleRemarkChange}
                      placeholder="Enter Remark" // value=
                      aria-describedby="emailHelp" // onChange=
                      style={{
                        backgroundColor: "var(--sidebar-bg-color)",
                        color: "var(--card-text-color)",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row ">
                  <div
                    style={{ padding: "10px" }}
                    className="row form-content-edit-data"
                  >
                    <label
                      className="form-label col-4 col-lg mt-3"
                      htmlFor="location"
                    >
                      Delete Source:
                    </label>
                    <Select
                      onChange={handleDeleteSource}
                      sx={{
                        color: "var(--card-text-color)",
                        ...inputselect,
                      }}
                      value={deleteSource}
                      className="select-input-field-card form-control col"
                      MenuProps={inputselect.MenuProps}
                      style={{ backgroundColor: "var(--sidebar-color)" }}
                    >
                      <MenuItem
                        value={true}
                        sx={{
                          paddingTop: "2px",
                          paddingBottom: "2px",
                          color: "var(--card-text-color)",
                        }}
                      >
                        Yes
                      </MenuItem>
                      <MenuItem
                        value={false}
                        sx={{
                          paddingTop: "2px",
                          paddingBottom: "2px",
                          color: "var(--card-text-color)",
                        }}
                      >
                        No
                      </MenuItem>
                    </Select>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row ">
                  <div
                    style={{ padding: "10px" }}
                    className="row form-content-edit-data"
                  >
                    <label
                      className="form-label col-4 col-lg  mt-3"
                      htmlFor="location"
                    >
                      Transfer Into Event:
                    </label>
                    <Select
                      onChange={handleDestination}
                      sx={{
                        color: "var(--card-text-color)",
                        ...inputselect,
                      }}
                      value={destination}
                      className="select-input-field-card form-control col"
                      MenuProps={inputselect.MenuProps}
                      style={{ backgroundColor: "var(--sidebar-color)" }}
                    >
                      <MenuItem
                        value={"alert"}
                        sx={{
                          paddingTop: "2px",
                          paddingBottom: "2px",
                          color: "var(--card-text-color)",
                        }}
                      >
                        Alert
                      </MenuItem>
                      <MenuItem
                        value={"event"}
                        sx={{
                          paddingTop: "2px",
                          paddingBottom: "2px",
                          color: "var(--card-text-color)",
                        }}
                      >
                        Event
                      </MenuItem>
                    </Select>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 ml-lg-auto">
              <img
                src={item.event_image}
                style={{ height: "100%", width: "100%" }}
              />
            </div>
          </div>

          <div className="form-group">
            <div className="row form-content-edit-data">
              <label
                className="form-label col-4 col-lg-3 mt-2"
                htmlFor="location"
              >
                Event Type:
              </label>
              <Select
                onChange={handleSelectEventType}
                sx={{
                  color: "var(--card-text-color)",
                  ...inputselect,
                }}
                value={newEventType}
                className="select-input-field-card form-control col"
                MenuProps={inputselect.MenuProps}
                style={{
                  backgroundColor: " var( --side-bar-bg-color)",
                  marginRight: "20px",
                }}
              >
                {state.AlertTableStates.AlertTypes.map((item, index) => {
                  return (
                    item.alert_or_event.toLowerCase() == destination && (
                      <MenuItem
                        key={index}
                        value={item.notification_type}
                        sx={{
                          paddingTop: "2px",
                          paddingBottom: "2px",
                          color: "var(--card-text-color)",
                          maxHeight: "40px",
                        }}
                        className={
                          item.notification_type === "compliance_issue" ||
                          item.notification_type === "security_thread" ||
                          item.notification_type === "facility_status" ||
                          item.notification_type === "loading_status" ||
                          item.notification_type === "vehicle_alert"
                            ? "multilebel-dropdown-item"
                            : ""
                        }
                        disabled={
                          item.notification_type === "compliance_issue" ||
                          item.notification_type === "security_thread" ||
                          item.notification_type === "facility_status" ||
                          item.notification_type === "loading_status" ||
                          item.notification_type === "vehicle_alert"
                        }
                      >
                        {item.name}
                      </MenuItem>
                    )
                  );
                })}
              </Select>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div
                style={{ padding: "10px", margin: "0px" }}
                className="row form-content-edit-data"
              >
                <label className="form-label col-4 col-lg-3" htmlFor="email">
                  Message:
                </label>
                <textarea
                  type="text"
                  className="form-control col"
                  id="event_message"
                  name="event_message"
                  value={event_message}
                  onChange={handleEventMessageChange}
                  placeholder="Enter Event Message"
                  //   value={formData.email_address[0]}
                  aria-describedby="emailHelp"
                  //   onChange={(event) => handleEmailChange(event, 0)}
                  style={{
                    backgroundColor: "var(--sidebar-bg-color)",
                    color: "var(--card-text-color)",
                    marginLeft: "8px",
                  }}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="form-group col-12  mb-3">
              <div className="row form-content-edit-data">
                <label className="form-label col-3 mt-3" htmlFor="location">
                  To Account:
                </label>
                <Autocomplete
                  className="col"
                  disablePortal
                  id="combo-box-demo"
                  options={AccountOptions}
                  sx={{ width: 300 }}
                  onChange={handleAccountChange}
                  isOptionEqualToValue={isOptionEqualToValue}
                  getOptionLabel={(option) => option.label}
                  // value={isFacilityPresent() ? selectedFacility : ""}
                  renderInput={(params) => (
                    <TextField {...params} label="To Account" />
                  )}
                />
              </div>
            </div>
            <div className="form-group col-12 mb-3">
              <div className="row form-content-edit-data">
                <label className="form-label col-3 mt-3" htmlFor="location">
                  To Facility:
                </label>
                {selectedFacility && (
                  <Autocomplete
                    className="col"
                    disablePortal
                    id="combo-box-demo"
                    options={FacilityOptions}
                    sx={{ width: 300 }}
                    isOptionEqualToValue={isOptionEqualToValue}
                    onChange={handleStoreChange}
                    getOptionLabel={(option) => option.label}
                    // value={selectedFacility}
                    renderInput={(params) => (
                      <TextField {...params} label="To Facility" />
                    )}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="row">
            {state.modalData.cameraList.length > 0 && (
              <div className="form-group col-12 mb-3">
                <div className="row form-content-edit-data">
                  <label className="form-label col-3 mt-3" htmlFor="location">
                    To Camera:
                  </label>
                  <Autocomplete
                    className="col"
                    disablePortal
                    id="combo-box-demo"
                    options={CameraOptions}
                    sx={{ width: 300 }}
                    onChange={handleCameraChange}
                    isOptionEqualToValue={isOptionEqualToValue}
                    getOptionLabel={(option) =>
                      option.label + `(${option.value})`
                    }
                    // value={isFacilityPresent() ? selectedFacility : ""}
                    renderInput={(params) => (
                      <TextField {...params} label="To Camera" />
                    )}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </DialogContent>
      <div
        className="col d-flex justify-content-end "
        style={{
          margin: "10px",
          padding: "10px",
          borderTop: "0.4px solid #e6e3dc",
        }}
      >
        <Button variant="contained" onClick={handleSave}>
          Transfer
        </Button>
      </div>
    </Dialog>
  );
}

export default TransferEventModal;
