import React, { useState, useEffect, useCallback, memo } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import AlertTableAction from "../../../redux/Action/AlertTableActions";

const SearchFilter = ({ type }) => {
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();

  // Debounce searchValue so it only updates after user has stopped typing for a delay
  const [debouncedSearchValue, setDebouncedSearchValue] = useState(searchValue);
  const debounceDelay = 500; // 500ms delay

  // Update debounced value after delay when searchValue changes
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchValue(searchValue);
    }, debounceDelay);

    return () => {
      clearTimeout(handler);
    };
  }, [searchValue, debounceDelay]);

  // Dispatch action when debouncedSearchValue changes and meets the conditions
  useEffect(() => {
    if (debouncedSearchValue.length > 3 || debouncedSearchValue.length === 0) {
      if (type === "AlertTable") {
        dispatch(AlertTableAction.updateSearchValue(debouncedSearchValue));
        dispatch(
          AlertTableAction.updateSearchValueLength(debouncedSearchValue.length)
        );
      }
    }
  }, [debouncedSearchValue, type, dispatch]);

  const handleSearchChange = useCallback((event) => {
    setSearchValue(event.target.value);
  }, []);

  return (
    <div>
      <div className="select-input-lebel">Search</div>
      <input
        type="search"
        placeholder="Search..."
        className="search-input-field"
        value={searchValue}
        onChange={handleSearchChange}
      />
    </div>
  );
};

SearchFilter.propTypes = {
  type: PropTypes.string.isRequired,
};

export default memo(SearchFilter);
