import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Player,
  ControlBar,
  PlaybackRateMenuButton,
  ForwardControl,
  ReplayControl,
} from "video-react";
import "video-react/dist/video-react.css";

const VideoPlayerMP4 = ({ timeToSeek, src, getNextVideo }) => {
  const player = useRef(null);
  const [videoSrc, setVideoSrc] = useState(src);

  useEffect(() => {
    const handleCanPlayThrough = () => {
      if (player.current) {
        player.current.seek(timeToSeek);
        player.current.play();
        videoElement.removeEventListener(
          "canplaythrough",
          handleCanPlayThrough
        );
      }
    };

    const handleEnded = () => {
      getNextVideo();
    };

    const videoElement = player.current.video.video;
    if (videoElement) {
      videoElement.addEventListener("canplaythrough", handleCanPlayThrough);
      videoElement.addEventListener("ended", handleEnded);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener(
          "canplaythrough",
          handleCanPlayThrough
        );
        videoElement.removeEventListener("ended", handleEnded);
      }
    };
  }, [videoSrc]);

  // Add this useEffect to handle when timeToSeek changes
  useEffect(() => {
    if (player.current) {
      player.current.seek(timeToSeek);
    }
  }, [timeToSeek]);

  // Add another useEffect to handle when src changes
  useEffect(() => {
    if (player.current) {
      player.current.load(); // load the new video source
      player.current.seek(timeToSeek); // seek to the given time if necessary
      player.current.play(); // start playback
    }
    setVideoSrc(src); // Ensure State update for new src
  }, [src]);

  return (
    <div style={{ width: "70%", height: "100%" }}>
      <Player ref={player} playsInline src={videoSrc}>
        <ControlBar autoHide={false}>
          <ReplayControl seconds={10} order={1.1} />
          <ForwardControl seconds={30} order={1.2} />
          <PlaybackRateMenuButton rates={[0.5, 1, 1.5, 2]} order={7.1} />
        </ControlBar>
      </Player>
    </div>
  );
};

VideoPlayerMP4.propTypes = {
  timeToSeek: PropTypes.number,
  src: PropTypes.string,
  getNextVideo: PropTypes.func,
};

export default VideoPlayerMP4;
