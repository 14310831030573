import React, { useEffect, useState } from "react";
import RouterIcon from "@mui/icons-material/Router";
import VideogameAssetOffIcon from "@mui/icons-material/VideogameAssetOff";
import VideogameAssetIcon from "@mui/icons-material/VideogameAsset";
import mainCss from "../../assets/css/mainCss";
import PropTypes from "prop-types";

const DeviceStatusStatistics = ({ loading, data }) => {
  const [onlineCount, setOnlineCount] = useState(0);
  const [offlineCount, setOfflineCount] = useState(0);

  useEffect(() => {
    const { online, offline } = data.reduce(
      (counts, device) => {
        counts[device.status === "ONLINE" ? "online" : "offline"]++;
        return counts;
      },
      { online: 0, offline: 0 }
    );
    setOnlineCount(online);
    setOfflineCount(offline);
  }, [data]);

  const deviceCountDisplay = loading ? "---" : data.length;
  const onlineCountDisplay = loading ? "---" : onlineCount;
  const offlineCountDisplay = loading ? "---" : offlineCount;

  const renderColumn = (icon, title, count) => (
    <div className="col-12 col-sm-6 col-xl-4">
      <div style={{ display: "flex" }}>
        <div>
          <button
            className="btn btn-sm m-1 shadow-none"
            style={mainCss.bigButtonIcon}
          >
            {icon}
          </button>
        </div>
        <div style={{ marginLeft: "10px", marginTop: "10px" }}>
          <h5>{title}</h5>
          <h5>{count}</h5>
        </div>
      </div>
    </div>
  );

  return (
    <div className="divbelowtopbarsecond">
      <div className="select-input-div card-custom card-stretch gutter-b">
        <div className="card-body-div">
          <div className="row">
            {renderColumn(
              <RouterIcon sx={mainCss.bigIconInRhms} />,
              "Total Devices",
              deviceCountDisplay
            )}
            {renderColumn(
              <VideogameAssetIcon sx={mainCss.bigIconInRhms} />,
              "Online Devices",
              onlineCountDisplay
            )}
            {renderColumn(
              <VideogameAssetOffIcon sx={mainCss.bigIconInRhms} />,
              "Offline Devices",
              offlineCountDisplay
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

DeviceStatusStatistics.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default DeviceStatusStatistics;
