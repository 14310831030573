import React, { useState } from "react";
import { ColorRing } from "react-loader-spinner";
import DownloadIcon from "@mui/icons-material/Download";
import mainCss from "../../assets/css/mainCss";
import { useDispatch, useSelector } from "react-redux";
import {
  notifiToasterSuccess,
  notifiToasterWarn,
} from "../../components/common/UseToast";
import moment from "moment";
import axios from "axios";
import EndPoints from "../../redux/features/EndPoints";

const CustomReportDownload = () => {
  const selectedMode = useSelector((state) => state.anpr.selectedMode.value);
  const endDate = useSelector((state) => state.anpr.dateRange.endDate);
  const startDate = useSelector((state) => state.anpr.dateRange.startDate);
  const alerteventDate = useSelector((state) => state.anpr.selectedDate);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const profile = JSON.parse(localStorage.getItem("profile"));

  const handleReportDownload = async () => {
    setLoading(true);
    await downloadExcelReport();
    setLoading(false);
  };

  const downloadExcelReport = async () => {
    let start_date = selectedMode === "day-wise" ? alerteventDate : startDate;
    let end_date = selectedMode === "day-wise" ? alerteventDate : endDate;

    const formatted_start_Date = moment(start_date).format("YYYY-MM-DD");
    const formatted_end_Date = moment(end_date).format("YYYY-MM-DD");

    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Token ${token}`,
    };

    try {
      const response = await axios.get(
        EndPoints.downloadSmcReport(formatted_start_Date, formatted_end_Date),
        {
          headers,
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.href = url;
      a.download = "ANPR REPORT.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);

      notifiToasterSuccess("Report Sent Successfully !!");
    } catch (error) {
      console.error("There was an error downloading the report:", error);
      notifiToasterWarn("Report Sent Failed");
    }
  };

  return (
    <div style={{ marginLeft: "20px" }}>
      {profile.services_selected.Alert.includes("smc_report") && (
        <button
          className="btn btn-sm m-1 shadow-none mt-4"
          style={mainCss.downloadButton}
          onClick={!loading ? handleReportDownload : null}
          disabled={loading}
        >
          {!loading ? (
            <DownloadIcon sx={mainCss.downloadIcon} />
          ) : (
            <ColorRing
              visible={true}
              height={24}
              width={24}
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
            />
          )}
        </button>
      )}
    </div>
  );
};

export default CustomReportDownload;
