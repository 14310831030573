import React, { useEffect, useState } from "react";
import FaceRecognitionTopMatchTable from "../FaceRecognitionTopMatchTable";
import PropTypes from "prop-types";
import { extractDateTime } from "../utils/FormatDateAndTime";
import FaceRecognitionCircularBar from "./FaceRecognitionCircularBar";
import FaceRecognitionActions from "../../../redux/Action/FaceRegnitionAction";
import { useSelector } from "react-redux";
const PersonRecognized = ({ data }) => {
  let formattedDateTime = extractDateTime(data.created_at);
  const [top5list, setTop5List] = useState([]);
  const [loading, setLoading] = useState(false);
  const selectedFacility = useSelector(
    (state) => state.faceRecognition.selectedFacility
  );
  useEffect(() => {
    if (data.uuid) {
      const fetchListData = async () => {
        setLoading(true);
        let listdata = await FaceRecognitionActions.getTop5MatchedRecognized(
          data.uuid,
          selectedFacility.value
        );
        setLoading(false);
        setTop5List(listdata);
      };

      fetchListData();
    }
  }, [data]);
  return (
    <div className="col-12 my-3">
      <div
        className="col-12 row card-container-face-rec-page"
        style={{
          backgroundColor: "var(--side-bar-bg-color)",
          color: "var(--black-white-darkmode)",
          minHeight: "auto",
          marginLeft: "20px",
          margin: 0,
        }}
      >
        <div className="col-md-6 col-lg-4">
          <h3 style={{ marginTop: "8px", marginBottom: "0" }}>Detection</h3>
          <img
            src={data.image}
            className="img-fluid detection-image-card"
            alt="Detection"
            style={{
              width: "90%",
              height: "35vh",
              // maxHeight: "55vh",
              objectFit: "fill",
            }}
          />
        </div>
        <div className="col-md-6 col-lg-4 ">
          <h3 style={{ marginTop: "8px", marginBottom: "0" }}>Best Match</h3>
          <img
            src={data.known_face_id.image}
            className="img-fluid"
            alt="Best Match"
            style={{
              width: "90%",
              height: "35vh",
              objectFit: "fill",
            }}
          />
        </div>

        <div className="col-12 col-md-12 col-lg-4 mt-4">
          <div className="row">
            <div className="col-12 card-container-face-rec-detail">
              <div>
                <span className="event-details-heading">Name:</span>
                <span className="event-details-content">
                  {data.known_face_id.name}
                </span>
              </div>
              <div>
                <span className="event-details-heading">Age:</span>
                <span className="event-details-content">
                  {data.known_face_id.age}
                </span>
              </div>
              <div>
                <span className="event-details-heading">Gender :</span>
                <span className="event-details-content">
                  {data.known_face_id.gender}
                </span>
              </div>
              <div>
                <span className="event-details-heading">Date:</span>
                <span className="event-details-content">
                  {formattedDateTime.date}
                </span>
              </div>
              <div>
                <span className="event-details-heading">Time:</span>
                <span className="event-details-content">
                  {formattedDateTime.time}
                </span>
              </div>
              <div>
                <span className="event-details-heading">Camera :</span>
                <span className="event-details-content">
                  {data.camera_id ? data.camera_id.camera_name : "N/A"}
                </span>
              </div>
              {data.is_recognized && (
                <div className="d-flex justify-content-start align-items-center">
                  <span className="event-details-heading">
                    Similarity Score:
                  </span>
                  <span className="event-details-content">
                    <FaceRecognitionCircularBar
                      value={
                        data.similarity_score ? data.similarity_score * 100 : 0
                      }
                    />
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="col-12">
        <FaceRecognitionTopMatchTable
          listData={top5list}
          loading={loading}
          usedIn="recognized"
        />
      </div>
    </div>
  );
};
PersonRecognized.propTypes = {
  data: PropTypes.object.isRequired,
};
export default PersonRecognized;
