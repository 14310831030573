import React from "react";
import { useSelector } from "react-redux";
import { DashWeekBarChart } from "../../../components/charts/DashWeekBarChart";
import DashboardFunction from "../DashboardFunctions";
import PropTypes from "prop-types";
const WeeklyAndDayWiseComparison = ({ selectedMode }) => {
  const { services_selected: servicesSelected } = JSON.parse(
    localStorage.getItem("profile") || "{}"
  );
  const { Dashboard: accessPermissions = [] } = JSON.parse(
    localStorage.getItem("agx_permission") || "{}"
  );

  const retailData = useSelector((state) => state.retailData);

  const isPeopleCountEnabled =
    servicesSelected?.Dashboard.includes("people_count");
  const isPasserByEnabled = servicesSelected?.Dashboard.includes("passer_by");
  const canViewPasserByAnalytics = accessPermissions.includes(
    "view_passer_by_analytics"
  );
  const isDayWiseSelected = selectedMode.value === "day-wise";

  return (
    <div>
      {isPeopleCountEnabled && canViewPasserByAnalytics && (
        <div>
          {isDayWiseSelected && (
            <div className="row">
              {isPasserByEnabled ? (
                <>
                  <div className="col-xxl-6">
                    <DashWeekBarChart
                      classNameActive={true}
                      data={DashboardFunction.specificDayGraphData(
                        retailData.weekdayPeopleCount,
                        retailData.weekdayPasserCount
                      )}
                      xlabel="date"
                      title="Day-wise Comparison"
                    />
                  </div>
                  <div className="col-xxl-6">
                    <DashWeekBarChart
                      classNameActive={true}
                      data={DashboardFunction.threeWeekGraphData(
                        retailData.threeWeekPeopleCount,
                        retailData.threeWeekPasserCount
                      )}
                      xlabel="string"
                      title="Weekly Comparison"
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="col-xxl-6">
                    <DashWeekBarChart
                      classNameActive={true}
                      data={DashboardFunction.specificDayGraphDataPeopleCount(
                        retailData.weekdayPeopleCount
                      )}
                      xlabel="date"
                      title="Day-wise Comparison (People Count)"
                    />
                  </div>
                  <div className="col-xxl-6">
                    <DashWeekBarChart
                      classNameActive={true}
                      data={DashboardFunction.threeWeekGraphDataPeopleCount(
                        retailData.threeWeekPeopleCount
                      )}
                      xlabel="date"
                      title="Weekly Comparison (People Count)"
                    />
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
WeeklyAndDayWiseComparison.propTypes = {
  selectedMode: PropTypes.object.isRequired,
};
export default WeeklyAndDayWiseComparison;
