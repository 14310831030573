import React, { useEffect, useState } from "react";
import AlertTableCard from "./AlertTableCard";
import { useSelector, useDispatch } from "react-redux";
import { getTableData } from "../Alert/AlertChartData";
import AlertTableAction from "../../redux/Action/AlertTableActions";
import Account from "../../redux/Action/CommonAction";
import AlertTableActions from "../../redux/Action/AlertTableActions";
import useWindowDimensions from "./../../components/common/useWindowDimensions";
import VmsHelperActions from "../../redux/Action/VmsHelperActions";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";

import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Paper,
  IconButton,
  Tooltip,
} from "@mui/material";

import ModalLoader from "../../components/common/Loader/ModalLoader";
import AlertTableModal from "./AlertTableModal";
import { IcRoundViewCarousel } from "../../assets/icons/CarouselIcon";
import CarouselModel from "./AlertTable/CarouselModel";
const AlertTableTabs = () => {
  const dispatch = useDispatch();
  const alertCardLoading = useSelector(
    (state) => state.AlertTableStates.loadingTableData
  );
  const [showCarousel, setShowCarousel] = useState(false);
  const isModelOpen = useSelector(
    (state) => state.AlertTableStates.isModelOpen
  );
  const state = useSelector((state) => state);
  const [selectionRange, setselectionRange] = useState({
    startDate: new Date(state.commonData.dateRange.startDate),
    endDate: new Date(state.commonData.dateRange.endDate),
    key: "selection",
  });
  const { width } = useWindowDimensions();
  useEffect(() => {
    setselectionRange({
      startDate: new Date(state.commonData.dateRange.startDate),
      endDate: new Date(state.commonData.dateRange.endDate),
      key: "selection",
    });
  }, [state.commonData.dateRange]);
  useEffect(() => {
    dispatch(VmsHelperActions.getAllFacilitiesCameraForPlayback(profileId));
    return () => {
      dispatch(AlertTableAction.updateTabValue([false, false]));
      dispatch(AlertTableAction.updateTabName("alert"));
    };
  }, []);
  const [time] = useState(state.AlertTableStates.timeRange);
  const [profileId] = useState(JSON.parse(localStorage.getItem("profile_id")));
  const [access_permission] = useState(
    JSON.parse(localStorage.getItem("agx_permission")).Alerts ?? []
  );

  //If profileId is 63(DPS) then set active tab to "resolved"
  const [tabValue, settabValue] = useState(
    profileId === 63 ? [false, true] : [false, false]
  );
  const getTableDataFunc = (showLoading) => {
    getTableData(
      state.commonData.alerteventDate,
      state.AlertTableStates.selectedMode.value,
      state.AlertTableStates.selectedAlertType.value,
      state.AlertTableStates.selectedFacility.value,
      state.AlertTableStates.selectedDevice.value,
      state.AlertTableStates.timeRange,
      selectionRange,
      state.AlertTableStates.currentPage,
      state.AlertTableStates.tabValue,
      state.AlertTableStates.searchValue,
      state.commonData.pageSize,
      state.AlertTableStates.selectedUseCase,
      state.AlertTableStates.selectedPriority.value,
      state.AlertTableStates.alertOrEvent,
      dispatch,
      showLoading
    );
  };
  useEffect(() => {
    getTableDataFunc(true);
  }, [
    tabValue,
    state.commonData.pageSize,
    state.commonData.alerteventDate,
    state.AlertTableStates.selectedMode,
    state.AlertTableStates.selectedDevice.value,
    state.AlertTableStates.selectedAlertType.value,
    state.AlertTableStates.selectedFacility.value,
    state.AlertTableStates.selectedAlertType.value,
    state.AlertTableStates.timeRange,
    state.AlertTableStates.currentPage,
    state.AlertTableStates.searchValue,
    state.AlertTableStates.selectedUseCase,
    state.AlertTableStates.selectedPriority.value,
  ]);

  const updateObjAlerts = async (data, id) => {
    await AlertTableAction.updateAlertEventPatch(data, id, "alert");
    getTableDataFunc(isModelOpen);
  };
  const updateObjEvents = async (data, id) => {
    await AlertTableAction.updateAlertEventPatch(data, id, "event");
    getTableDataFunc(isModelOpen);
  };
  const tabToggled = (newValue) => {
    // dispatch({ type: "TABLE_DATA_ALERT", payload: { data: [], count: 0 } });
    if (newValue === "alert") {
      settabValue([false, false]);
      dispatch(AlertTableAction.updateTabValue([false, false]));
      dispatch(AlertTableAction.updateTabName(newValue));
    } else if (newValue === "dismissed") {
      settabValue([true, false]);
      dispatch(AlertTableAction.updateTabValue([true, false]));
      dispatch(AlertTableAction.updateTabName(newValue));
    } else if (newValue === "resolved") {
      settabValue([false, true]);
      dispatch(AlertTableAction.updateTabValue([false, true]));
      dispatch(AlertTableAction.updateTabName(newValue));
    }
  };
  const [selectedTab, setSelectedTab] = useState("alert");

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
    tabToggled(newValue);
  };

  function handleGridChange(event) {
    let grid = event.target.value;
    dispatch(Account.updatePageSize(grid));
    localStorage.setItem("pageSize", grid);
    dispatch(AlertTableActions.updateCurrentPage(1));
  }
  const handleClose = (_, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setShowCarousel(false);
    }
  };

  return (
    <Paper
      elevation={0}
      className="custom-tab"
      sx={{
        backgroundColor: "transparent",
        color: "var(--side-item-color)",
      }}
    >
      <Box sx={{ marginTop: "10px", paddingX: "15px" }}>
        <Box
          sx={{
            // borderBottom: 1,
            backgroundColor: "transparent",
            color: "var(--black-white-darkmode)",
          }}
          className="row "
        >
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            aria-label="event tabs"
            className="col-12 col-md-9"
          >
            <Tab
              value="alert"
              label={`Alert ${
                selectedTab === "alert" && !alertCardLoading
                  ? "(" + state.AlertData.tableAlertCount + ")"
                  : ""
              }`}
              sx={{
                color: selectedTab !== "alert" && "var(--black-white-darkmode)",
              }}
            />
            {access_permission.includes("view_dismissed_alerts") && (
              <Tab
                value="dismissed"
                label={`Dismissed ${
                  selectedTab === "dismissed" && !alertCardLoading
                    ? "(" + state.AlertData.tableAlertCount + ")"
                    : ""
                }`}
                sx={{
                  color:
                    selectedTab !== "dismiss" && "var(--black-white-darkmode)",
                }}
              />
            )}
            {access_permission.includes("view_resolved_alerts") && (
              <Tab
                value="resolved"
                label={`Resolved ${
                  selectedTab === "resolved" && !alertCardLoading
                    ? "(" + state.AlertData.tableAlertCount + ")"
                    : ""
                }`}
                sx={{
                  color:
                    selectedTab !== "resolved" && "var(--black-white-darkmode)",
                }}
              />
            )}
          </Tabs>
          <Box
            sx={{
              width: 150,
              paddingTop: "10px",
              marginLeft: "auto",
              marginRight: "10px",
              display: "flex",
              justifyContent: "end",
              alignContent: "center",
            }}
            className="col-12 col-md-4"
          >
            <Tooltip title="Carousel View">
              <IconButton
                size="small"
                onClick={() => setShowCarousel(true)}
                disabled={state.AlertData.alertTableData.length == 0}
                sx={{ margin: 0, padding: 0.2, marginRight: "5px" }}
              >
                <IcRoundViewCarousel style={{ fontSize: "35px" }} />
              </IconButton>
            </Tooltip>
            <FormControl className="mt-1">
              <InputLabel id="demo-simple-select-label">Grid</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={state.commonData.pageSize}
                label="No of Grid"
                onChange={handleGridChange}
                sx={{ height: "30px" }}
              >
                <MenuItem value={12}>12</MenuItem>
                <MenuItem value={24}>24</MenuItem>
                <MenuItem value={36}>36</MenuItem>
                <MenuItem value={48}>48</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        {alertCardLoading ? (
          <ModalLoader open={alertCardLoading} />
        ) : !alertCardLoading && state.AlertData.alertTableData.length == 0 ? (
          <div
            className="col-12 d-flex justify-content-center align-items-center"
            style={{ marginTop: "10%" }}
          >
            <div className="d-flex justify-content-center align-items-center">
              <div>
                <center>
                  <CreditCardOffIcon
                    sx={{
                      fontSize: "100px",
                      color: "var(--black-white-darkmode)",
                    }}
                  />
                </center>
                <h2 style={{ color: "var(--black-white-darkmode)" }}>
                  No Alerts to show{" "}
                </h2>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="row">
              {state.AlertData.alertTableData.map((item, index) => {
                return (
                  <div
                    className={
                      width > 4000
                        ? "col-1"
                        : width > 1800
                        ? "col-2"
                        : "col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-md-12 col-sm-12"
                    }
                    key={item.id}
                  >
                    <AlertTableCard
                      incrementcount={
                        item.notification_type == "alerts"
                          ? updateObjAlerts
                          : updateObjEvents
                      }
                      title={selectedTab}
                      item={item}
                      index={index}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {showCarousel && state.AlertData.alertTableData.length > 0 && (
          <CarouselModel
            show={showCarousel}
            handleClose={handleClose}
            updateObjAlerts={updateObjAlerts}
            updateObjEvents={updateObjEvents}
            title={selectedTab}
          />
        )}
      </Box>
    </Paper>
  );
};

export default AlertTableTabs;
