/* eslint-disable react/prop-types */
import React from "react";
import "../../assets/css/Login.css";
import Skeleton from "@mui/material/Skeleton";

const DashboardCard = ({ icon, headText, paraText, isLoading }) => {
  return (
    <>
      <div className="retail-top-card">
        {isLoading ? (
          <Skeleton
            className="dashcaricondiv"
            variant="circular"
            width={70}
            height={70}
            sx={{ bgcolor: "var(--loading-skeleton)" }}
          />
        ) : (
          <div className="dashcaricondiv">{icon}</div>
        )}
        <div style={{ marginLeft: "10px" }}>
          <span className="card-heading-retail">
            {isLoading ? (
              <Skeleton
                variant="rectangular"
                width={60}
                height={25}
                sx={{ bgcolor: "var(--loading-skeleton)" }}
              />
            ) : (
              headText
            )}
          </span>
          <div className="card-paragraph-retail" style={{ marginTop: "10px" }}>
            {isLoading ? (
              <Skeleton
                variant="rectangular"
                width={150}
                height={25}
                sx={{ bgcolor: "var(--loading-skeleton)" }}
              />
            ) : (
              paraText
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardCard;
