import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";

const ActiveChannelComponent = ({
  channelCapacity,
  uncheckedChannels,
  onUncheckedChannelsChange,
}) => {
  const isInitialMount = useRef(true); // to track initial mount

  // Function to create initial channel state
  const createInitialChannels = (capacity) =>
    Array.from({ length: capacity }, (_, i) => ({
      index: i + 1,
      isChecked: !uncheckedChannels.includes(i + 1),
    }));

  const [channels, setChannels] = useState(
    createInitialChannels(channelCapacity)
  );

  useEffect(() => {
    // Update the channels state when channelCapacity changes
    setChannels(createInitialChannels(channelCapacity));
  }, [channelCapacity]);

  useEffect(() => {
    if (!isInitialMount.current) {
      // Only run this effect after the initial mount
      const uncheckedChannels = channels
        .filter((channel) => !channel.isChecked)
        .map((channel) => channel.index);

      onUncheckedChannelsChange(uncheckedChannels);
    } else {
      isInitialMount.current = false;
    }
  }, [channels]);

  const handleCheckboxChange = (index) => {
    setChannels((prevChannels) =>
      prevChannels.map((channel) =>
        channel.index === index
          ? { ...channel, isChecked: !channel.isChecked }
          : channel
      )
    );
  };

  return (
    <>
      <Typography variant="h5">Select the Active Channels</Typography>
      <Typography variant="body2">
        Uncheck the channels that are not in use or if you want to ignore the
        alerts from these cameras.
      </Typography>
      <FormGroup>
        <div className="row">
          {channels.map((channel) => (
            <div className="col-12 col-md-6 col-lg-4" key={channel.index}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={channel.isChecked}
                    onChange={() => handleCheckboxChange(channel.index)}
                    name={`channel-${channel.index}`}
                  />
                }
                label={`Channel ${channel.index}`}
              />
            </div>
          ))}
        </div>
      </FormGroup>
    </>
  );
};

// Define prop types
ActiveChannelComponent.propTypes = {
  channelCapacity: PropTypes.number.isRequired,
  onUncheckedChannelsChange: PropTypes.func.isRequired,
  uncheckedChannels: PropTypes.array.isRequired,
};

export default ActiveChannelComponent;
