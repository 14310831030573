import React, { useEffect, useRef, useState } from "react";
import { Outlet } from "react-router";
import { useDispatch } from "react-redux";
import EmailIcon from "@mui/icons-material/Email";
import { MenuItem, Select } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import { changeTopbarTitle } from "../../redux/Action/ChangeTopbarTitle";
import mainCss from "../../assets/css/mainCss";
import Account, {
  reportDownloadByMailByCamera,
} from "../../redux/Action/CommonAction";
import { reportDownloadByMail } from "../../redux/Action/CommonAction";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import getChartData from "./DashGetChartData";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { ColorRing } from "react-loader-spinner";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Grid from "@mui/material/Grid";
import DescriptionIcon from "@mui/icons-material/Description";
import { DateRange } from "react-date-range";
import DashboardCharts from "./DashboardCharts";
import DashboardAllCards from "./DashboardAllCards";
import { useNavigate } from "react-router-dom";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
const inputselect = {
  boxShadow: "none",
  borderRadius: "8px",
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
  MenuProps: {
    PaperProps: {
      style: {
        backgroundColor: "var(--top-bar-bg-color)",
        maxHeight: 350,
        width: 50,
        overflowX: "auto",
      },
    },
  },
};

const DashboardPageLayout = () => {
  const [selectedMode, setselectedMode] = useState({ value: "day-wise" });
  const state = useSelector((state) => state);
  const [selectedStore, setSelectedStore] = useState({ value: "all" });
  const [selectedDevice, setselectedDevice] = useState({ value: "all" });
  const [selectedDate, setselectedDate] = useState(
    new Date(state.commonData.alerteventDate)
  );
  const [realSelectedMode, setRealSelecetedMode] = useState({
    value: "day-wise",
  });
  const [access_permission] = useState(
    JSON.parse(localStorage.getItem("agx_permission")).Dashboard ?? []
  );
  const [startTime] = useState("00:00:00");
  const [endTime] = useState("23:00:00");
  const tabRef = useRef(null);
  const [showPicker, setShowPicker] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isReportDownloading, setReportDownloading] = useState(false);
  const navigate = useNavigate();
  var profile = JSON.parse(localStorage.getItem("profile"));
  var profile_id = JSON.parse(localStorage.getItem("profile_id"));

  const [selectionRange, setselectionRange] = useState({
    startDate: new Date(state.commonData.dateRange.startDate),
    endDate: new Date(state.commonData.dateRange.endDate),
    key: "selection",
  });
  //state fro checking screen width
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  //code for checking the screen width
  useEffect(() => {
    // Update the screenWidth state when the window is resized
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Add event listener to listen for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const dispatch = useDispatch();
  useEffect(() => {
    if (profile.dashboard !== true) {
      navigate("/unauthorized");
    } else {
      dispatch(changeTopbarTitle("Analytics Dashboard"));
      Account.getStoresByAccount(profile_id)(dispatch);
    }
  }, []);

  const handleDateChange = (date) => {
    setselectedDate(date);
    dispatch(Account.updateDate(date)); // dispatch updateDate action with the selected date
  };
  const togglePicker = () => {
    setShowPicker(!showPicker);
  };
  const handleWindowClick = (event) => {
    if (tabRef.current && !tabRef.current.contains(event.target)) {
      setShowPicker(false);
    }
  };
  useEffect(() => {
    window.addEventListener("click", handleWindowClick);

    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
  }, []);

  const handleSendEmailReport = () => {
    setIsLoading(true);
    const type = "dashboard";
    selectedDevice.value == "all"
      ? reportDownloadByMail(selectedDate, type, selectedMode, selectionRange)
      : reportDownloadByMailByCamera(
          selectedDate,
          type,
          selectedMode,
          selectionRange,
          selectedDevice.value
        )
          .then((res) => {
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
          });
  };
  const handleReportDownloadCult = () => {
    setReportDownloading(true);
    dispatch(
      Account.downloadExcelReport({ id: profile_id, date: selectedDate })
    )
      .then(() => setReportDownloading(false))
      .catch(() => setReportDownloading(false));
  };
  const printDocument = () => {
    setIsLoading(true);
    //CSS rule to hide elements with the 'hide-from-pdf' class
    const style = document.createElement("style");
    style.innerHTML = ".hide-from-pdf { display: none !important; }";
    document.head.appendChild(style);
    const input = document.getElementById("divToPrint");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      let pdf = new jsPDF("p", "mm", "a4"); // A4 size page of PDF
      let pageHeight = pdf.internal.pageSize.getHeight();
      let canvasHeight = (canvas.height * 210) / canvas.width;
      let heightLeft = canvasHeight;
      let position = 0;

      pdf.addImage(
        imgData,
        "PNG",
        0,
        position,
        210,
        (210 * canvas.height) / canvas.width
      );
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - canvasHeight;
        pdf.addPage();
        pdf.addImage(
          imgData,
          "PNG",
          0,
          position,
          210,
          (210 * canvas.height) / canvas.width
        );
        heightLeft -= pageHeight;
      }
      pdf.save("download.pdf");
    });
    // Remove the CSS rule after generating the PDF
    document.head.removeChild(style);
    setIsLoading(false);
  };

  setTimeout(function () {
    setIsLoading(false);
  }, 10000); // wait for 3 seconds (3000 milliseconds)

  const formateDateRange = (date) => {
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };
  const formateDateRangeShort = (date) => {
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    });
  };

  const handleDateRangeChange = (dtRange) => {
    setselectionRange(dtRange.selection);
    (dtRange = {
      startDate: dtRange.selection.startDate.toISOString(),
      endDate: dtRange.selection.endDate.toISOString(),
      key: "selection",
    }),
      dispatch(Account.updateDateRange(dtRange));
    if (dtRange.startDate != dtRange.endDate) {
      setRealSelecetedMode({ value: "date-range" });
    } else {
      setRealSelecetedMode({ value: "day-wise" });
    }
  };

  const handleStoreChange = (event) => {
    setSelectedStore({ value: event.target.value });
    if (event.target.value != "all") {
      Account.getDevicesByAccount(event.target.value)(dispatch);
      setselectedDevice({ value: "all" });
    } else {
      getChartData(selectedMode, selectedStore, selectedDevice);
    }
  };

  const handleDeviceChange = (event) => {
    setselectedDevice({ value: event.target.value });
  };

  const handleModeChange = (event) => {
    if (event.target.value == "day-wise") {
      setRealSelecetedMode({ value: "day-wise" });
    }
    setselectedMode({ value: event.target.value });
  };

  useEffect(() => {
    if (selectionRange.startDate !== selectionRange.endDate) {
      getChartData(
        selectedDate,
        selectedMode.value,
        selectedStore.value,
        selectedDevice.value,
        selectionRange,
        startTime,
        endTime,
        dispatch
      );
    }
  }, [
    selectedDevice,
    realSelectedMode,
    selectedStore,
    selectedDate,
    selectionRange,
    startTime,
    endTime,
  ]);

  return (
    <>
      <Outlet />
      <div id="divToPrint">
        <div>
          <DashboardAllCards selectedMode={realSelectedMode} />

          <div className="select-input-div card-custom card-stretch gutter-b hide-from-pdf">
            <div className="card-body-div">
              <div className="row">
                {access_permission.includes("day_time_range_selection") && (
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-2">
                    <div className="select-input-lebel">Filters</div>
                    <OverlayTrigger
                      placement="bottom-start"
                      trigger="click"
                      rootClose="false"
                      overlay={
                        <Popover className="col-9 col-sm-12">
                          <Popover.Body
                            style={{
                              backgroundColor: "var(--side-bar-bg-color)",
                            }}
                          >
                            <div className="select-input-lebel">Mode</div>
                            <Select
                              value={selectedMode.value}
                              sx={{
                                color: "var(--card-text-color)",
                                ...inputselect,
                              }}
                              className="select-input-field"
                              MenuProps={inputselect.MenuProps}
                              onChange={handleModeChange}
                            >
                              <MenuItem
                                value="day-wise"
                                sx={{
                                  paddingTop: "2px",
                                  paddingBottom: "2px",
                                  color: "var(--card-text-color)",
                                }}
                                onClick={(e) => e.stopPropagation()}
                              >
                                Day Wise
                              </MenuItem>
                              <MenuItem
                                value="date-range"
                                sx={{
                                  paddingTop: "2px",
                                  paddingBottom: "2px",
                                  color: "var(--card-text-color)",
                                }}
                                onClick={(e) => e.stopPropagation()}
                              >
                                Date Range
                              </MenuItem>
                            </Select>

                            <div className="mt-2">
                              {selectedMode.value === "date-range" ? (
                                <div>
                                  <div className="select-input-lebel">
                                    Date Range
                                  </div>
                                  <div ref={tabRef}>
                                    <button
                                      className="select-input-field"
                                      onClick={togglePicker}
                                    >
                                      {formateDateRange(
                                        selectionRange.startDate
                                      )}{" "}
                                      -{" "}
                                      {formateDateRange(selectionRange.endDate)}
                                    </button>
                                    <div
                                      className="date-range-picker-container"
                                      style={{
                                        display: showPicker ? "block" : "none",
                                        marginTop: "15px",
                                      }}
                                    >
                                      {screenWidth > 1000 ? (
                                        <DateRangePicker
                                          className="select-input-field"
                                          ranges={[selectionRange]}
                                          onChange={handleDateRangeChange}
                                        />
                                      ) : (
                                        <DateRange
                                          className="select-input-field"
                                          editableDateInputs={true}
                                          moveRangeOnFirstSelection={false}
                                          ranges={[selectionRange]}
                                          onChange={handleDateRangeChange}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  <div className="select-input-lebel">Date</div>
                                  <DatePicker
                                    className="select-input-field-date"
                                    customInput={
                                      <input className="custom-input-date-text" />
                                    }
                                    selected={selectedDate}
                                    onChange={handleDateChange}
                                    dateFormat="dd/MM/yyyy"
                                  />
                                </div>
                              )}
                            </div>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <div className="d-flex filter-div">
                        {selectedMode.value === "date-range" ? (
                          <div className="filter-div-text col-10 mt-1 overflow-hidden">
                            {formateDateRangeShort(selectionRange.startDate)} -{" "}
                            {formateDateRangeShort(selectionRange.endDate)}{" "}
                          </div>
                        ) : (
                          <div className="filter-div-text col-10 overflow-hidden">
                            {formateDateRange(selectedDate)}
                          </div>
                        )}
                        <div className="filter-drop-icon col-2">
                          <ArrowDropDownOutlinedIcon
                            style={{
                              float: "right",
                              marginRight: "16px",
                              marginTop: "7px",
                            }}
                          />
                        </div>
                      </div>
                    </OverlayTrigger>
                  </div>
                )}
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-2">
                  <div className="select-input-lebel">Facility</div>
                  <Select
                    value={selectedStore.value}
                    onChange={handleStoreChange}
                    sx={{
                      color: "var(--card-text-color)",
                      ...inputselect,
                    }}
                    className="select-input-field"
                    MenuProps={inputselect.MenuProps}
                  >
                    {state.commonData.storeList.map((item) => {
                      return (
                        <MenuItem
                          sx={{ color: "var(--card-text-color)" }}
                          key={item.value}
                          value={item.value}
                        >
                          {item.text}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
                {selectedStore.value !== "all" && (
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-2">
                    <div className="select-input-lebel">Devices</div>

                    <Select
                      value={selectedDevice.value}
                      onChange={handleDeviceChange}
                      MenuProps={inputselect.MenuProps}
                      sx={{
                        color: "var(--card-text-color)",
                        ...inputselect,
                      }}
                      className="select-input-field"
                    >
                      {state.commonData.deviceList.map((item) => {
                        return (
                          <MenuItem
                            sx={{ color: "var(--card-text-color)" }}
                            key={item.value}
                            value={item.value}
                          >
                            {item.text}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </div>
                )}
                <div className="col mt-3">
                  {access_permission.includes(
                    "analytics_report_email_download"
                  ) && (
                    <Grid container justifyContent="flex-end" spacing={2}>
                      {profile.services_selected.Dashboard.includes(
                        "enable_beta_features"
                      ) && (
                        <Grid item>
                          <div
                            className="btn btn-sm m-1 shadow-none"
                            style={mainCss.downloadButton}
                            onClick={printDocument}
                            title="Download PDF Document"
                          >
                            {!isLoading ? (
                              <DescriptionIcon sx={mainCss.downloadIcon} />
                            ) : (
                              <ColorRing
                                visible={true}
                                height="40"
                                width="40"
                                ariaLabel="blocks-loading"
                                wrapperStyle={{}}
                                wrapperClass="blocks-wrapper"
                                colors={[
                                  "#ffff",
                                  "#ffff",
                                  "#ffff",
                                  "#ffff",
                                  "#ffff",
                                ]}
                              />
                            )}
                          </div>
                        </Grid>
                      )}
                      {profile.account.account_id == 44 && (
                        <Grid item>
                          <div
                            className="btn btn-sm m-1 shadow-none"
                            style={mainCss.downloadButton}
                            onClick={handleReportDownloadCult}
                            title="Download Daily Report"
                          >
                            {!isReportDownloading ? (
                              <SimCardDownloadIcon sx={mainCss.downloadIcon} />
                            ) : (
                              <ColorRing
                                visible={true}
                                height="40"
                                width="40"
                                ariaLabel="blocks-loading"
                                wrapperStyle={{}}
                                wrapperClass="blocks-wrapper"
                                colors={[
                                  "#ffff",
                                  "#ffff",
                                  "#ffff",
                                  "#ffff",
                                  "#ffff",
                                ]}
                              />
                            )}
                          </div>
                        </Grid>
                      )}
                      <Grid item>
                        <div
                          className="btn btn-sm m-1 shadow-none"
                          style={mainCss.downloadButton}
                          onClick={handleSendEmailReport}
                          title="Send Email Report"
                        >
                          {!isLoading ? (
                            <EmailIcon sx={mainCss.downloadIcon} />
                          ) : (
                            <ColorRing
                              visible={true}
                              height="40"
                              width="40"
                              ariaLabel="blocks-loading"
                              wrapperStyle={{}}
                              wrapperClass="blocks-wrapper"
                              colors={[
                                "#ffff",
                                "#ffff",
                                "#ffff",
                                "#ffff",
                                "#ffff",
                              ]}
                            />
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  )}
                </div>
              </div>
            </div>
          </div>
          <DashboardCharts selectedMode={realSelectedMode} />
        </div>
      </div>
    </>
  );
};
export default DashboardPageLayout;
