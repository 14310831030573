import moment from "moment";
import * as DateFns from "date-fns";
const initialState = {
  totalMaleFemalHourly: [],
  totalMaleFemaleWeekly: [],
  peopleCountGender: [],
  passerCountGender: [],
  peopleCountGenderHourly: [],
  peopleCountMaleFemaleHourly: [],
  maleFemaleDayData: [],
};

export default function MaleFemaleReducer(state = initialState, action) {
  switch (action.type) {
    case "TOTAL_MALEFEMAL_COUNT_HOURLY_DATA":
      return {
        ...state,
        totalMaleFemalHourly: action.payload.map((datapoint) => {
          let dater = DateFns.setHours(
            new Date(action.date),
            new Date(datapoint.recorded_at__hour)
          );
          datapoint.date = moment(dater)
            .subtract(30, "minutes")
            .toLocaleString();
          delete datapoint.recorded_at__hour;
          return datapoint;
        }),
      };
    case "PEOPLE_COUNT_GENDER_WEEKLY_DATA":
      return {
        ...state,
        totalMaleFemaleWeekly: action.payload.map((datapoint) => {
          datapoint.date = new Date(datapoint.recorded_at__date).toISOString();
          return datapoint;
        }),
      };
    case "PEOPLE_COUNT_GENDER_HOURLY_DATA":
      return {
        ...state,
        peopleCountGender: action.payload,
        peopleCountGenderHourly: action.payload.hourly.map((datapoint) => {
          let dater = DateFns.setHours(
            new Date(action.date),
            new Date(datapoint.recorded_at__hour)
          );
          datapoint.date = moment(dater)
            .subtract(30, "minutes")
            .toLocaleString();
          delete datapoint.recorded_at__hour;
          return datapoint;
        }),
      };
    case "MALE_FEMALE_DAY_DATA":
      return {
        ...state,
        maleFemaleDayData: action.payload.map((datapoint) => {
          datapoint.date = new Date(datapoint.recorded_at__date).toISOString();
          return datapoint;
        }),
      };
    case "PASSER_COUNT_GENDER_HOURLY_DATA":
      return { ...state, passerCountGender: action.payload };
    case "MALE_FEMALE_COUNT_DATE_RANG_PC":
      return { ...state, peopleCountGender: action.payload };
    case "MALE_FEMALE_COUNT_DATE_RANG_PS":
      return { ...state, passerCountGender: action.payload };
    default:
      return state;
  }
}
