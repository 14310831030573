import React, { useEffect, useState } from "react";
import Slider from "@mui/material/Slider";
import Tooltip from "@mui/material/Tooltip";
import { Box } from "@mui/material";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

/**
 * Generates marks for the slider, representing each hour of the day.
 */
const marks = Array.from({ length: 25 }, (_, i) => ({
  value: i,
  label: `${String(i).padStart(2, "0")}`,
}));

/**
 * Formats a decimal time value into a human-readable string.
 * @param {number} value - The decimal time value.
 * @returns {string} - The formatted time string.
 */
const formatTime = (value) => {
  const hours = Math.floor(value);
  const minutes = Math.round((value - hours) * 60);
  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )}`;
};
const parseFormattedTime = (formattedTime) => {
  const [hours, minutes] = formattedTime.split(":").map(Number);
  return hours + minutes / 60;
};

/**
 * Tooltip component for displaying formatted time values.
 */
const ValueLabelComponent = ({ children, value }) => (
  <Tooltip enterTouchDelay={0} placement="top" title={formatTime(value)}>
    {children}
  </Tooltip>
);

ValueLabelComponent.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.number.isRequired,
};

/**
 * Generates a CSS gradient string based on recording availability data.
 * @param {Array} data - The recording segments data.
 * @returns {string} - The CSS gradient string.
 */
const generateGradient = (data) => {
  const segments = data.map((segment) => {
    if (segment) {
      const start =
        ((parseInt(segment.start_time.split(":")[0], 10) * 60 +
          parseInt(segment.start_time.split(":")[1], 10)) /
          1440) *
        100;
      const end =
        ((parseInt(segment.end_time.split(":")[0], 10) * 60 +
          parseInt(segment.end_time.split(":")[1], 10)) /
          1440) *
        100;
      const color = segment.is_available ? "green" : "red";
      return `${color} ${start}%, ${color} ${end}%`;
    }
  });
  return `linear-gradient(to right, ${segments.join(", ")})`;
};

/**
 * Styled slider component with custom properties.
 */
const StyledSlider = styled(Slider)`
  & .MuiSlider-track {
    height: 16px;
    background: transparent;
    border-radius: 0px;
  }
  & .MuiSlider-rail {
    height: 16px;
    background: ${({ gradient }) => gradient};
    border-radius: 0px;
    opacity: 1;
  }
  & .MuiSlider-thumb {
    width: 10px;
    height: 30px;
    background-color: blue;
    border-radius: 2px;
    &:hover,
    &:focus,
    &:active {
      box-shadow: 0px 0px 0px 8px rgba(0, 0, 255, 0.16);
    }
  }
  & .MuiSlider-valueLabel {
    background-color: #4318ff;
    border-radius: 10px;
    color: white;
  }
  & .MuiSlider-markLabel {
    color: black;
  }
`;

/**
 * TimelineSlider component for selecting a time from a 24-hour slider.
 */
const TimelineSlider = () => {
  const [selectedTime, setSelectedTime] = useState(0);
  const availableRecording = useSelector((state) => state.vms.timeline);
  const dispatch = useDispatch();
  const playbackSelectedTime = useSelector(
    (state) => state.vms.playbackSelectedTime
  );
  const handleChange = (event, newValue) => {
    setSelectedTime(newValue);
  };
  useEffect(() => {
    setSelectedTime(parseFormattedTime(playbackSelectedTime));
  }, [playbackSelectedTime]);
  const handleChangeRedux = (event, newValue) => {
    setSelectedTime(newValue);
    console.log(newValue);
    dispatch({
      type: "UPDATE_PLAYBACK_SELECTED_TIME",
      payload: formatTime(newValue),
    });
  };

  const gradient = generateGradient(availableRecording);

  return (
    <Box sx={{ width: "95%", margin: "auto" }}>
      <StyledSlider
        value={selectedTime}
        min={0}
        max={24}
        marks={marks}
        valueLabelDisplay="on"
        step={0.0167}
        valueLabelFormat={formatTime(selectedTime)}
        onChange={handleChange}
        onChangeCommitted={handleChangeRedux}
        gradient={gradient}
      />
    </Box>
  );
};

export default TimelineSlider;
