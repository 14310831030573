import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Bars } from "react-loader-spinner";
import { PropTypes } from "prop-types";
export default function IpValidationModal({ open }) {
  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="d-flex flex-column justify-content-center">
          <div className="d-flex justify-content-center mb-3">
            <Bars
              height="60"
              width="60"
              color="var(--card-text-color)"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
          <h4>Verifying the IP Address</h4>
        </DialogContent>
      </Dialog>
    </div>
  );
}
IpValidationModal.propTypes = {
  open: PropTypes.bool.isRequired,
};
