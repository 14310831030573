import React from "react";
import { Tabs, Tab, Box } from "@mui/material";
import { styled, useTheme } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import WbCloudyIcon from "@mui/icons-material/WbCloudy";
import SaveIcon from "@mui/icons-material/Save";

const StyledTabs = styled(Tabs)({
  backgroundColor: "lightgrey",
  borderRadius: "8px",
  padding: "3px",
  paddingTop: "4px",
  "& .MuiTabs-indicator": {
    display: "none",
  },
});

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  fontWeight: 500,
  fontSize: "16px",
  borderRadius: "8px",
  display: "flex",
  margin: "2px",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "row",
  color: theme.palette.text.primary,
  minHeight: "24px",
  padding: "6px 6px",
  transition: "all 0.1s ease",
  "&.Mui-selected": {
    color: "white",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "8px",
  },
  "&:hover": {
    backgroundColor: "lightgray",
  },
  "& .MuiTab-iconWrapper": {
    marginBottom: "0px",
    marginRight: "4px",
  },
}));

const PlaybackModeMethodTabs = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const activeMethod = useSelector((state) => state.vms.activePlaybackMethod);
  const [value, setValue] = React.useState(activeMethod === "hdd" ? 1 : 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const method = newValue === 1 ? "hdd" : "cloud";
    dispatch({
      type: "UPDATE_ACTIVE_PLAYBACK_METHOD",
      payload: method,
    });
  };

  return (
    <Box
      sx={{
        width: "250px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <StyledTabs value={value} onChange={handleChange} theme={theme}>
        <StyledTab icon={<WbCloudyIcon />} label="Cloud" iconPosition="start" />
        <StyledTab
          icon={<SaveIcon />}
          label="Local Disk"
          iconPosition="start"
        />
      </StyledTabs>
    </Box>
  );
};

export default PlaybackModeMethodTabs;
