import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const notifiToasterSuccess = (data) => {
  toast.success(data);
};
export const notifiToasterInfo = (data) => {
  toast.info(data);
};

export const notifiToasterWarn = (msg) => {
  toast.warn(msg);
};

const UseToast = () => {
  return (
    <div>
      <ToastContainer />
    </div>
  );
};

export default UseToast;
