import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import MultiSelectAutoAutoComplete from "../../../components/common/formLayout/MultiSelectAutoAutoComplete";
import VmsHelperActions from "../../../redux/Action/VmsHelperActions";
import VmsLiveviewActions from "../../../redux/Action/VmsActions";

const getCameraOptionsFromFacilityList = (facilityList) => {
  if (!facilityList) return [];
  return facilityList.flatMap((facility) =>
    facility.nvr_list.flatMap((nvr) =>
      nvr.camera_list.map((camera) => ({
        value: camera.camera_id,
        label: camera.camera_name,
      }))
    )
  );
};

const CopyCameraSettingToOtherCameras = ({ cameraList, currentCameraId }) => {
  const [open, setOpen] = useState(false);
  const [selectedCameras, setSelectedCameras] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const cameraFacilityList = useSelector(
    (state) => state.vmsHelper.FacilityCameraListForPlayback
  );
  const [cameraOptions, setCameraOptions] = useState([]);

  useEffect(() => {
    if (!open) return;
    setLoading(true);
    const data = JSON.parse(localStorage.getItem("profile")).id;
    dispatch(VmsHelperActions.getAllFacilitiesCameraForPlayback(data)).finally(
      () => setLoading(false)
    );
  }, [open, dispatch]);

  useEffect(() => {
    const options = getCameraOptionsFromFacilityList(cameraFacilityList);
    setCameraOptions(options);
  }, [cameraFacilityList]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCameraChange = (newValue) => {
    setSelectedCameras(newValue);
  };

  const handleSave = useCallback(async () => {
    setLoading(true);
    const cameraData = cameraList.find((item) => item.id == currentCameraId);
    console.log(cameraData);
    if (cameraData) {
      const dataToCopy = {
        is_recording_cloud: cameraData.is_recording_cloud,
        recording_basis_cloud: cameraData.recording_basis_cloud,
        stream_to_record: cameraData.stream_to_record,
        recording_schedule: cameraData.recording_schedule,
        recording_resolution: cameraData.recording_resolution,
      };
      await Promise.all(
        selectedCameras.map((item) =>
          dispatch(VmsLiveviewActions.patchCamera(item.value, dataToCopy))
        )
      );
      handleClose();
    }
    setLoading(false);
  }, [cameraList, currentCameraId, selectedCameras, dispatch]);

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Copy Settings to Other Cameras
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          Copy Camera Settings
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <p>
            Select the cameras on which you want to copy the settings of the
            current camera:
          </p>
          <MultiSelectAutoAutoComplete
            option={cameraOptions}
            value={selectedCameras}
            onChange={handleCameraChange}
            label="Select Cameras"
            getOptionDisabled={(option) => option.value === currentCameraId}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={loading}>
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

CopyCameraSettingToOtherCameras.propTypes = {
  currentCameraId: PropTypes.string.isRequired,
  cameraList: PropTypes.array.isRequired,
};

export default CopyCameraSettingToOtherCameras;
