import React from "react";
import { useSelector } from "react-redux";
import DashboardFunction from "../DashboardFunctions";
import DemographicsFunction from "../DemographicsFunctions";
import DashAreaChart from "../../../components/charts/DashAreaChart";
import DashBarChart from "../../../components/charts/DashBarChart";
import PropTypes from "prop-types";

const DwellTimeCharts = React.memo(({ selectedMode }) => {
  // Select only necessary pieces of state to minimize rerenders.
  const profile = JSON.parse(localStorage.getItem("profile") || "{}");
  const accessPermissions =
    JSON.parse(localStorage.getItem("agx_permission") || "{}").Dashboard || [];

  const retailData = useSelector((state) => state.retailData);
  const genderData = useSelector((state) => state.gender);
  const ageData = useSelector((state) => state.age);

  // Check permissions and services selected outside of the return statement.
  const hasDwellTimePermission =
    profile.services_selected.Dashboard.includes("dwell_time") ||
    accessPermissions.includes("view_dwell_time_analytics");
  const includesNicobarDwellTime =
    profile.services_selected.Dashboard.includes("nicobar_dwell_time");

  return (
    <div>
      {hasDwellTimePermission && (
        <div className="row">
          {selectedMode.value === "day-wise" ? (
            <div className="col-12 col-lg-12 col-xl-6">
              {includesNicobarDwellTime ? (
                <div className="col-12">
                  <DashBarChart
                    classNameActive
                    data={DemographicsFunction.dwellHourlyData(
                      ageData.dwellTimeHourly
                    )}
                    type="hourly"
                    title="Dwell Time hourly"
                    labelToShowY="Avg. Dwell Time (Mins)"
                  />
                </div>
              ) : (
                <div className="col-12">
                  <DashAreaChart
                    title="Dwell Time Hourly Comparison"
                    classNameActive
                    data={DemographicsFunction.dwellHourlyData(
                      retailData.lessthan2,
                      retailData.weeklymorethan2,
                      retailData.weeklymorethan10
                    )}
                    labelToShowY="People"
                    type="hourly"
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="col-xxl-12">
              <DashAreaChart
                classNameActive
                data={DemographicsFunction.weeklyDwellTime(
                  retailData.weeklylessthan2,
                  retailData.weeklymorethan2,
                  retailData.weeklymorethan10
                )}
                selectedModeBypage="dateRange"
                xlabel="date"
                type="dateRange"
                title="Dwell Time Day Comparison"
                labelToShowY="People"
              />
            </div>
          )}
          {selectedMode.value === "day-wise" ? (
            // <div className="col-12 col-lg-12 col-xl-6">
            //   <DashAreaChart
            //     classNameActive={true}
            //     data={DemographicsFunction.weeklyDwellTime(
            //       retailData.weeklylessthan2,
            //       retailData.weeklymorethan2,
            //       retailData.weeklymorethan10
            //     )}
            //     title="Dwell Time Weekly Comparison"
            //     type="week"
            //     labelToShowY="People"
            //   />
            // </div>

            <div className="col-12 col-lg-6">
              <DashAreaChart
                classNameActive
                data={DemographicsFunction.demographicsWeeklyData(
                  genderData.totalMaleFemaleWeekly
                )}
                type="week"
                title="Demographics Weekly Comparison"
                labelToShowY="People"
              />
            </div>
          ) : (
            <div className="col-xxl-6"></div>
          )}
        </div>
      )}
    </div>
  );
});
DwellTimeCharts.propTypes = {
  selectedMode: PropTypes.object.isRequired,
};
DwellTimeCharts.displayName = "DwellTimeCharts";
export default DwellTimeCharts;
