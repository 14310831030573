import moment from "moment";
import * as DateFns from "date-fns";
const initialState = {
  emotionHoulry: [],
  emotionData: [],
  emotionDayData: [],
};

export default function EmotionReducer(state = initialState, action) {
  switch (action.type) {
    case "PEOPLE_COUNT_EMOTION_HOURLY_DATA":
      return {
        ...state,
        emotionData: action.payload,
        emotionHoulry: action.payload.hourly.map((datapoint) => {
          let dater = DateFns.setHours(
            new Date(action.date),
            new Date(datapoint.recorded_at__hour)
          );
          datapoint.date = moment(dater)
            .subtract(30, "minutes")
            .toLocaleString();
          delete datapoint.recorded_at__hour;
          return datapoint;
        }),
      };
    case "EMOTION_DEMOGRAPHIC_DAY_DATA":
      return {
        ...state,
        emotionDayData: action.payload,
      };

    default:
      return state;
  }
}
