import React, { useState, useEffect } from "react";
import Textfield from "../../components/common/formLayout/TextField";
import Button from "@mui/material/Button";
import { notifiToasterWarn } from "../../components/common/UseToast";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import OnboardingActions from "../../redux/Action/OnboardingActions";

const OnboadingFacility = ({ isEditable, id }) => {
  const dispatch = useDispatch();
  const activeStep = useSelector((state) => state.onbaording.currentStep);
  const [facilityName, setFacilityName] = useState("");
  const [account, setAccount] = useState(null);
  const [address, setAddress] = useState("");
  const [ContactNumber, setContactNumber] = useState("");
  const [storeManager, setStoreManager] = useState("");
  const [DisableAddAnother, setAddAnother] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isEditablem, setIsEditable] = useState(false);
  const handleFacilityNameChange = (event) => {
    const selectedValue = event.target.value;
    setFacilityName(selectedValue);
  };

  const handleAddressChange = (event) => {
    const selectedValue = event.target.value;
    setAddress(selectedValue);
  };

  const handleContactNumberChange = (event) => {
    const selectedValue = event.target.value;
    setContactNumber(selectedValue);
  };

  const handleStoreManagerChange = (event) => {
    const selectedValue = event.target.value;
    setStoreManager(selectedValue);
  };
  useEffect(() => {
    const facility_data = JSON.parse(localStorage.getItem("onboardFacility"));
    if (facility_data) {
      setFacilityName(facility_data.facility_name);
      setContactNumber(facility_data.phone_number);
      setStoreManager(facility_data.store_manager_name);
      setAddress(facility_data.address);
      setIsEditable(true);
    }
  }, []);
  const handleClick = async () => {
    const onboardAccount = JSON.parse(
      localStorage.getItem("onboardAccount") || "null"
    );
    const account_id = onboardAccount?.account_id;

    //checking is the account created before
    if (!account_id) {
      notifiToasterWarn(
        "We are Unable to Find Your Organization details. Please Create Account First"
      );
      dispatch({ type: "updateCurrentStep", payload: 1 });
      return;
    }

    if (facilityName.length < 3) {
      notifiToasterWarn("Enter Facility Name Correctly");
      return;
    } else if (address.length < 3) {
      notifiToasterWarn("Enter Facility Adress");
      return;
    } else if (ContactNumber.length != 10) {
      notifiToasterWarn("Enter 10 Digit Contact Number");
      return;
    } else {
      const facilityResponse = {
        facility_name: facilityName,
        address: address,
        phone_number: ContactNumber,
        store_manager_name: storeManager,
        account: account_id,
        holiday_schedule: "string",
        user_id: JSON.parse(localStorage.getItem("onboardUser")).userId,
        go_live_date: new Date(Date.now()).toISOString().split("T")[0],
      };
      setLoading(true);
      await OnboardingActions.addFacility(facilityResponse)
        .then(() => {
          dispatch({ type: "updateCurrentStep", payload: activeStep + 1 });
          localStorage.setItem("completedStep", 2);
        })
        .catch((error) => {
          console.error("Error updating account info:", error);
        });
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <div>{!isEditable && <h2 className="mt-2 mb-4">Add Facility:</h2>}</div>
      <div className="row">
        <div className="col-12 col-sm-6 col-lg-6 mb-3">
          <Textfield
            label={"Facility Name"}
            value={facilityName}
            onChange={handleFacilityNameChange}
            type={"text"}
            placeholder={"Facility Name"}
            name="facility name"
            isrequired={true}
          />
        </div>

        <div className="col-12 col-sm-6 col-lg-6 mb-3">
          <Textfield
            label={"Address"}
            value={address}
            onChange={handleAddressChange}
            type={"text"}
            placeholder={"Address"}
            name="address"
            isrequired={true}
          />
        </div>
        <div className="col-12 col-sm-6 col-lg-6 mb-3">
          <Textfield
            label={"Facility Manager Name"}
            value={storeManager}
            onChange={handleStoreManagerChange}
            type={"text"}
            placeholder={"Facility Manager Name"}
            name="Facility manager name"
            isrequired={true}
          />
        </div>
        <div className="col-12 col-sm-6 col-lg-6 mb-3">
          <Textfield
            label={"Facility Manager Number"}
            value={ContactNumber}
            onChange={handleContactNumberChange}
            type={"text"}
            placeholder={"Facility Manager Number"}
            name="Facility Manager  Number"
            isrequired={true}
          />
        </div>
      </div>

      <div className="submit-button-onboarding">
        <Button
          variant="contained"
          onClick={handleClick}
          disabled={!DisableAddAnother || loading}
          sx={{ minWidth: "100px" }}
        >
          {loading ? (
            <CircularProgress sx={{ color: "white" }} />
          ) : isEditable ? (
            "Update Facility"
          ) : (
            "Add Facility"
          )}
        </Button>
      </div>
    </div>
  );
};
OnboadingFacility.propTypes = {
  isEditable: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
};
export default OnboadingFacility;
