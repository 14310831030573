import React, { useState } from "react";
import Textfield from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { addNewFacility } from "../../../redux/Action/RhmsAction";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import AccessControlActions from "../../../redux/Action/AccessControlAction";
const AddNewFacility = ({ isOpen, handleClose, getAllFacilitiesList }) => {
  const dispatch = useDispatch();
  const [facilityName, setFacilityName] = useState("");
  const [address, setAddress] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [storeManager, setStoreManager] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const validateInput = () => {
    let tempErrors = {};
    tempErrors.facilityName = facilityName ? "" : "Facility name is required.";
    tempErrors.address = address ? "" : "Address is required.";
    if (contactNumber && !/^(?:\+91|0)?[6-9]\d{9}$/.test(contactNumber)) {
      tempErrors.contactNumber = "Invalid  phone number.";
    } else if (!contactNumber) {
      // This means the number is not entered which is valid scenario
      tempErrors.contactNumber = "";
    }
    setErrors(tempErrors);

    return Object.values(tempErrors).every((x) => x === "");
  };

  const handleClick = () => {
    if (!validateInput()) return;

    let profile = JSON.parse(localStorage.getItem("profile"));
    let account_id = profile.account.account_id;
    let data = {
      facility_name: facilityName,
      address: address,
      store_manager_name: storeManager,
      phone_number: contactNumber,
      holiday_schedule: "string",
      go_live_date: new Date(Date.now()).toISOString().split("T")[0],
      service_on: [1],
      account: account_id,
    };

    setLoading(true);
    dispatch(addNewFacility(data, profile.id))
      .then(() => {
        getAllFacilitiesList();

        handleClose();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Add Facility
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className="row pt-4">
          <div className="col-12 col-md-6 mt-4">
            <Textfield
              label="Facility Name"
              value={facilityName}
              onChange={(e) => setFacilityName(e.target.value)}
              type="text"
              placeholder="Facility Name"
              name="facility name"
              error={!!errors.facilityName}
              helperText={errors.facilityName}
              required
            />
          </div>
          <div className="col-12 col-md-6 mt-4">
            <Textfield
              label="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              type="text"
              placeholder="Address"
              name="address"
              error={!!errors.address}
              helperText={errors.address}
              required
            />
          </div>
          <div className="col-12 col-md-6 mt-4">
            <Textfield
              label="Facility Manager Name"
              value={storeManager}
              onChange={(e) => setStoreManager(e.target.value)}
              type="text"
              placeholder="Facility Manager Name"
              name="facility manager name"
            />
          </div>
          <div className="col-12 col-md-6 mt-4">
            <Textfield
              label="Contact Number"
              value={contactNumber}
              onChange={(e) => setContactNumber(e.target.value)}
              type="text"
              placeholder="Facility Manager Number"
              name="facility manager number"
              error={!!errors.contactNumber}
              helperText={errors.contactNumber}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleClick}
          color="primary"
          disabled={loading}
          variant="contained"
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddNewFacility.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  getAllFacilitiesList: PropTypes.func.isRequired,
};

export default AddNewFacility;
