import React, { useState, useEffect } from "react";
// Removed unused import (Outlet from "react-router")
import AddTaskIcon from "@mui/icons-material/AddTask";
import LastSeenCard from "./LastSeenCard";
import { useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeTopbarTitle } from "../../redux/Action/ChangeTopbarTitle";
import { Button, Divider, IconButton } from "@mui/material"; // Imported Button seems unused, consider removing if not needed later.
import ArrowCircleLeftSharpIcon from "@mui/icons-material/ArrowCircleLeftSharp";
import AllocateModal from "./FaceRecognitionModals/AllocateModal"; // Ensure this component is used or remove this import if not needed.
import PersonRecognized from "./persondetailspagecomponents/PersonRecognized";
import PersonUnrecognized from "./persondetailspagecomponents/PersonUnrecognized";
import AllocateEditableModal from "./FaceRecognitionModals/AllocateEditableModal";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore"; // Ensure this component is used or remove this import if not needed.
import FaceRecognitionActions from "../../redux/Action/FaceRegnitionAction";
const FaceRecognitionPersonDetailsPage = () => {
  const { faceId } = useParams(); // 'isMatched' seems unused. Remove if not needed.
  const [isModalOpen, setModalOpen] = useState(false);
  const [personDetails, setPersonDetails] = useState(null);
  const [lastSeenData, setLastSeenData] = useState([]);
  const selectedFacility = useSelector(
    (state) => state.faceRecognition.selectedFacility
  );
  const faceList = useSelector(
    (state) => state.faceRecognition.faceDetectedList
  );

  useEffect(() => {
    // Ensure faceId is available and the faceList is not empty
    if (faceId && faceList.length > 0) {
      // Find the person object by faceId, ensure the comparison is strict
      const person = faceList.find((item) => item.id == faceId);

      // Check if person exists before proceeding
      if (person) {
        setPersonDetails(person);

        // Ensure person.is_recognized is true and person.known_face_id is not null before accessing uuid
        if (person.is_recognized && person.known_face_id) {
          const faceListLastSeen = faceList.filter(
            (item) =>
              item.known_face_id &&
              item.known_face_id.uuid === person.known_face_id.uuid
          );

          setLastSeenData(faceListLastSeen.slice(0, 5));
        } else {
          const fetchListData = async () => {
            let listdata =
              await FaceRecognitionActions.getTop5MatchedUnRecognized(
                person.uuid,
                selectedFacility.value
              );
            setLastSeenData(listdata.slice(0, 5));
          };

          fetchListData();
        }
      }
    }
  }, [faceId, faceList]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeTopbarTitle("Face Recognition"));
  }, [dispatch]);

  if (!personDetails) {
    return <div>404 - Person not found</div>;
  }

  return (
    <>
      <div
        className="row  py-2"
        style={{ backgroundColor: "var(--side-bar-bg-color)" }}
      >
        <div className="col-12 d-flex justify-content-start">
          <Link to="/facerecognition" style={{ textDecoration: "none" }}>
            <IconButton>
              <ArrowCircleLeftSharpIcon color="primary" fontSize="large" />
            </IconButton>
          </Link>
          <div className="mt-2">
            <span className="event-details-top-heading">Event Details</span>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-xl-8">
            {personDetails.is_recognized ? (
              <PersonRecognized data={personDetails} />
            ) : (
              <PersonUnrecognized data={personDetails} />
            )}
          </div>
          <div className="col-12 col-xl-4 last-seen-container px-1 mx-2 mx-lg-0 pt-4 mt-3 ">
            <div className="d-flex justify-content-between align-items-center last-seen-heading-icons px-2">
              <h3>Last Seen</h3>
              {/* <IconButton
                onClick={() => setModalOpen(true)}
                sx={{ marginBottom: "10px" }}
              >
                <AddTaskIcon color="primary" fontSize="large" />
              </IconButton> */}
            </div>
            <div id="face-rec-scrollbar" className="px-2 ">
              {lastSeenData.map((item) => (
                <LastSeenCard
                  key={item.id}
                  data={item}
                  usedIn={item.is_recognized ? "recognized" : "unrecognized"}
                />
              ))}
            </div>
          </div>
        </div>
        <AllocateEditableModal
          isOpen={isModalOpen}
          handleClose={() => setModalOpen(false)}
        />
      </div>
    </>
  );
};

export default FaceRecognitionPersonDetailsPage;
