import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CheckboxAutocomplete from "../../Profile/AccessControl/CommonComponent/CheckboxAutoComplete";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import RuleBasedAlertAction from "../../../redux/Action/RuleBasedAlertActions";
import AutocompleteCompUncontrollable from "../../../components/common/FormField/AutoCompleteUncontrollable";
import AutocompleteComp from "../../../components/common/FormField/Autocomplete";
const PotentialCustomerDialog = ({ open, handleClose, isEditable, data }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    frequency: "60",
    time_difference_entry_exit: "",
    time_difference_beyond: "",
    name: "",
    prevDate: new Date().toISOString().slice(0, 10),
  });
  const [errors, setErrors] = useState({
    frequency: "",
    time_difference_entry_exit: "",
    time_difference_beyond: "",
    name: "",
  });

  const [facilityOptions, setFacilityOption] = useState([]);
  const [selectEntryCameras, setEntryCameras] = useState([]);
  const [selectExitCameras, setExitCameras] = useState([]);
  const [selectedExcludeCameras, setExcludedCameras] = useState([]);
  const [selectedFacility, setSelectedFacility] = useState([]);
  const facilitiesList = useSelector((state) => state.commonData.storeList);
  const cameraList = useSelector((state) => state.ruleBasedAlerts.cameraList);
  const [checked, setChecked] = React.useState(false);

  useEffect(() => {
    if (isEditable && data && cameraList.length > 0) {
      setEntryCameras(
        data.entry_cameras.map((item) => ({
          id: item.id,
          value: item.camera_name,
        }))
      );
      setExcludedCameras(
        data.exclude_cameras.map((item) => ({
          id: item.id,
          value: item.camera_name,
        }))
      );
      setExitCameras(
        data.exit_cameras.map((item) => ({
          id: item.id,
          value: item.camera_name,
        }))
      );
      setFormData({
        ...formData,
        time_difference_entry_exit: data.time_diff_entry_exit,
        time_difference_beyond: data.time_beyond_exit_no_detection,
        name: data.rule_name,
        frequency: data.frequency,
        prevDate: data.prev_date,
      });
      setChecked(Boolean(data.prev_date));
    }
  }, [isEditable, data, cameraList]);

  useEffect(() => {
    if (data) {
      let my_facility = facilityOptions.find(
        (item) => item.value == data.facility.id
      );
      setSelectedFacility(my_facility);
      if (my_facility)
        dispatch(RuleBasedAlertAction.getCameraByFacilities(my_facility.value));
    }
  }, [data, facilityOptions]);

  useEffect(() => {
    if (Array.isArray(facilitiesList)) {
      let facilities = facilitiesList.slice(1).map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setFacilityOption(facilities);
    }
  }, [facilitiesList]);

  const handleChangeCheckbox = (event) => {
    setChecked(event.target.checked);
  };

  const handleFacilityChange = (newValue) => {
    setSelectedFacility(newValue);
    dispatch(RuleBasedAlertAction.getCameraByFacilities(newValue.value));
  };
  const handleEntryCameraChange = (event, newValue) => {
    setEntryCameras(newValue);
  };
  const handleExitCameraChange = (event, newValue) => {
    setExitCameras(newValue);
  };
  const handleExcludeCameraChange = (event, newValue) => {
    setExcludedCameras(newValue);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  function validate() {
    const newError = {};

    // Validate selected facility
    if (selectedFacility.length === 0) {
      newError.selectedFacility = "Please select at least one facility.";
    }

    // Validate selected cameras
    if (selectEntryCameras.length === 0) {
      newError.selectedCameras = "Please select at least one camera.";
    }

    // Validate name
    if (!formData.name || formData.name.trim() === "") {
      newError.name = "Please enter a suitable name.";
    }

    if (
      !formData.time_difference_beyond ||
      formData.time_difference_beyond === ""
    ) {
      newError.time_difference_beyond = "Please enter a valid time difference.";
    } else {
      if (formData.time_difference_beyond <= 0) {
        newError.time_difference_beyond = "Please enter value greater than 0.";
      }
    }
    if (
      !formData.time_difference_entry_exit ||
      formData.time_difference_entry_exit === ""
    ) {
      newError.time_difference_entry_exit =
        "Please enter a valid time difference.";
    } else {
      if (formData.time_difference_entry_exit <= 0) {
        newError.time_difference_entry_exit =
          "Please enter value greater than 0.";
      }
    }
    // Validate frequency
    if (!formData.frequency || formData.frequency === "") {
      newError.frequency = "Please enter a valid frequency.";
    } else {
      if (newError.frequency <= 0) {
        newError.frequency = "Please enter value greater than 0.";
      }
    }

    // Set errors or reset them if there are no validation issues
    if (Object.keys(newError).length > 0) {
      setErrors(newError);
      return false;
    } else {
      setErrors({
        count_threshold: "",
        alert_event_name: "",
        action_alert_message: "",
        frequency: "",
        selectedAlertType: "",
        selectedCameras: "",
        selectedFacility: "",
        name: "",
      });
      return true;
    }
  }

  const handleSubmit = async () => {
    if (!validate()) {
      return;
    }

    const dataToSubmit = {
      entry_cameras: selectEntryCameras.map((item) => ({
        camera_name: item.value,
        id: item.id,
      })),
      exit_cameras: selectExitCameras.map((item) => ({
        camera_name: item.value,
        id: item.id,
      })),
      exclude_cameras: selectedExcludeCameras.map((item) => ({
        camera_name: item.value,
        id: item.id,
      })),
      facility: {
        facility_name: selectedFacility.label,
        id: selectedFacility.value,
      },
      time_beyond_exit_no_detection: formData.time_difference_beyond,
      time_diff_entry_exit: formData.time_difference_entry_exit,
      frequency: formData.frequency,
      rule_name: formData.name,
      is_active: true,
      prev_date: checked ? formData.prevDate : null,
    };

    if (isEditable)
      dispatch(
        RuleBasedAlertAction.updateRulesForPotential(dataToSubmit, data.id)
      );
    else dispatch(RuleBasedAlertAction.addNewRulesForPotential(dataToSubmit));
    setFormData({
      frequency: "60",
      time_difference_entry_exit: "",
      time_difference_beyond: "",
      name: "",
    });
    setChecked(false);
    setEntryCameras([]);
    setExcludedCameras([]);
    setExitCameras([]);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <DialogTitle>Potential Customer </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <div className="row">
          <div className="col-12 col-md-6 mt-2">
            <TextField
              fullWidth
              label="Name"
              name="name"
              type="text"
              value={formData.name}
              onChange={handleChange}
              error={!!(errors.name && errors.name.length > 0)}
              helperText={
                errors.name && errors.name.length > 0
                  ? errors.name
                  : "Enter Name for your convience"
              }
            />
          </div>
          <div className="col-12 col-md-6 mt-2">
            {!isEditable ? (
              <AutocompleteCompUncontrollable
                onChange={handleFacilityChange}
                label="Select Facility"
                size="large"
                option={facilityOptions}
                heading={""}
                fullWidth
                loading={false}
                error={
                  !!(errors.selectedFacility &&
                  errors.selectedFacility.length > 0
                    ? true
                    : false)
                }
                helperText={
                  errors.selectedFacility && errors.selectedFacility.length > 0
                    ? errors.selectedFacility
                    : "Select the facilities on which you want to receive Alerts"
                }
              />
            ) : (
              <AutocompleteComp
                option={facilityOptions}
                onChange={handleFacilityChange}
                value={selectedFacility}
                label="Facility"
                showValue={false}
              />
            )}
          </div>

          <div className="col-12 col-md-6 mt-2">
            <CheckboxAutocomplete
              value={selectEntryCameras}
              onChange={handleEntryCameraChange}
              label="Select Entry Cameras"
              size="large"
              options={cameraList}
              heading={""}
              fullWidth
              loading={false}
              error={
                !!(errors.selectedCameras && errors.selectedCameras.length > 0)
              }
              helperText={
                errors.selectedCameras && errors.selectedCameras.length > 0
                  ? errors.selectedCameras
                  : "Select the cameras on which you want to recieve Alerts"
              }
            />
          </div>
          <div className="col-12 col-md-6 mt-2">
            <CheckboxAutocomplete
              value={selectExitCameras}
              onChange={handleExitCameraChange}
              label="Select Exit Cameras"
              size="large"
              options={cameraList}
              heading={""}
              fullWidth
              loading={false}
              error={
                !!(errors.selectedCameras && errors.selectedCameras.length > 0)
              }
              helperText={
                errors.selectedCameras && errors.selectedCameras.length > 0
                  ? errors.selectedCameras
                  : "Select the cameras on which you want to recieve Alerts"
              }
            />
          </div>
          <div className="col-12 col-md-6 mt-2">
            <CheckboxAutocomplete
              value={selectedExcludeCameras}
              onChange={handleExcludeCameraChange}
              label="Exclude Cameras"
              size="large"
              options={cameraList}
              heading={""}
              fullWidth
              loading={false}
              error={
                !!(errors.selectedCameras && errors.selectedCameras.length > 0)
              }
              helperText={
                errors.selectedCameras && errors.selectedCameras.length > 0
                  ? errors.selectedCameras
                  : "Select the cameras on which the customer will not be detected"
              }
            />
          </div>

          <div className="col-12 col-md-6 mt-3">
            <TextField
              fullWidth
              label="Time Difference between entry and exit"
              name="time_difference_entry_exit"
              type="number"
              value={formData.time_difference_entry_exit}
              onChange={handleChange}
              error={
                !!(
                  errors.time_difference_entry_exit &&
                  errors.time_difference_entry_exit.length > 0
                )
              }
              helperText={
                errors.time_difference_entry_exit &&
                errors.time_difference_entry_exit.length > 0
                  ? errors.time_difference_entry_exit
                  : "Enter the time difference between entry and exit (In Minutes)"
              }
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              fullWidth
              label="Post Exit Not Detected Time"
              name="time_difference_beyond"
              type="number"
              value={formData.time_difference_beyond}
              onChange={handleChange}
              error={
                !!(
                  errors.time_difference_beyond &&
                  errors.time_difference_beyond.length > 0
                )
              }
              helperText={
                errors.time_difference_beyond &&
                errors.time_difference_beyond.length > 0
                  ? errors.time_difference_beyond
                  : "Enter the time beyond which person is not detected on entry or exit cam(in Minutes)"
              }
            />
          </div>

          <div className="col-12 col-md-6  mt-3">
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleChangeCheckbox}
                  name="myCheckbox"
                  color="primary"
                  size="small"
                />
              }
              label="Run this rule on previous Date"
            />
            {checked && (
              <TextField
                fullWidth
                label="Date"
                name="prevDate"
                type="date"
                size={"small"}
                value={formData.prevDate}
                onChange={handleChange}
                error={!!(errors.prevDate && errors.prevDate.length > 0)}
                helperText={
                  errors.prevDate && errors.prevDate.length > 0
                    ? errors.prevDate
                    : "Enter the date on which you want to apply this rule"
                }
              />
            )}
          </div>
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PotentialCustomerDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  data: PropTypes.any,
};

export default PotentialCustomerDialog;
