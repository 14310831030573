import React from "react";
import Chart from "react-apexcharts";

const StackedBarchart = (propTypes) => {
  const options = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
          dashArray: 2,
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    xaxis: {
      categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      labels: {
        show: true,
        style: {
          colors: "var(--card-text-color)", //change text color to var(--card-text-color)
        },
      },
    },
    yaxis: {
      title: {
        text: "",
      },
      labels: {
        show: true,
        style: {
          colors: "var(--card-text-color)", //change text color to var(--card-text-color)
        },
      },
    },
    colors: ["#7960FF", "#93E8FF"],
    legend: {
      position: "top",
      labels: {
        colors: "var(--card-text-color)",
      },
    },
  };

  const series = propTypes.data.series;

  return (
    <div style={{ overflow: "hidden" }}>
      <Chart
        options={options}
        series={series}
        type="bar"
        width="100%"
        height={352}
      />
    </div>
  );
};

export default StackedBarchart;
