import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  Alert,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

const RecordingScheduleModal = ({
  open,
  handleClose,
  data,
  selectedDay,
  cam_id,
}) => {
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [recordingType, setRecordingType] = useState("continuous");
  const [intervals, setIntervals] = useState(data[selectedDay.toLowerCase()]);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const handleAddInterval = () => {
    // Validate the new interval
    if (startTime >= endTime) {
      setError("Start time must be before end time.");
      return;
    }
    for (const interval of intervals) {
      if (
        (startTime >= interval.start_time && startTime < interval.end_time) ||
        (endTime > interval.start_time && endTime <= interval.end_time) ||
        (interval.start_time >= startTime && interval.start_time < endTime) ||
        (interval.end_time > startTime && interval.end_time <= endTime)
      ) {
        setError("New interval conflicts with an existing interval.");
        return;
      }
      if (
        startTime === interval.start_time &&
        endTime === interval.end_time &&
        recordingType === interval.type
      ) {
        setError("Duplicate interval.");
        return;
      }
    }
    const newIntervals = [
      ...intervals,
      {
        start_time: startTime,
        end_time: endTime,
        type: recordingType,
        recording: true,
      },
    ];
    newIntervals.sort((a, b) => (a.start_time > b.start_time ? 1 : -1)); // Sort intervals by start time
    setIntervals(newIntervals);
    setError("");
  };

  const handleDeleteInterval = (index) => {
    const newIntervals = [...intervals];
    newIntervals.splice(index, 1);
    setIntervals(newIntervals);
  };
  const handleSaveSchedule = () => {
    console.log(intervals);
    const newRecordingSchedule = {
      ...data,
      [selectedDay.toLowerCase()]: intervals,
    };
    dispatch({
      type: "UPDATE_DAY_WISE_RECORDING_SCHEDULE",
      payload: newRecordingSchedule,
    });
    handleClose();
  };
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle style={{ backgroundColor: "#3f51b5", color: "white" }}>
        Schedule for {selectedDay}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{ position: "absolute", right: 8, top: 8, color: "white" }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {error && (
          <Alert
            severity="error"
            style={{ marginBottom: "20px", marginTop: "20px" }}
          >
            {error}
          </Alert>
        )}
        <Grid container spacing={2} style={{ marginTop: "20px" }}>
          <Grid item>
            <TextField
              label="Start Time"
              type="time"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300,
              }}
            />
          </Grid>
          <Grid item>
            <Typography>—</Typography>
          </Grid>
          <Grid item>
            <TextField
              label="End Time"
              type="time"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
            />
          </Grid>
          <Grid item>
            <RadioGroup
              row
              value={recordingType}
              onChange={(e) => setRecordingType(e.target.value)}
            >
              <FormControlLabel
                value="continuous"
                control={<Radio />}
                label="Continuous"
              />
              <FormControlLabel
                value="motion"
                control={<Radio />}
                label="Motion Based"
              />
            </RadioGroup>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={handleAddInterval}>
              Add Interval
            </Button>
          </Grid>
        </Grid>

        <Typography variant="h6" style={{ marginTop: "20px" }} gutterBottom>
          Preview:
        </Typography>
        <div
          style={{
            border: "1px solid #ccc",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          {intervals.map((interval, index) => (
            <Grid
              container
              alignItems="center"
              spacing={2}
              key={index}
              style={{ marginBottom: "10px" }}
            >
              <Grid item>
                <Typography>Interval {index + 1}:</Typography>
              </Grid>
              <Grid item>
                <TextField
                  value={interval.start_time}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  value={interval.end_time}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item>
                <Typography>{interval.type}</Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={() => handleDeleteInterval(index)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button onClick={handleSaveSchedule} color="primary">
          Save Schedule
        </Button>
      </DialogActions>
    </Dialog>
  );
};

RecordingScheduleModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  selectedDay: PropTypes.string.isRequired,
  cam_id: PropTypes.string.isRequired,
};

export default RecordingScheduleModal;
