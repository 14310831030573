import axios from "axios";
import AlertApiSpec from "../features/AlertEndPoints";
import EventApiSpec from "../features/EventEndPoints";
import AlertEventMergedEndpoints from "../features/AlertEventMergedEndpoints";
class AlertAction {
  // top seven site alerts
  static getAlertTypeStaticsHourlyByCamera(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };

      axios
        .get(AlertApiSpec.getAlertTypeStaticsByCamera(data.id, data.date), {
          headers,
        })
        .then((response) => {
          dispatch({
            type: "ALERT_TYPE_STATICS_HOURLY",
            payload: response.data,
            date: data.date,
          });
        });
    };
  }
  static getAlertTypeStaticsHourlyByFacility(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      axios
        .get(AlertApiSpec.getAlertTypeStaticsByFacility(data.id, data.date), {
          headers,
        })
        .then((response) => {
          dispatch({
            type: "ALERT_TYPE_STATICS_HOURLY",
            payload: response.data,
            date: data.date,
          });
        });
    };
  }
  static getAlertTypeStaticsHourlyByAccount(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      axios
        .get(AlertApiSpec.getAlertTypeStaticsByAccount(data.id, data.date), {
          headers,
        })
        .then((response) => {
          dispatch({
            type: "ALERT_TYPE_STATICS_HOURLY",
            payload: response.data,
            date: data.date,
          });
        });
    };
  }
  static getTopSevenAlerts(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      axios
        .get(
          AlertApiSpec.getTopSevenSitesAlerts(
            data.id,
            data.date,
            data.priority,
            data.alertOrEvent
          ),
          {
            headers,
          }
        )
        .then((response) => {
          dispatch({
            type: "TOP_SEVEN_SITE_ALERTS",
            payload: response.data,
            date: data.date,
          });
        });
    };
  }

  // get top 10 alerts side

  /**
   * getTopTenAlertsSites function
   *
   * This function fetches the data of the top ten sites which received most alerts and events.
   * Does so based on the id, date, priority, and alertOrEvent provided.
   * If a high priority is specified, it retrieves the top ten sites with the most alerts.
   * If the priority is normal, then it fetches the top ten sites with the most events.
   * If the request results in an error or the returned data is not an array,
   * it dispatches an empty array as payload.
   *
   * @param {Object} data - Object containing id, date, priority, and alertOrEvent information.
   * @returns {Function} - Dispatches actions to the store.
   */
  static getTopTenAlertsSites(data) {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Token ${token}` };

    const fetchAlertsData = async () => {
      try {
        const response = await axios.get(
          AlertApiSpec.getTopTenAlertSites(
            data.id,
            data.date,
            data.priority,
            data.alert_type,
            data.start_time,
            data.end_time
          ),
          { headers }
        );
        return Array.isArray(response.data) ? response.data : [];
      } catch (error) {
        console.log(error);
        return [];
      }
    };

    return async (dispatch) => {
      dispatch({
        type: "TOP_TEN_SITES_ALERTS",
        payload: [],
        date: data.date,
      });
      const alertsData = await fetchAlertsData();

      // Map over the array and transform each item
      const transformedAlertsData = alertsData.map((item) => ({
        facility_name: item.facility_name,
        alert_count: item.alert_count ?? item.event_count,
      }));

      dispatch({
        type: "TOP_TEN_SITES_ALERTS",
        payload: transformedAlertsData,
        date: data.date,
      });
    };
  }
  /**
   * getTopTenAlertsSitesBySites function
   *
   * This function fetches the data of the selected sites which received most alerts and events.
   * The selection is based on the account_id, obj, date, priority, and alertOrEvent provided.
   *
   * @param {String} account_id - Account ID to filter the data.
   * @param {Object} obj - Additional filter parameters.
   * @param {String} date - Date to filter the data.
   * @param {String} priority - Priority level to filter the data.
   * @param {String} alertOrEvent - Specific alert or event type to filter the data.
   *
   * @returns {Function} - Dispatches actions to the store.
   */
  static getTopTenAlertsSitesBySites(
    account_id,
    obj,
    date,
    priority,
    alertOrEvent,
    start_time,
    end_time
  ) {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Token ${token}` };

    const fetchAlertsDataBySites = async () => {
      try {
        const response = await axios.get(
          AlertApiSpec.getTenSiteAlertsDataByStore(
            account_id,
            obj,
            date,
            priority,
            alertOrEvent,
            start_time,
            end_time
          ),
          { headers }
        );
        return Array.isArray(response.data) ? response.data : [];
      } catch (error) {
        console.log(error);
        return [];
      }
    };

    return async (dispatch) => {
      const alertsData = await fetchAlertsDataBySites();
      dispatch({
        type: "TOP_TEN_SITES_ALERTS",
        payload: alertsData,
        date: date,
      });
    };
  }

  static yumfoodsChartAnalysis(id, start_date, end_date) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };

      axios
        .get(
          AlertEventMergedEndpoints.yumfoodChartAnalysis(
            id,
            start_date,
            end_date
          ),
          {
            headers,
          }
        )
        .then((response) => {
          dispatch({
            type:
              start_date == end_date
                ? "YUM_FOODS_CHART_DATA"
                : "YUM_FOODS_CHART_DATA_DATE_RANGE",
            payload: response.data,
            date: start_date,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    };
  }
}
export default AlertAction;
