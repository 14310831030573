import React, { useState } from "react";
import { Chip, TextField } from "@mui/material";
import PropTypes from "prop-types";

const FreeSoloChipInput = ({ placeholder, label, value, setValue }) => {
  const [inputValue, setInputValue] = useState("");
  const chipColors = ["primary", "secondary", "error", "success"];
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && inputValue) {
      event.preventDefault(); // Prevent the default form submit behavior
      // Split input value by commas and trim each chip
      const chipsToAdd = inputValue
        .split(",")
        .map((chip) => chip.trim())
        .filter((chip) => chip);
      const newChips = chipsToAdd.filter((newChip) => !value.includes(newChip)); // Only add new chips
      if (newChips.length > 0) {
        setValue([...value, ...newChips]);
        setInputValue(""); // Clear the input field after adding
      }
    }
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleDelete = (chipToDelete) => () => {
    setValue((chips) => chips.filter((chip) => chip !== chipToDelete));
  };

  return (
    <>
      <TextField
        variant="outlined"
        label={label}
        placeholder={placeholder}
        value={inputValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        fullWidth
      />
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
          marginTop: "10px",
        }}
      >
        {value.map(
          (chip, index) =>
            chip !== "null" && (
              <Chip
                key={index}
                label={chip}
                onDelete={handleDelete(chip)}
                variant="outlined"
                color={chipColors[index % chipColors.length]} // Cycle through chipColors
              />
            )
        )}
      </div>
    </>
  );
};

FreeSoloChipInput.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.array.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default FreeSoloChipInput;
