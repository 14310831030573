import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import moment from "moment";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import { useEffect, useState } from "react";
const converDateInformate = (date) => {
  const dateString = date;
  const parts = dateString.split("T")[0].split("-");
  const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
  return formattedDate;
};
import TransferAlertModal from "./TransferAlertModal";
import TransferEventModal from "../../Events/EventTable/TransferEventModal";
import { restructureResponse } from "./utils";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import UndoIcon from "@mui/icons-material/Undo";
import ReactCompareImage from "react-compare-image";
import { DialogActions, Skeleton } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import AlertTableAction from "../../../redux/Action/AlertTableActions";
import SendIcon from "@mui/icons-material/Send";
let leftImages = {
  10408:
    "https://analytics-data-collection.s3.ap-south-1.amazonaws.com/pantaloons_images/captured_image_channel6.jpg",
  10413:
    "https://analytics-data-collection.s3.ap-south-1.amazonaws.com/pantaloons_images/captured_image_channel11.jpg",
  10415:
    "https://analytics-data-collection.s3.ap-south-1.amazonaws.com/pantaloons_images/captured_image_channel13.jpg",
  10416:
    "https://analytics-data-collection.s3.ap-south-1.amazonaws.com/pantaloons_images/captured_image_channel14.jpg",
  8441: "https://analytics-data-collection.s3.ap-south-1.amazonaws.com/pantaloons_images/captured_image_channel6.jpg",
  8446: "https://analytics-data-collection.s3.ap-south-1.amazonaws.com/pantaloons_images/captured_image_channel11.jpg",
  8448: "https://analytics-data-collection.s3.ap-south-1.amazonaws.com/pantaloons_images/captured_image_channel13.jpg",

  8449: "https://analytics-data-collection.s3.ap-south-1.amazonaws.com/pantaloons_images/captured_image_channel14.jpg",
};

const CarouselModel = ({
  show,
  handleClose,
  updateObjAlerts,
  updateObjEvents,
  title,
}) => {
  const dispatch = useDispatch();
  const currentPage = useSelector(
    (state) => state.AlertTableStates.currentPage
  );
  const alertCardLoading = useSelector(
    (state) => state.AlertTableStates.loadingTableData
  );
  const pageSize = useSelector((state) => state.commonData.pageSize);
  const eventArray = useSelector((state) => state.AlertData.alertTableData);
  const [activePage, setActivePage] = useState(currentPage);
  if (eventArray.leftImage == 0) {
    handleClose();
  }
  const [data, setData] = useState(restructureResponse(eventArray[0]));
  var profile = JSON.parse(localStorage.getItem("profile"));
  const [transferModal, setTransferModal] = useState(false);
  const [access_permission] = useState(
    JSON.parse(localStorage.getItem("agx_permission")).Alerts ?? []
  );
  const [currentIndex, setIndex] = useState(0);
  const handleTransferAlertModalChange = () => {
    setTransferModal(true);
  };
  function handleDataNext() {
    if (currentIndex < eventArray.length - 1) {
      let temp = eventArray[currentIndex + 1];
      setData(restructureResponse(temp));
      setIndex(currentIndex + 1);
    } else {
      dispatch(AlertTableAction.updateCurrentPage(currentPage + 1));
    }
  }

  function handleDataPrevChange() {
    if (currentIndex > 0) {
      let temp = eventArray[currentIndex - 1];
      setData(restructureResponse(temp));
      setIndex(currentIndex - 1);
    } else {
      dispatch(AlertTableAction.updateCurrentPage(currentPage - 1));
    }
  }

  useEffect(() => {
    console.log(eventArray, currentIndex, currentPage, activePage);
    if (
      currentIndex == eventArray.length - 1 &&
      currentPage == activePage + 1
    ) {
      setIndex(0);
      setData(restructureResponse(eventArray[0]));
      setActivePage(activePage + 1);
    } else if (currentIndex == 0 && currentPage == activePage - 1) {
      setIndex(pageSize - 1);
      let temp = eventArray[pageSize - 1];
      setData(restructureResponse(temp));
      setActivePage(activePage - 1);
    } else {
      setData(restructureResponse(eventArray[currentIndex]));
    }
  }, [eventArray, currentIndex]);

  async function handleAlertAction(operation, id) {
    let patchData = {};
    if (operation == "dismiss") {
      patchData.is_false = true;
    }
    if (operation == "resolve") {
      patchData.is_resolved = true;
    }
    if (operation == "revert") {
      patchData.is_false = false;
      patchData.is_resolved = false;
    }

    if (data.priority == "High") {
      await updateObjAlerts(patchData, id);
    } else {
      await updateObjEvents(patchData, id);
    }
  }
  function formatText(inputText) {
    return inputText
      .replace(/_/g, " ")
      .replace(/\b\w/g, (c) => c.toUpperCase());
  }

  const imageSrc =
    data.type === "device_offline_alert"
      ? "https://analytics-data-collection.s3.ap-south-1.amazonaws.com/alerts/device_offline_alert.png"
      : data.image;
  const isNVRAlertType = [
    "nvr_offline_alert",
    "hdd_not_recording",
    "hdd_health",
  ].includes(data.type);

  useEffect(() => {
    dispatch({ type: "UPDATE_MODEL_STATUS", payload: true });
    return () => {
      dispatch({ type: "UPDATE_MODEL_STATUS", payload: false });
    };
  }, []);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={show}
      maxWidth={"lg"}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        id="customized-dialog-title"
        className="header-background modal-title-color"
      >
        Alert
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent
        dividers
        style={{ backgroundColor: "var(--side-bar-bg-color)" }}
      >
        <div
          className="card"
          style={{
            backgroundColor: "var(--side-bar-bg-color)",
            border: "none",
          }}
        >
          <div className="row no-gutters">
            <div className="col-md-7 col-lg-8">
              {data.type !== "layout_change_alert" ? (
                <img src={imageSrc} className="card-img" alt="Alert" />
              ) : leftImages[data.camera[1]] ? (
                <div className="alert-compare-image">
                  <ReactCompareImage
                    leftImage={leftImages[data.camera[1]]}
                    rightImage={imageSrc}
                    aspectRatio="wider"
                  />
                </div>
              ) : (
                <img src={imageSrc} className="card-img" alt="Alert" />
              )}
            </div>

            <div className="col-md-5 col-lg-4">
              <div style={{ position: "absolute", right: 0 }}>
                {profile.account.config.Operation === "True" && (
                  <div
                    className="table-card-icon"
                    style={{
                      backgroundColor: "black",
                      height: 50,
                      width: 50,
                    }}
                    onClick={handleTransferAlertModalChange}
                  >
                    <SendIcon sx={{ fontSize: "25px" }} />
                  </div>
                )}
              </div>
              <div
                className="card-body d-flex flex-column justify-content-between col-10 "
                style={{ height: "100%", minWidth: "100%" }}
              >
                {data.type && <h4>{formatText(data.type)}</h4>}

                <AlertField title="Ticket Number" value={data.id} />
                <AlertField
                  title="Site"
                  value={
                    data.facility && data.facility.length > 0
                      ? formatText(data.facility[0])
                      : ""
                  }
                />
                {isNVRAlertType && data.nvr && data.nvr.name ? (
                  <AlertField
                    title="NVR/DVR"
                    value={formatText(data.nvr.name)}
                  />
                ) : (
                  data.camera &&
                  data.camera.length > 0 && (
                    <AlertField
                      title="Camera"
                      value={formatText(data.camera[0])}
                    />
                  )
                )}

                <AlertField
                  title="Message"
                  value={data.message
                    .replace(/_/g, " ")
                    .replace(/\b\w/g, (c) => c.toUpperCase())}
                />
                <AlertField
                  title="Date"
                  value={converDateInformate(data.received_at)}
                  icon={<CalendarTodayIcon />}
                />
                <AlertField
                  title="Time"
                  value={moment.utc(data.received_at).format("h:mm A")}
                  icon={<AccessTimeIcon />}
                />

                <div className="d-flex justify-content-end">
                  {title === "alert" &&
                    access_permission.includes("can_resolve") && (
                      <button
                        onClick={() => {
                          handleAlertAction("resolve", data.id);
                        }}
                        type="submit"
                        className="alert-card-reso-btn alert-card-resolve-dismiss"
                      >
                        <DoneIcon />
                        Resolve
                      </button>
                    )}
                  {title === "alert" &&
                    access_permission.includes("can_dismiss") && (
                      <button
                        onClick={() => {
                          handleAlertAction("dismiss", data.id);
                        }}
                        type="submit"
                        className="alert-card-diss-btn alert-card-resolve-dismiss"
                        style={{ marginLeft: "4px" }}
                      >
                        <CloseIcon />
                        Dismiss
                      </button>
                    )}
                  {title !== "alert" && (
                    <button
                      onClick={() => {
                        handleAlertAction("revert", data.id);
                      }}
                      type="submit"
                      className="alert-card-revert-btn alert-card-resolve-dismiss"
                    >
                      <UndoIcon />
                      Revert
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
      {transferModal &&
        (data.priority == "High" ? (
          <TransferAlertModal
            item={eventArray[currentIndex]}
            show={transferModal}
            incrementcount={updateObjAlerts}
            handleClose={() => setTransferModal(false)}
          />
        ) : (
          <TransferEventModal
            item={eventArray[currentIndex]}
            show={transferModal}
            incrementcount={updateObjEvents}
            handleClose={() => setTransferModal(false)}
          />
        ))}
      <DialogActions sx={{ justifyContent: "space-between", paddingX: "20px" }}>
        <Button
          startIcon={<ArrowBackIcon />}
          variant="contained"
          disabled={(currentPage == 1 && currentIndex == 0) || alertCardLoading}
          onClick={handleDataPrevChange}
        >
          Previos
        </Button>
        <Button
          endIcon={<ArrowForwardIcon />}
          onClick={handleDataNext}
          variant="contained"
          disabled={
            alertCardLoading ||
            (currentIndex == eventArray.length - 1 &&
              currentIndex !== pageSize - 1)
          }
        >
          Next
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AlertField = ({ title, value, icon }) => (
  <div className="alert-main-field-modal">
    {icon && <div className="alert-field-icon">{icon}</div>}
    <div className="card-title-modal">{title}: </div>
    <div className="modal-card-text-value">{value}</div>
  </div>
);
AlertField.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  icon: PropTypes.element,
};

CarouselModel.propTypes = {
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  incrementcount: PropTypes.func.isRequired,
  updateObjAlerts: PropTypes.func.isRequired,
  updateObjEvents: PropTypes.func.isRequired,
};

export default CarouselModel;
