import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import { useState } from "react";
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditNvrModal from "../ConfigurableRhms/EditNvrModal";
import { useEffect } from "react";
import PropTypes from "prop-types";
import Row from "./RemoteMonitoringRow";
const GetAllRow = ({ dataList }) => {
  const [Data, setData] = useState([]);
  useEffect(() => {
    setData(dataList);
  }, [dataList]);

  return Data.map((row, index) => (
    <Row key={`row-${row.nvr_id}`} index={index} row={row} />
  ));
};

// Define the prop type validation
GetAllRow.propTypes = {
  dataList: PropTypes.array.isRequired, // Ensure dataList is an array and is required
};

const RemoteMonitoringTable = ({ loading }) => {
  const rhmsData = useSelector((state) => state.rhmsData.rhmsData);
  const [showModal, setShowModal] = useState(false);
  const profile = JSON.parse(localStorage.getItem("profile"));
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const [access_permission] = useState(
    JSON.parse(localStorage.getItem("agx_permission")).Remote_monitoring ?? []
  );
  const handleOpenModal = () => {
    setShowModal(true);
  };

  return (
    <>
      <EditNvrModal
        addNew={true}
        open={showModal}
        id={-1}
        handleClose={handleCloseModal}
      />
      <TableContainer component={Paper} className="mt-4 ">
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell>NVR/DVR</TableCell>
              <TableCell align="center">NVR ONLINE</TableCell>
              <TableCell align="center">HDD HEALTH</TableCell>
              <TableCell align="center">HDD RECORDING</TableCell>
              <TableCell align="center">
                {profile.services_selected.Dashboard.includes(
                  "configurable_rhms"
                ) &&
                  access_permission.includes("add_nvr_dvr") && (
                    <Button
                      variant="contained"
                      startIcon={<AddCircleOutlineIcon />}
                      sx={{
                        backgroundColor: "#8A9AFF",
                        color: "#fff",
                        borderRadius: "20px",
                        paddingTop: "2px",
                        paddingBottom: "2px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        fontSize: "14px",
                        marginLeft: "20px",
                      }}
                      onClick={handleOpenModal}
                    >
                      Add NVR
                    </Button>
                  )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ backgroundColor: "var(--side-bar-bg-color)" }}>
            <>
              {loading ? (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="center"
                    style={{ color: "var(--card-text-color)" }}
                  >
                    Loading Data ...
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {rhmsData.length === 0 ? (
                    <TableRow>
                      <TableCell
                        colSpan={6}
                        align="center"
                        style={{ color: "var(--card-text-color)" }}
                      >
                        No data available
                      </TableCell>
                    </TableRow>
                  ) : (
                    <GetAllRow dataList={rhmsData} />
                  )}
                </>
              )}
            </>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
RemoteMonitoringTable.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default RemoteMonitoringTable;
