import React, { useEffect } from "react";
import HeatmapChart from "../../../components/charts/HeatMapChart";
import {
  getHeatmapDataByNvrNetworkUptime,
  removeHeatMapData,
} from "../../../redux/Action/RhmsAction";
import { useDispatch, useSelector } from "react-redux";
const DeviceUptime = (propTypes) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.rhmsData);
  useEffect(() => {
    dispatch(getHeatmapDataByNvrNetworkUptime(propTypes.nvr_id));
    return () => {
      dispatch(removeHeatMapData());
    };
  }, []);
  return (
    <div>
      <HeatmapChart data={state.heatmapData} title={"Network Uptime"} />
    </div>
  );
};

export default DeviceUptime;
