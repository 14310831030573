import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import FaceRecognitionActions from "../../../redux/Action/FaceRegnitionAction";
import Autocomplete from "@mui/material/Autocomplete";
import MenuItem from "@mui/material/MenuItem";
import {
  notifiToasterSuccess,
  notifiToasterWarn,
} from "../../../components/common/UseToast";
import { CircularProgress } from "@mui/material";
import ImageUpload from "../KnownFaces/UploadImageDiv";
import FreeSoloChipInput from "../../../components/common/formLayout/AutocompleteFreeSolo";
import DeleteKnownFace from "../KnownFaces/DeleteKnownFace";

function generateUUID() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}
const genders = [
  {
    value: "Male",
    label: "Male",
  },
  {
    value: "Female",
    label: "Female",
  },
];

const AddDetailModal = ({ isOpen, handleClose, isEditable, faceData }) => {
  const [formData, setFormData] = useState({
    name: "",
    department: "",
    gender: "",
    age: "",
    teamId: "",
    id: "",
  });
  const [prevData, setPrevData] = useState(null);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isImageChanged, setIsImageChanged] = useState(false);
  const [newFacilityOptions, setNewFacilityOptions] = useState([]);
  const [choosedFacility, setChooseFacility] = useState(null);

  var facilityOptions = useSelector((state) => state.commonData.storeList);
  useEffect(() => {
    if (isEditable && faceData) {
      setPrevData(faceData);
      setFormData({
        ...formData,
        name: faceData.name,
        gender: faceData.gender,
        age: faceData.age,
        department: faceData.remarks,
      });
      setSelectedImage(faceData.image);
      if (faceData?.others?.[0] && typeof faceData.others[0] === "string") {
        setTags(faceData.others[0].split(","));
      } else {
        // Set to empty array if there is no valid data
        setTags([]);
      }
    }
  }, [faceData, isEditable]);
  useEffect(() => {
    const options = facilityOptions
      .filter((item) => item.text !== "All")
      .map((item) => ({
        label: item.text,
        value: item.value,
      }));

    setNewFacilityOptions(options);
    if (isEditable && faceData.facility_id) {
      const selFacility = options.find(
        (item) => item.value === faceData.facility_id
      );
      setChooseFacility(selFacility);
    }
  }, [facilityOptions, faceData]);
  function getKnownFacesData() {
    let userprofileid = JSON.parse(localStorage.getItem("profile")).id;
    dispatch(FaceRecognitionActions.getListOfKnownFaces(userprofileid, 1, 20));
  }
  const [selectedImage, setSelectedImage] = useState(null);
  const dispatch = useDispatch();

  const handleSave = async () => {
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = "Name is required";
    }

    if (!formData.department) {
      newErrors.department = "Please write a remark for the image";
    }

    if (!formData.gender) {
      newErrors.gender = "Gender is required";
    }

    if (!formData.age) {
      newErrors.age = "Age is required";
    } else if (formData.age <= 0) {
      newErrors.age = "Please Enter Valid Age";
    }

    if (!selectedImage) {
      newErrors.file = "File is required";
      notifiToasterWarn("Please select a file to upload.");
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const newformData = new FormData();

      if (isImageChanged && isEditable) {
        newformData.append("image", selectedImage);
      } else if (!isEditable) {
        newformData.append("file", selectedImage);
        newformData.append("uuid_str", generateUUID());
      }

      newformData.append("name", formData.name);
      newformData.append("age", formData.age);
      newformData.append("gender", formData.gender);
      newformData.append("facility_id", choosedFacility.value);
      newformData.append("description", formData.department);
      newformData.append("remarks", formData.department);

      newformData.append("others", tags.length === 0 ? ["null"] : tags);

      setLoading(true);
      await dispatch(
        isEditable
          ? FaceRecognitionActions.modifyFaceDetails(newformData, faceData.uuid)
          : FaceRecognitionActions.addFaceDetails(newformData)
      );
      setLoading(false);
      setFormData({
        name: "",
        department: "",
        gender: "",
        age: "",
        teamId: "",
        id: "",
      });
      setSelectedImage(null);
      handleClose();
      getKnownFacesData();
    } catch (error) {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleFacilityChange = (e, value) => {
    if (value) {
      setChooseFacility(value);
    }
  };

  const isOptionEqualToValue = (option, value) => {
    return option.value === value?.value;
  };

  return (
    <div className="col-12 col-sm-12 col-md-6 col-lg-4">
      <Dialog
        open={isOpen}
        onClose={handleClose}
        maxWidth="md"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "md",
          },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {isEditable ? "Edit Details" : " Add Details"}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "theme.palette.grey[500]",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="mt-4" sx={{ overflow: "hidden" }}>
          <ImageUpload
            image={selectedImage}
            changeImage={setSelectedImage}
            setIsImageChanged={() => setIsImageChanged(true)}
          />
          <div className="row">
            <div className="col-12 col-md-6">
              <TextField
                fullWidth
                id="outlined-basic"
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                variant="outlined"
                size="large"
                margin="normal"
                error={!!errors.name}
                helperText={errors.name}
              />
            </div>
            <div className="col-12 col-md-6 mt-3">
              {!isEditable ? (
                <Autocomplete
                  className="col"
                  id="combo-box-demo"
                  options={newFacilityOptions}
                  isOptionEqualToValue={isOptionEqualToValue}
                  onChange={handleFacilityChange}
                  getOptionLabel={(option) => option.label}
                  // error={!!errors.facility}
                  // helperText={errors.facility}
                  renderInput={(params) => (
                    <TextField {...params} label="Facility" />
                  )}
                />
              ) : (
                choosedFacility && (
                  <Autocomplete
                    className="col"
                    id="combo-box-demo"
                    value={choosedFacility}
                    options={newFacilityOptions}
                    isOptionEqualToValue={isOptionEqualToValue}
                    onChange={handleFacilityChange}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField {...params} label="Facility" />
                    )}
                  />
                )
              )}
            </div>
          </div>
          {/* Age and Gender */}
          <div className="row">
            <div className="col-12 col-md-6">
              <TextField
                id="outlined-basic"
                select
                label="Gender"
                name="gender"
                value={formData.gender}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                margin="normal"
                error={!!errors.gender}
                helperText={errors.gender}
              >
                {genders.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            <div className="col-12 col-md-6">
              <TextField
                id="outlined-basic"
                label="Age"
                name="age"
                type="number"
                value={formData.age}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                size="large"
                margin="normal"
                error={!!errors.age}
                helperText={errors.age}
              />
            </div>
          </div>
          {/* Team Id and Id */}
          <div className="row">
            <div className="col-12 col-md-6">
              <TextField
                id="outlined-basic"
                label="Remarks"
                name="department"
                value={formData.department}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                size="large"
                margin="normal"
                error={!!errors.department}
                helperText={errors.department}
              />
            </div>
            <div className="col-12 col-md-6 mt-3">
              <FreeSoloChipInput
                placeholder="Type and press enter..."
                label="Assign Tag"
                value={tags}
                setValue={setTags}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions
          className={isEditable ? "d-flex justify-content-between" : null}
        >
          {isEditable && (
            <DeleteKnownFace userId={faceData.uuid} handleClose={handleClose} />
          )}
          <Button
            onClick={handleSave}
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {loading ? (
              <>
                <CircularProgress size={24} color="inherit" />
                {"      Uploading...."}
              </>
            ) : (
              "Save"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

AddDetailModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
  faceData: PropTypes.any,
};

export default AddDetailModal;
