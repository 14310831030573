import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";

const initialFacilityState = {
  facility_name: "",
  address: "",
  phone_number: "",
  store_manager_name: "",
  service_on: [1],
};

const FacilityDialog = ({ open, handleClose, handleSave, facilityData }) => {
  const [facility, setFacility] = useState(initialFacilityState);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (facilityData) {
      setFacility(facilityData);
    } else {
      setFacility(initialFacilityState);
    }
  }, [facilityData]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFacility({ ...facility, [name]: value });

    // Clear error when user starts typing
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
  };

  const validate = () => {
    let tempErrors = {};
    if (!facility.facility_name) {
      tempErrors.facility_name = "Facility name is required";
    }
    if (!facility.address) {
      tempErrors.address = "Address is required";
    }
    setErrors(tempErrors);

    // Check if there are no errors
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validate()) {
      let account_id = JSON.parse(localStorage.getItem("profile")).account
        .account_id;
      facility.account = account_id;
      handleSave(facility);
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "sm",
        },
      }}
    >
      <DialogTitle>
        {facilityData ? "Edit Facility" : "Add Facility"}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "theme.palette.grey[500]",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className="mt-3">
          <TextField
            margin="dense"
            label="Facility Name"
            name="facility_name"
            value={facility.facility_name}
            onChange={handleChange}
            fullWidth
            error={Boolean(errors.facility_name)}
            helperText={errors.facility_name}
          />
        </div>
        <div className="mt-3">
          <TextField
            margin="dense"
            label="Address"
            name="address"
            value={facility.address}
            onChange={handleChange}
            fullWidth
            error={Boolean(errors.address)}
            helperText={errors.address}
          />
        </div>
        <div className="mt-3">
          <TextField
            margin="dense"
            label="Phone Number"
            name="phone_number"
            value={facility.phone_number}
            onChange={handleChange}
            fullWidth
          />
        </div>
        <div className="mt-3">
          <TextField
            margin="dense"
            label="Facility Manager Name"
            name="store_manager_name"
            value={facility.store_manager_name}
            onChange={handleChange}
            fullWidth
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

FacilityDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  facilityData: PropTypes.any,
  handleSave: PropTypes.func.isRequired,
};

export default FacilityDialog;
