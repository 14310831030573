import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AlertsCard from "../../Alert/AlertsCard";
import { CountingIcon } from "../../../assets/icons/CountingIcon";
import { capitalizeFirstLetter } from "../../Alert/utils/Functions";
const iconFont = {
  fontSize: "50px",
  color: "#4318FF",
};

const PizzaCountCard = () => {
  const [profile, setProfile] = useState(null);
  const pizzaCount = useSelector(
    (state) => state.AlertDashboardStates.pizzaCount.count
  );

  useEffect(() => {
    const storedProfile = JSON.parse(localStorage.getItem("profile") || "{}");
    setProfile(storedProfile);
  }, []);

  if (!profile || !profile.account || !profile.account.config) {
    return null; // Return null if profile or nested properties don't exist
  }

  const {
    account: { config },
  } = profile;

  return config["count"] ? (
    <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-2">
      <AlertsCard
        icon={<CountingIcon style={iconFont} />}
        headText={pizzaCount}
        paraText={`${capitalizeFirstLetter(config["count"])} Count`}
      />
    </div>
  ) : null;
};

export default PizzaCountCard;
