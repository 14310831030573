import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ChatInterface from "./ChatInterface";
import ChatBotHomePage from "./ChatBotHomePage";
import { changeTopbarTitle } from "../../redux/Action/ChangeTopbarTitle";
import "./../chat/chat.css";

const ChatBot = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [firstText, setFirstText] = useState(true);
  const [messages, setMessages] = useState(() => {
    try {
      return JSON.parse(sessionStorage.getItem("agx_chat_bot_chat")) ?? [];
    } catch (error) {
      console.error("Error reading from sessionStorage", error);
      return [];
    }
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let profile = null;
    try {
      profile = JSON.parse(localStorage.getItem("profile"));
    } catch (error) {
      console.error("Error reading profile from localStorage", error);
    }

    if (!profile || profile.chat !== true) {
      navigate("/unauthorized");
      return;
    } else {
      dispatch(changeTopbarTitle("Chat"));
    }

    return () => {
      try {
        sessionStorage.setItem("agx_chat_bot_chat", JSON.stringify(messages));
      } catch (error) {
        console.error("Error writing to sessionStorage", error);
      }
    };
  }, []);
  useEffect(() => {
    setFirstText(messages.length === 0);
  }, [messages]);
  function handleClearChat() {
    sessionStorage.removeItem("agx_chat_bot_chat");
    setMessages([]);
  }
  function handleInputChange(val) {
    setMessages((currentMessages) => {
      if (!Array.isArray(currentMessages)) {
        console.error(
          "Expected currentMessages to be an array, but got:",
          currentMessages
        );
        return [];
      }
      return [...currentMessages, val];
    });

    setLoading(true);
    setTimeout(() => setLoading(false), 5000);
  }

  return (
    <div className="container-fluid">
      <div className="col-12">
        {firstText ? (
          <ChatBotHomePage
            handleInputChange={handleInputChange}
            loading={loading}
          />
        ) : (
          <ChatInterface
            messages={messages}
            loading={loading}
            handleInputChange={handleInputChange}
            handleClearChat={handleClearChat}
          />
        )}
      </div>
    </div>
  );
};

export default ChatBot;
