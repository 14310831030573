import moment from "moment";
import FaceRecognitionActions from "../../../redux/Action/FaceRegnitionAction";
export const fetchFaceRecognitionDataWithParam = (
  mode,
  date,
  facility,
  camera,
  dateRange,
  currentTab,
  pageNumber,
  pageSize,
  dispatch
) => {
  const singleDate = moment(date);
  const formattedDate = singleDate.format("DD-MM-YYYY");
  const datestart = moment(dateRange.startDate);
  const startDate = datestart.format("DD-MM-YYYY");
  const dateEnd = moment(dateRange.endDate);
  const endDate = dateEnd.format("DD-MM-YYYY");

  const face_rec_params = {
    page: pageNumber,
    type_c: currentTab,
  };
  if (facility.value !== "all") {
    face_rec_params.facility_id = facility.value;
  }
  if (camera.value !== "all") {
    face_rec_params.camera_id = camera.value;
  }

  if (mode.value == "day-wise") {
    face_rec_params.start_date = formattedDate;
    face_rec_params.end_date = formattedDate;
  } else {
    face_rec_params.start_date = startDate;
    face_rec_params.end_date = endDate;
  }

  dispatch(FaceRecognitionActions.getFaceRecognitionDataTable(face_rec_params));
};
