import moment from "moment";
import AnprActions from "../../../redux/Action/AnprActions";
export const fetchAnprDataWithParam = (
  mode,
  date,
  facility,
  camera,
  dateRange,
  pageNumber,
  pageSize = 10,
  dispatch
) => {
  const singleDate = moment(date);
  const formattedDate = singleDate.format("DD-MM-YYYY");
  const datestart = moment(dateRange.startDate);
  const startDate = datestart.format("DD-MM-YYYY");
  const dateEnd = moment(dateRange.endDate);
  const endDate = dateEnd.format("DD-MM-YYYY");
  let profile_id = JSON.parse(localStorage.getItem("profile")).id;
  const anpr_params = {
    page: pageNumber,
    user_profile_id: profile_id,
  };
  if (facility.value !== "all") {
    anpr_params.facility_id = facility.value;
  }
  if (camera.value !== "all") {
    anpr_params.camera_id = camera.value;
  }

  if (mode.value == "day-wise") {
    anpr_params.start_date = formattedDate;
    anpr_params.end_date = formattedDate;
  } else {
    anpr_params.start_date = startDate;
    anpr_params.end_date = endDate;
  }

  dispatch(AnprActions.getANPRData(anpr_params));
};
