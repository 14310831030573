import React from "react";
import Chart from "react-apexcharts";

const DashPieChart = (propTypes) => {
  const options = {
    series: propTypes.data,
    colors: [
      "#C4C1E4",
      "#4339F2",
      "#F64E60",
      "#1BC5BD",
      "#FFA800",
      "#E5EAEE",
      "#ACC3FF",
    ],
    labels: propTypes.labels,
    legend: {
      position: "top",
      labels: {
        colors: "var(--card-text-color)",
      },
      horizontalAlign: "center",
      offsetY: 10,
      itemMargin: {
        horizontal: 10,
        vertical: 5,
      },
    },
    plotOptions: {
      pie: {
        offsetY: 30,
      },
    },
  };

  return (
    <div className={propTypes.classNameActive ? "chart-container" : null}>
      <div className={propTypes.classNameActive ? "chart-div" : null}>
        <div className="chart-div">
          <p
            className="chart-title"
            style={{ textAlign: "left", marginLeft: "20px" }}
          >
            {propTypes.title}
          </p>
          <div style={{ marginTop: "10px" }}>
            <Chart
              className="charty"
              options={options}
              series={options.series}
              type="pie"
              height={400}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashPieChart;
