import { React, useState } from "react";
import Chart from "react-apexcharts";
import moment from "moment";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 15,
  },
}));
export const DashWeekBarChart = (propTypes) => {
  const [dataLabelsEnabled, setDataLabelsEnabled] = useState(false);
  const [dataPointTooltipText, setDataPointTooltipText] =
    useState("Show data points");
  let chartOptions = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    labels: {
      show: false,
      formatter: function (val) {
        return val + "%";
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: "top",
        },
        columnWidth: "40%",
      },
    },
    dataLabels: {
      enabled: dataLabelsEnabled,
      offsetY: -15,
      style: {
        fontSize: "14px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: "bold",
        colors: undefined,
      },
      background: {
        enabled: true,
        padding: 5,
        borderWidth: 1,
        borderRadius: 0,
        borderColor: "#fff",
      },
    },
    colors:
      propTypes.data.series.length == 1
        ? ["#4339F2"]
        : ["#C4C1E4", "#4339F2", "#1BC5BD"],
    legend: {
      position: "top",
      labels: {
        colors: "var(--card-text-color)",
      },
      markers: {
        radius: 12,
      },
    },
    yaxis: {
      title: {
        text: dataLabelsEnabled ? propTypes.xlabel : "",
        style: {
          fontSize: "16px", // Set the font size
          fontFamily: "Roboto", // Set the font family
          fontWeight: "bold", // Set the font weight
          color: "var(--card-text-color)", // Set the font color
        },
      },

      labels: {
        style: {
          colors: "var(--card-text-color)", //change text color to var(--card-text-color)
        },
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        left: 20,
        right: 40,
      },
    },
    xaxis: {
      type: "category",
      categories: propTypes.data.date,
      labels: {
        formatter: (val) => {
          if (propTypes.xlabel === "date") {
            return moment(new Date(val)).format("DD-MM-YYYY");
          } else if (propTypes.xlabel === "string" && typeof val === "string") {
            const [dt1, dt2] = val.split(",");
            return `${moment(new Date(dt2)).format("ddd")} - ${moment(
              new Date(dt1)
            ).format("ddd")}`;
          } else if (propTypes.xlabel === "age" && typeof val === "string") {
            return `${val} y/o`;
          } else {
            return val;
          }
        },
        style: {
          colors: "var(--card-text-color)", //change text color to var(--card-text-color)
        },
      },
      title: {
        text: dataLabelsEnabled
          ? propTypes.xlabel === "date"
            ? "Date"
            : propTypes.xlabel === "age"
            ? "Age Group"
            : propTypes.xlabel === "string"
            ? "Time Period"
            : propTypes.xlabel === "facility"
            ? "Facility"
            : ""
          : "",
        style: {
          fontSize: "16px", // Set the font size
          fontFamily: "Roboto", // Set the font family
          fontWeight: "bold", // Set the font weight
          color: "var(--card-text-color)", // Set the font color
        },
      },
    },

    tooltip: {
      shared: true,
      intersect: false,
      x: {
        formatter: (val) => {
          if (propTypes.xlabel === "date") {
            return moment(new Date(val)).format("DD-MM-YYYY, dddd");
          } else if (propTypes.xlabel === "string" && typeof val === "string") {
            const [dt1, dt2] = val.split(",");
            return `${moment(new Date(dt2)).format(
              "Do MMMM YY (ddd)"
            )} - ${moment(new Date(dt1)).format("Do MMMM YY (ddd)")}`;
          } else if (propTypes.xlabel === "age" && typeof val === "string") {
            return `${val} years old`;
          } else {
            return val;
          }
        },
        style: {
          colors: "var(--card-text-color)",
        },
      },
    },
  };
  const dataPointsToggle = (ev) => {
    const check = ev.currentTarget.checked;
    setDataLabelsEnabled(check);
    setDataPointTooltipText(check ? "Hide data points" : "Show data points");
  };
  return (
    <div>
      <div className={propTypes.classNameActive ? "chart-container" : null}>
        <div className={propTypes.classNameActive ? "chart-div" : null}>
          <div className="chart-div">
            <p
              className="chart-title"
              style={{ textAlign: "left", marginLeft: "20px" }}
            >
              {propTypes.title}
            </p>
            <div
              className="chart-title"
              style={{ textAlign: "right", marginLeft: "20px" }}
            >
              <span className="d-block text-dark font-weight-bolder">
                <label className="checkbox checkbox-lg checkbox-single flex-shrink-0 m-0 mx-4 checkbox-light-secondary">
                  <LightTooltip placement="top" title={dataPointTooltipText}>
                    <input
                      className="chart-checkbox"
                      type="checkbox"
                      onChange={dataPointsToggle}
                    />
                  </LightTooltip>

                  <span className="checkbox-icon"></span>
                </label>
              </span>
            </div>
            <div style={{ overflow: "hidden" }}>
              <Chart
                options={chartOptions}
                series={propTypes.data.series}
                type="bar"
                height={400}
                style={{ margin: "0 auto" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
