import moment from "moment";
import * as DateFns from "date-fns";
const initialState = {
  ageHourly: [],
  ageDistribution: {},
  dwellTimeHourly: [],
};

export default function AgeDistReducer(state = initialState, action) {
  switch (action.type) {
    case "PEOPLE_COUNT_AGE_HOURLY_DATA":
      return {
        ...state,
        ageHourly: action.payload.hourly.map((datapoint) => {
          let dater = DateFns.setHours(
            new Date(action.date),
            new Date(datapoint.recorded_at__hour)
          );
          datapoint.date = moment(dater)
            .subtract(30, "minutes")
            .toLocaleString();
          delete datapoint.recorded_at__hour;
          return datapoint;
        }),
        ageDistribution: action.payload.age_totals,
      };
    case "NICOBAR_DWEEL_TIME":
      return {
        ...state,
        dwellTimeHourly: action.payload.map((datapoint) => {
          let dater = DateFns.setHours(
            new Date(action.date),
            new Date(datapoint.recorded_at_hour)
          );
          datapoint.date = moment(dater)
            .subtract(30, "minutes")
            .toLocaleString();
          delete datapoint.recorded_at_hour;
          return datapoint;
        }),
      };
    default:
      return state;
  }
}
