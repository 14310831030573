import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import DashboardCard from "../DashboardCard";
import SentimentSatisfiedOutlinedIcon from "@mui/icons-material/SentimentSatisfiedOutlined";

const iconFont = {
  fontSize: "50px",
  color: "#4318FF",
};

const PotentailCustomerCard = ({ selectedMode }) => {
  const profile = JSON.parse(localStorage.getItem("profile") || "{}") || {};
  const potentailCustomerLoading = useSelector(
    (state) => state.loading.potentailCustomerLoading
  );
  const event_data =
    useSelector((state) => state.AlertData.tableAlertCount) || "";

  return (
    <>
      {profile.services_selected?.Event?.includes("potential_customer") && (
        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-2">
          <DashboardCard
            icon={<SentimentSatisfiedOutlinedIcon style={iconFont} />}
            headText={event_data}
            paraText="Potential Customer"
            isLoading={potentailCustomerLoading}
          />
        </div>
      )}
    </>
  );
};

PotentailCustomerCard.propTypes = {
  selectedMode: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }).isRequired,
};

export default PotentailCustomerCard;
