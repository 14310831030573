const initialState = {
  facilityList: [],
  playingCameraList: [],
  playlist: [],
  selectedCameraList: [],
  currentPage: 1,
  selectedGrid: 4,
  tempCurrentPage: 1,
  selectedQuality: "sub_stream",
  activeView: "liveview",
  playbackCamera: [],
  showDialog: false,
  playbackFeed: [],
  feed_available: [],
  time: [0, 23.99],
  timeRange: [0, 23.59],
  activePlaybackMethod: "hdd",
  playbackSelectedDate: new Date().toISOString(),
  recordingSchedule: {
    sunday: [],
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
  },
  timeline: [],
  playbackSelectedTime: "00:00",
};

export default function VmsReducer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_FACILITY_NVR_CAMERA_LIST_FOR_VMS":
      return {
        ...state,
        facilityList: action.payload,
      };
    case "UPDATE_LIVEVIEW_FEATURE_VMS":
      return {
        ...state,
        activeView: action.payload,
      };
    case "ADD_CAMERA_TO_SELECTED_CAMERA_LIST":
      return {
        ...state,
        selectedCameraList: [...state.selectedCameraList, ...action.payload],
      };
    case "REPLACE_CAMERAS_TO_SELECTED_CAMERA_LIST":
      return {
        ...state,
        selectedCameraList: action.payload,
      };
    case "VMS_LIVE_FEEDS":
      return {
        ...state,
        playlist: [...state.playlist, ...action.payload],
      };
    case "REMOVE_SELECTED_LIVE_FEEDS_VMS":
      return {
        ...state,
        playlist: state.playlist.filter(
          (camera) => !action.payload.includes(camera.cam_id)
        ),
      };
    case "REPLACE_PLAYLIST_WITH_NEW_PLAYLIST":
      return {
        ...state,
        playlist: action.payload,
      };
    case "UPDATE_CURRENT_PAGE_VMS":
      return {
        ...state,
        currentPage: action.payload,
      };
    case "UPDATE_TEMP_CURRENT_PAGE_VMS":
      return {
        ...state,
        tempCurrentPage: action.payload,
      };
    case "UPDATE_SELECTED_GRID_VMS":
      return {
        ...state,
        selectedGrid: action.payload,
      };
    case "UPDATE_SELECTED_QUALITY_VMS":
      return {
        ...state,
        selectedQuality: action.payload,
      };

    case "ADD_PLAYBACK_CAMERAS_IN_VMS":
      return {
        ...state,
        playbackCamera: [...state.playbackCamera, action.payload],
      };
    case "REMOVE_SELECTED_CAMERA_FROM_PLAYBACK_LIST":
      return {
        ...state,
        playbackCamera: state.playbackCamera.filter(
          (item) => item !== action.payload
        ),
      };
    case "SHOW_DIALOG_TO_CHNAGE_THE_CAMERA":
      return {
        ...state,
        showDialog: action.payload,
      };
    case "UPDATE_PLAYBACK_STREAM_VMS":
      return {
        ...state,
        playbackFeed: action.payload,
      };
    case "UPDATE_PLAYBACK_CHUNKS_AVAILABLE":
      return {
        ...state,
        feed_available: action.payload,
      };
    case "UPDATE_TIME_RANGE_FOR_PLAYBACK":
      return {
        ...state,
        timeRange: action.payload,
      };
    case "UPDATE_TIME_FOR_PLAYBACK":
      return {
        ...state,
        time: action.payload,
      };
    case "REMOVE_PLAYBACK_STREAM_FOR_VMS":
      return {
        ...state,
        playbackFeed: [],
      };
    case "UPDATE_DAY_WISE_RECORDING_SCHEDULE": {
      return {
        ...state,
        recordingSchedule: action.payload,
      };
    }
    case "UPDATE_TIME_LINE_FOR_RECORDING":
      return {
        ...state,
        timeline: action.payload,
      };
    case "UPDATE_PLAYBACK_SELECTED_TIME":
      return {
        ...state,
        playbackSelectedTime: action.payload,
      };
    case "UPDATE_ACTIVE_PLAYBACK_METHOD":
      return {
        ...state,
        activePlaybackMethod: action.payload,
      };
    case "UPDATE_PLAYBACK_SELECTED_DATE":
      return {
        ...state,
        playbackSelectedDate: action.payload,
      };
    default:
      return state;
  }
}
