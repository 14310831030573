class DemographicsFunction {
  static returnLineChartCount(data) {
    return data.map((data) => ({
      x: data.date,
      y: data.count ?? data.people_count_in,
    }));
  }
  static returnNicobarDweelTime(data) {
    return data.map((data) => ({
      x: data.date,
      y: data.average_dwell_time ?? 0,
    }));
  }

  static dwellHourlyData(data) {
    if (data.length > 0) {
      return [
        {
          name: "Avg Dwell Time",
          data: DemographicsFunction.returnNicobarDweelTime(data),
        },
      ];
    }
    return [
      {
        name: "Avg Dwell Time",
        data: [],
      },
    ];
  }

  static weeklyDwellTime(weeklylessthan2, weeklymorethan2, weeklymorethan10) {
    if (
      weeklylessthan2.length > 0 ||
      weeklymorethan2.length > 0 ||
      weeklymorethan10.length > 0
    ) {
      return [
        {
          name: "<2 Mins",
          data: DemographicsFunction.returnLineChartCount(weeklylessthan2),
        },
        {
          name: ">2 Mins & <10 Mins",
          data: DemographicsFunction.returnLineChartCount(weeklymorethan2),
        },
        {
          name: ">10 Mins",
          data: DemographicsFunction.returnLineChartCount(weeklymorethan10),
        },
      ];
    }
    return [
      {
        name: "<2 Mins",
        data: [],
      },
      {
        name: ">2 Mins & <10 Mins",
        data: [],
      },
      {
        name: ">10 Mins",
        data: [],
      },
    ];
  }

  static returnMaleGraphData(data) {
    return data.map((data) => ({
      x: data.date,
      y: data.male_count,
    }));
  }
  static returnFemaleGraphData(data) {
    return data.map((data) => ({
      x: data.date,
      y: data.female_count,
    }));
  }

  static demographicsHourlyData(totalMaleFemalHourly) {
    if (totalMaleFemalHourly.length > 0) {
      return [
        {
          name: "Male",
          data: DemographicsFunction.returnMaleGraphData(totalMaleFemalHourly),
        },
        {
          name: "Female",
          data: DemographicsFunction.returnFemaleGraphData(
            totalMaleFemalHourly
          ),
        },
      ];
    }
    return [
      {
        name: "Male",
        data: [],
      },
      {
        name: "Female",
        data: [],
      },
    ];
  }

  static demographicsWeeklyData(maleFemaleData) {
    if (maleFemaleData.length > 0) {
      return [
        {
          name: "Male",
          data: DemographicsFunction.returnMaleGraphData(maleFemaleData),
        },
        {
          name: "Female",
          data: DemographicsFunction.returnFemaleGraphData(maleFemaleData),
        },
      ];
    }
    return [
      {
        name: "Male",
        data: [],
      },
      {
        name: "Female",
        data: [],
      },
    ];
  }
  static maleFemaleHourlyGraphData(peopleCountGenderHourly) {
    if (peopleCountGenderHourly.length > 0) {
      return [
        {
          name: "Male",
          data: DemographicsFunction.returnMaleGraphData(
            peopleCountGenderHourly
          ),
        },
        {
          name: "Female",
          data: DemographicsFunction.returnFemaleGraphData(
            peopleCountGenderHourly
          ),
        },
      ];
    }
    return [
      {
        name: "Male",
        data: [],
      },
      {
        name: "Female",
        data: [],
      },
    ];
  }

  static maleFemaleDayGraphData(maleFemaleDayData) {
    if (maleFemaleDayData.length > 0) {
      return [
        {
          name: "Male",
          data: DemographicsFunction.returnMaleGraphData(maleFemaleDayData),
        },
        {
          name: "Female",
          data: DemographicsFunction.returnFemaleGraphData(maleFemaleDayData),
        },
      ];
    }
    return [
      {
        name: "Male",
        data: [],
      },
      {
        name: "Female",
        data: [],
      },
    ];
  }
  static returnLineChartAgeCount12(data) {
    return data.map((data) => ({
      x: data.date,
      y: data.age_0_12,
    }));
  }
  static returnLineChartAgeCount18(data) {
    return data.map((data) => ({
      x: data.date,
      y: data.age_13_18,
    }));
  }
  static returnLineChartAgeCount25(data) {
    return data.map((data) => ({
      x: data.date,
      y: data.age_19_25,
    }));
  }
  static returnLineChartAgeCount35(data) {
    return data.map((data) => ({
      x: data.date,
      y: data.age_26_35,
    }));
  }
  static returnLineChartAgeCount45(data) {
    return data.map((data) => ({
      x: data.date,
      y: data.age_36_45,
    }));
  }
  static returnLineChartAgeCount60(data) {
    return data.map((data) => ({
      x: data.date,
      y: data.age_46_60,
    }));
  }
  static returnLineChartAgeCount100(data) {
    return data.map((data) => ({
      x: data.date,
      y: data.age_60_ahead,
    }));
  }
  static ageDistributionHourlyGraphData(ageHourly) {
    if (ageHourly.length > 0) {
      return [
        {
          name: "0-12 y/o",
          data: DemographicsFunction.returnLineChartAgeCount12(ageHourly),
        },
        {
          name: "13-18 y/o",
          data: DemographicsFunction.returnLineChartAgeCount18(ageHourly),
        },
        {
          name: "19-25 y/o",
          data: DemographicsFunction.returnLineChartAgeCount25(ageHourly),
        },
        {
          name: "26-35 y/o",
          data: DemographicsFunction.returnLineChartAgeCount35(ageHourly),
        },
        {
          name: "36-45 y/o",
          data: DemographicsFunction.returnLineChartAgeCount45(ageHourly),
        },
        {
          name: "46-60 y/o",
          data: DemographicsFunction.returnLineChartAgeCount60(ageHourly),
        },
        {
          name: "61-100 y/o",
          data: DemographicsFunction.returnLineChartAgeCount100(ageHourly),
        },
      ];
    }
    return [
      {
        name: "0-12 y/o",
        data: [],
      },
      {
        name: "13-18 y/o",
        data: [],
      },
      {
        name: "19-25 y/o",
        data: [],
      },
      {
        name: "26-35 y/o",
        data: [],
      },
      {
        name: "36-45 y/o",
        data: [],
      },
      {
        name: "46-60 y/o",
        data: [],
      },
      {
        name: "61-100 y/o",
        data: [],
      },
    ];
  }
}
export default DemographicsFunction;
