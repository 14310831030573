import React from "react";
import PropTypes from "prop-types";
import { LIVEVIEW_SERVER_URL } from "../../../routes/config";

const LiveViewVideoPlayer = ({ src }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <iframe
        allowFullScreen
        src={`${LIVEVIEW_SERVER_URL}/stream.html?src=${src.key}`}
        style={{
          width: "100%",
          height: "100%",
          border: "none",
          display: "block",
          overflow: "hidden",
        }}
        title="GoToRTC Stream"
      />
    </div>
  );
};

LiveViewVideoPlayer.propTypes = {
  src: PropTypes.object.isRequired,
};

export default LiveViewVideoPlayer;
