const initialState = {
  peopleCountLoading: false,
  passerbyLoading: false,
  groupDataLoading: false,
  demographicsLoadingPeopleCount: false,
  demographicsLoadingPasserby: false,
  totalMaleFemale: false,
  ageDemographicsLoading: false,
  dwelltimeHourlyLoading: false,
  potentailCustomerLoading: false,
};

export default function LoadingReducer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_LOADING_FOR_PEOPLE_COUNT":
      return {
        ...state,
        peopleCountLoading: action.payload,
      };
    case "UPDATE_LOADING_FOR_PASSERBY":
      return {
        ...state,
        passerbyLoading: action.payload,
      };
    case "UPDATE_LOADING_FOR_GROUP_DATA":
      return {
        ...state,
        groupDataLoading: action.payload,
      };
    case "UPDATE_LOADING_FOR_MALE_FEMALE":
      return {
        ...state,
        demographicsLoadingPeopleCount: action.payload,
      };
    case "UPDATE_LOADING_FOR_MALE_FEMALE_PASSER":
      return {
        ...state,
        demographicsLoadingPasserby: action.payload,
      };
    case "UPDATE_LOADING_FOR_MALE_FEMALE_TOTAL":
      return {
        ...state,
        totalMaleFemale: action.payload,
      };
    case "UPDATE_LOADING_FOR_AGE_DEMOGRAPHICS":
      return {
        ...state,
        ageDemographicsLoading: action.payload,
      };
    case "UPDATE_LOADING_FOR_AGE_DWELL_TIME":
      return {
        ...state,
        dwelltimeHourlyLoading: action.payload,
      };
    case "UPDATE_LOADING_FOR_POTENTAIL_CUSTOMER":
      return {
        ...state,
        potentailCustomerLoading: action.payload,
      };
    default:
      return state;
  }
}
