import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { extractDateTime } from "../utils/FormatDateAndTime";
import Chip from "@mui/material/Chip";
const PersonDetailViewModal = ({ isOpen, handleClose, data }) => {
  const { date, time } = extractDateTime(data.created_at);
  const chipColors = ["primary", "secondary", "error", "success"];
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="sm"
      aria-labelledby="person-detail-view-title"
      aria-describedby="person-detail-view-description"
      PaperProps={{
        sx: { maxWidth: "sm" },
      }}
    >
      <DialogTitle id="person-detail-view-title" sx={{ m: 0, p: 2 }}>
        Detailed View
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <div className="row">
          <div className="col-12 col-md-6 d-flex justify-content-center">
            <img
              className="img-fluid"
              alt="Person"
              src={data.image || "../../../assets/images/unknownPerson.png"}
              style={{ width: "180px", height: "200px", borderRadius: "10px" }}
            />
          </div>
          <div className="col-12 col-md-6">
            <p className="text-bold" style={{ fontSize: "larger" }}>
              Name: {data.name}
            </p>
            <p className="text-bold" style={{ fontSize: "larger" }}>
              Gender: {data.gender}
            </p>
            <p className="text-bold" style={{ fontSize: "larger" }}>
              Age: {data.age}
            </p>
            <p className="text-bold" style={{ fontSize: "larger" }}>
              Added Date: {date}
            </p>
            <p className="text-bold" style={{ fontSize: "larger" }}>
              Added Time: {time}
            </p>
            <p className="text-bold" style={{ fontSize: "larger" }}>
              Assigned Tags:
            </p>
            {data.tag &&
              data.tag.map((chip, index) => (
                <Chip
                  key={index}
                  label={chip}
                  variant="outlined"
                  className="m-1"
                  color={chipColors[index % chipColors.length]} // Cycle through chipColors
                />
              ))}
            <p className="text-bold" style={{ fontSize: "larger" }}>
              Remarks:{data.remarks ?? ""}
            </p>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

PersonDetailViewModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default PersonDetailViewModal;
