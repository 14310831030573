import buildUrlWithParams from "./AddUrlsWIthParams";
import AnalyticsDashboardEndpoints from "../features/AnalyticsDashboardEndpoints";
import axios from "axios";
function getDataByKey(dataArray, key) {
  for (const item of dataArray) {
    if (item[key]) {
      return item[key];
    }
  }
  return null;
}
class DemographicsActions {
  static cancelTokenSources = {};

  static cancelPreviousRequest(apiName) {
    if (DemographicsActions.cancelTokenSources[apiName]) {
      DemographicsActions.cancelTokenSources[apiName].cancel(
        "Operation canceled due to new request."
      );
    }
    DemographicsActions.cancelTokenSources[apiName] =
      axios.CancelToken.source();
  }
  /**
   * Fetches the data for demographics for people count.
   * The parameters include user profile id, facility id, camera id, date, date
   * range with a additional param camera_type which is people_count.
   * If an error occurs, or the response is not in the provisioned format, the function
   * stops the loading and dispatches an action with a predefined structure.
   *
   * @param {object} params - Parameters used to form the URL for the data request.
   * @return {function} - A dispatch function that performs async data fetch and state update
   */
  static getDemographicsDataForAllPeopleCount(params) {
    return async (dispatch) => {
      dispatch({ type: "UPDATE_LOADING_FOR_MALE_FEMALE", payload: true });
      const apiName = "getDemographicsDataForAllPeopleCount";
      this.cancelPreviousRequest(apiName);
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      const urls = AnalyticsDashboardEndpoints.getDemographicsDataAll();
      axios
        .get(buildUrlWithParams(params, urls), {
          headers,
          cancelToken: DemographicsActions.cancelTokenSources[apiName].token,
        })
        .then((response) => {
          if (params.start_date == params.end_date) {
            dispatch({
              type: "PEOPLE_COUNT_GENDER_HOURLY_DATA",
              payload: getDataByKey(response.data, "mvf"),
              date: params.start_date,
            });
            dispatch({
              type: "PEOPLE_COUNT_EMOTION_HOURLY_DATA",
              payload: getDataByKey(response.data, "emd"),
              date: params.start_date,
            });
            dispatch({
              type: "PEOPLE_COUNT_AGE_HOURLY_DATA",
              payload: getDataByKey(response.data, "agd"),
              date: params.start_date,
            });
          } else {
            dispatch({
              type: "MALE_FEMALE_DAY_DATA",
              payload: getDataByKey(response.data, "mvf").hourly,
              date: params.start_date,
            });
            dispatch({
              type: "MALE_FEMALE_COUNT_DATE_RANG_PC",
              payload: getDataByKey(response.data, "mvf"),
              date: params.start_date,
            });
          }
          dispatch({
            type: "UPDATE_LOADING_FOR_MALE_FEMALE",
            payload: false,
          });
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            dispatch({
              type: "UPDATE_LOADING_FOR_MALE_FEMALE",
              payload: false,
            });
          }
        });
    };
  }
  /**
   * Fetches the data for demographics for people count on weekly basis.
   * The parameters include user profile id, facility id, camera id, date.
   * If an error occurs, or the response is not in the provisioned format, the function
   * stops the loading and dispatches an action with a predefined structure.
   *
   * @param {object} params - Parameters used to form the URL for the data request.
   * @return {function} - A dispatch function that performs async data fetch and state update
   */
  static getDemographicsWeeklyByUserProfileFacility(params) {
    return async (dispatch) => {
      const apiName = "getDemographicsWeeklyByUserProfileFacility";
      this.cancelPreviousRequest(apiName);
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      const urls = AnalyticsDashboardEndpoints.getDemographicsWeeklyData();
      axios
        .get(buildUrlWithParams(params, urls), {
          headers,
          cancelToken: DemographicsActions.cancelTokenSources[apiName].token,
        })
        .then((response) => {
          dispatch({
            type: "PEOPLE_COUNT_GENDER_WEEKLY_DATA",
            payload: response.data,
          });
        });
    };
  }
  /**
   * Fetches the data for demographics for passerby count.
   * The parameters include user profile id, facility id, camera id, date, date
   * range with a additional param camera_type which is passer_by.
   * If an error occurs, or the response is not in the provisioned format, the function
   * stops the loading and dispatches an action with a predefined structure.
   *
   * @param {object} params - Parameters used to form the URL for the data request.
   * @return {function} - A dispatch function that performs async data fetch and state update
   */
  static getDemographicsDataForAllPasserbyCount(params) {
    return async (dispatch) => {
      dispatch({
        type: "UPDATE_LOADING_FOR_MALE_FEMALE_PASSER",
        payload: true,
      });
      const apiName = "getDemographicsDataForAllPasserbyCount";
      this.cancelPreviousRequest(apiName);
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      const urls = AnalyticsDashboardEndpoints.getDemographicsDataAll();
      axios
        .get(buildUrlWithParams(params, urls), {
          headers,
          cancelToken: DemographicsActions.cancelTokenSources[apiName].token,
        })
        .then((response) => {
          if (params.start_date == params.end_date) {
            dispatch({
              type: "PASSER_COUNT_GENDER_HOURLY_DATA",
              payload: getDataByKey(response.data, "mvf"),
              date: params.start_date,
            });
          } else {
            dispatch({
              type: "MALE_FEMALE_COUNT_DATE_RANG_PS",
              payload: getDataByKey(response.data, "mvf"),
              date: params.start_date,
            });
          }
          dispatch({
            type: "UPDATE_LOADING_FOR_MALE_FEMALE_PASSER",
            payload: false,
          });
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            dispatch({
              type: "UPDATE_LOADING_FOR_MALE_FEMALE_PASSER",
              payload: false,
            });
          }
        });
    };
  }
}
export default DemographicsActions;
