import React, { useState, useEffect } from "react";
import FaceRecognitionTopBar from "./FaceRecognitionTopBar";
import { useDispatch } from "react-redux";
import { changeTopbarTitle } from "../../redux/Action/ChangeTopbarTitle";
import { Outlet } from "react-router-dom";
const FaceDetectedDashboard = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeTopbarTitle("Face Recognition"));
  }, []);
  return (
    <div>
      <Outlet />
      <FaceRecognitionTopBar />
    </div>
  );
};

export default FaceDetectedDashboard;
