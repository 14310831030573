import React from "react";

export function AnprIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 2048 2048"
      {...props}
    >
      <path
        fill="currentColor"
        d="M768 1920h128v128H0V0h1024v384H768v920l1085-542l185 371l-1270 635zm0-296l256-128v-176l-256 128zm384-192l256-128v-176l-256 128zm643-498l-259 130v176l330-165zM640 1280V640H384v640zM128 256h768V128H128zm512 1664v-512H256V512h384V384H128v1536z"
      ></path>
    </svg>
  );
}
