import AgeGroupDistributionCard from "./DashboardCardComponent/AgeGroupDistributionCard";
import DemographicsCard from "./DashboardCardComponent/DemographicsCard";
import PeopleCountAndPasserbyCards from "./DashboardCardComponent/PeopleCountAndPasserbyCards";
import DwellTimeAnalyticsCards from "./DashboardCardComponent/DwellTimeAnalyticsCards";
import PotentailCustomerCard from "./DashboardCardComponent/PotentialCustomerCards";
const DashboardAllCards = (propTypes) => {
  return (
    <div>
      <div className="row">
        <PeopleCountAndPasserbyCards selectedMode={propTypes.selectedMode} />
        <DemographicsCard selectedMode={propTypes.selectedMode} />
        <AgeGroupDistributionCard selectedMode={propTypes.selectedMode} />
        <DwellTimeAnalyticsCards />
        <PotentailCustomerCard selectedMode={propTypes.selectedMode} />
      </div>
    </div>
  );
};

export default DashboardAllCards;
