import Autocomplete from "@mui/material/Autocomplete";
import React from "react";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";

const AutocompleteCompUncontrollable = ({
  option,
  onChange,
  label,
  helperText,
  size = "large",
  error,
}) => {
  const isOptionEqualToValue = (option, value) => {
    return option.value === value?.value;
  };

  function handleChange(event, value) {
    onChange(value);
  }

  return (
    <div>
      <Autocomplete
        disableClearable
        id="combo-box-demo"
        options={option}
        size={size}
        onChange={handleChange}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            error={error}
            helperText={helperText}
          />
        )}
      />
    </div>
  );
};

AutocompleteCompUncontrollable.propTypes = {
  option: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  error: PropTypes.bool.isRequired,
};

export default AutocompleteCompUncontrollable;
