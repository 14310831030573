import Autocomplete from "@mui/material/Autocomplete";
import React from "react";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
const AutocompleteComp = ({ option, onChange, value, label, showValue }) => {
  const isOptionEqualToValue = (option, value) => {
    return option.value === value?.value;
  };
  function handleChange(event, value) {
    onChange(event, value);
  }

  return (
    <div>
      {option && option.length > 0 && value && (
        <Autocomplete
          // disablePortal
          disableClearable
          id="combo-box-demo"
          options={option}
          onChange={handleChange}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          getOptionLabel={(option) =>
            showValue ? `${option.label} (${option.value})` : option.label
          }
          getOptionDisabled={(item) =>
            item.value === "compliance_issue" ||
            item.value === "security_thread" ||
            item.value === "facility_status" ||
            item.value === "loading_status" ||
            item.value === "vehicle_alert"
          }
          key={label}
          value={value}
          renderInput={(params) => <TextField {...params} label={label} />}
          error={true} // Set error to true when no facility is selected
          helperText={"Please select a facility"} // Display the helper text conditionally
        />
      )}
    </div>
  );
};
AutocompleteComp.propTypes = {
  option: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  label: PropTypes.string.isRequired,
  showValue: PropTypes.bool.isRequired,
};
export default AutocompleteComp;
