export const dialogTheme = {
  MuiDialog: {
    styleOverrides: {
      paper: {
        borderRadius: "15px",
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        backgroundColor: "#425CDF",
        opacity: "100%",
        color: "white",
        borderTopLeftRadius: "15px",
        borderTopRightRadius: "15px",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: "24px",
      },
    },
  },
};
