import React from "react";
import { useSelector } from "react-redux";
import DashboardCard from "./../DashboardCard";
import EqualizerRoundedIcon from "@mui/icons-material/EqualizerRounded"; // Ensure icons are imported
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import TimelineIcon from "@mui/icons-material/Timeline";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";

const iconFont = {
  fontSize: "50px",
  color: "#4318FF",
};
//gives dwell time % for less than 2 mins
const totalDwellTimeCountLessthan2 = (whole) => {
  const total =
    whole.countlessthan2 + whole.countmorethan2 + whole.countmorethan10;
  if (total === 0) {
    return 0;
  }
  const percent = (whole.countlessthan2 / total) * 100;
  return "/" + percent.toFixed(2) + "%";
};
//gives dwell time % for more than 2 mins
const totalDwellTimeCountMorethan2 = (whole) => {
  const total =
    whole.countlessthan2 + whole.countmorethan2 + whole.countmorethan10;
  if (total === 0) {
    return 0;
  }
  const percent = (whole.countmorethan2 / total) * 100;
  return "/" + percent.toFixed(2) + "%";
};
//gives dwell time % for more than 10 mins
const totalDwellTimeCountMorethan10 = (whole) => {
  const total =
    whole.countlessthan2 + whole.countmorethan2 + whole.countmorethan10;
  if (total === 0) {
    return 0;
  }
  const percent = (whole.countmorethan10 / total) * 100;

  return "/" + percent.toFixed(2) + "%";
};

function dwellTimeAvg(dwellData) {
  if (dwellData.length === 0) {
    return 0;
  }

  let sum = 0;
  for (let i = 0; i < dwellData.length; i++) {
    if (!isNaN(dwellData[i].average_dwell_time)) {
      sum += dwellData[i].average_dwell_time;
    }
  }
  let avg = sum / dwellData.length;

  return parseFloat(avg.toFixed(1)) || 0;
}

const DwellTimeAnalyticsCards = () => {
  const profile = JSON.parse(localStorage.getItem("profile") || "{}");
  const retailData = useSelector((state) => state.retailData); // Adjust according to your actual state structure
  const accessPermissions =
    JSON.parse(localStorage.getItem("agx_permission") || "{}").Dashboard || [];
  const ageData = useSelector((state) => state.age);
  const canViewDwellTimeAnalytics =
    profile.services_selected?.Dashboard.includes("dwell_time") &&
    accessPermissions.includes("view_dwell_time_analytics");
  const DwelltimeLoading = useSelector(
    (state) => state.loading.dwelltimeHourlyLoading
  );
  return (
    <>
      {canViewDwellTimeAnalytics && (
        <>
          <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-2">
            <DashboardCard
              icon={<EqualizerRoundedIcon style={iconFont} />}
              headText={
                retailData.dwellTimeCountHourly.countlessthan2 +
                totalDwellTimeCountLessthan2(retailData.dwellTimeCountHourly)
              }
              paraText="Time spent < 2 Mins"
              isLoading={DwelltimeLoading}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-2">
            <DashboardCard
              icon={<PeopleAltOutlinedIcon style={iconFont} />}
              headText={
                retailData.dwellTimeCountHourly.countmorethan2 +
                totalDwellTimeCountMorethan2(retailData.dwellTimeCountHourly)
              }
              paraText="Time spent > 2 to 10 Mins"
              isLoading={DwelltimeLoading}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-2">
            <DashboardCard
              icon={<TimelineIcon style={iconFont} />}
              headText={
                retailData.dwellTimeCountHourly.countmorethan10 +
                totalDwellTimeCountMorethan10(retailData.dwellTimeCountHourly)
              }
              paraText="Time spent > 10 Mins"
              isLoading={DwelltimeLoading}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-2">
            <DashboardCard
              icon={<TimerOutlinedIcon style={iconFont} />}
              headText={retailData.dwellTimeCountHourly.avg.time_recorded__avg.toFixed(
                2
              )}
              paraText="Average Time Spend"
              isLoading={DwelltimeLoading}
            />
          </div>
        </>
      )}
      {profile.services_selected.Dashboard.includes("nicobar_dwell_time") && (
        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-2">
          <DashboardCard
            icon={<TimerOutlinedIcon sx={iconFont} />}
            headText={dwellTimeAvg(ageData.dwellTimeHourly)}
            paraText="Average Dwell Time (Mins)"
            isLoading={DwelltimeLoading}
          />
        </div>
      )}
    </>
  );
};

export default DwellTimeAnalyticsCards;
