import moment from "moment";
class AlertChartFunction {
  static returnAlertLineCount(data) {
    return data.map((data) => ({
      x: data.date,
      y: data.alert ?? data.event,
    }));
  }

  static totalAlertsHourlyGraphData(totalAlertHourly) {
    if (totalAlertHourly.length > 0) {
      return [
        {
          name: "Alerts",
          data: AlertChartFunction.returnAlertLineCount(totalAlertHourly),
        },
      ];
    }
    return [
      {
        name: "Alerts",
        data: [],
      },
    ];
  }

  static returnCountGraphData(data) {
    return data.map((data) => ({
      x: data.date,
      y: data.hourly_count ?? data.day_count,
    }));
  }
  static countHourlyData(countData) {
    if (countData.length > 0) {
      return [
        {
          name: "Count",
          data: AlertChartFunction.returnCountGraphData(countData),
        },
      ];
    }
    return [
      {
        name: "count",
        data: [],
      },
    ];
  }
  static countDateRangeData(countData) {
    if (countData.length > 0) {
      return {
        series: [
          {
            name: "Count",
            data: countData.map((count) => count.day_count),
          },
        ],
        date:
          countData.length > 0
            ? countData.map((dt) => moment(dt.date).format("DD-MM-YYYY"))
            : [],
      };
    }
    return {
      series: [
        {
          name: "Count",
          data: [],
        },
      ],
      date: [],
    };
  }
  static returnLineChartAlertMessage(data, name) {
    return data.map((data) => ({
      x: data.date,
      y: data[name] ?? 0,
    }));
  }

  static alertTypeFilterMainFunction(alertTypeData) {
    if (alertTypeData.length > 0) {
      return [
        {
          name: "Floor sweeping adherence",
          data: AlertChartFunction.returnLineChartAlertMessage(
            alertTypeData,
            "Floor sweeping adherence"
          ),
        },
        {
          name: "Cobweb dusting adherence",
          data: AlertChartFunction.returnLineChartAlertMessage(
            alertTypeData,
            "Cobweb dusting adherence"
          ),
        },
        {
          name: "Brass lamp cleaning adherence",
          data: AlertChartFunction.returnLineChartAlertMessage(
            alertTypeData,
            "Brass lamp cleaning adherence"
          ),
        },
        {
          name: "Floor washing adherence",
          data: AlertChartFunction.returnLineChartAlertMessage(
            alertTypeData,
            "Floor washing adherence"
          ),
        },
        {
          name: "Dustbin clearing adherence",
          data: AlertChartFunction.returnLineChartAlertMessage(
            alertTypeData,
            "Dustbin clearing adherence"
          ),
        },
      ];
    } else {
      return [
        {
          name: "Floor sweeping adherence",
          data: [],
        },
        {
          name: "Cobweb dusting adherence",
          data: [],
        },
        {
          name: "Brass lamp cleaning adherence",
          data: [],
        },
        {
          name: "Floor washing adherence",
          data: [],
        },
        {
          name: "Dustbin clearing adherence",
          data: [],
        },
      ];
    }
  }

  static totalWeeklyGraphData(totalWeeklyAlerts) {
    if (totalWeeklyAlerts.length > 0) {
      return {
        series: [
          {
            name: "Resolved",
            data: totalWeeklyAlerts.map((pc) => pc.resolved ?? pc.event),
          },
          {
            name: "Unresolved",
            data: totalWeeklyAlerts.map((pb) => pb.unresolved ?? pb.dismissed),
          },
        ],
      };
    }
    return {
      series: [
        {
          name: "People Count",
          data: [],
        },
        {
          name: "Passer-By Count",
          data: [],
        },
      ],
    };
  }

  static returnResolvedHourly(data) {
    return data.map((data) => ({
      x: data.date,
      y: data.alert ?? data.event,
    }));
  }

  static resolvedHourly(resolvedAlertHourly) {
    if (resolvedAlertHourly.length > 0) {
      return [
        {
          name: "Resolved",
          data: AlertChartFunction.returnResolvedHourly(resolvedAlertHourly),
        },
      ];
    } else {
      return [
        {
          name: "Resolved",
          data: [],
        },
      ];
    }
  }

  static unresolvedHourly(unresolvedAlertHourly) {
    if (unresolvedAlertHourly.length > 0) {
      return [
        {
          name: "Unresolved",
          data: AlertChartFunction.returnResolvedHourly(unresolvedAlertHourly),
        },
      ];
    } else {
      return [
        {
          name: "Resolved",
          data: [],
        },
      ];
    }
  }

  static topSevenSiteAlertsGraphData(topSevenSiteAlertData) {
    if (topSevenSiteAlertData.length > 0) {
      return [
        {
          data: topSevenSiteAlertData.map((pb) => pb.facility_name),
        },
        {
          data: topSevenSiteAlertData.map(
            (pb) => pb.alert_count ?? pb.event_count
          ),
        },
      ];
    } else {
      return [
        {
          data: [],
        },
        {
          data: [],
        },
      ];
    }
  }

  static separateAlertTypesAndCounts(totalAlertTypeData) {
    let types = [];
    let counts = [];
    if (totalAlertTypeData.length > 0) {
      for (let i = 0; i < totalAlertTypeData.length; i++) {
        types.push(totalAlertTypeData[i].alert_type);
        counts.push(totalAlertTypeData[i].alert_count);
      }

      return [
        {
          data: types,
        },
        {
          data: counts,
        },
      ];
    } else {
      return [
        {
          data: [],
        },
        {
          data: [],
        },
      ];
    }
  }
  static totalAlertCount = (data) => {
    if (!Array.isArray(data) || data.length === 0) {
      return 0;
    }

    return data.reduce((accumulator, currentValue) => {
      const alertCount = Number.isFinite(currentValue.alert)
        ? currentValue.alert
        : 0;
      const eventCount = Number.isFinite(currentValue.event)
        ? currentValue.event
        : 0;

      return accumulator + alertCount + eventCount;
    }, 0);
  };

  static topTenSitesAlertData = (tenSitesAlerts) => {
    if (tenSitesAlerts.length > 0) {
      return {
        series: [
          {
            name: "Sites",
            data: tenSitesAlerts.map((pc) => pc.alert_count),
          },
        ],
        date: tenSitesAlerts.map((pc) => pc.facility_name),
      };
    }
    return {
      series: [
        {
          name: "People Count",
          data: [],
        },
        {
          name: "Passer-By Count",
          data: [],
        },
      ],
      date: [],
    };
  };
  static convertYumFoodsDataToChartData(yumFoodsChartData, isDayWiseMode) {
    // Initialize arrays to hold the data for each series
    const peopleInQueueData = [];
    const avgTimeToFrontData = [];
    const avgTimeToServeOrderData = [];
    const avgTimeToPlaceOrderData = [];
    const labels = [];
    // Process each data point
    yumFoodsChartData.forEach((dataPoint) => {
      // Add data to the series arrays
      peopleInQueueData.push(dataPoint.people_count);
      avgTimeToFrontData.push(dataPoint.avg_time_to_come_to_front);
      avgTimeToServeOrderData.push(dataPoint.avg_time_to_receiving_order);
      avgTimeToPlaceOrderData.push(dataPoint.avg_time_to_place_order);

      // Convert date to a more readable format and add to labels
      if (isDayWiseMode) {
        const date = new Date(dataPoint.date);
        const hours = date.getHours();
        const ampm = hours >= 12 ? "PM" : "AM";
        const readableHours = ((hours + 11) % 12) + 1; // Convert 24h to 12h format
        const formattedTime = `${readableHours} ${ampm}`;
        labels.push(formattedTime);
      } else {
        // Format the date for day and month
        const date = new Date(dataPoint.date);
        const day = date.getDate();
        const month = date.toLocaleString("default", { month: "short" }); // 'Nov', 'Dec', etc.
        const formattedDate = `${day} ${month}`;
        labels.push(formattedDate);
      }
    });

    // Construct the final seriesData object
    const seriesData = [
      {
        name: "People in Queue",
        type: "column",
        data: peopleInQueueData,
      },
      {
        name: "Avg Time Taken To Come To The Front Of The Queue",
        type: "line",
        data: avgTimeToFrontData,
      },
      {
        name: "Avg Time Taken To Serve The Order",
        type: "line",
        data: avgTimeToServeOrderData,
      },
      {
        name: "Avg Time Taken To Place The Order",
        type: "line",
        data: avgTimeToPlaceOrderData,
      },
    ];

    // Return the converted data
    return { seriesData, label: labels };
  }
}

export default AlertChartFunction;
