/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";

const SimpleCardText = ({ icon, headText, paraText, head }) => {
  return (
    <>
      <div className="retail-top-card">
        <div className="dashcaricondiv">{icon}</div>
        <div style={{ marginLeft: "10px" }}>
          <span className="card-heading-retail">{headText}</span>
          <div className="card-paragraph-retail">{paraText}</div>
        </div>
      </div>
    </>
  );
};

SimpleCardText.propTypes = {};

export default SimpleCardText;
