import EndPoints from "../features/EndPoints";
import axios from "axios";
import RuleBaseActionEndpoints from "../features/RuleBaseActionEndpoints";
import {
  notifiToasterSuccess,
  notifiToasterWarn,
} from "../../components/common/UseToast";
class RuleBasedAlertAction {
  static getCameraByFacilities(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      let userprofileId = JSON.parse(localStorage.getItem("profile")).id;
      axios
        .get(EndPoints.getCamerasByStore(data, userprofileId), { headers })
        .then((response) => {
          let device = [];
          response.data.forEach((element) => {
            device.push({ value: element.camera_name, id: element.id });
          });

          dispatch({
            type: "CAMERA_LIST_FOR_MULTIPLE_FACILITIES",
            payload: device,
          });
        });
    };
  }
  static updateTheAlertRule(data, id) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      axios
        .put(RuleBaseActionEndpoints.UpdateAndDeleteAlertRuleById(id), data, {
          headers,
        })
        .then((response) => {
          notifiToasterSuccess("Rule Updated Successfully");
          dispatch(RuleBasedAlertAction.getRulesData());
        })
        .catch(() => {
          notifiToasterWarn("Failed to update rules");
        });
    };
  }
  static addNewAttendanceRule(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      await axios
        .post(RuleBaseActionEndpoints.createNewRulesForAttendance(), data, {
          headers,
        })
        .then(() => {
          notifiToasterSuccess("Rule added Successfully");
          dispatch(RuleBasedAlertAction.getRulesData());
        })
        .catch(() => {
          notifiToasterWarn("Failed to add rules");
        });
    };
  }
  static updateRulesForPotential(data, id) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      await axios
        .put(RuleBaseActionEndpoints.RulesForPotentialById(id), data, {
          headers,
        })
        .then(() => {
          notifiToasterSuccess("Rule Updated  Successfully");
          dispatch(RuleBasedAlertAction.getRulesData());
        })
        .catch(() => {
          notifiToasterWarn("Failed to update rules");
        });
    };
  }
  static addNewRulesForPotential(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      await axios
        .post(RuleBaseActionEndpoints.RulesForPotential(), data, {
          headers,
        })
        .then(() => {
          notifiToasterSuccess("Rule added Successfully");
          dispatch(RuleBasedAlertAction.getRulesData());
        })
        .catch(() => {
          notifiToasterWarn("Failed to add rules");
        });
    };
  }
  /**
   * Fetches rules data for both potential and attendance according to service adopted based on user profile and dispatches the data to update the rule list for alerts.
   * @returns {Function} Async function that dispatches the updated rule list for alerts.
   */
  static getRulesData() {
    return async (dispatch) => {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Token ${token}`, // Use "Bearer" if required by your backend
      };
      const profile = JSON.parse(localStorage.getItem("profile"));

      try {
        let response = null;
        let response2 = null;
        let response3 = null;
        if (profile.services_selected?.Event?.includes("potential_customer")) {
          response = await axios.get(
            RuleBaseActionEndpoints.RulesForPotential(profile.id),
            { headers }
          );
        }
        if (profile.services_selected?.Event?.includes("potential_customer")) {
          response3 = await axios.get(
            RuleBaseActionEndpoints.RulesForAlertEvent(profile.id),
            { headers }
          );
        }
        if (profile.services_selected?.Event?.includes("teacher_present")) {
          response2 = await axios.get(
            RuleBaseActionEndpoints.createNewRulesForAttendance(profile.id),
            { headers }
          );
        }

        const data = response
          ? response.data.map((item) => ({
              ...item,
              type: "potential_customer",
            }))
          : [];
        const data2 = response2
          ? response2.data.map((item) => ({
              ...item,
              type: "attendance",
            }))
          : [];
        const data3 = response3
          ? response3.data.map((item) => ({
              ...item,
              type: "custom_alerts",
            }))
          : [];

        dispatch({
          type: "RULE_LIST_FOR_ALERT",
          payload: [...data, ...data2, ...data3],
        });
      } catch (error) {
        console.error("Failed to fetch rules:", error);
      }
    };
  }
  static deleteAlertRule(id, type) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      const url =
        type == "potential_customer"
          ? RuleBaseActionEndpoints.RulesForPotentialById(id)
          : type == "attendance"
          ? RuleBaseActionEndpoints.RulesForAttendanceById(id)
          : RuleBaseActionEndpoints.UpdateAndDeleteAlertRuleById(id);
      await axios
        .delete(url, {
          headers,
        })
        .then(() => {
          notifiToasterSuccess("Rule Deleted Successfully");
          dispatch(RuleBasedAlertAction.getRulesData());
        })
        .catch(() => {
          notifiToasterWarn("Failed to delete rules");
        });
    };
  }
  static toggleRuleStatus(id, status, type) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      const data = { is_active: status };
      if (status) {
        data.prev_date = null;
      }
      const url =
        type == "potential_customer"
          ? RuleBaseActionEndpoints.RulesForPotentialById(id)
          : type == "attendance"
          ? RuleBaseActionEndpoints.RulesForAttendanceById(id)
          : RuleBaseActionEndpoints.UpdateAndDeleteAlertRuleById(id);
      await axios
        .patch(url, data, {
          headers,
        })
        .then(() => {
          notifiToasterSuccess(
            "The status of the rule has been successfully changed."
          );

          dispatch(RuleBasedAlertAction.getRulesData());
        })
        .catch(() => {
          notifiToasterWarn("Failed to change status of rule");
        });
    };
  }
  static updateRuleForAttendance(data, id) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      await axios
        .put(RuleBaseActionEndpoints.RulesForAttendanceById(id), data, {
          headers,
        })
        .then(() => {
          notifiToasterSuccess("Rule Updated  Successfully");
          dispatch(RuleBasedAlertAction.getRulesData());
        })
        .catch(() => {
          notifiToasterWarn("Failed to update rules");
        });
    };
  }
}
export default RuleBasedAlertAction;
