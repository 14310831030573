import React, { useEffect } from "react";
import { List, Drawer, Button } from "@mui/material";
import { Tooltip } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import sizeConfigs from "../../../configs/sizeConfigs";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import Account from "../../../redux/Action/CommonAction";
import BarsRotateLoader from "../../../components/common/Loader/BarsRotateLoader";
import useWindowDimensions from "../../../components/common/useWindowDimensions";
import assets from "../../../assets";
import FacilityOptions from "./VmsSidebarFacilityOption";
import VmsLiveviewActions from "../../../redux/Action/VmsActions";
import { useState } from "react";
const VmsSidebar = () => {
  const [loading, setLoading] = useState(false);
  const facilityList = useSelector((state) => state.vms.facilityList);
  const toggle = useSelector((state) => state.commonData.toggleSidebar);
  const activeView = useSelector((state) => state.vms.activeView);
  const dispatch = useDispatch();
  const profile = JSON.parse(localStorage.getItem("profile"));
  let sidebarWidth = toggle
    ? sizeConfigs.sidebarMin.width
    : sizeConfigs.sidebar.width;
  const { width } = useWindowDimensions();
  function handleToggleVideoSidebar() {
    dispatch(Account.toglleVideoSidebar(false));
  }
  useEffect(() => {
    let profile_id = localStorage.getItem("profile_id");
    setLoading(true);
    dispatch(
      activeView == "liveview"
        ? VmsLiveviewActions.getAllFacilitiesCameraLiveview(profile_id)
        : VmsLiveviewActions.getAllFacilitiesCameraPlayback(profile_id)
    )
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
    dispatch(VmsLiveviewActions.UpdateLiveviewFeature(activeView));
  }, [activeView]);

  function clearAllStreams() {
    dispatch(VmsLiveviewActions.replaceSelectedCameraList([]));
    dispatch(VmsLiveviewActions.replacePlayingList([]));
  }

  return (
    <>
      <div>
        <Drawer
          variant="permanent"
          sx={{
            width: sidebarWidth,
            transition: "sidebarWidth 0.0s",
            flexShrink: 0,
            position: "relative",
            "& .MuiDrawer-paper": {
              width: sidebarWidth,
              boxSizing: "border-box",
              borderRight: "0px",
              backgroundColor: "var(--side-bar-bg-color)",
              color: "var(--side-item-color)",
              transition: "sidebarWidth 0.0s",
            },
          }}
        >
          {width > 800 && (
            <>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={
                    "client_logo" in profile.account.config
                      ? profile.account.config.client_logo
                      : assets.images.logo
                  }
                  style={{ width: "85%", height: "50px" }}
                  id="sidebar-logo-image"
                  className="logo-visible"
                />
              </div>
              <div className="mt-2">
                <Divider sx={{ color: "var(--card-text-color)" }}>
                  <Tooltip title="Click to See Sidebar">
                    <Chip
                      style={{
                        color: "var(--card-text-color)",
                        backgroundColor: "var(--top-bar-bg-color)",
                      }}
                      label={<KeyboardDoubleArrowUpIcon />}
                      onClick={handleToggleVideoSidebar}
                    />
                  </Tooltip>
                </Divider>
              </div>
            </>
          )}
          <div
            style={{ overflow: "auto", maxHeight: "90vh" }}
            className="video-sidebar-camera-list"
          >
            <List>
              {loading ? (
                <div className="loading-icon-videowall-sidebar">
                  <BarsRotateLoader
                    style={{
                      fontSize: "40px",
                      color: "var(--black-white-darkmode)",
                    }}
                  />
                </div>
              ) : facilityList.length > 0 ? (
                <>
                  {facilityList.map((item, ind) => (
                    <FacilityOptions item={item} key={ind} />
                  ))}
                </>
              ) : (
                <p>Sorry, Playback is not available for your cameras.</p>
              )}
            </List>
          </div>
          {activeView == "liveview" && (
            <div
              style={{
                width: "90%",
                marginLeft: "10px",
                marginBottom: "20px",
                marginTop: "auto",
              }}
            >
              <Button
                variant="outlined"
                color="secondary"
                fullWidth
                onClick={clearAllStreams}
              >
                Clear All
              </Button>
            </div>
          )}
        </Drawer>
      </div>
    </>
  );
};

export default VmsSidebar;
