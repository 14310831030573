import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainLayout from "./components/layout/MainLayout";
import routes from "./routes";
import Login from "./components/common/Login";
import Profile from "./pages/Profile/Profile";
import ProtectedRoute from "./routes/ProtectedRoute";
import { useState, useEffect } from "react";
import AppLoader from "./components/common/AppLoader";
import PageNotFound from "./components/common/PageNotFound";
import { CustomThemeProvider } from "./theme/CustomThemeProvider";
import OnboardingMain from "./pages/Onboarding/OnboardingMain";
import FaceRecognitionPersonDetailsPage from "./pages/Facerecognition/FaceRecognitionPersonDetailsPage";

function App() {
  const [loading, setLoading] = useState(true); // add a loading state
  let agx_per = JSON.parse(localStorage.getItem("agx_permission"));
  if (!agx_per) {
    localStorage.clear();
  }

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, [0]);
  }, []);

  if (loading) {
    return <AppLoader />;
  }
  return (
    <CustomThemeProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path=""
            exact
            element={
              <ProtectedRoute>
                <MainLayout />
              </ProtectedRoute>
            }
          >
            {routes}
            <Route path="profile" element={<Profile />}></Route>
            <Route path="*" element={<PageNotFound />} />{" "}
            <Route
              path="/face-details/:faceId/:isMatched"
              element={<FaceRecognitionPersonDetailsPage />}
            ></Route>
          </Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/onboarding" element={<OnboardingMain />}></Route>
        </Routes>
      </BrowserRouter>
    </CustomThemeProvider>
  );
}

export default App;
