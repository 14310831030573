import React, { useState } from "react";
import { Button, IconButton, Avatar } from "@mui/material";
import { PhotoCamera, Cancel } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { notifiToasterWarn } from "../../../components/common/UseToast";
import PropTypes from "prop-types";
const Input = styled("input")({
  display: "none",
});

const ImageUploadButton = styled(Button)({
  border: "2px dashed #ccc", // Dashed border similar to typical upload sections
  color: "#666", // Mild grey text
  backgroundColor: "#fafafa", // Light grey background
  "&:hover": {
    backgroundColor: "#f0f0f0", // Slightly darker background on hover
  },
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  textAlign: "center",
  borderRadius: "50%", // Makes the button circular
  width: "160px", // Ensure width and height are equal for a perfect circle
  height: "160px", // Ensure width and height are equal for a perfect circle
  marginTop: "10px", // Added space for visual comfort
});
const IconContainer = styled("div")({
  // Container for the icon
  backgroundColor: "transparent", // Keep the icon background transparent
  borderRadius: "50%", // Circle around the icon
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "10px", // Space between icon and text
});
const ImageUpload = ({ image, changeImage, setIsImageChanged }) => {
  const [imagePreview, setImagePreview] = useState(null);
  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const img = event.target.files[0];
      if (!img.type.startsWith("image/")) {
        notifiToasterWarn(
          "Please select an image file. Only Image File is allowed"
        );
        return;
      }
      changeImage(img);
      setIsImageChanged(true);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(img);
    }
  };
  const handleCancel = () => {
    changeImage(null);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {image ? (
        <div style={{ position: "relative" }}>
          <Avatar
            alt="Uploaded Image"
            src={imagePreview ? imagePreview : image}
            style={{
              width: "150px",
              height: "150px",
              border: "2px solid #ccc",
            }}
          />
          <IconButton
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              background: "white",
              margin: 4,
            }}
            onClick={handleCancel}
          >
            <Cancel />
          </IconButton>
        </div>
      ) : (
        <>
          <ImageUploadButton variant="outlined" component="label">
            <IconContainer>
              <PhotoCamera style={{ fontSize: "large" }} />
            </IconContainer>
            Upload photo
            <Input
              type="file"
              onChange={handleImageChange}
              accept=".jpg,.png,.jpeg"
            />
          </ImageUploadButton>
          <span style={{ color: "gray", fontSize: "0.8em" }}>
            Supported Formats: .JPG, .PNG, .JPEG
          </span>
        </>
      )}
    </div>
  );
};
ImageUpload.propTypes = {
  changeImage: PropTypes.func.isRequired,
  image: PropTypes.any,
  setIsImageChanged: PropTypes.func.isRequired,
};
export default ImageUpload;
