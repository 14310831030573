import React from "react";
import Chart from "react-apexcharts";

const DashDonuChart = (propTypes) => {
  var options = {
    series: [propTypes.data.happiness, propTypes.data.neutral],
    labels: ["Happiness", "Neutral"],
    colors: ["#C4C1E4", "#4339F2"],
    legend: {
      position: "top",
      labels: {
        colors: "var(--card-text-color)",
      },
      horizontalAlign: "center",
      offsetY: 10,
      itemMargin: {
        horizontal: 10,
        vertical: 5,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: "60%",
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "22px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              offsetY: -10,
              formatter: function (val) {
                return val;
              },
            },
            value: {
              show: true,
              fontSize: "16px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              offsetY: 16,
              formatter: function (val) {
                return val;
              },
            },
            total: {
              show: true,
              showAlways: false,
              label: "Total",
              fontSize: "1.4rem",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              color: "var(--card-text-color)",
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
              },
            },
          },
        },
        offsetY: 30,
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val.toFixed(2) + "%";
          },
        },
      },
    },
  };

  return (
    <div className={propTypes.classNameActive ? "chart-container" : null}>
      <div className={propTypes.classNameActive ? "chart-div" : null}>
        <div className="chart-div">
          <p
            className="chart-title"
            style={{ textAlign: "left", marginLeft: "20px" }}
          >
            {propTypes.title}
          </p>
          <div>
            <Chart
              className="charty"
              options={options}
              series={options.series}
              type="donut"
              height={400}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashDonuChart;
