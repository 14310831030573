import moment from "moment";
import * as DateFns from "date-fns";
const initialState = {
  storeList: [],
  cameraList: [],
  nvrList: [],
  deviceList: [],
  groupList: [],
  userList: [],
  profileList: [],
  permissionList: [],
  filteredGroupList: [],
  filteredProfile: [],
  filteredUser: [],
  userOptions: [],
  pagesToShow: [],
  groupLoading: false,
  userLoading: false,
  profileLoading: false,
  alert_event_list: [],
};

export default function AccessControlReducer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_NVR_FROM_FACILITY_ACCESS_CONTROL":
      return {
        ...state,
        nvrList: [...state.nvrList, ...action.payload],
      };
    case "SET_NVR_FROM_FACILITY_ACCESS_CONTROL":
      return {
        ...state,
        nvrList: action.payload,
      };
    case "SET_CAMERA_FROM_ACCESS_CONTROL":
      return {
        ...state,
        cameraList: action.payload,
      };
    case "UPDATE_CAMERA_FROM_ACCESS_CONTROL":
      return {
        ...state,
        cameraList: [...state.cameraList, ...action.payload],
      };
    case "SET_DEVICES_FROM_FACILITY_ACCESS_CONTROL":
      return {
        ...state,
        deviceList: action.payload,
      };
    case "UPDATE_DEVICES_FROM_FACILITY_ACCESS_CONTROL":
      return {
        ...state,
        deviceList: [...state.deviceList, ...action.payload],
      };
    case "ADD_DATA_TO_GROUP_LIST":
      return {
        ...state,
        groupList: action.payload,
      };
    case "ADD_DATA_TO_PROFILE_LIST":
      return {
        ...state,
        profileList: action.payload,
      };
    case "ADD_DATA_TO_USER_LIST": {
      let userOptions = action.payload.map((item) => ({
        id: item.user_id,
        value: item.name,
      }));

      return {
        ...state,
        userList: action.payload,
        userOptions: userOptions,
      };
    }
    case "ADD_DATA_TO_PERMISSION_LIST":
      return {
        ...state,
        permissionList: action.payload,
      };
    case "SEARCH_GROUP_LIST":
      return {
        ...state,
        filteredGroupList: action.payload,
      };
    case "SEARCH_PROFILE_LIST":
      return {
        ...state,
        filteredProfile: action.payload,
      };
    case "SEARCH_USER_LIST":
      return {
        ...state,
        filteredUser: action.payload,
      };
    case "ADD_PAGES_TO_SHOW":
      return {
        ...state,
        pagesToShow: action.payload,
      };
    case "SET_LOADING_FOR_USER":
      return {
        ...state,
        userLoading: action.payload,
      };
    case "SET_LOADING_FOR_GROUP":
      return {
        ...state,
        groupLoading: action.payload,
      };
    case "SET_LOADING_FOR_PROFILE":
      return {
        ...state,
        profileLoading: action.payload,
      };
    case "ADD_ALERT_EVENT_LIST_ACCESS_CONTROL":
      return {
        ...state,
        alert_event_list: action.payload,
      };
    default:
      return state;
  }
}
