export const nvrWithApi = [
  "hikvision",
  "prama",
  "cpplus",
  "dahua",
  "uniview",
  "indinatus",
];
export const rhmsAlerts = [
  "nvr_offline_alert",
  "hdd_not_recording",
  "cameras_offline_alert",
  "hdd_health",
  "blackout_alert",
];
export const liveViewTunnelUserProfile = [202, 215, 162, 254, 293];
