import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Paper, Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import FaceRecognitionActions from "../../redux/Action/FaceRegnitionAction";
import { useDispatch, useSelector } from "react-redux";
import FaceCard from "./FaceCard";
import FaceRecognitionTable from "./FaceRecognitionTable";
import Pagination from "../../components/common/Pagination";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import { Bars } from "react-loader-spinner";
import unknownPerson from "../../assets/images/unknownPerson.png";
import Account from "../../redux/Action/CommonAction";
function a11yProps(value) {
  return {
    id: `simple-tab-${value}`,
    "aria-controls": `simple-tabpanel-${value}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
const FaceRecognitionTabs = () => {
  const selectedTab = useSelector((state) => state.faceRecognition.selectedTab);

  const [value, setValue] = useState(
    selectedTab === "all" ? 0 : selectedTab === "match" ? 1 : 2
  );
  const selectedView = useSelector(
    (state) => state.faceRecognition.selectedView
  );
  const dispatch = useDispatch();
  const [pageSize] = useState(selectedView == "grid" ? 12 : 50);
  const dataLoading = useSelector((state) => state.faceRecognition.dataLoading);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    let tabValue =
      newValue === 0 ? "all" : newValue === 1 ? "match" : "unmatched";
    dispatch(FaceRecognitionActions.uptaeSelectedTabFaceRec(tabValue));
    dispatch({
      type: "UPDATE_CURRENT_PAGE_FACE_RECOGNITION",
      payload: 1,
    });
  };
  const faceList = useSelector(
    (state) => state.faceRecognition.faceDetectedList
  );
  const [currentPage, setCurrentPage] = useState(1);
  const currentPageSaved = useSelector(
    (state) => state.faceRecognition.currentPage
  );

  useEffect(() => {
    setCurrentPage(currentPageSaved);
    handleCurrentPageData(
      (currentPageSaved - 1) * pageSize,
      currentPageSaved * pageSize
    );
  }, [currentPageSaved]);
  const [currentPageData, setCurrentPageData] = useState([]);
  function handleCurrentPageData(startIndex, endIndex) {
    setCurrentPageData(faceList.slice(startIndex, endIndex));
  }
  const selectedFacility = useSelector(
    (state) => state.faceRecognition.selectedFacility
  );
  useEffect(() => {
    if (selectedFacility.value !== "all") {
      Account.getCamerasByStore(selectedFacility.value)(dispatch);
    }
  }, [selectedFacility]);
  const handlePagination = (newPage) => {
    setCurrentPage(newPage);

    dispatch({
      type: "UPDATE_CURRENT_PAGE_FACE_RECOGNITION",
      payload: newPage,
    });
  };

  useEffect(() => {
    handleCurrentPageData(
      (currentPageSaved - 1) * pageSize,
      currentPageSaved * pageSize
    );
  }, [faceList]);

  const hidePagination =
    dataLoading || faceList.length == 0 || selectedFacility.value == "all";
  return (
    <Paper
      elevation={0}
      className="custom-tab"
      sx={{
        backgroundColor: "transparent",
        color: "var(--side-item-color)",
        marginLeft: "20px",
      }}
    >
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="All" {...a11yProps("all")} />
          <Tab label="Matched" {...a11yProps("matched")} />
          <Tab label="Unmatched" {...a11yProps("unmatched")} />
        </Tabs>
      </Box>
      <div className="row m-2">
        {dataLoading && (
          <div className="d-flex justify-content-center align-items-center">
            <div>
              <center>
                <Bars color="var(--black-white-darkmode)" />
              </center>
            </div>
          </div>
        )}
        {selectedFacility.value === "all" ? (
          <div className="d-flex justify-content-center align-items-center">
            <h3>Please Select a Facility</h3>
          </div>
        ) : dataLoading ? (
          <div className="loading-container">
            <div className="d-flex justify-content-center align-items-center">
              <div>Loading...</div>
            </div>
          </div>
        ) : faceList.length === 0 ? (
          <div className="d-flex flex-column justify-content-center align-items-center">
            <CreditCardOffIcon
              sx={{ fontSize: "60px", color: "var(--black-white-darkmode)" }}
            />
            <h2
              style={{
                color: "var(--black-white-darkmode)",
                textAlign: "center",
              }}
            >
              No Data to Show
            </h2>
          </div>
        ) : selectedView === "grid" ? (
          currentPageData.map((data, index) => (
            <div className="col-12 col-sm-12 col-md-6 col-lg-4" key={index}>
              <FaceCard
                imageUrl1={data.image}
                imageUrl2={
                  data.is_recognized ? data.known_face_id.image : unknownPerson
                }
                facility={
                  data.facility_id ? data.facility_id.facility_name : "N/A"
                }
                date={data.created_at}
                time={data.created_at}
                id={data.id}
                camera={data.camera_id ? data.camera_id.camera_name : "N/A"}
                matched={data.is_recognized}
              />
            </div>
          ))
        ) : (
          <FaceRecognitionTable rowData={currentPageData} />
        )}
      </div>
      <div className="pagination-div">
        {!hidePagination && (
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={faceList.length}
            pageSize={pageSize}
            onPageChange={(page) => handlePagination(page)}
            usedIn="videowall"
          />
        )}
      </div>
    </Paper>
  );
};

export default FaceRecognitionTabs;
