import axios from "axios";
import OnboardingEndpoints from "../features/OnboardingEndpoints";
import {
  notifiToasterWarn,
  notifiToasterSuccess,
} from "../../components/common/UseToast";

class OnboardingActions {
  static async sendDataToVerification(data) {
    try {
      // Use async/await syntax for the asynchronous operation
      const response = await axios.post(
        OnboardingEndpoints.createUserAndVarifyMail(),
        data
      );
      notifiToasterSuccess(
        `Verification Mail is successfully sent to you on ${data.email}`
      );
      // Log the response data

      // Return true if the request is successful
      return data.email;
    } catch (error) {
      notifiToasterWarn(
        `User with email ${data.email} is already exist. Please login`
      );
      // Handle errors that occur during the axios request
      console.error("Error sending data for verification:", error.message);
      // Return false instead of throwing an error to allow the calling function to handle it as a boolean result
      return false;
    }
  }

  static async addAccountOnboarding(data) {
    try {
      // Use async/await syntax for the asynchronous operation
      const authToken = JSON.parse(localStorage.getItem("onboardUser")).token;
      const response = await axios.post(
        OnboardingEndpoints.createAccountAndOrganisation(),
        data,
        {
          headers: {
            // Include the authorization header with your token
            Authorization: `Token ${authToken}`,
          },
        }
      );
      notifiToasterSuccess(
        `Account Created Successfully for your organisation ${data.name}`
      );

      localStorage.setItem("onboardAccount", JSON.stringify(response.data));
    } catch (error) {
      // Check if the error response contains the specific key for a duplicate user
      if (error.response && error.response.data.error.includes("'user'")) {
        notifiToasterWarn(
          `User with this email id is already registered. Please start the process again.`
        );
      } else {
        notifiToasterWarn("Unable to add account.");
      }

      // Log the error message to the console for debugging
      console.error("Error sending data for verification:", error.message);
      // Optionally, rethrow the error if you want the calling function to handle it
      throw error;
    }
  }

  static async addFacility(data) {
    try {
      // Use async/await syntax for the asynchronous operation
      const authToken = JSON.parse(localStorage.getItem("onboardUser")).token;
      const response = await axios.post(
        OnboardingEndpoints.createFacility(),
        data,
        {
          headers: {
            // Include the authorization header with your token
            Authorization: `Token ${authToken}`,
          },
        }
      );

      let facilityData = response.data;
      facilityData.facility_name = data.facility_name;
      facilityData.address = data.address;
      facilityData.phone_number = data.phone_number;
      facilityData.store_manager_name = data.store_manager_name;
      localStorage.setItem("onboardFacility", JSON.stringify(facilityData));
      notifiToasterSuccess(`${data.facility_name} is created successfully`);
    } catch (error) {
      notifiToasterWarn("User Creation Failed");
      // Handle errors that occur during the axios request
      console.error("Error sending data for verification:", error.message);
      // Optionally, rethrow the error if you want the calling function to handle it
      throw error;
    }
  }
  static async addNVR(data) {
    try {
      // Use async/await syntax for the asynchronous operation
      const authToken = JSON.parse(localStorage.getItem("onboardUser")).token;
      const response = await axios.post(OnboardingEndpoints.createNvr(), data, {
        headers: {
          // Include the authorization header with your token
          Authorization: `Token ${authToken}`,
        },
      });
      // Log the response data
      console.log(response.data);
      const nvrData = response.data;
      nvrData.type = "nvr";
      localStorage.setItem("onboardNvr", JSON.stringify(nvrData));
      notifiToasterSuccess("SuccessFully Added NVR");
      return nvrData.id;
    } catch (error) {
      notifiToasterWarn("NVR Creation Failed");
      console.error("Error sending data for verification:", error.message);
    }
  }
  static async createCameraAutocreate(nvr_id) {
    try {
      const authToken = JSON.parse(localStorage.getItem("onboardUser")).token;
      const response = await axios.post(
        OnboardingEndpoints.createCameraAuto(nvr_id),
        {},
        {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      );
      notifiToasterSuccess("SuccessFully Added NVR");
      return response.data.created_cameras; // Return response data on success
    } catch (error) {
      notifiToasterWarn("NVR Creation Failed");
      console.error("Error sending data for verification:", error.message);
      return []; // Return empty array on error
    }
  }
  static async updateCamerasDetails(cam_id, data, isStandalone) {
    try {
      const authToken = JSON.parse(localStorage.getItem("onboardUser")).token;
      const response = await axios.patch(
        OnboardingEndpoints.updateCameraInfo(cam_id),
        data,
        {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      );

      if (isStandalone) {
        let storageData = JSON.parse(localStorage.getItem("standaloneCamera")); // Assuming 'storageData' is the key where data is stored
        if (storageData && storageData.cameras) {
          const cameraIndex = storageData.cameras.findIndex(
            (camera) => camera.id === cam_id
          );
          if (cameraIndex !== -1) {
            storageData.cameras[cameraIndex] = {
              ...storageData.cameras[cameraIndex],
              ...data,
              id: cam_id,
            };
            localStorage.setItem(
              "standaloneCamera",
              JSON.stringify(storageData)
            );
          }
        }
        // Success notification
      }
      notifiToasterSuccess(
        `Camera Details updated for Channel No ${data.channel_number}`
      );
      return true; // Return response data on success
    } catch (error) {
      // Warning notification
      notifiToasterWarn(`Failed to update Details for ${data.channel_number}`);
      console.error("Error sending data for verification:", error.message);
      return false; // Return empty array on error
    }
  }

  static async updateNVRDetails(nvr_id, data) {
    try {
      const authToken = JSON.parse(localStorage.getItem("onboardUser")).token;
      const response = await axios.patch(
        OnboardingEndpoints.updateNvrInfo(nvr_id),
        data,
        {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      );
      console.log(response.data);
      return true; // Return response data on success
    } catch (error) {
      console.error("Error sending data for verification:", error.message);
      return false; // Return empty array on error
    }
  }
  static async createStandaloneCameraAndNVR(data) {
    try {
      const authToken = JSON.parse(localStorage.getItem("onboardUser")).token;
      const response = await axios.post(
        OnboardingEndpoints.createStandaloneCameraAndNvr(),
        data,
        {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      );

      const nvrData = response.data;

      // Prepare the new standaloneCamera object
      const newStandaloneCamera = {
        nvr: [
          {
            id: nvrData.nvr_id,
            value: nvrData.nvr_name,
          },
        ],
        cameras: [
          {
            camera_name: nvrData.camera_name,
            channel_number: nvrData.channel_number,
            main_rtsp_url: nvrData.main_rtsp_url,
            is_active: true,
            live_view: false,
            id: nvrData.camera_id,
          },
        ],
      };

      // Check if standaloneCamera data already exists in localStorage
      let existingData = localStorage.getItem("standaloneCamera");
      if (existingData) {
        existingData = JSON.parse(existingData);
        // If exists, append new NVR and camera details to respective arrays
        existingData.nvr.push(...newStandaloneCamera.nvr);
        existingData.cameras.push(...newStandaloneCamera.cameras);
        localStorage.setItem("standaloneCamera", JSON.stringify(existingData));
      } else {
        // If doesn't exist, save new standaloneCamera data to localStorage
        localStorage.setItem(
          "standaloneCamera",
          JSON.stringify(newStandaloneCamera)
        );
      }

      return nvrData.nvr_id; // Return response data on success
    } catch (error) {
      console.error("Error sending data for verification:", error.message);
      return false; // Return empty array on error
    }
  }

  static async updateAccountInfo(acc_id, data) {
    try {
      const authToken = JSON.parse(localStorage.getItem("onboardUser")).token;
      const response = await axios.patch(
        OnboardingEndpoints.updateAccountInfo(acc_id),
        data,
        {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      );

      const nvrData = response.data;
      nvrData.type = "standalone";
      notifiToasterSuccess("SuccessFully Updated");
      return true; // Return response data on success
    } catch (error) {
      console.error("Error sending data for verification:", error.message);
      return false; // Return empty array on error
    }
  }
  static async deleteCameras(nvr) {
    try {
      const authToken = JSON.parse(localStorage.getItem("onboardUser")).token;
      const response = await axios.delete(
        OnboardingEndpoints.deleteCamerasOfNvr(nvr),
        {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      );
    } catch (error) {
      console.error("Error sending data for verification:", error.message);
      return false; // Return empty array on error
    }
  }
  static async createDefaultProfiles(data) {
    try {
      const authToken = JSON.parse(localStorage.getItem("onboardUser")).token;
      const response = await axios.post(
        OnboardingEndpoints.createDefaultProfile(),
        data,
        {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      );
      notifiToasterSuccess("SuccessFully Added Profile");
    } catch (error) {
      notifiToasterSuccess("Error in adding profile");
      return []; // Return empty array on error
    }
  }
}
export default OnboardingActions;
