import { BACKEND_URL } from "../../routes/config";
class OnboardingEndpoints {
  constructor() {
    /**
     * Returns the URL for creating a new user and verifying email.
     * @returns {string} The URL for creating a new user and verifying email.
     */
    this.createUserAndVarifyMail = () => {
      return `${BACKEND_URL}/onboarding/user_new/`;
    };

    /**
     * Returns the URL for creating a new account and organization.
     * @returns {string} The URL for creating a new account and organization.
     */
    this.createAccountAndOrganisation = () => {
      return `${BACKEND_URL}/onboarding/account_new/`;
    };

    /**
     * Returns the URL for creating a new facility.
     * @returns {string} The URL for creating a new facility.
     */
    this.createFacility = () => {
      return `${BACKEND_URL}/onboarding/facility_new/`;
    };

    /**
     * Returns the URL for creating a new NVR.
     * @returns {string} The URL for creating a new NVR.
     */
    this.createNvr = () => {
      return `${BACKEND_URL}/operations/nvr/`;
    };

    /**
     * Returns the URL for creating bulk cameras for a given NVR.
     * @param {string} nvr_id - The NVR ID.
     * @returns {string} The URL for creating bulk cameras for a given NVR.
     */
    this.createCameraAuto = (nvr_id) => {
      return `${BACKEND_URL}/operations/bulk_camera/${nvr_id}`;
    };

    /**
     * Returns the URL for updating camera information.
     * @param {string} cam_id - The camera ID.
     * @returns {string} The URL for updating camera information.
     */
    this.updateCameraInfo = (cam_id) => {
      return `${BACKEND_URL}/operations/camera/${cam_id}/`;
    };

    /**
     * Returns the URL for updating NVR information.
     * @param {string} nvr_id - The NVR ID.
     * @returns {string} The URL for updating NVR information.
     */
    this.updateNvrInfo = (nvr_id) => {
      return `${BACKEND_URL}/operations/nvr/${nvr_id}/`;
    };

    /**
     * Returns the URL for creating a standalone camera and NVR.
     * @returns {string} The URL for creating a standalone camera and NVR.
     */
    this.createStandaloneCameraAndNvr = () => {
      return `${BACKEND_URL}/onboarding/nvr_new/`;
    };

    /**
     * Returns the URL for updating account information.
     * @param {string} id - The account ID.
     * @returns {string} The URL for updating account information.
     */
    this.updateAccountInfo = (id) => {
      return `${BACKEND_URL}/operations/accounts/${id}/`;
    };

    /**
     * Returns the URL for deleting cameras of a given NVR.
     * @param {string} id - The NVR ID.
     * @returns {string} The URL for deleting cameras of a given NVR.
     */
    this.deleteCamerasOfNvr = (id) => {
      return `${BACKEND_URL}/onboarding/delete_cameras_from_nvr/${id}`;
    };

    /**
     * Returns the URL for creating a default profile.
     * @returns {string} The URL for creating a default profile.
     */
    this.createDefaultProfile = () => {
      return `${BACKEND_URL}/controlled_access/create_default_profiles`;
    };

    /**
     * Returns the URL for creating a facility independently.
     * @returns {string} The URL for creating a facility independently.
     */
    this.createFacilityIndependently = () => {
      return `${BACKEND_URL}/operations/facility/`;
    };

    /**
     * Returns the URL for creating a facility profile mapping.
     * @returns {string} The URL for creating a facility profile mapping.
     */
    this.createFacilityProfileMapping = () => {
      return `${BACKEND_URL}/operations/facilityprofilemapping/`;
    };
  }
}
export default new OnboardingEndpoints();
