import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import { ListItemButton, ListItemIcon } from "@mui/material";
import PropTypes from "prop-types";

const SidebarItem = ({ item }) => {
  const state = useSelector((state) => state);
  const toggle = state.commonData.toggleSidebar;
  const location = useLocation();
  const [isActive, setIsActive] = useState(location.pathname === item.path);

  useEffect(() => {
    setIsActive(location.pathname === item.path);
  }, [location.pathname, item.path]);

  return item.sidebarProps && item.path ? (
    <ListItemButton
      component={Link}
      to={item.path}
      onClick={() => setIsActive(!isActive)}
      sx={{
        "&:hover": {
          backgroundColor: "var(--top-bar-bg-color)",
        },
        borderRadius: "10px",
        paddingY: "12px",
        paddingX: "12px",
      }}
    >
      <ListItemIcon
        sx={{
          color: isActive ? "#4318FF" : "var(--card-text-color)",
        }}
      >
        {item.sidebarProps.icon && item.sidebarProps.icon}
      </ListItemIcon>
      {!toggle && (
        <h5
          style={{
            margin: "0px",
            padding: "0px",
            color: isActive ? "#4318FF" : "var(--card-text-color)",
          }}
        >
          {item.sidebarProps.displayText}
        </h5>
      )}
    </ListItemButton>
  ) : null;
};

SidebarItem.propTypes = {
  item: PropTypes.shape({
    path: PropTypes.string.isRequired,
    sidebarProps: PropTypes.shape({
      icon: PropTypes.node,
      displayText: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default SidebarItem;
