import React, { useEffect, useState, useMemo } from "react";
import "video.js/dist/video-js.css";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";
import VideoPlayerMP4 from "./VideoPlayer/VideoPlayerMP4";
import VmsLiveviewActions from "../../../redux/Action/VmsActions";
import VideoPlayerIframe from "./VideoPlayer/VideoPlayerIframe";
import { notifiToasterWarn } from "../../../components/common/UseToast";
import VmsHelperActions from "../../../redux/Action/VmsHelperActions";
const findRecordingSegment = (selectedTime, recordingData) => {
  const [hours, minutes] = selectedTime.split(":").map(Number);
  const selectedMinutes = hours * 60 + minutes;

  for (let i = 0; i < recordingData.length; i++) {
    const [startHours, startMinutes] = recordingData[i].start_time
      .split(":")
      .map(Number);
    const [endHours, endMinutes] = recordingData[i].end_time
      .split(":")
      .map(Number);

    const startTotalMinutes = startHours * 60 + startMinutes;
    const endTotalMinutes = endHours * 60 + endMinutes;

    if (
      selectedMinutes >= startTotalMinutes &&
      selectedMinutes < endTotalMinutes
    ) {
      const timeDifferenceInSeconds =
        (selectedMinutes - startTotalMinutes) * 60;
      return { index: i, timeDifferenceInSeconds };
    }
  }
  return null;
};

const PlaybackVideoPlayer = ({ src, onHide, usedIn }) => {
  const dispatch = useDispatch();
  const [timeToSeek, setTimeToSeek] = useState(0);
  const [loading, setLoading] = useState(false);
  const [streamToPlay, setStreamToPlay] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);

  const playbackSelectedTime = useSelector(
    (state) => state.vms.playbackSelectedTime
  );
  const activePlaybackMethod = useSelector(
    (state) => state.vms.activePlaybackMethod
  );
  const playbackSelectedDate = useSelector(
    (state) => state.vms.playbackSelectedDate
  );
  const playbackCamera = useSelector((state) => state.vms.playbackCamera);
  const availableRecording = useSelector((state) => state.vms.timeline);
  const playbackStream = useSelector((state) => state.vms.playbackFeed);

  const dispatchGetPlaybackStream = (startTime) => {
    const formattedDate = moment(playbackSelectedDate).format("YYYY-MM-DD");
    const data = {
      cam_id: playbackCamera[0],
      date: formattedDate,
      startTime: startTime + ":00",
      endTime: "23:59:59",
    };
    dispatch(VmsLiveviewActions.getPlaybackStream(data));
    setLoading(false);
  };

  // Generalized effect to manage playback stream
  const handlePlayback = (selectedTime) => {
    setLoading(true);
    const data = findRecordingSegment(selectedTime, availableRecording);

    if (data && availableRecording[data.index].is_available) {
      setActiveIndex(data.index);
      setTimeToSeek(data.timeDifferenceInSeconds);
      setStreamToPlay(availableRecording[data.index].file_url);
    } else {
      notifiToasterWarn("Recording for this time is not available");
      setActiveIndex(data?.index || 0);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (activePlaybackMethod === "cloud") {
      handlePlayback(playbackSelectedTime);
    } else {
      dispatchGetPlaybackStream(playbackSelectedTime);
    }
  }, [
    playbackSelectedTime,
    playbackCamera,
    activePlaybackMethod,
    playbackSelectedDate,
    availableRecording,
  ]);

  useEffect(() => {
    dispatch({ type: "UPDATE_PLAYBACK_SELECTED_TIME", payload: "00:00" });
    const formattedDate = moment(playbackSelectedDate).format("YYYY-MM-DD");
    const data = {
      cam_id: playbackCamera[0],
      date: formattedDate,
      startTime: "00:00:00",
      endTime: "23:59:00",
    };
    dispatch(VmsHelperActions.updateLoadingForPlayback([data.cam_id]));

    dispatch(
      activePlaybackMethod === "hdd"
        ? VmsLiveviewActions.getPlaybackChunkDetailsFromNvrAction(data)
        : VmsLiveviewActions.getAvailableRecordingChunk(data)
    ).finally(() => {
      dispatch(VmsHelperActions.updateLoadingForPlayback([data.cam_id]));
    });
    if (activePlaybackMethod === "cloud") {
      handlePlayback("00:00");
    } else {
      dispatchGetPlaybackStream("00:00");
    }
  }, [activePlaybackMethod]);

  const getNextVideo = () => {
    const data = availableRecording[activeIndex];
    setStreamToPlay(data.is_available ? data.file_url : "");
  };
  console.log(playbackStream);
  return (
    <div style={{ height: "90%" }}>
      {activePlaybackMethod === "cloud" ? (
        <VideoPlayerMP4
          src={streamToPlay}
          timeToSeek={timeToSeek}
          getNextVideo={getNextVideo}
        />
      ) : (
        !loading &&
        playbackStream.length > 0 && (
          <VideoPlayerIframe src={playbackStream[0]} />
        )
      )}
    </div>
  );
};

PlaybackVideoPlayer.propTypes = {
  onHide: PropTypes.func.isRequired,
  src: PropTypes.object.isRequired,
  usedIn: PropTypes.string.isRequired,
};

export default PlaybackVideoPlayer;
