import axios from "axios";
import AlertEventMergedEndpoints from "../features/AlertEventMergedEndpoints";
// get Store
import buildUrlWithParams from "./AddUrlsWIthParams";
import { notifiToasterWarn } from "../../components/common/UseToast";
import ApiSpec from "../features/EndPoints";
class AlertEventTableActions {
  /**
   * Fetches the data for the alert table based on various parameters.
   * The parameters include user profile id, facility id, camera id, date, date
   * range, search, alert type, and priority. It handles errors and optimizes the data.
   * If an error occurs, or the response is not in the provisioned format, the function
   * stops the loading and dispatches an action with a predefined structure.
   *
   * @param {object} params - Parameters used to form the URL for the data request.
   * @param {boolean} showLoading - Indicator if the loading state should be displayed.
   * @return {function} - A dispatch function that performs async data fetch and state update
   */
  static currentRequestCancelToken = null;
  static AlertEventMergedAction(params, showLoading) {
    return async (dispatch) => {
      // Check if loading state is enabled

      if (showLoading)
        dispatch({ type: "UPDATE_LOADING_DATA_FOR_TABLE", payload: true });
      // Cancel the previous request if it exists
      if (AlertEventTableActions.currentRequestCancelToken) {
        AlertEventTableActions.currentRequestCancelToken.cancel(
          "A new request was made, cancelling the previous request."
        );
      }
      // Get endpoints for building URL
      const urls = AlertEventMergedEndpoints.getMergedTableDataUsingParams();

      // Fetch authentication token from local storage
      const token = localStorage.getItem("token");

      // Set headers for the request
      const headers = { Authorization: `Token ${token}` };
      AlertEventTableActions.currentRequestCancelToken =
        axios.CancelToken.source();

      // Execute GET request using Axios to fetch data
      axios
        .get(buildUrlWithParams(params, urls), {
          headers,
          cancelToken: AlertEventTableActions.currentRequestCancelToken.token,
        })
        .then((response) => {
          // Check if the format of response data is valid
          const isValidFormat =
            typeof response.data === "object" &&
            Object.prototype.hasOwnProperty.call(response.data, "count") &&
            Array.isArray(response.data.data);

          // Dispatch action with received data if format is valid else with a standard format
          dispatch({
            type: "TABLE_DATA_ALERT",
            payload: isValidFormat ? response.data : { count: 0, data: [] },
          });
          dispatch({
            type: "UPDATE_LOADING_DATA_FOR_TABLE",
            payload: false,
          });
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            //request is canceled via cancel token
          } else {
            // Handle other errors
            dispatch({
              type: "TABLE_DATA_ALERT",
              payload: { count: 0, data: [] },
            });
            dispatch({
              type: "UPDATE_LOADING_DATA_FOR_TABLE",
              payload: false,
            });
          }
        })
        .finally(() => {
          // Turn off the loading state after the request is processed
          AlertEventTableActions.currentRequestCancelToken = null;
        });
    };
  }
  static getPlaybackStreamAlertEvent(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      await axios
        .get(
          ApiSpec.getPlaybackStream(
            data.cam_id,
            data.date,
            data.startTime,
            data.endTime
          ),
          { headers }
        )
        .then((response) => {
          if (response.data.length == 0) {
            notifiToasterWarn(
              "Sorry, there is no recording available for the selected time."
            );
            dispatch({
              type: "UPDATE_ALERT_EVENT_PLAYBACK_STREAM",
              payload: [],
            });
          } else {
            dispatch({
              type: "UPDATE_ALERT_EVENT_PLAYBACK_STREAM",
              payload: [response.data],
            });
            const arr = JSON.parse(sessionStorage.getItem("NewItems")) || [];
            const res_data = arr.concat(response.data);
            sessionStorage.setItem("NewItems", JSON.stringify(res_data));
          }
        })
        .catch(() => {
          dispatch({
            type: "UPDATE_ALERT_EVENT_PLAYBACK_STREAM",
            payload: [],
          });
        });
    };
  }
}
export default AlertEventTableActions;
