import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import AddEditProfileDialog from "./AddEditProfile";
import ConfirmationDialog from "../../../../components/common/ConfirmationDailog";
import ManageUsers from "../ProfileTab/ManageUsers";
import { useDispatch, useSelector } from "react-redux";
import AccessControlActions from "../../../../redux/Action/AccessControlAction";
import PropTypes from "prop-types";

const ProfileTable = ({ searchValue }) => {
  const [isDeleteUserOpen, setIsDeleteUserOpen] = useState(false);
  const [isAddEditMemberOpen, setIsAddEditMemberOpen] = useState(false);
  const [isManageMemberOpen, setIsManageMemberOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(-1);
  const dispatch = useDispatch();
  const [access_permission] = useState(
    JSON.parse(localStorage.getItem("agx_permission")).Profile ?? []
  );
  const isDeleteEdirProfilePermission =
    access_permission.includes("delete_profiles") ||
    access_permission.includes("edit_profiles");
  const isProfileLoading = useSelector(
    (state) => state.accessControl.profileLoading
  );
  const profileList = useSelector((state) => state.accessControl.profileList);
  const filteredProfileList = useSelector(
    (state) => state.accessControl.filteredProfile
  );

  useEffect(() => {
    const userprofileId = JSON.parse(localStorage.getItem("profile")).id;
    dispatch(AccessControlActions.getPermissionList());
    dispatch(AccessControlActions.getProfileListViaUserprofile(userprofileId));
  }, [dispatch]); // added dispatch as a dependency, although it's likely constant

  const handleDeleteUser = (userId) => {
    setIsDeleteUserOpen(true);
    setSelectedUserId(userId);
  };

  const handleEditUser = (userId) => {
    setIsAddEditMemberOpen(true);
    setSelectedUserId(userId);
  };

  const handleManageMember = (userId) => {
    setIsManageMemberOpen(true);
    setSelectedUserId(userId);
  };

  const handleDeleteProfile = async () => {
    try {
      // Await the dispatch call to delete the profile
      await dispatch(
        AccessControlActions.deleteProfileToAccessControl(selectedUserId)
      );

      // After the profile has been successfully deleted, proceed to get the profile list
      const userprofileId = JSON.parse(localStorage.getItem("profile")).id;
      await dispatch(
        AccessControlActions.getProfileListViaUserprofile(userprofileId)
      );

      // Close the delete user modal/dialog
      setIsDeleteUserOpen(false);
    } catch (error) {
      console.error("Error during profile deletion:", error.message);
      // Optionally, handle the error (e.g., showing an error message to the user)
    }
  };

  return (
    <div>
      <TableContainer component={Paper} className="mt-3">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>S.No.</TableCell>
              <TableCell>Profile Type</TableCell>
              <TableCell>No. Of Users</TableCell>
              <TableCell>Permissions</TableCell>
              {isDeleteEdirProfilePermission && (
                <TableCell align="center">Actions</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {isProfileLoading ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  Loading Data ...
                </TableCell>
              </TableRow>
            ) : searchValue.length > 0 && filteredProfileList.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No Data Found
                </TableCell>
              </TableRow>
            ) : (
              (searchValue.length === 0
                ? profileList
                : filteredProfileList
              ).map((profile, index) => (
                <TableRow key={profile.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{profile.profile_name}</TableCell>
                  <TableCell>{profile.selected_user.length}</TableCell>
                  <TableCell>{profile.permission.length}</TableCell>
                  {isDeleteEdirProfilePermission && (
                    <TableCell align="center">
                      <span>
                        {access_permission.includes("edit_profiles") && (
                          <IconButton
                            aria-label="edit"
                            onClick={() => handleEditUser(profile.id)}
                          >
                            <ModeEditIcon />
                          </IconButton>
                        )}
                        {access_permission.includes("edit_profiles") && (
                          <IconButton
                            aria-label="manage members"
                            onClick={() => handleManageMember(profile.id)}
                          >
                            <GroupAddIcon />
                          </IconButton>
                        )}
                        {access_permission.includes("delete_profiles") && (
                          <IconButton
                            aria-label="delete"
                            onClick={() => handleDeleteUser(profile.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </span>
                    </TableCell>
                  )}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <ConfirmationDialog
        title="Delete Profile"
        dialogContent="Are you sure to delete the Profile?"
        handleConfirm={handleDeleteProfile}
        handleDisconfirm={() => setIsDeleteUserOpen(false)}
        isOpen={isDeleteUserOpen}
        handleClose={() => setIsDeleteUserOpen(false)}
        confirmButtonLabel="Yes"
        disconfirmButtonLabel="No"
        confirmButtonVariant="contained"
        disconfirmButtonVariant="outlined"
      />

      <AddEditProfileDialog
        isOpen={isAddEditMemberOpen}
        handleClose={() => setIsAddEditMemberOpen(false)}
        userId={selectedUserId}
        isEditable={true}
        profileList={profileList}
      />

      <ManageUsers
        isOpen={isManageMemberOpen}
        handleClose={() => setIsManageMemberOpen(false)}
        userId={selectedUserId}
      />
    </div>
  );
};

ProfileTable.propTypes = {
  searchValue: PropTypes.string,
};

export default ProfileTable;
