import React, { useEffect, useState } from "react";
import CheckboxAutocomplete from "../Profile/AccessControl/CommonComponent/CheckboxAutoComplete";
import { Button } from "@mui/material";
import OnboardingActions from "../../redux/Action/OnboardingActions";
import {
  notifiToasterSuccess,
  notifiToasterWarn,
} from "../../components/common/UseToast";
import { useDispatch } from "react-redux";

const OnboardServices = () => {
  const [selectNvr, setSelectNVR] = useState([]);
  const [nvrList, setNVRList] = useState([]);
  const [isCamera, setIsCamera] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    try {
      const nvrData = JSON.parse(localStorage.getItem("onboardNvr"));
      if (nvrData && nvrData.id && nvrData.name) {
        const nvrOptions = [{ id: nvrData.id, value: nvrData.name }];
        setNVRList(nvrOptions);
      } else {
        const camerasData = JSON.parse(
          localStorage.getItem("standaloneCamera")
        );
        setNVRList(camerasData.nvr);
        setIsCamera(true);
      }
    } catch (error) {
      console.error("Error parsing NVR data from localStorage:", error);
    }
  }, []);

  const handleChmsEnable = async () => {
    const savedOption = [];
    for (const nvr of selectNvr) {
      const { id, value } = nvr;
      const data = { cloud_monitoring_enabled: true };
      const isSuccess = await OnboardingActions.updateNVRDetails(id, data);
      if (isSuccess) {
        notifiToasterSuccess(`CHMS is Enabled on ${value}`);
        savedOption.push(nvr);
      } else {
        notifiToasterWarn(`Unable to turn on the CHMS on ${value}`);
      }
      const onboardAccount = JSON.parse(
        localStorage.getItem("onboardAccount") || "null"
      );
      const account_id = onboardAccount?.account_id;
      await OnboardingActions.createDefaultProfiles({ account_id: account_id });
    }
    localStorage.setItem("completedStep", 6);
    dispatch({ type: "updateCurrentStep", payload: 6 });
    localStorage.setItem("chmsEnableOn", JSON.stringify(savedOption));
  };

  return (
    <>
      {<h2 className="mt-2 mb-4">Select Services</h2>}
      <div className="row" style={{ width: "100%" }}>
        <div className="col-12 col-md-6">
          <CheckboxAutocomplete
            value={selectNvr}
            onChange={(event, newValue) => setSelectNVR(newValue)}
            label={!isCamera ? "Select NVRs" : "Select Cameras"}
            heading="Enable CHMS"
            options={nvrList}
            size="medium"
            error={false}
          />
        </div>
      </div>

      <div className="submit-button-onboarding mt-4">
        <Button
          variant="contained"
          color="primary"
          sx={{ width: "auto" }}
          onClick={handleChmsEnable}
        >
          Enable Service
        </Button>
      </div>
    </>
  );
};

export default OnboardServices;
