import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import DashBarChart from "../../../components/charts/DashBarChart";
import { DashWeekBarChart } from "../../../components/charts/DashWeekBarChart";
import DashAreaChart from "../../../components/charts/DashAreaChart";
import DashboardFunction from "../DashboardFunctions";
import DemographicsFunction from "../DemographicsFunctions";

const PeopleCountCharts = ({ selectedMode }) => {
  const { services_selected: servicesSelected } = JSON.parse(
    localStorage.getItem("profile") || "{}"
  );
  const { Dashboard: accessPermissions = [] } = JSON.parse(
    localStorage.getItem("agx_permission") || "{}"
  );

  const retailData = useSelector((state) => state.retailData);
  const genderData = useSelector((state) => state.gender);

  const isPeopleCountEnabled =
    servicesSelected?.Dashboard.includes("people_count");
  const isPasserByEnabled = servicesSelected?.Dashboard.includes("passer_by");
  const canViewPasserByAnalytics = accessPermissions.includes(
    "view_passer_by_analytics"
  );
  const isDemographicsEnabled =
    servicesSelected?.Dashboard.includes("demographics");
  const canViewDemographics = accessPermissions.includes(
    "view_demographics_analytics"
  );
  const isDayWise = selectedMode.value === "day-wise";

  return (
    <div>
      {isPeopleCountEnabled && canViewPasserByAnalytics && (
        <div className="row">
          {isDayWise ? (
            <>
              <div className="col-xxl-6">
                <DashBarChart
                  classNameActive={true}
                  data={DashboardFunction.pcPbGraphData(
                    retailData.peopleCountHourly,
                    retailData.passerCountHourly
                  )}
                  type="hourly"
                  title="People Count & Passerby Count"
                />
              </div>
              <div className="col-xxl-6">
                <DashAreaChart
                  classNameActive={true}
                  data={DashboardFunction.weekDayGraphData(
                    retailData.weeklyPeopleCount,
                    retailData.weeklyPasserCount
                  )}
                  type="week"
                  title="Week Day Comparison"
                  labelToShowY="People"
                />
              </div>
            </>
          ) : (
            <>
              <div className="col-xxl-6">
                <DashWeekBarChart
                  classNameActive={true}
                  data={DashboardFunction.specificDayGraphData(
                    retailData.peopleCountDayData,
                    retailData.passerCountDayData
                  )}
                  xlabel="date"
                  title="People Count & Passer by Count"
                />
              </div>
              {isDemographicsEnabled && canViewDemographics && (
                <div className="col-xxl-6">
                  <DashAreaChart
                    classNameActive={true}
                    data={DemographicsFunction.maleFemaleDayGraphData(
                      genderData.maleFemaleDayData
                    )}
                    selectedModeBypage="dateRange"
                    type="dateRange"
                    title="Demographics Day Wise"
                    labelToShowY="People"
                  />
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

PeopleCountCharts.propTypes = {
  selectedMode: PropTypes.object.isRequired,
};

export default PeopleCountCharts;
