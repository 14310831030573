import { React, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import moment from "moment";

const Areachart = (propTypes) => {
  const [tickAmount, settickAmount] = useState(0);
  const [series, setseries] = useState([]);
  const [selectedMode, setselectedMode] = useState("hourly");

  useEffect(() => {
    if (
      propTypes.data.length > 0 &&
      Object.prototype.hasOwnProperty.call(propTypes.data[0], "data")
    ) {
      assignData(propTypes.data);
    }
  }, [propTypes.data]);

  const assignData = (val) => {
    settickAmount(val[0].data.length);
    let seriesTemp = [];
    for (let i = 0; i < val.length; i++) {
      seriesTemp.push({
        name: val[i].name,
        data: val[i].data,
      });
      if (tickAmount < val[i].data.length) {
        settickAmount(val[i].data.length);
      }
    }
    setseries(seriesTemp);
  };

  function xaxisForChart() {
    if (propTypes.type === "week") {
      return {
        type: "date",
        labels: {
          show: false, // disable x axis labels
          formatter: (val) => {
            if (selectedMode === "hourly") {
              return moment(new Date(val)).format("ddd");
            }
            return moment(new Date(val)).format("DD-MM-YY");
          },
        },
        // title: {
        //   text: "Day",
        // },
        tooltip: {
          enabled: false,
        },
      };
    } else if (propTypes.type === "daterange") {
      return {
        type: "datetime",
        tickAmount:
          tickAmount === 0 ? 0 : tickAmount === 1 ? 2 : tickAmount - 1,
        labels: {
          show: false, // disable x axis labels
          formatter: (val) => {
            return moment(new Date(val)).format("DD-MM-YY");
          },
        },
        tooltip: {
          enabled: false,
        },
      };
    } else {
      return {
        type: "datetime",
        tickAmount:
          tickAmount === 0 ? 0 : tickAmount === 1 ? 2 : tickAmount - 1,
        labels: {
          show: false, // disable x axis labels
          formatter: (val) => {
            if (selectedMode === "hourly") {
              return moment(new Date(val)).format("h A");
            }
            return moment(new Date(val)).format("DD-MM-YY");
          },
        },
        tooltip: {
          enabled: false,
        },
      };
    }
  }

  function toolTipObj() {
    if (propTypes.type === "week") {
      return {
        shared: true,
        intersect: false,
        followCursor: true,
        x: {
          formatter: (val, { seriesIndex, dataPointIndex, w }) => {
            return moment(
              w.config.series[seriesIndex].data[dataPointIndex].x
            ).format("dddd, MMMM D YYYY");
          },
        },
        y: {
          formatter: (value, { series, seriesIndex }) => {
            const total = series[seriesIndex].reduce(
              (acc, cur) => acc + cur,
              0
            );
            return `${value} (${((value / total) * 100).toFixed(2)}%)`;
          },
        },
      };
    } else if (propTypes.type === "daterange") {
      return {
        shared: true,
        intersect: false,
        followCursor: true,
        x: {
          formatter: (val, { seriesIndex, dataPointIndex, w }) => {
            return moment(
              w.config.series[seriesIndex].data[dataPointIndex].x
            ).format("dddd, MMMM D YYYY");
          },
        },
        y: {
          formatter: (value, { series, seriesIndex }) => {
            const total = series[seriesIndex].reduce(
              (acc, cur) => acc + cur,
              0
            );
            return `${value} (${((value / total) * 100).toFixed(2)}%)`;
          },
        },
      };
    } else {
      return {
        shared: true,
        intersect: false,
        followCursor: true,
        x: {
          formatter: (val) => {
            return moment(val).format("MMMM D YYYY, h:mm A");
          },
        },
        y: {
          formatter: (value, { series, seriesIndex }) => {
            const total = series[seriesIndex].reduce(
              (acc, cur) => acc + cur,
              0
            );
            return `${value} (${((value / total) * 100).toFixed(2)}%)`;
          },
        },
      };
    }
  }

  const options = {
    fill: {
      opacity: 0.8, // set the opacity of the bottom of the chart
    },
    chart: {
      height: 200,
      type: "area",
      stacked: true,
      yaxis: {
        reverse: true,
      },
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      yaxis: {
        lines: {
          show: false,
        },
        labels: {
          show: false, // disable y axis labels
        },
      },
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    // tooltip: toolTipObj(),
    xaxis: xaxisForChart(),
    yaxis: {
      show: false,
    },
    legend: {
      position: "top",
    },
    plotOptions: {
      area: {
        horizontal: false,
        dataLabels: {
          position: "top",
        },
      },
    },
    colors: [propTypes.color],
  };

  return (
    <div className="chart-contaoner">
      <div style={{ marginTop: "16px", overflow: "hidden" }}>
        <Chart options={options} series={series} type="area" height={274} />
      </div>
    </div>
  );
};

export default Areachart;
