import { Drawer, List, Toolbar } from "@mui/material";
import { Stack } from "@mui/system";
import { React } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import sizeConfigs from "../../configs/sizeConfigs";
import assets from "../../assets";
import appRoutes from "../../routes/appRoutes";
import SidebarItem from "./SidebarItem";
import SidebarItemCollapse from "./SidebarItemCollapse";
import KeyboardDoubleArrowRightSharpIcon from "@mui/icons-material/KeyboardDoubleArrowRightSharp";
import KeyboardDoubleArrowLeftSharpIcon from "@mui/icons-material/KeyboardDoubleArrowLeftSharp";
import { Navigate, useLocation } from "react-router-dom";
import Account from "../../redux/Action/CommonAction";
import { useEffect } from "react";
import LiveViewAction from "../../redux/Action/LiveViewAction";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import Tooltip from "@mui/material/Tooltip";
const filterApprouter = (appRoutes) => {
  let location = useLocation();
  <Navigate to="/profile" state={{ from: location }} replace />;
  var profile = JSON.parse(localStorage.getItem("profile"));
  var permissions = JSON.parse(localStorage.getItem("agx_permission"));
  let pageWidthPermission = Object.keys(permissions).map((permission) =>
    permission.replace(/_/g, "").toLowerCase()
  );

  var list = appRoutes.filter(
    (item) =>
      profile[item.state] &&
      pageWidthPermission.includes(item.state.replace(/_/g, "").toLowerCase())
  );
  let newList = appRoutes.filter(
    (item) => profile[item.state] && item.state == "alert_customisation"
  );
  let listWithPermissionOnly = appRoutes.filter(
    (item) =>
      pageWidthPermission.includes(
        item.state.replace(/_/g, "").toLowerCase()
      ) &&
      ["face_recognition", "customization"].includes(item.state) &&
      !list.includes(item)
  );

  return [...list, ...newList, ...listWithPermissionOnly];
};

const Sidebar = () => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const toggle = state.commonData.toggleSidebar;
  const handleToggleSidebar = () => {
    Account.toglleSidebar(!toggle)(dispatch);
  };
  const profile = JSON.parse(localStorage.getItem("profile"));
  let width = toggle ? sizeConfigs.sidebarMin.width : sizeConfigs.sidebar.width;
  const filteredPages = filterApprouter(appRoutes);
  let number = 1;
  useEffect(() => {
    let pagesToShow = filteredPages.map((item) =>
      item.state.replace(/_/g, "").toLowerCase()
    );
    dispatch({ type: "ADD_PAGES_TO_SHOW", payload: pagesToShow });
  }, [number]);
  function handleToggleVideoSidebar() {
    dispatch(Account.toglleVideoSidebar(true));
    dispatch(Account.toglleSidebar(false));
  }
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: width,
        transition: "width 0.0s",
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: width,
          boxSizing: "border-box",
          borderRight: "0px",
          backgroundColor: "var(--side-bar-bg-color)",
          color: "var(--side-item-color)",
          transition: "width 0.0s",
        },
      }}
    >
      <List disablePadding>
        <Toolbar
          sx={{
            marginBottom: "20px",
            display: "flex",
            justifyContent: "space-between",
            overflow: "hidden",
            transition: "width 0.0s",
          }}
        >
          <Stack sx={{ width: "100%" }} direction="row" justifyContent="center">
            <img
              src={
                "client_logo" in profile.account.config
                  ? profile.account.config.client_logo
                  : assets.images.logo
              }
              style={{ width: "95%", height: "50px" }}
              id="sidebar-logo-image"
              className={toggle ? "logo-hidden" : "logo-visible"}
              onClick={handleToggleSidebar}
            />
          </Stack>
          <div
            style={{ marginTop: "25px", cursor: "pointer" }}
            onClick={handleToggleSidebar}
          >
            {toggle ? (
              <KeyboardDoubleArrowRightSharpIcon
                sx={{
                  marginRight: "20px",
                  color: "var(--card-text-color)",
                }}
              />
            ) : (
              <KeyboardDoubleArrowLeftSharpIcon
                sx={{
                  color: "var(--card-text-color)",
                }}
              />
            )}
          </div>
        </Toolbar>
        <div className="mx-2">
          {filteredPages.map((route, index) =>
            route.sidebarProps ? (
              route.child ? (
                <SidebarItemCollapse item={route} key={index} />
              ) : (
                <SidebarItem item={route} key={index} />
              )
            ) : null
          )}
        </div>
        {state.title.title === "Live View" ? (
          <div className="mt-5">
            <Divider>
              <Tooltip title="Click to See Camera List">
                <Chip
                  style={{
                    color: "var(--card-text-color)",
                    backgroundColor: "var(--top-bar-bg-color)",
                  }}
                  label={<KeyboardDoubleArrowDownIcon />}
                  onClick={handleToggleVideoSidebar}
                />
              </Tooltip>
            </Divider>
          </div>
        ) : null}
      </List>
    </Drawer>
  );
};

export default Sidebar;
