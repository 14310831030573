import React from "react";
import PropTypes from "prop-types"; // Import PropTypes
import { useSelector } from "react-redux";
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
} from "@mui/material/styles";
import { getDesignTokens } from "./LightDarkPallet";
import {
  tableTheme,
  tableHeadTheme,
  tableCellTheme,
  tableRowTheme,
} from "./customComponents/TableTheme";
import { switchTheme } from "./customComponents/SwitchTheme";
import { dialogTheme } from "./customComponents/DialogTheme";

export const CustomThemeProvider = ({ children }) => {
  const isDarkMode = useSelector((state) => state.commonData.appTheme);
  const mode = isDarkMode ? "dark" : "light";
  const dynamicPalette = getDesignTokens(mode);
  const theme = createTheme({
    ...dynamicPalette,
    components: {
      ...tableTheme,
      ...tableHeadTheme,
      ...tableCellTheme,
      ...tableRowTheme,
      ...switchTheme,
      ...dialogTheme,
    },
  });

  return <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>;
};

// Define prop types for CustomThemeProvider
CustomThemeProvider.propTypes = {
  children: PropTypes.node.isRequired, // Validate children prop as a node
};
