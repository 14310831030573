import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import CheckboxAutocomplete from "../CommonComponent/CheckboxAutoComplete";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import AccessControlActions from "../../../../redux/Action/AccessControlAction";
import { notifiToasterWarn } from "../../../../components/common/UseToast";
export default function AddEditUserDialog({
  isOpen,
  handleClose,
  isEditable,
  userId,
}) {
  const [selectedOptionsProfile, setSelectedOptionsProfile] = useState([]);
  const dispatch = useDispatch();
  const [prevData, setPrevData] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    username: "",
    password: "",
    profile: "",
  });
  const [errors, setErrors] = useState({});
  const profileList = useSelector((state) => state.accessControl.profileList);
  const [profileOption, setProfileOptions] = useState([]);
  const [prevProfile, setPrevProfile] = useState([]);
  const userList = useSelector((state) => state.accessControl.userList);
  useEffect(() => {
    let formattedProfile = profileList.map((item) => ({
      value: item.profile_name,
      id: item.id,
    }));
    setProfileOptions(formattedProfile);
  }, [profileList]);

  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  useEffect(() => {
    if (isEditable && userId && userList.length > 0) {
      const user = userList.find((item) => item.user_profile_id == userId);
      if (user) {
        // Map user's profile_id array to profile objects
        const userProfiles = user.profile_id
          .map((pid) => profileList.find((profile) => profile.id === pid))
          .filter((profile) => profile !== undefined) // Filter out any undefined entries
          .map((profile) => ({
            value: profile.profile_name,
            id: profile.id,
          }));

        // Set the formData with the first profile's value if available, or an empty string
        setFormData((prevData) => ({
          ...prevData,
          name: user.name,
          username: user.username,
          password: "", // Assuming password should not be directly editable
          profile: userProfiles.length > 0 ? userProfiles[0].value : "",
        }));

        // Set the selected profiles
        setSelectedOptionsProfile(userProfiles);

        // Update prevData to reflect the current state
        setPrevData({
          name: user.name,
          username: user.username,
          profile: userProfiles.map((profile) => profile.id),
        });
      }
    }
  }, [isOpen, isEditable, userId, userList, profileList]);

  const handleSubmit = () => {
    // Validate the form fields
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = "Name is required";
    }

    if (!formData.username) {
      newErrors.username = "Username is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.username)) {
      newErrors.username = "Invalid username. Try like abc@agrexai.com";
    }

    if (!formData.password && !isEditable) {
      newErrors.password = "Password is required";
    } else if (formData.password.length < 5 && !isEditable) {
      newErrors.password = "Password is too short";
    }

    // If there are errors, update the state and prevent form submission
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    if (isEditable) {
      const patchData = {};
      // Check for changes in name and username
      // ["name", "username"].forEach((field) => {
      //   if (formData[field] !== prevData[field]) {
      //     patchData[field] = formData[field];
      //   }
      // });
      patchData.username = formData.username;
      patchData.name = formData.name;
      patchData.toRemoveProfiles = [];
      patchData.toAddProfiles = [];
      const selectedProfileIds = selectedOptionsProfile.map(
        (profile) => profile.id
      );
      if (selectedProfileIds.length == 0) {
        notifiToasterWarn("Please select atleast one profile");
        return;
      }
      if (
        JSON.stringify(selectedProfileIds) !== JSON.stringify(prevData.profile)
      ) {
        let toRemoveProfiles = prevData.profile.filter(
          (item) => !selectedProfileIds.includes(item)
        );
        let toAddProfiles = selectedProfileIds.filter(
          (item) => !prevData.profile.includes(item)
        );
        patchData.toRemoveProfiles = toRemoveProfiles;
        patchData.toAddProfiles = toAddProfiles;
        patchData.profile = selectedProfileIds;
      }

      if (Object.keys(patchData).length > 0) {
        dispatch(
          AccessControlActions.updateUserToAccessControl(userId, patchData)
        ).then(() => {
          dispatch(AccessControlActions.getUserToAccessControlList());
          handleClose();
        });
      }
    } else {
      // For creating a new user, aggregate selected profile IDs
      const selectedProfileIds = selectedOptionsProfile.map(
        (profile) => profile.id
      );
      if (selectedProfileIds.length > 0) {
        const postData = {
          name: formData.name,
          username: formData.username,
          password: formData.password,
          profile: selectedProfileIds,
          account: JSON.parse(localStorage.getItem("profile")).account
            .account_id,
          is_active: true,
        };
        dispatch(AccessControlActions.addUserToAccessControl(postData)).then(
          () => {
            dispatch(AccessControlActions.getUserToAccessControlList());
            handleClose();
          }
        );
      } else {
        notifiToasterWarn("Please select atleast one profile");
        return;
      }
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      maxWidth="sm"
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "sm",
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        {isEditable ? "Edit User" : "Create User"}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box>
          <div className="row">
            <div className="col-12 col-md-6">
              <TextField
                fullWidth
                id="outlined-basic"
                label="Name"
                variant="outlined"
                margin="normal"
                size="large"
                value={formData.name}
                onChange={(e) => handleInputChange("name", e.target.value)}
                error={!!errors.name}
                helperText={errors.name}
              />
            </div>
            <div className="col-12 col-md-6">
              <TextField
                fullWidth
                id="outlined-basic"
                label="Username"
                variant="outlined"
                margin="normal"
                value={formData.username}
                size="large"
                onChange={(e) => handleInputChange("username", e.target.value)}
                error={!!errors.username}
                helperText={errors.username}
              />
            </div>
            {!isEditable && (
              <div className="col-12 col-md-6">
                <TextField
                  fullWidth
                  id="outlined-basic-pass"
                  label="Password"
                  variant="outlined"
                  margin="normal"
                  size="large"
                  type="password"
                  autoComplete="off"
                  onChange={(e) =>
                    handleInputChange("password", e.target.value)
                  }
                  error={!!errors.password}
                  helperText={errors.password}
                />
              </div>
            )}
            <div className="col-12 col-md-6 mt-3">
              <CheckboxAutocomplete
                value={selectedOptionsProfile}
                onChange={(event, newValue) =>
                  setSelectedOptionsProfile(newValue)
                }
                label="Profiles"
                heading={""}
                options={profileOption}
                size={"medium"}
                error={false}
              />
            </div>
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={handleSubmit}
          variant="contained"
          color="primary"
        >
          {isEditable ? "Update Changes" : "Create User"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AddEditUserDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
};
