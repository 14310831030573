import Button from "@mui/material/Button";

import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import { notifiToasterWarn } from "./../../../components/common/UseToast";
import OperationModeAction from "../../../redux/Action/OperationModeAction";
import PropTypes from "prop-types";
import Account from "../../../redux/Action/CommonAction";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
const inputselect = {
  boxShadow: "none",
  borderRadius: "8px",
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
  MenuProps: {
    PaperProps: {
      style: {
        backgroundColor: "var(--top-bar-bg-color)",
        maxHeight: 150,
        width: 50,
        overflowX: "auto",
      },
    },
  },
};

function EditEventModal({ item, show, incrementcount, handleClose }) {
  EditEventModal.propTypes = {
    incrementcount: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    item: PropTypes.object.isRequired,
  };
  const [newEventType, setNewEventType] = useState(item.event_type);
  const [selectedFacility, setSelectedFacility] = useState(item.facility[1]);
  const [selectedDate, setSelectedDate] = useState(
    new Date(
      new Date(item.event_recieved_at).toLocaleString("en-US", {
        timeZone: "GMT",
      })
    )
  );
  function extractTime(timestamp) {
    // Split the timestamp into date and time parts
    const [datePart, timePart] = timestamp.split("T");

    // Split the time part into hours, minutes, and seconds
    const [hours, minutes] = timePart.split(":");

    // Return the time in hh:mm format
    return `${hours}:${minutes}`;
  }
  const [time, setTime] = useState(extractTime(item.event_recieved_at));
  function modifyDate(dateString, timeString) {
    // Create a new Date object using the date string
    const dateObj = new Date(dateString);

    // Extract the individual time components
    const [hours, minutes] = timeString.split(":").map(Number); // convert to numbers

    // Apply timezone offset
    const offset = dateObj.getTimezoneOffset();
    const hourOffset = Math.floor(offset / 60);
    const minuteOffset = offset % 60;
    const localHours = hours - hourOffset - 1;
    const localMinutes = minutes - minuteOffset;

    // Set the extracted time components to the date object
    dateObj.setHours(localHours, localMinutes);

    // Convert the date object to ISO 8601 format
    const isoString = dateObj.toISOString();

    return isoString;
  }

  const profile_id = localStorage.getItem("profile_id");
  const dispatch = useDispatch();
  const [selectedCamera, setSelectedCamera] = useState(item.camera[1]);
  const [event_message, setEventMessage] = useState(item.event_message);
  const [itemRemark, setItemRemark] = useState(item.remark);
  const state = useSelector((state) => state);
  const handleSelectEventType = (e) => {
    setNewEventType(e.target.value);
  };
  const handleStoreChange = (e) => {
    setSelectedFacility(e.target.value);
    OperationModeAction.getCamerasByStore(e.target.value)(dispatch);
  };
  const handleCameraChange = (e) => {
    setSelectedCamera(e.target.value);
  };

  const handleEventMessageChange = (e) => {
    setEventMessage(e.target.value);
  };
  function isCameraPresent() {
    let flag = state.modalData.cameraList.some(
      (item) => item.value === selectedCamera
    );
    return flag;
  }

  const handleSave = (e) => {
    if (event_message) {
      if (isCameraPresent()) {
        const changedDate = modifyDate(selectedDate, time);
        incrementcount("modify_event_operation_mode", {
          ...item,
          event_type: newEventType,
          event_message: event_message,
          event_recieved_at: changedDate,
          facility: selectedFacility,
          remark: itemRemark,
          camera: selectedCamera,
        });
        handleClose();
      } else {
        notifiToasterWarn("Camera and Facility Is Not Matched");
      }
    } else {
      notifiToasterWarn("Event Message can not be Empty");
      setEventMessage(item.event_message);
    }
  };
  const handleRemarkChange = (e) => {
    setItemRemark(e.target.value);
  };
  const handleDateChange = (date) => {
    setSelectedDate(date); // update local state
  };
  const handleTimeChange = (e) => {
    setTime(e.target.value);
  };
  useEffect(() => {
    if (show) {
      OperationModeAction.getCamerasByStore(selectedFacility)(dispatch);
    }
    Account.toglleSidebar(true)(dispatch);
    return () => {
      Account.toglleSidebar(false)(dispatch);
    };
  }, [show]);
  return (
    <Dialog
      open={show}
      onClose={handleClose}
      maxWidth={"md"}
      aaria-labelledby="customized-dialog-title"
      className="editTransferModal"
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "md",
        },
      }}
    >
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        id="customized-dialog-title"
        className="header-background modal-title-color"
        style={{
          backgroundColor: "#4D3EF8",
          color: "white",
        }}
      >
        Edit Event
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers
        style={{ backgroundColor: "var( --side-bar-bg-color" }}
      >
        <div className="profile-detail-form">
          <div className="d-lg-flex">
            <div className="col-12 col-lg-6">
              <div className="form-group-edit-card ">
                <div className="row">
                  <div className="row form-content-edit-data">
                    <label className="col form-label" htmlFor="email">
                      Date:
                    </label>
                    <div style={{ width: "190px" }}>
                      <DatePicker
                        className="form-control col"
                        // customInput={
                        //   <input className="custom-input-date-text" />
                        // }

                        selected={selectedDate}
                        onChange={handleDateChange}
                        dateFormat="dd/MM/yyyy"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div
                    style={{ padding: "10px" }}
                    className="form-content-edit-data row"
                  >
                    <label className="form-label col-4 col-lg" htmlFor="email">
                      Time:
                    </label>
                    <input
                      type="time"
                      className="form-control col"
                      id="event_time"
                      name="event_time"
                      placeholder="Event Time"
                      value={time}
                      onChange={handleTimeChange}
                      style={{
                        backgroundColor: "var(--sidebar-bg-color)",
                        color: "var(--card-text-color)",
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* Email Address onSubmit={handleSubmit*/}
              <div className="form-group">
                <div className="row">
                  <div
                    style={{ padding: "10px" }}
                    className="row form-content-edit-data"
                  >
                    <label className="form-label col-4 col-lg" htmlFor="email">
                      Remark:
                    </label>
                    <input
                      type="text"
                      className="form-control col"
                      id="event_message"
                      name="event_message"
                      value={itemRemark}
                      onChange={handleRemarkChange}
                      placeholder="Enter Remark" // value=
                      aria-describedby="emailHelp" // onChange=
                      style={{
                        backgroundColor: "var(--sidebar-bg-color)",
                        color: "var(--card-text-color)",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 ml-lg-auto">
              <img
                src={item.event_image}
                style={{ height: "100%", width: "100%" }}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="row form-content-edit-data">
              <label className="form-label col-3" htmlFor="location">
                Event Type:
              </label>
              <Select
                onChange={handleSelectEventType}
                sx={{
                  color: "var(--card-text-color)",
                  ...inputselect,
                }}
                value={newEventType}
                className="select-input-field-card form-control col"
                MenuProps={inputselect.MenuProps}
                style={{ backgroundColor: "var(--sidebar-color)" }}
              >
                {state.AlertTableStates.AlertTypes.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={item.notification_type}
                      sx={{
                        paddingTop: "2px",
                        paddingBottom: "2px",
                        color: "var(--card-text-color)",
                      }}
                      className={
                        item.notification_type === "compliance_issue" ||
                        item.notification_type === "security_thread" ||
                        item.notification_type === "facility_status" ||
                        item.notification_type === "loading_status" ||
                        item.notification_type === "vehicle_alert"
                          ? "multilebel-dropdown-item"
                          : ""
                      }
                      disabled={
                        item.notification_type === "compliance_issue" ||
                        item.notification_type === "security_thread" ||
                        item.notification_type === "facility_status" ||
                        item.notification_type === "loading_status" ||
                        item.notification_type === "vehicle_alert"
                      }
                    >
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          </div>
          {/* Location */}
          <div className="form-group">
            <div className="row">
              <div
                style={{ padding: "10px" }}
                className="row form-content-edit-data"
              >
                <label className="form-label col-4 col-lg" htmlFor="email">
                  Message:
                </label>
                <textarea
                  type="text"
                  className="form-control col"
                  id="event_message"
                  name="event_message"
                  value={event_message}
                  onChange={handleEventMessageChange}
                  placeholder="Enter Event Message"
                  //   value={formData.email_address[0]}
                  aria-describedby="emailHelp"
                  //   onChange={(event) => handleEmailChange(event, 0)}
                  style={{
                    backgroundColor: "var(--sidebar-bg-color)",
                    color: "var(--card-text-color)",
                  }}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row form-content-edit-data">
              <label className="form-label col-3" htmlFor="location">
                Facility:
              </label>
              <Select
                onChange={handleStoreChange}
                sx={{
                  color: "var(--card-text-color)",
                  ...inputselect,
                }}
                value={selectedFacility}
                className="select-input-field-card form-control col"
                MenuProps={inputselect.MenuProps}
                style={{ backgroundColor: "var(--sidebar-color)" }}
              >
                {state.modalData.facilityList.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={item.value}
                      sx={{
                        paddingTop: "2px",
                        paddingBottom: "2px",
                        color: "var(--card-text-color)",
                      }}
                    >
                      {item.text}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          </div>
          {state.modalData.cameraList.length > 0 && (
            <div className="form-group">
              <div className="row form-content-edit-data">
                <label className="form-label col-3" htmlFor="location">
                  Camera:
                </label>
                <Select
                  onChange={handleCameraChange}
                  sx={{
                    color: "var(--card-text-color)",
                    ...inputselect,
                  }}
                  value={isCameraPresent() ? selectedCamera : ""}
                  className="select-input-field-card form-control col"
                  MenuProps={inputselect.MenuProps}
                  style={{ backgroundColor: "var(--sidebar-color)" }}
                >
                  {state.modalData.cameraList.map((item, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={item.value}
                        sx={{
                          paddingTop: "2px",
                          paddingBottom: "2px",
                          color: "var(--card-text-color)",
                        }}
                      >
                        {item.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
            </div>
          )}
        </div>
      </DialogContent>
      <div
        className="col d-flex justify-content-end "
        style={{
          margin: "10px",
          padding: "5px",
          backgroundColor: " var( --side-bar-bg-color)",
          color: "var(--card-text-color)",
        }}
      >
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      </div>
    </Dialog>
  );
}
export default EditEventModal;
