import axios from "axios";
import AnalyticsDashboardEndpoints from "../features/AnalyticsDashboardEndpoints";
import buildUrlWithParams from "../../redux/Action/AddUrlsWIthParams";
function getDispatchType(week_range) {
  if (week_range == "three_weekly") return "THREE_WEEK_PEOPLE_COUNT_DATA";
  else if (week_range == "weekday") return "WEEKDAY_PEOPLE_COUNT_DATA";
  else if (week_range == "weekly") return "WEEKLY_PEOPLE_COUNT_DATA";
}
class PeopleCountAction {
  /**
   * Triggers an asynchronous API call to fetch people count  data analytics, including date wise and hourly
   * information for user profiles, facilities, and devices. It dispatches actions to manage the loading state
   * and to handle the response data or errors accordingly.
   *
   * @param {Object} params Parameters to be sent with the API call, including the date,ids, and any filters.
   * @returns {Function} A thunk action that can be dispatched by Redux's `dispatch` method.
   */
  static getPeopleCountData(params) {
    return async (dispatch) => {
      dispatch({ type: "UPDATE_LOADING_FOR_PEOPLE_COUNT", payload: true });
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      const urls =
        AnalyticsDashboardEndpoints.getPeopleCountForFacilityCameraUserDayDateRange();
      axios
        .get(buildUrlWithParams(params, urls), { headers })
        .then((response) => {
          dispatch({
            type:
              params.start_date === params.end_date
                ? "PEOPLE_COUNT_HOURLY_DATA"
                : "PEOPLE_COUNT_DAY_DATA",
            payload: response.data,
            date: params.start_date,
          });
          dispatch({
            type: "UPDATE_LOADING_FOR_PEOPLE_COUNT",
            payload: false,
          });
        })
        .catch(() => {
          dispatch({ type: "UPDATE_LOADING_FOR_PEOPLE_COUNT", payload: false });
        });
    };
  }
  /**
   * Fetches the data for weekly data for people count
   * The parameters include user profile id, facility id, camera id, date etc  .
   * If an error occurs, or the response is not in the provisioned format, the function
   * stops the loading and dispatches an action with a predefined structure.
   *
   * @param {object} params - Parameters used to form the URL for the data request.
   * @return {function} - A dispatch function that performs async data fetch and state update
   */
  static getweeklyDataAnalyticsForPeopleCount(params) {
    return async (dispatch) => {
      dispatch({ type: "UPDATE_LOADING_FOR_PEOPLE_COUNT", payload: true });
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      const urls = AnalyticsDashboardEndpoints.getWeeklyAnalyticsPeopleCount();
      axios
        .get(buildUrlWithParams(params, urls), { headers })
        .then((response) => {
          dispatch({
            type: getDispatchType(params.week_range),
            payload: response.data,
          });
          dispatch({
            type: "UPDATE_LOADING_FOR_PEOPLE_COUNT",
            payload: false,
          });
        })
        .catch(() => {
          dispatch({ type: "UPDATE_LOADING_FOR_PEOPLE_COUNT", payload: false });
        });
    };
  }
  /**
   * Triggers an asynchronous API call to fetch group data analytics, including consolidated and hourly
   * information for user profiles, facilities, and devices. It dispatches actions to manage the loading state
   * and to handle the response data or errors accordingly.
   *
   * @param {Object} params Parameters to be sent with the API call, including the date and any filters.
   * @returns {Function} A thunk action that can be dispatched by Redux's `dispatch` method.
   */
  static getGroupCountAnalyticsForPeopleCount(params) {
    return async (dispatch) => {
      // Indicate the beginning of an API call for loading states.
      dispatch({ type: "UPDATE_LOADING_FOR_GROUP_DATA", payload: true });

      // Retrieve the authentication token from local storage.
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Token ${token}` };

      // Construct the URL for the API call.
      const urls = AnalyticsDashboardEndpoints.getGroupCountOfPeopleCount();

      try {
        // Perform the API call.
        const response = await axios.get(buildUrlWithParams(params, urls), {
          headers,
        });

        // Dispatch the hourly group count data.
        dispatch({
          type: "GROUP_COUNT_HOURLY",
          payload: response.data[1], // Assuming the second item is the hourly data.
          date: params.date,
        });

        // Dispatch the consolidated group data.
        dispatch({
          type: "GROUP_COUNT_DATA",
          payload: response.data[0], // Assuming the first item is the consolidated data.
          date: params.date,
        });
      } catch (error) {
        // Handle errors by resetting the relevant state.
        console.error("Failed to fetch group count analytics:", error);
        const defaultGroupData = { Groups: 0, Individual: 0 };
        const defaultHourlyData = [];

        // Dispatch fallback actions for both data types.
        dispatch({
          type: "GROUP_COUNT_HOURLY",
          payload: [defaultGroupData],
          date: params.date,
        });
        dispatch({
          type: "GROUP_COUNT_DATA",
          payload: defaultHourlyData,
          date: params.date,
        });
      } finally {
        // Reset the loading state regardless of the outcome.
        dispatch({ type: "UPDATE_LOADING_FOR_GROUP_DATA", payload: false });
      }
    };
  }
}
export default PeopleCountAction;
