const initialState = {
  TransitionTimer: 120,
  LoadingCameras: ["idle", "idle", "idle", "idle"],
  timerEnabled: false,
  playbackDate: new Date().toISOString(),
  monthlyAvailableRecording: [],
  playbackLoading: [],
  cameraIdsForPlayback: [],
};

export default function VmsHelperReducer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_TIMER_ENABLED_CAMERAS":
      return {
        ...state,
        timerEnabled: action.payload,
      };
    case "UPDATE_TRANSITION_TIMER":
      return {
        ...state,
        TransitionTimer: action.payload,
      };
    case "UPDATE_LOADING_CAMERAS_GRID_SIZE": {
      return {
        ...state,
        LoadingCameras: action.payload,
      };
    }
    case "INCREASE_LOADING_CAMERAS": {
      let data = [...state.LoadingCameras];
      data[action.payload] = "loading";
      return {
        ...state,
        LoadingCameras: data,
      };
    }

    case "DECREASE_LOADING_CAMERAS": {
      let data = [...state.LoadingCameras];
      data[action.payload] = "idle";
      return {
        ...state,
        LoadingCameras: data,
      };
    }
    case "EMPTY_LOADING_CAMERAS":
      return {
        ...state,
        LoadingCameras: [],
      };
    case "UPDATE_FACILITY_LIST_FOR_PLAYBACK_CAMERAS":
      return {
        ...state,
        FacilityCameraListForPlayback: action.payload,
      };
    case "UPDATE_CAMERA_LIST_FOR_PLAYBACK_CAMERAS":
      return {
        ...state,
        cameraIdsForPlayback: action.payload,
      };
    case "UPDATE_SELECETED_DATE_FOR_PLAYBACK":
      return {
        ...state,
        playbackDate: action.payload,
      };
    case "UPDATE_MONTHLY_AVALIAVLE_RECORDING":
      return {
        ...state,
        monthlyAvailableRecording: action.payload,
      };
    case "UPDATE_LOADING_FOR_PLAYBACK":
      return {
        ...state,
        playbackLoading: [...action.payload],
      };
    default:
      return state;
  }
}
