import React, { useState } from "react";
import PropTypes from "prop-types";
import EndPoints from "../../../redux/features/EndPoints";
import axios from "axios";
import { useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
const options = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  hour12: true, // Use 12-hour clock (AM/PM)
  timeZoneName: "short", // Display time zone abbreviation
};
const LastOnlineModal = ({ NvrData, showLastOnline, hideLastOnline }) => {
  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState({});
  useEffect(() => {
    if (showLastOnline) {
      getLastOnlineTime();
      if (NvrData.nvr_id) {
        console.log(resData, NvrData.nvr_id);
      }
    }
  }, [showLastOnline, NvrData.nvr_id]);

  function getLastOnlineTime() {
    setLoading(true);
    const token = localStorage.getItem("token"); // Retrieve token from localStorage
    axios
      .get(EndPoints.getLastOnlineStatusOfBothApi(NvrData.nvr_id), {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        setResData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setLoading(false);
      });
  }

  return (
    <div>
      <Dialog
        open={showLastOnline}
        onClose={hideLastOnline}
        style={{ zIndex: 9999 }}
        size="md"
        aria-labelledby="customized-dialog-title"
        className="editTransferModal"
        PaperProps={{
          sx: {
            width: "70%",
            maxWidth: "md",
          },
        }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
          className="header-background modal-title-color"
          style={{
            backgroundColor: "#4D3EF8",
            color: "white",
          }}
        >
          Last Online
          <IconButton
            aria-label="close"
            onClick={hideLastOnline}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "white",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          style={{
            height: "16vh",
            overflowY: "scroll",
            padding: "10px",
            backgroundColor: " var( --side-bar-bg-color)",
          }}
        >
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
              }}
            >
              <TailSpin color="var(--card-text-color)" height={50} />
            </div>
          ) : (
            <table className="rhms-modal-table " style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td
                    className="rhms-table-text-modal-details-label"
                    style={{ position: "relative" }}
                  >
                    {NvrData.active_ip == NvrData.nvr_ip && (
                      <sup
                        style={{
                          color: "green",
                          fontSize: "14px",
                          position: "absolute",
                          top: "9px",
                          left: "2px",
                        }}
                      >
                        Active
                      </sup>
                    )}
                    Primary IP
                  </td>
                  <td className="rhms-table-text-modal-details">
                    <span>{NvrData.nvr_ip}</span>
                  </td>
                  <td className="rhms-table-text-modal-details">
                    <span>{NvrData.nvr_http}</span>
                  </td>
                  <td className="rhms-table-text-modal-details">
                    <span>
                      {resData.last_online_primary
                        ? moment
                            .utc(resData.last_online_primary)
                            .format("DD-MM-YYYY h.mm A")
                        : "N/A"}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td
                    className="rhms-table-text-modal-details-label"
                    style={{ position: "relative" }}
                  >
                    {NvrData.active_ip == NvrData.nvr_ip2 && (
                      <sup
                        style={{
                          color: "green",
                          fontSize: "14px",
                          position: "absolute",
                          top: "9px",
                          left: "2px",
                        }}
                      >
                        Active
                      </sup>
                    )}{" "}
                    Secondary IP{" "}
                  </td>
                  <td className="rhms-table-text-modal-details">
                    <span>{NvrData.nvr_ip2}</span>
                  </td>{" "}
                  <td className="rhms-table-text-modal-details">
                    <span>{NvrData.nvr_http2}</span>
                  </td>
                  <td className="rhms-table-text-modal-details">
                    <span>
                      {resData.last_online_secondary
                        ? moment
                            .utc(resData.last_online_secondary)
                            .format("DD-MM-YYYY h.mm A")
                        : "N/A"}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};
LastOnlineModal.propTypes = {
  NvrData: PropTypes.object,
  showLastOnline: PropTypes.bool,
  hideLastOnline: PropTypes.func,
};
export default LastOnlineModal;
