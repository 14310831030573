import React from "react";
import HomePage from "../pages/home/HomePage";
import DashboardPageLayout from "../pages/Dashboard/DashboardPageLayout";
import Alert from "../pages/Alert/Alert";
import Table from "../pages/Alert/Table";
import Dashboard from "../pages/Alert/Dashboard";
// import DashboardEvent from "../pages/Events/DashboardEvent";
import EqualizerRoundedIcon from "@mui/icons-material/EqualizerRounded";
import DashboardIcon from "@mui/icons-material/Dashboard";

// import EventNoteIcon from "@mui/icons-material/EventNote";
import PreviewIcon from "@mui/icons-material/Preview";
// import Event from "../pages/Events/Event";
// import EventTable from "../pages/Events/EventTable";

import HeatMapDashboard from "../pages/Heatmap/HeatMapDashboard";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

import RemoteMonitoringDash from "../pages/Remote Monitoring/RemoteMonitoringDash";
import PollIcon from "@mui/icons-material/Poll";

import DeviceStatus from "../pages/Device Status/DeviceStatus";

import Liveview from "../pages/LiveView/Liveview";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";

import DeviceControl from "../pages/DeviceControl/DeviceControl";

import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import OnlinePredictionOutlinedIcon from "@mui/icons-material/OnlinePredictionOutlined";
import MessageIcon from "@mui/icons-material/Message";
import Chat from "../pages/chat/Chat";
import ChatBot from "./../pages/ChatBot/ChatBot";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import FaceDetectedDashboard from "../pages/Facerecognition/FaceDetectedDashboard";
import FaceRetouchingOffIcon from "@mui/icons-material/FaceRetouchingOff";
import KnownFacesMainComponent from "../pages/Facerecognition/KnownFaces/KnownFacesMainComponent";
import FaceRecognition from "../pages/Facerecognition/FaceRecognition";
import { MdiAccountDetailsOutline } from "../assets/icons/MdiAccountDetailsOutline";
import { MdiFaceRecognition } from "../assets/icons/MdiFaceRecognition";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import SettingMainPage from "../pages/Alert/Settings/SettingMainPage";
import VmsHomePage from "../pages/VMS/VmsHomePage";
import SystemParameters from "../pages/SystemParameters/SystemParameters";
import FacilityTable from "../pages/SystemParameters/FacilityManagement/FacilityTable";
import CameraTable from "../pages/SystemParameters/CameraManagement/CameraTable";
import StoreIcon from "@mui/icons-material/Store";
import { MdiCctv } from "../assets/icons/MdiCctv";
import { TablerListDetails } from "../assets/icons/ManageDetails";
import AnprMainPage from "../pages/ANPR/AnprMainPage";
import { AnprIcon } from "../assets/icons/AnprIcon";
const appRoutes = [
  {
    index: true,
    element: <HomePage />,
    state: "home",
  },
  {
    path: "/dashboard",
    element: <DashboardPageLayout />,
    state: "dashboard",
    sidebarProps: {
      displayText: "Dashboard",
      icon: <HomeRoundedIcon />,
    },
  },
  {
    path: "/alerts",
    element: <Alert />,
    state: "alerts",
    sidebarProps: {
      displayText: "Alerts",
      icon: <OnlinePredictionOutlinedIcon />,
    },
    child: [
      {
        index: true,
        path: "/alerts",
        element: <Dashboard />,
        state: "alerts",
        sidebarProps: {
          displayText: "Dashboard",
          icon: <EqualizerRoundedIcon />,
        },
      },
      {
        path: "/alerts/table",
        element: <Table />,
        state: "alerts",
        sidebarProps: {
          displayText: "Table",
          icon: <DashboardIcon />,
        },
      },
    ],
  },
  {
    path: "/heatmap",
    element: <HeatMapDashboard />,
    state: "heatmap",
    sidebarProps: {
      displayText: "Heatmap",
      icon: <PreviewIcon />,
    },
  },
  {
    path: "/remote_monitoring",
    element: <RemoteMonitoringDash />,
    state: "remote_monitoring",
    sidebarProps: {
      displayText: "Remote Monitoring",
      icon: <RemoveRedEyeIcon />,
    },
  },
  {
    path: "/devicestatus",
    element: <DeviceStatus />,
    state: "device_status",
    sidebarProps: {
      displayText: "Device Status",
      icon: <PollIcon />,
    },
  },
  {
    path: "/device_control",
    element: <DeviceControl />,
    state: "device_control",
    sidebarProps: {
      displayText: "Device Control",
      icon: <SettingsApplicationsIcon />,
    },
  },
  {
    path: "/live_view",
    element: <VmsHomePage />,
    state: "live_view",
    sidebarProps: {
      displayText: "Live View",
      icon: <OndemandVideoIcon sx={{ fontSize: "24px" }} />,
    },
  },
  {
    path: "/alerts/setting",
    element: <SettingMainPage />,
    state: "alert_customisation",
    sidebarProps: {
      displayText: "Alert Customization",
      icon: <SettingsSuggestIcon />,
    },
  },
  {
    path: "/chat",
    element: <Chat />,
    state: "chat",
    sidebarProps: {
      displayText: (
        <p
          style={{
            margin: "0px",
            color: "var(--card-text-color)",
            opacity: 1,
            fontWeight: 500,
          }}
        >
          Chat<sup> Beta</sup>
        </p>
      ),
      icon: <MessageIcon />,
    },
  },
  {
    path: "/chatbot",
    element: <ChatBot />,
    state: "chat",
    sidebarProps: {
      displayText: "ChatBot",
      icon: <MessageIcon />,
    },
  },
  {
    path: "/anpr",
    element: <AnprMainPage />,
    state: "anpr",
    sidebarProps: {
      displayText: "ANPR",
      icon: <AnprIcon style={{ fontSize: "26px" }} />,
    },
  },
  {
    path: "/Customization",
    element: <SystemParameters />,
    state: "customization",
    sidebarProps: {
      displayText: "Configurations",
      icon: <TablerListDetails style={{ fontSize: "26px" }} />,
    },
    child: [
      {
        path: "/Customization/facility",
        element: <FacilityTable />,
        state: "customization",
        sidebarProps: {
          displayText: "Facility",
          icon: <StoreIcon />,
        },
      },
      {
        path: "/Customization/camera",
        element: <CameraTable />,
        state: "customization",
        sidebarProps: {
          displayText: "Cameras",
          icon: <MdiCctv style={{ fontSize: "26px" }} />,
        },
      },
    ],
  },
  {
    path: "/facerecognition",
    element: <FaceRecognition />,
    state: "face_recognition",
    sidebarProps: {
      displayText: "Face Recognition",
      icon: <MdiFaceRecognition />,
    },
    child: [
      {
        path: "/facerecognition",
        element: <FaceDetectedDashboard />,
        state: "face_recognition",
        sidebarProps: {
          displayText: "Dashboard",
          icon: <RecentActorsIcon sx={{ fontSize: "26px" }} />,
        },
      },
      {
        path: "/facerecognition/known-faces",
        element: <KnownFacesMainComponent />,
        state: "face_recognition",
        sidebarProps: {
          displayText: "Known Faces",
          icon: <MdiAccountDetailsOutline />,
        },
      },
    ],
  },
];

export default appRoutes;
