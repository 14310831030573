import * as React from "react";
import { Bars } from "react-loader-spinner";
import { PropTypes } from "prop-types";
import { Box } from "@mui/material";

export default function ModalLoader({ open }) {
  return (
    <Box>
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{ height: "50vh" }}
      >
        <div className="text-center mb-3">
          <div className="pl-4">
            <Bars
              height={60}
              width={60}
              color="var(--card-text-color)"
              ariaLabel="bars-loading"
              visible={true}
            />
          </div>
        </div>
        <h4>Fetching Your Data </h4>
      </div>
    </Box>
  );
}

ModalLoader.propTypes = {
  open: PropTypes.bool.isRequired,
};
