import React, { useState, useEffect, useCallback, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MenuItem, Select } from "@mui/material";
import AlertTableAction from "../../../redux/Action/AlertTableActions";
import PropTypes from "prop-types";
const inputSelectStyles = {
  boxShadow: "none",
  borderRadius: "8px",
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
  MenuProps: {
    PaperProps: {
      style: {
        backgroundColor: "var(--top-bar-bg-color)",
        maxHeight: 350,
        width: 50,
        overflowX: "auto",
      },
    },
  },
};
const UseCaseFilter = ({ type }) => {
  const commonData = useSelector((state) => state.commonData);
  const alertTableStates = useSelector((state) => state.AlertTableStates);
  const eventTableStates = useSelector((state) => state.EventTableStates);
  const [timeRange, setTimeRange] = useState(
    type === "AlertTable"
      ? alertTableStates.timeRange
      : type === "EventTable"
      ? eventTableStates.timeRange
      : [0, 23.59]
  );

  const dispatch = useDispatch();

  const handleStoreChange = (event) => {
    const value = event.target.value;
    const action = AlertTableAction;
    dispatch(action.updateSelectedUseCase({ value }));
  };

  useEffect(() => {
    let account_id = JSON.parse(localStorage.getItem("profile")).account
      .account_id;
    dispatch(AlertTableAction.getUseCasesList(account_id)); // Assuming 26 is a static ID here
    return () => {
      const action = AlertTableAction;
      dispatch(action.updateSelectedUseCase({ value: "all" }));
    };
  }, [dispatch]);

  const selectedUseCaseValue =
    type === "AlertTable"
      ? alertTableStates.selectedUseCase.value
      : eventTableStates.selectedUseCase.value;

  return (
    <div>
      <div className="select-input-lebel">UseCases</div>
      <Select
        value={selectedUseCaseValue}
        onChange={handleStoreChange}
        sx={{ color: "var(--card-text-color)", ...inputSelectStyles }}
        className="select-input-field"
        MenuProps={inputSelectStyles.MenuProps}
      >
        {alertTableStates.useCaseList.map((item) => (
          <MenuItem
            key={item.value}
            value={item.value}
            sx={{
              paddingTop: "2px",
              paddingBottom: "2px",
              color: "var(--card-text-color)",
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
UseCaseFilter.propTypes = {
  type: PropTypes.string.isRequired,
};
export default memo(UseCaseFilter);
