import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import DashboardCard from "../DashboardCard";
import SentimentSatisfiedOutlinedIcon from "@mui/icons-material/SentimentSatisfiedOutlined";
import EqualizerRoundedIcon from "@mui/icons-material/EqualizerRounded";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
const iconFont = {
  fontSize: "50px",
  color: "#4318FF",
};

const totalFaceAnalysis = (data, isDateRange) => {
  if (data && !isDateRange) {
    // When data is not an array and isDateRange is false, return the sum of male and female counts
    return (data.male ?? 0) + (data.female ?? 0);
  } else if (data && isDateRange) {
    // When isDateRange is true, data is expected to be an array of items
    // Use reduce to iterate over the array and sum up the male_count and female_count
    const totalFaces = data.reduce((acc, item) => {
      return acc + (item.male_count ?? 0) + (item.female_count ?? 0);
    }, 0); // Initialize accumulator to 0
    return totalFaces;
  }
  return 0; // Return 0 if data is falsy
};

const DemographicsOverviewCard = ({ selectedMode }) => {
  const profile = JSON.parse(localStorage.getItem("profile") || "{}");

  const accessPermissions =
    JSON.parse(localStorage.getItem("agx_permission") || "{}").Dashboard || [];

  const gender = useSelector((state) => state.gender);
  const PeopleDemographicsLoading = useSelector(
    (state) => state.loading.demographicsLoadingPeopleCount
  );
  const PasserbyDemographicsLoading = useSelector(
    (state) => state.loading.demographicsLoadingPasserby
  );
  const canViewDemographics =
    profile.services_selected?.Dashboard.includes("demographics") &&
    accessPermissions.includes("view_demographics_analytics");
  const canViewPasserBy =
    profile.services_selected?.Dashboard.includes("passer_by") &&
    accessPermissions.includes("view_passer_by_analytics");

  return (
    <>
      {canViewDemographics && (
        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-2">
          <DashboardCard
            icon={<PeopleAltOutlinedIcon style={iconFont} />}
            headText={`M:${gender.peopleCountGender.male || 0}/${(
              gender.peopleCountGender.male_percent || 0
            ).toFixed(0)}% - F:${gender.peopleCountGender.female || 0}/${(
              gender.peopleCountGender.female_percent || 0
            ).toFixed(0)}%`}
            paraText="People Count Gender Dist"
            isLoading={PeopleDemographicsLoading}
          />
        </div>
      )}
      {canViewPasserBy && (
        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-2">
          <DashboardCard
            icon={<EqualizerRoundedIcon style={iconFont} />}
            headText={`M:${gender.passerCountGender.male || 0}/${(
              gender.passerCountGender.male_percent || 0
            ).toFixed(0)}% - F:${gender.passerCountGender.female || 0}/${(
              gender.passerCountGender.female_percent || 0
            ).toFixed(0)}%`}
            paraText="Passer-By Gender Dist"
            isLoading={PasserbyDemographicsLoading}
          />
        </div>
      )}
      {canViewDemographics && (
        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-2">
          <DashboardCard
            icon={<SentimentSatisfiedOutlinedIcon style={iconFont} />}
            headText={totalFaceAnalysis(
              selectedMode.value === "day-wise"
                ? gender.peopleCountGender
                : gender.maleFemaleDayData,
              selectedMode.value !== "day-wise"
            )}
            paraText="Total Faces Analyzed"
            isLoading={PeopleDemographicsLoading}
          />
        </div>
      )}
    </>
  );
};

DemographicsOverviewCard.propTypes = {
  selectedMode: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }).isRequired,
};

export default DemographicsOverviewCard;
