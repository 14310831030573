import React from "react";
import { TableCell, Chip, Badge } from "@mui/material";
import PropTypes from "prop-types";

const RenderTagCell = ({ item }) => {
  // Check if 'item' is defined and has 'others' that is a non-empty array
  if (
    !item ||
    !item.others ||
    !Array.isArray(item.others) ||
    item.others.length === 0
  ) {
    return <TableCell>No Tags</TableCell>;
  }

  // Filter out null or "null" values
  const validOthers = item.others.filter((tag) => tag && tag !== "null");

  // Handle case where no valid tags are present
  if (validOthers.length === 0) {
    return <TableCell>No Tags</TableCell>;
  }

  // Split the first valid tag string into individual tags
  const tags = validOthers[0].split(",").filter((tag) => tag && tag !== "null");

  // Handle case where no valid tags are present after splitting
  if (tags.length === 0) {
    return <TableCell>No Tags</TableCell>;
  }

  const firstTag = tags[0];
  const remainingCount = tags.length - 1;

  return (
    <TableCell>
      <Badge
        badgeContent={remainingCount > 0 ? `+${remainingCount}` : null}
        color="primary"
      >
        <Chip label={firstTag} />
      </Badge>
    </TableCell>
  );
};

RenderTagCell.propTypes = {
  item: PropTypes.shape({
    others: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default RenderTagCell;
