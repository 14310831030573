import axios from "axios";

import ApiSpec from "../features/EndPoints";

// get Store

class HeatMapAction {
  // get heatmap data
  static getHeatmapByStore(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      if (data.id != "all") {
        axios
          .get(ApiSpec.getHeatMapByStore(data.id, data.date), { headers })
          .then((response) => {
            dispatch({
              type: "HEAT_MAP_DATA",
              payload: response.data,
              date: data.date,
            });
          });
      }
    };
  }
  static dismissHeatMapData(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      dispatch({
        type: "HEAT_MAP_DATA",
        payload: [],
        date: [],
      });
    };
  }
}

export default HeatMapAction;
