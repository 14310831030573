import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import VmsHelperActions from "../../../redux/Action/VmsHelperActions";
import VmsLiveviewActions from "../../../redux/Action/VmsActions";

/**
 * Component for displaying and managing the playback toolbar.
 * Allows for date and time selection, and applying or canceling playback data.
 *
 * @param {Object} propTypes - Props passed to this component.
 */
const PlaybackToolbar = (propTypes) => {
  const dispatch = useDispatch();
  const playbackSelectedDate = useSelector(
    (state) => new Date(state.vms.playbackSelectedDate)
  );
  const playbackCamera = useSelector((state) => state.vms.playbackCamera);
  const playbackFeed = useSelector((state) => state.vms.playbackFeed);
  const activePlaybackMethod = useSelector(
    (state) => state.vms.activePlaybackMethod
  );
  const state = useSelector((state) => state.vmsHelper);

  const [datesToRemove, setDatesToRemove] = useState([]);

  // Handles date selection changes.
  const handleDateChange = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    dispatch({
      type: "UPDATE_PLAYBACK_SELECTED_TIME",
      payload: "00:00",
    });
    dispatch({
      type: "UPDATE_PLAYBACK_SELECTED_DATE",
      payload: date.toISOString(),
    });
    const data = {
      cam_id: playbackCamera[0],
      date: formattedDate,
      startTime: "00:00:00",
      endTime: "23:59:00",
    };
    propTypes.updateLoading(true);
    dispatch(VmsHelperActions.updateLoadingForPlayback([data.cam_id]));

    dispatch(
      activePlaybackMethod === "hdd"
        ? VmsLiveviewActions.getPlaybackChunkDetailsFromNvrAction(data)
        : VmsLiveviewActions.getAvailableRecordingChunk(data)
    ).finally(() => {
      propTypes.updateLoading(false);
      dispatch(VmsHelperActions.updateLoadingForPlayback([data.cam_id]));
    });
  };

  useEffect(() => {
    handleDateChange(new Date());
  }, []);

  // Fetches available recording dates on component mount.
  useEffect(() => {
    const date = new Date();
    dispatch(
      VmsHelperActions.getMonthlyAvailableRecording({
        cam_id: playbackCamera[0],
        month: date.getMonth() + 1,
        year: date.getFullYear(),
      })
    );
  }, [dispatch, playbackCamera]);

  // Updates the list of dates to remove based on availability.
  useEffect(() => {
    const unavailableDates = state.monthlyAvailableRecording
      .filter((item) => item.Availabe === "false")
      .map((item) => new Date(item.Date));
    setDatesToRemove(unavailableDates);
  }, [state.monthlyAvailableRecording]);

  // Applies the selected date and time range.
  const handleApply = () => {
    const formattedDate = moment(playbackSelectedDate).format("YYYY-MM-DD");
    const data = {
      cam_id: playbackCamera[0],
      date: formattedDate,
      startTime: "00:00:00",
      endTime: "23:59:00",
    };
    propTypes.updateLoading(true);
    dispatch(VmsHelperActions.updateLoadingForPlayback([data.cam_id]));

    dispatch(
      activePlaybackMethod === "hdd"
        ? VmsLiveviewActions.getPlaybackChunkDetailsFromNvrAction(data)
        : VmsLiveviewActions.getAvailableRecordingChunk(data)
    ).finally(() => {
      propTypes.updateLoading(false);
      dispatch(VmsHelperActions.updateLoadingForPlayback([]));
    });
  };

  // Handles month changes for date picker.
  const handleMonthChange = (tdate) => {
    const date = new Date(tdate);
    dispatch(
      VmsHelperActions.getMonthlyAvailableRecording({
        cam_id: playbackCamera[0],
        month: date.getMonth() + 1,
        year: date.getFullYear(),
      })
    );
  };

  // Cancels the current playback selection.
  const handleCancel = () => {
    dispatch(VmsLiveviewActions.removePlaybackStream());
    dispatch({
      type: "UPDATE_TIME_RANGE_FOR_PLAYBACK",
      payload: [0.0, 23.59],
    });
    dispatch({
      type: "UPDATE_TIME_FOR_PLAYBACK",
      payload: [0.0, 23.99],
    });
    dispatch({
      type: "UPDATE_PLAYBACK_CHUNKS_AVAILABLE",
      payload: [],
    });
  };

  return (
    <div className="">
      <div className="card-body-div">
        <div className="row align-items-center">
          <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
            <div className="select-input-lebel">Date</div>
            <DatePicker
              className="select-input-field-date"
              customInput={<input className="custom-input-date-text" />}
              selected={playbackSelectedDate}
              onChange={handleDateChange}
              excludeDates={datesToRemove}
              dateFormat="dd/MM/yyyy"
              onMonthChange={handleMonthChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlaybackToolbar;
