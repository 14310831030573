const initialState = {
  anprData: { count: 0, results: [] },
  currentPage: 1,
  pageSize: 9,
  selectedFacility: { value: "all" },
  selectedCamera: { value: "all" },
  selectedMode: { value: "day-wise" },
  dateRange: {
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    key: "selection",
  },
  selectedDate: new Date().toISOString(),
  dataLoading: false,
  editMode: false,
};

export default function AnprReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_ANPR_DATA":
      return { ...state, anprData: action.payload };

    case "UPDATE_ANPR_CURRENT_PAGE":
      return { ...state, currentPage: action.payload };

    case "UPDATE_ANPR_PAGE_SIZE":
      return { ...state, pageSize: action.payload };

    case "UPDATE_ANPR_SELECTED_FACILITY":
      return { ...state, selectedFacility: action.payload };

    case "UPDATE_ANPR_SELECTED_CAMERA":
      return { ...state, selectedCamera: action.payload };

    case "UPDATE_ANPR_SELECTED_MODE":
      return { ...state, selectedMode: action.payload };

    case "UPDATE_ANPR_DATE_RANGE":
      return { ...state, dateRange: action.payload };

    case "UPDATE_ANPR_SELECTED_DATE":
      return { ...state, selectedDate: action.payload };

    case "UPDATE_ANPR_DATA_LOADING":
      return { ...state, dataLoading: action.payload };

    case "TOGGLE_ANPR_EDIT_MODE":
      return { ...state, editMode: action.payload };

    default:
      return state;
  }
}
