import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
const DeviceStatusTable = ({ loading }) => {
  const state = useSelector((state) => state);
  const changeDateFormat = (dateStr, hoursToAdd, minutesToAdd) => {
    const date = new Date(dateStr);
    if (isNaN(date.getTime())) {
      return "Invalid date";
    }

    date.setHours(date.getHours() + hoursToAdd);
    date.setMinutes(date.getMinutes() + minutesToAdd);
    return date;
  };
  return (
    <>
      <TableContainer component={Paper} className="mt-4 ">
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Facility</TableCell>
              <TableCell>Last Online</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ backgroundColor: "var(--side-bar-bg-color)" }}>
            {loading ? (
              <TableRow>
                <TableCell
                  colSpan={5}
                  align="center"
                  style={{ color: "var(--card-text-color)" }}
                >
                  Loading Data ...
                </TableCell>
              </TableRow>
            ) : state.deviceStatusData.deviceStatus.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={5}
                  align="center"
                  style={{ color: "var(--card-text-color)" }}
                >
                  No data available
                </TableCell>
              </TableRow>
            ) : (
              state.deviceStatusData.deviceStatus.map((item, index) => (
                <TableRow
                  key={`row-${index}`}
                  sx={{
                    "& > *": {
                      borderBottom: "unset",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      font: "Roboto",
                    },
                  }}
                >
                  <TableCell
                    style={{ color: "var(--chatbot-welcome-text-color)" }}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      color: "var(--chatbot-welcome-text-color)",
                      fontSize: "16px",
                    }}
                  >
                    {item.fleet_name.replace(/[_-]/g, " ")}
                  </TableCell>
                  <TableCell
                    style={{ color: "var(--chatbot-welcome-text-color)" }}
                  >
                    {item.store.replace(/[_-]/g, " ")}
                  </TableCell>
                  <TableCell
                    style={{ color: "var(--chatbot-welcome-text-color)" }}
                  >
                    {changeDateFormat(item.last_online, 5, 30).toDateString()}
                    <br />
                    {changeDateFormat(
                      item.last_online,
                      5,
                      30
                    ).toLocaleTimeString()}
                  </TableCell>
                  <TableCell>
                    {item.status === "ONLINE" ? (
                      <span className="online-span-device-status">
                        {item.status}
                      </span>
                    ) : (
                      <span className="offline-span-device-status">
                        {" "}
                        {item.status}
                      </span>
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
DeviceStatusTable.propTypes = {
  loading: PropTypes.bool.isRequired,
};
export default DeviceStatusTable;
