class DashboardFunction {
  static totalPeopleCount = (data) => {
    return data.reduce(
      (accumulator, currentValue) => accumulator + currentValue.people_count_in,
      0
    );
  };
  static totalPeopleCountOut = (data) => {
    return data.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.people_count_out,
      0
    );
  };
  static totalPasserCount = (data) => {
    return data.reduce(
      (accumulator, currentValue) => accumulator + currentValue.count,
      0
    );
  };

  static calculateOccupancy(data) {
    const totalIn = data.reduce((acc, curr) => acc + curr.people_count_in, 0);
    const totalOut = data.reduce((acc, curr) => acc + curr.people_count_out, 0);
    const occupancy = totalIn - totalOut;
    return occupancy >= 0 ? occupancy : 0;
  }

  static returnLineChartCount(data) {
    return data.map((data) => ({
      x: data.date,
      y: data.count ?? data.people_count_in,
    }));
  }
  // Function to create a map of dates to counts
  static createMapOfDatesToCounts = (dataArray) => {
    const dateToCountMap = new Map();
    dataArray.forEach((data) => {
      dateToCountMap.set(data.date, data.count || data.people_count_in || 0);
    });
    return dateToCountMap;
  };
  static convertToChartData = (people_count, passerBy_count) => {
    // Create maps for people_count and passerBy_count
    const peopleCountMap =
      DashboardFunction.createMapOfDatesToCounts(people_count);
    const passerByCountMap =
      DashboardFunction.createMapOfDatesToCounts(passerBy_count);

    // Get all unique dates from both arrays
    const allDatesSet = new Set([
      ...peopleCountMap.keys(),
      ...passerByCountMap.keys(),
    ]);

    // Create separate arrays for people_count and passerBy_count data
    const peopleCountArray = Array.from(allDatesSet).map((date) => ({
      x: date,
      y: peopleCountMap.get(date) || 0,
    }));

    const passerByCountArray = Array.from(allDatesSet).map((date) => ({
      x: date,
      y: passerByCountMap.get(date) || 0,
    }));

    return [peopleCountArray, passerByCountArray];
  };

  static returnLineChartPeopleCount(data) {
    return data.map((data) => ({
      x: data.date,
      y: data.people_count_in,
    }));
  }
  static returnLineChartPasserCount(data) {
    return data.map((data) => ({
      x: data.date,
      y: data.count,
    }));
  }
  //functions for returing data for people count hourly graph data
  static pcPbGraphDataPeopleCount(peopleCountHourly) {
    if (peopleCountHourly.length > 0) {
      return [
        {
          name: "People Count",
          data: DashboardFunction.returnLineChartCount(peopleCountHourly),
        },
      ];
    }
    return [
      {
        name: "People Count",
        data: [],
      },
    ];
  }

  static pcPbGraphData(peopleCountHourly, passerCountHourly) {
    const data = DashboardFunction.convertToChartData(
      peopleCountHourly,
      passerCountHourly
    );

    if (peopleCountHourly.length > 0 || passerCountHourly.length > 0) {
      return [
        {
          name: "Passer-By Count",
          // data: DashboardFunction.returnLineChartCount(passerCountHourly),
          data: data[1],
        },
        {
          name: "People Count",
          // data: DashboardFunction.returnLineChartCount(peopleCountHourly),
          data: data[0],
        },
      ];
    }
    return [
      {
        name: "Passer-By Count",
        data: [],
      },
      {
        name: "People Count",
        data: [],
      },
    ];
  }
  static returnLineChartInOut(data) {
    return data.map((data) => ({
      x: data.date,
      y: data.people_count_out,
    }));
  }

  static returnLineChartOccupancy(data) {
    return data.map((data) => ({
      x: data.date,
      y: Math.max(0, data.people_count_in - data.people_count_out),
    }));
  }

  static PeopleCountInOutGraphData(peopleCountHourly) {
    if (peopleCountHourly.length > 0) {
      return [
        {
          name: "People Count In",
          data: DashboardFunction.returnLineChartCount(peopleCountHourly),
        },
        {
          name: "People Count Out",
          data: DashboardFunction.returnLineChartInOut(peopleCountHourly),
        },
        {
          name: "Occupancy",
          data: DashboardFunction.returnLineChartOccupancy(peopleCountHourly),
        },
      ];
    }
    return [
      {
        name: "People Count In",
        data: [],
      },
      {
        name: "People Count Out",
        data: [],
      },
      {
        name: "Occupancy",
        data: [],
      },
    ];
  }

  static specificDayGraphDataInOut(Data) {
    if (Data.length > 0 || Data.length > 0) {
      return {
        series: [
          {
            name: "People Count In",
            data: Data.map((pc) => pc.people_count_in),
          },
          {
            name: "People Count Out",
            data: Data.map((pb) => pb.people_count_out),
          },
          {
            name: "Occupancy",
            data: Data.map((pb) =>
              Math.max(pb.people_count_in - pb.people_count_out, 0)
            ),
          },
        ],
        date:
          Data.length > 0
            ? Data.map((dt) => dt.date)
            : Data.map((dt) => dt.date),
      };
    }
    return {
      series: [
        {
          name: "People Count In",
          data: [],
        },
        {
          name: "People Count Out",
          data: [],
        },
        {
          name: "Occupancy",
          data: [],
        },
      ],
      date: [],
    };
  }
  static weekDayGraphDataPeopleCount(weeklyPeopleCount) {
    if (weeklyPeopleCount.length > 0) {
      return [
        {
          name: "People Count",
          data: DashboardFunction.returnLineChartCount(weeklyPeopleCount),
        },
      ];
    }
    return [
      {
        name: "People Count",
        data: [],
      },
    ];
  }

  static weekDayGraphData(weeklyPeopleCount, weeklyPasserCount) {
    if (weeklyPeopleCount.length > 0 || weeklyPasserCount.length > 0) {
      return [
        {
          name: "People Count",
          data: DashboardFunction.returnLineChartCount(weeklyPeopleCount),
        },
        {
          name: "Passer-By Count",
          data: DashboardFunction.returnLineChartCount(weeklyPasserCount),
        },
      ];
    }
    return [
      {
        name: "People Count",
        data: [],
      },
      {
        name: "Passer-By Count",
        data: [],
      },
    ];
  }

  //function for returning people count date range data
  static specificDayGraphDataPeopleCount(pcData) {
    if (pcData.length > 0) {
      return {
        series: [
          {
            name: "People Count",
            data: pcData.map((pc) => pc.people_count_in),
          },
        ],
        date: pcData.length > 0 ? pcData.map((dt) => dt.date) : 0,
      };
    }
    return {
      series: [
        {
          name: "People Count",
          data: [],
        },
      ],
      date: [],
    };
  }

  static specificDayGraphData(pcData, psData) {
    if (pcData.length > 0 || psData.length > 0) {
      return {
        series: [
          {
            name: "Passer-By Count",
            data: psData.map((pb) => pb.count),
          },
          {
            name: "People Count",
            data: pcData.map((pc) => pc.people_count_in),
          },
        ],
        date:
          pcData.length > 0
            ? pcData.map((dt) => dt.date)
            : psData.map((dt) => dt.date),
      };
    }
    return {
      series: [
        {
          name: "Passer-By Count",
          data: [],
        },
        {
          name: "People Count",
          data: [],
        },
      ],
      date: [],
    };
  }
  //function for returning three week graph data of people count
  static threeWeekGraphDataPeopleCount(threeWeekPeopleCount) {
    const dateForThreeWeekPeopleCount =
      threeWeekPeopleCount.length > 0
        ? threeWeekPeopleCount.map((dt) => dt.date)
        : 0;
    if (threeWeekPeopleCount.length > 0) {
      return {
        series: [
          {
            name: "People Count",
            data: threeWeekPeopleCount.map((pc) => pc.people_count_in),
          },
        ],
        date: dateForThreeWeekPeopleCount.reverse(),
      };
    }
    return {
      series: [
        {
          name: "Passer-By Count",
          data: [],
        },
        {
          name: "People Count",
          data: [],
        },
      ],
      date: [],
    };
  }

  static threeWeekGraphData(threeWeekPeopleCount, threeWeekPasserCount) {
    const dateForThreeWeekPeoplePasserby =
      threeWeekPeopleCount.length > 0
        ? threeWeekPeopleCount.map((dt) => dt.date)
        : threeWeekPasserCount.map((dt) => dt.date);
    if (threeWeekPeopleCount.length > 0 || threeWeekPasserCount.length > 0) {
      return {
        series: [
          {
            name: "Passer-By Count",
            data: threeWeekPasserCount.map((pb) => pb.count),
          },
          {
            name: "People Count",
            data: threeWeekPeopleCount.map((pc) => pc.people_count_in),
          },
        ],
        date: dateForThreeWeekPeoplePasserby.reverse(),
      };
    }
    return {
      series: [
        {
          name: "Passer-By Count",
          data: [],
        },
        {
          name: "People Count",
          data: [],
        },
      ],
      date: [],
    };
  }

  static returnHappinessGraphData(data) {
    return data.map((data) => ({
      x: data.date,
      y: data.happiness,
    }));
  }
  static returnNeutralGraphData(data) {
    return data.map((data) => ({
      x: data.date,
      y: data.neutral,
    }));
  }

  static emotionGraphDataHourly(emotionHoulry) {
    if (emotionHoulry.length > 0) {
      return [
        {
          name: "Happiness",
          data: DashboardFunction.returnHappinessGraphData(emotionHoulry),
        },
        {
          name: "Neutral",
          data: DashboardFunction.returnNeutralGraphData(emotionHoulry),
        },
      ];
    }
    return [
      {
        name: "Happiness",
        data: [],
      },
      {
        name: "Neutral",
        data: [],
      },
    ];
  }

  //functions for returing data for people count hourly graph data
  static returnGroupLineChartCount(data) {
    return data.map((data) => ({
      x: data.date,
      y: data.group_count,
    }));
  }
  static hourlyGroupCount(groupCountHourly) {
    if (groupCountHourly.length > 0) {
      return [
        {
          name: "Group Count",
          data: DashboardFunction.returnGroupLineChartCount(groupCountHourly),
        },
      ];
    }
    return [
      {
        name: "Group Count",
        data: [],
      },
    ];
  }
}
export default DashboardFunction;
