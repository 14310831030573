import ApiSpec from "./../features/EndPoints";
import AlertEndPoints from "../features/AlertEndPoints";
import AlertEventMergedEndpoints from "../features/AlertEventMergedEndpoints";
import {
  notifiToasterSuccess,
  notifiToasterWarn,
} from "../../components/common/UseToast";
import axios from "axios";
import EndPoints from "./../features/EndPoints";

// Action Type Constants
const UPDATE_SELECTED_MODE = "UPDATE_SELECTED_MODE";
const UPDATE_FACILITY = "UPDATE_FACILITY";
const UPDATE_CLIENT = "UPDATE_CLIENT";
const UPDATE_DEVICE = "UPDATE_DEVICE";
const UPDATE_ALERT_TYPE = "UPDATE_ALERT_TYPE";
const UPDATE_SEARCH_VALUE = "UPDATE_SEARCH_VALUE";
const UPDATE_SEARCH_VALUE_LENGTH = "UPDATE_SEARCH_VALUE_LENGTH";
const UPDATE_TIME = "UPDATE_TIME";
const UPDATE_CURRENT_PAGE = "UPDATE_CURRENT_PAGE";
const UPDATE_TAB_VALUE = "UPDATE_TAB_VALUE";
const UPDATE_TAB_NAME = "UPDATE_TAB_NAME";
const UPDATE_ALERT_TYPE_SELECTED = "UPDATE_ALERT_TYPE_SELECTED";
const UPDATE_USECASE_SELECTED = "UPDATE_USECASE_SELECTED";
const UPDATE_CAMERA = "UPDATE_CAMERA";
const UPDATE_SELECTED_PRIORITY = "UPDATE_SELECTED_PRIORITY";
const UPDATE_ALERT_OR_EVENT = "UPDATE_ALERT_OR_EVENT";
const UPDATE_TOTAL_ALERT_AND_EVENT = "UPDATE_TOTAL_ALERT_AND_EVENT";
const UPDATE_MODEL_STATUS = "UPDATE_MODEL_STATUS";
class AlertTableAction {
  static updateSelectedMode(data) {
    return {
      type: UPDATE_SELECTED_MODE,
      payload: data,
    };
  }

  static updateFacility(data) {
    return {
      type: UPDATE_FACILITY,
      payload: data,
    };
  }

  static updateClient(data) {
    return {
      type: UPDATE_CLIENT,
      payload: data,
    };
  }

  static updateDevice(data) {
    return {
      type: UPDATE_DEVICE,
      payload: data,
    };
  }
  static updateCamera(data) {
    return {
      type: UPDATE_CAMERA,
      payload: data,
    };
  }
  static updateAlertOrEvent(data) {
    return {
      type: UPDATE_ALERT_OR_EVENT,
      payload: data,
    };
  }

  static updateAlertType(data) {
    return {
      type: UPDATE_ALERT_TYPE,
      payload: data,
    };
  }
  static updateSelectedPriority(data) {
    return {
      type: UPDATE_SELECTED_PRIORITY,
      payload: data,
    };
  }
  static updateTotalAlertEvent(data) {
    return {
      type: UPDATE_TOTAL_ALERT_AND_EVENT,
      payload: data,
    };
  }

  static updateSearchValue(data) {
    return {
      type: UPDATE_SEARCH_VALUE,
      payload: data,
    };
  }
  static updateSearchValueLength(data) {
    return {
      type: UPDATE_SEARCH_VALUE_LENGTH,
      payload: data,
    };
  }
  static updateTime(data) {
    return {
      type: UPDATE_TIME,
      payload: data,
    };
  }
  static updateCurrentPage(data) {
    return {
      type: UPDATE_CURRENT_PAGE,
      payload: data,
    };
  }
  static updateTabValue(data) {
    return {
      type: UPDATE_TAB_VALUE,
      payload: data,
    };
  }
  static updateAlertTypesSelected(data) {
    return {
      type: UPDATE_ALERT_TYPE_SELECTED,
      payload: data,
    };
  }
  static updateTabName(data) {
    return {
      type: UPDATE_TAB_NAME,
      payload: data,
    };
  }
  static updateSelectedUseCase(data) {
    return {
      type: UPDATE_USECASE_SELECTED,
      payload: data,
    };
  }
  static updateModalOpenStatus(data) {
    return {
      type: UPDATE_MODEL_STATUS,
      payload: data,
    };
  }
  static getPlaybackStreamAlert(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      await axios
        .get(
          ApiSpec.getPlaybackStream(
            data.cam_id,
            data.date,
            data.startTime,
            data.endTime
          ),
          { headers }
        )
        .then((response) => {
          if (response.data.length == 0) {
            notifiToasterWarn(
              "Sorry, there is no recording available for the selected time."
            );
            dispatch({ type: "UPDATE_ALERT_PLAYBACK_STREAM", payload: [] });
          } else {
            dispatch({
              type: "UPDATE_ALERT_PLAYBACK_STREAM",
              payload: response.data,
            });
            const arr = JSON.parse(sessionStorage.getItem("NewItems")) || [];
            const res_data = arr.concat(response.data);
            sessionStorage.setItem("NewItems", JSON.stringify(res_data));
          }
        })
        .catch(() => {
          dispatch({
            type: "UPDATE_ALERT_PLAYBACK_STREAM",
            payload: [],
          });
        });
    };
  }
  static async getAlertEventNotificationList() {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Token ${token}`,
    };

    try {
      const response = await axios.get(
        AlertEventMergedEndpoints.getAllNotificationList(),
        {
          headers,
        }
      );
      return response.data;
    } catch (error) {
      return [];
    }
  }

  static sendNotificationToFacility(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      await axios
        .get(AlertEndPoints.sendAlertNotification(data), { headers })
        .then((response) => {
          notifiToasterSuccess("Notification Send Successfully");
        })
        .catch(() => {
          notifiToasterWarn("Unable to send notification");
        });
    };
  }
  static getUseCasesList(userId) {
    return async (dispatch) => {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Token ${token}`,
      };

      try {
        const response = await axios.get(EndPoints.useCaseList(userId), {
          headers,
        });
        const filteredUseCases = response.data.map((item) => ({
          value: item.id,
          label: item.usecase,
        }));
        const newUsecaseList = [
          { label: "All", value: "all" },
          ...filteredUseCases,
        ];
        dispatch({ type: "GET_USE_CASE_LIST", payload: newUsecaseList });
      } catch (error) {
        dispatch({ type: "GET_USE_CASE_LIST", payload: [] });
      }
    };
  }
  static async getAlertEventList() {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Token ${token}`,
    };
    const user_profile_id = JSON.parse(localStorage.getItem("profile")).id;
    try {
      const response = await axios.get(
        AlertEventMergedEndpoints.getAllAlertEventMerged(user_profile_id),
        {
          headers,
        }
      );
      sessionStorage.setItem(
        "selected_notification_type",
        JSON.stringify(response.data.data)
      );
      return response.data.data;
    } catch (error) {
      return [];
    }
  }
  static async updateAlertEventPatch(data, id, type) {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Token ${token}`,
    };
    let url =
      type == "alert"
        ? AlertEndPoints.updateAlertInstance(id)
        : AlertEndPoints.updateEventInstance(id);
    try {
      const response = await axios.patch(url, data, {
        headers,
      });
      notifiToasterSuccess("Alert Updated Successfully");
      return response.data;
    } catch (error) {
      notifiToasterWarn("Failed to update alert");
      return [];
    }
  }
}

export default AlertTableAction;
