import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CheckboxAutocomplete from "../../Profile/AccessControl/CommonComponent/CheckboxAutoComplete";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Checkbox,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import SelectFieldComp from "../../../components/common/FormField/SelectFieldComp";
import AutocompleteCompUncontrollable from "../../../components/common/FormField/AutoCompleteUncontrollable";
import AutocompleteComp from "../../../components/common/FormField/Autocomplete";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import RuleBasedAlertAction from "../../../redux/Action/RuleBasedAlertActions";
import { notifiToasterWarn } from "../../../components/common/UseToast";
const OperationalDaysOptions = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const PriorityOptions = ["High", "Normal"];
function restructureTimings(timing, days) {
  const opsDaysArray = days.split(",").map((day) => day.trim());
  const timingArray = timing.split(",").map((time) => time.trim());
  let timingDict = {};

  opsDaysArray.forEach((day, index) => {
    const newTiming = timingArray[index]?.split("-").map((time) => time.trim());
    if (newTiming && newTiming.length === 2) {
      timingDict[day] = {
        start: newTiming[0],
        end: newTiming[1],
      };
    }
  });

  return timingDict;
}

function restructureDaysSelected(days) {
  const opsDaysArray = days.split(",").map((day) => day.trim());
  let daysDict = {};

  OperationalDaysOptions.forEach((day) => {
    daysDict[day] = opsDaysArray.includes(day);
  });

  return daysDict;
}

function generateTimingArray(timing, operationalDays) {
  const timingArray = [];
  console.log(timing, operationalDays);
  Object.entries(operationalDays).forEach(([day, isActive]) => {
    if (isActive) {
      const { start, end } = timing[operationalDays[day]] || {};

      if (start && end) {
        timingArray.push(`${start} - ${end}`);
      } else {
        notifiToasterWarn(
          `Please enter Start Time and End Time for ${operationalDays[day]}`
        );
        return [];
      }
    }
  });

  return timingArray;
}

const AlertRuleDialogs = ({ open, handleClose, isEditable, data }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    count_threshold: "",
    alert_or_event_type: "",
    operational_days: [],
    alert_event_name: "",
    action_alert_message: "",
    frequency: "",
    timings: {},
    name: "",
  });
  const [errors, setErrors] = useState({
    count_threshold: "",
    alert_or_event_type: "",
    alert_event_name: "",
    action_alert_message: "",
    frequency: "",
    selectedAlertType: "",
    selectedCameras: "",
    selectedFacility: "",
    name: "",
  });
  const [selectedAlertType, setSelectedAlertType] = useState(null);
  const [facilityOptions, setFacilityOption] = useState([]);
  const [alertTypeOptions, setAlertTypeOptions] = useState([]);
  const [selectedCameras, setSeletectedCameras] = useState([]);
  const [selectedFacility, setSelectedFacility] = useState([]);
  const facilitiesList = useSelector((state) => state.commonData.storeList);
  const AlertEventTypesFilter = useSelector(
    (state) => state.AlertTableStates.AlertTypes
  );
  const cameraList = useSelector((state) => state.ruleBasedAlerts.cameraList);
  const [selectedDays, setSelectedDays] = useState({});
  const [proiority, setPriority] = useState("High");

  const handleFacilityChange = (event, newValue) => {
    setSelectedFacility(newValue);
  };
  useEffect(() => {
    selectedFacility.map((item) => {
      dispatch(RuleBasedAlertAction.getCameraByFacilities(item.id));
    });
  }, [selectedFacility]);
  const handleCameraChange = (event, newValue) => {
    setSeletectedCameras(newValue);
  };

  useEffect(() => {
    if (Array.isArray(facilitiesList)) {
      let facilities = facilitiesList.map((item) => ({
        id: item.value,
        value: item.text,
      }));
      setFacilityOption(facilities);
    }
  }, [facilitiesList]);

  useEffect(() => {
    // Check if facilitiesList exists and is an array
    if (Array.isArray(AlertEventTypesFilter)) {
      let alerts = AlertEventTypesFilter.map((item) => ({
        value: item.notification_type,
        label: item.name,
      }));
      setAlertTypeOptions(alerts);
    }
  }, [AlertEventTypesFilter]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    // Initialize timings for each daySelectedDays
    const initialTimings = {};
    OperationalDaysOptions.forEach((day) => {
      initialTimings[day] = { start: "", end: "" };
    });
    setFormData({ timings: initialTimings });
    const initialDays = {};
    OperationalDaysOptions.forEach((day) => {
      initialDays[day] = false;
    });
    setSelectedDays(initialDays);
  }, []);

  useEffect(() => {
    if (isEditable && data && alertTypeOptions.length > 0) {
      setSelectedFacility(
        data.facility_ids.map((item) => ({
          id: item.id,
          value: item.facility_name,
        }))
      );
      setSeletectedCameras(
        data.camera_ids.map((item) => ({
          id: item.id,
          value: item.camera_name,
        }))
      );

      setFormData({
        ...formData,
        name: data.name,
        count_threshold: data.count_threshold,
        alert_event_name: data.alert_event_name,
        action_alert_message: data.action_alert_message,
        frequency: data.frequency,
        timings: restructureTimings(data.timings, data.operational_days),
      });
      console.log(data);
      setSelectedAlertType(
        alertTypeOptions.find((item) => item.value == data.alert_or_event_type)
      );
      setSelectedDays(restructureDaysSelected(data.operational_days));
    }
  }, [isEditable, data, alertTypeOptions]);

  const handleTimeChange = (event, day, type) => {
    const { value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      timings: {
        ...prevFormData.timings,
        [day]: {
          ...prevFormData.timings[day],
          [type]: value,
        },
      },
    }));
  };

  const handleDayToggle = (day) => {
    setSelectedDays((prevSelectedDays) => ({
      ...prevSelectedDays,
      [day]: !prevSelectedDays[day],
    }));
  };

  function validate() {
    const newError = {};

    // Validate selected facility
    if (selectedFacility.length === 0) {
      newError.selectedFacility = "Please select at least one facility.";
    }

    // Validate selected cameras
    if (selectedCameras.length === 0) {
      newError.selectedCameras = "Please select at least one camera.";
    }

    // Validate name
    if (!formData.name || formData.name.trim() === "") {
      newError.name = "Please enter a suitable name.";
    }

    // Validate selected alert type
    if (!selectedAlertType) {
      newError.selectedAlertType =
        "Please select the type of alert on which you want to apply the rule.";
    }

    // Validate count threshold
    if (!formData.count_threshold || formData.count_threshold === "") {
      newError.count_threshold = "Please enter a valid threshold.";
    } else {
      if (formData.count_threshold <= 0) {
        newError.count_threshold = "Please enter a valid threshold.";
      }
    }

    // Validate alert event name
    if (!formData.alert_event_name || formData.alert_event_name.trim() === "") {
      newError.alert_event_name = "Please enter a valid alert type name.";
    }

    // Validate action alert message
    if (
      !formData.action_alert_message ||
      formData.action_alert_message.trim() === ""
    ) {
      newError.action_alert_message = "Please enter a valid alert message.";
    }

    // Validate frequency
    if (!formData.frequency || formData.frequency.trim() === "") {
      newError.frequency = "Please enter a valid frequency.";
    } else {
      if (newError.frequency <= 0) {
        newError.frequency = "Please enter a valid frequency.";
      }
    }

    // Set errors or reset them if there are no validation issues
    if (Object.keys(newError).length > 0) {
      setErrors(newError);
      return false;
    } else {
      setErrors({
        count_threshold: "",
        alert_event_name: "",
        action_alert_message: "",
        frequency: "",
        selectedAlertType: "",
        selectedCameras: "",
        selectedFacility: "",
        name: "",
      });
      return true;
    }
  }

  const handleSubmit = async () => {
    if (!validate()) {
      return;
    }
    const operationalDays = Object.keys(selectedDays).filter(
      (item) => selectedDays[item]
    );

    const newData = generateTimingArray(formData.timings, operationalDays);
    if (operationalDays.length == 0) {
      notifiToasterWarn(
        "Please Select at least one day on which this alert will work"
      );
      return;
    }
    if (newData.length === operationalDays.length) {
      const dataToSubmit = {
        camera_ids: selectedCameras.map((item) => item.id),
        facility_ids: selectedFacility.map((item) => item.id),
        count_threshold: formData.count_threshold,
        alert_or_event_type: selectedAlertType.value,
        alert_event_name: formData.alert_event_name.trim().replace(/\s+/g, "_"),
        operational_days: operationalDays.join(","),
        timings: newData.join(","),
        priority: proiority,
        action_alert_message: formData.action_alert_message,
        frequency: formData.frequency,
        name: formData.name,
        is_active: true,
      };
      !isEditable
        ? dispatch(RuleBasedAlertAction.addNewAlertRules(dataToSubmit))
        : dispatch(
            RuleBasedAlertAction.updateTheAlertRule(dataToSubmit, data.id)
          );
      handleClose();
    } else {
      notifiToasterWarn("Please enter the timing for each selected day");
    }
  };

  function handleAlertTypeChange(val) {
    if (val) {
      setSelectedAlertType(val);
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <DialogTitle>Add New Configuration </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <div className="row">
          <div className="col-12 col-md-6 mt-3">
            <TextField
              fullWidth
              label="Name"
              name="name"
              type="text"
              value={formData.name}
              onChange={handleChange}
              error={!!(errors.name && errors.name.length > 0)}
              helperText={
                errors.name && errors.name.length > 0
                  ? errors.name
                  : "Enter Number"
              }
            />
          </div>
          <div className="col-12 col-md-6 mt-2">
            <CheckboxAutocomplete
              value={selectedFacility}
              onChange={handleFacilityChange}
              label="Select Facility"
              size="large"
              options={facilityOptions}
              heading={""}
              fullWidth
              loading={false}
              error={
                !!(
                  errors.selectedFacility && errors.selectedFacility.length > 0
                )
              }
              helperText={
                errors.selectedFacility && errors.selectedFacility.length > 0
                  ? errors.selectedFacility
                  : "Select the facilities on which you want to recieve Alerts"
              }
            />
          </div>

          <div className="col-12 col-md-6 mt-2">
            <CheckboxAutocomplete
              value={selectedCameras}
              onChange={handleCameraChange}
              label="Select Cameras"
              size="large"
              options={cameraList}
              heading={""}
              fullWidth
              loading={false}
              error={
                !!(errors.selectedCameras && errors.selectedCameras.length > 0)
              }
              helperText={
                errors.selectedCameras && errors.selectedCameras.length > 0
                  ? errors.selectedCameras
                  : "Select the cameras on which you want to recieve Alerts"
              }
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            {isEditable ? (
              <AutocompleteComp
                option={alertTypeOptions}
                onChange={handleAlertTypeChange}
                value={selectedAlertType}
                label="Alert Type"
                showValue={false}
              />
            ) : (
              <AutocompleteCompUncontrollable
                option={alertTypeOptions}
                onChange={handleAlertTypeChange}
                label={"Alert Types *"}
                helperText={
                  errors.selectedAlertType &&
                  errors.selectedAlertType.length > 0
                    ? errors.selectedAlertType
                    : "Select the Alert Types on which you want to recieve Alerts"
                }
                error={
                  !!(
                    errors.selectedAlertType &&
                    errors.selectedAlertType.length > 0
                  )
                }
              />
            )}
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              fullWidth
              label="Count Threshold"
              name="count_threshold"
              type="number"
              value={formData.count_threshold}
              onChange={handleChange}
              error={
                !!(errors.count_threshold && errors.count_threshold.length > 0)
              }
              helperText={
                errors.count_threshold && errors.count_threshold.length > 0
                  ? errors.count_threshold
                  : "Enter Number of Maximum Alerts on which a new alert is trigerred"
              }
            />
          </div>

          <div className="col-12 col-md-6 mt-3">
            <TextField
              fullWidth
              label="Alert Type"
              name="alert_event_name"
              type="text"
              value={formData.alert_event_name}
              onChange={handleChange}
              error={
                !!(
                  errors.alert_event_name && errors.alert_event_name.length > 0
                )
              }
              helperText={
                errors.alert_event_name && errors.alert_event_name.length > 0
                  ? errors.alert_event_name
                  : "Enter the Type of alert you want to recieve"
              }
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              fullWidth
              label="Alert Message "
              name="action_alert_message"
              type="text"
              value={formData.action_alert_message}
              onChange={handleChange}
              error={
                !!(
                  errors.action_alert_message &&
                  errors.action_alert_message.length > 0
                )
              }
              helperText={
                errors.action_alert_message &&
                errors.action_alert_message.length > 0
                  ? errors.action_alert_message
                  : "Enter the message you want to recieve on your alert"
              }
            />
          </div>
          <div className="form-group col-12 col-sm-6 col-lg-6 mt-3">
            <SelectFieldComp
              options={PriorityOptions}
              value={proiority}
              onChange={(val) => setPriority(val)}
              label="Priority"
              helperText={"Select the proiority of your alerts"}
            />
          </div>
          <div className="col-12 col-md-6  mt-3">
            <TextField
              fullWidth
              label="Frequency"
              name="frequency"
              type="number"
              value={formData.frequency}
              onChange={handleChange}
              error={!!(errors.frequency && errors.frequency.length > 0)}
              helperText={
                errors.frequency && errors.frequency.length > 0
                  ? errors.frequency
                  : "Enter the frequency of checking the threshold( in hours)"
              }
            />
          </div>
          <div className="mt-4">
            {Object.keys(selectedDays).length > 0 &&
              OperationalDaysOptions.map((day) => (
                <div key={day} className="row my-2">
                  <div className="col-12 col-md-4">
                    <h5>
                      <Checkbox
                        checked={selectedDays[day]}
                        onChange={() => handleDayToggle(day)}
                      />
                      {day}
                    </h5>
                  </div>
                  <div className="col-6 col-md-4">
                    <TextField
                      fullWidth
                      label={`Start Time - ${day}`}
                      type="time"
                      value={formData.timings[day]?.start || "00:00"}
                      onChange={(event) =>
                        handleTimeChange(event, day, "start")
                      }
                    />
                  </div>
                  <div className="col-6 col-md-4">
                    <TextField
                      fullWidth
                      label={`End Time - ${day}`}
                      type="time"
                      value={formData.timings[day]?.end || "23:59"}
                      onChange={(event) => handleTimeChange(event, day, "end")}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AlertRuleDialogs.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  data: PropTypes.any,
};

export default AlertRuleDialogs;
