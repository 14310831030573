export const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          primary: {
            main: "#425CDF",
          },
          secondary: {
            main: "#2b3674",
          },
        }
      : {
          // Dark mode palette settings
          primary: {
            main: "#425CDF",
            contrastText: "white",
          },
          secondary: {
            main: "#FFFFFF",
            contrastText: "black",
          },
        }),
  },
});
