import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

const styling = {
  fontSize: "16px", // Set the font size
  fontFamily: "Roboto", // Set the font family
  fontWeight: "bold", // Set the font weight
  color: "var(--card-text-color)", // Set the font color
};
const label_styling = {
  colors: "var(--card-text-color)", // Set the font color
};
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 15,
  },
}));

const YumFoods = ({ label, seriesData, mode }) => {
  const [dataLabelsEnabled, setDataLabelsEnabled] = useState(false);

  const chartOptions = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    legend: {
      position: "top",
      labels: {
        colors: "var(--card-text-color)", // Set the color of the legend labels
        useSeriesColors: false, // Set to false to use the custom color above
      },
    },
    colors: ["#4339F2", "#FF4560", "#3AACFF", "#ebc334", "#9C27B0"],
    grid: {
      show: false,
    },
    stroke: {
      width: [0, 3, 3, 3],
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1, 2, 3],
    },
    labels: label,
    xaxis: {
      type: "category",
      tooltip: {
        enabled: false,
      },
      title: {
        text: dataLabelsEnabled ? (mode == "date-range" ? "Date" : "Time") : "",
        style: styling,
      },
      labels: {
        style: label_styling,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "30%", // Adjust the width percentage as needed
      },
    },
    yaxis: [
      {
        title: {
          text: dataLabelsEnabled ? "People in Queue" : "",
          style: styling,
        },
        labels: {
          style: label_styling,
        },
      },
      {
        opposite: true,
        labels: {
          style: label_styling,
        },
        title: {
          text: dataLabelsEnabled
            ? mode == "date-range"
              ? "Average Time Taken (sec)"
              : "Time Taken (sec)"
            : "",
          style: styling,
        },
      },
    ],
  };

  const dataPointsToggle = (ev) => {
    setDataLabelsEnabled(ev.currentTarget.checked);
  };

  return (
    <div className="chart-container">
      <div className="chart-div">
        <p
          className="chart-title"
          style={{ textAlign: "left", marginLeft: "20px" }}
        >
          Queue Time
        </p>
        <div style={{ textAlign: "right", marginLeft: "20px" }}>
          <label className="checkbox checkbox-lg checkbox-single flex-shrink-0 m-0 mx-4 checkbox-light-secondary">
            <LightTooltip
              placement="top"
              title={
                dataLabelsEnabled ? "Hide data points" : "Show data points"
              }
            >
              <input
                className="chart-checkbox"
                type="checkbox"
                onChange={dataPointsToggle}
                checked={dataLabelsEnabled}
              />
            </LightTooltip>
            <span className="checkbox-icon"></span>
          </label>
        </div>
        <div id="chart">
          <ReactApexChart
            options={chartOptions}
            series={seriesData}
            type="line"
            height={350}
          />
        </div>
      </div>
    </div>
  );
};
YumFoods.propTypes = {
  label: PropTypes.array.isRequired,
  seriesData: PropTypes.array.isRequired,
  mode: PropTypes.string.isRequired,
};
export default YumFoods;
