import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./../../assets/css/FaceRecognition.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { extractDateTime } from "./utils/FormatDateAndTime";
const FaceCard = ({
  imageUrl1,
  imageUrl2,
  facility,
  date,
  time,
  camera,
  id,
  matched,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const formattedDateTime = extractDateTime(time);
  const divStyle = {
    backgroundColor: "transparent",

    padding: "10px",
    boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.5)",
    borderRadius: "10px",
    width: "100%",
    border: isHovered
      ? "2px solid var(--black-white-darkmode)"
      : "0.5px solid rgba(0, 0, 0, 0.08)",
  };

  return (
    <>
      <Link
        to={`/face-details/${id}/${matched}`}
        style={{ textDecoration: "none" }}
      >
        <div
          className="row m-2 "
          style={divStyle}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="col-6 py-3 ">
            <h3
              className=" font-weight-bolder  flex-no-wrap"
              style={{
                color: "var(--black-white-darkmode)",
                fontSize: "1.4rem",
              }}
            >
              Face Detected
            </h3>
            <p
              className="face_detected_card_text"
              style={{ color: "var(--black-white-darkmode)" }}
            >
              <span style={{ color: "var(--black-white-darkmode)" }}>
                Camera
              </span>
              :{camera}
            </p>
            <p
              className="face_detected_card_text"
              style={{ color: "var(--black-white-darkmode)" }}
            >
              <span style={{ color: "var(--black-white-darkmode)" }}>Date</span>
              :{formattedDateTime.date}
            </p>
            <p
              className="face_detected_card_text"
              style={{ color: "var(--black-white-darkmode)" }}
            >
              <span style={{ color: "var(--black-white-darkmode)" }}>Time</span>
              :{formattedDateTime.time}
            </p>{" "}
          </div>
          <div className="col-6 d-flex flex-column justify-content-end align-items-center">
            <div className="col-12 mt-2">
              <img
                className="img-fluid face_detected_card_image"
                src={imageUrl1}
                alt="face1"
              />
            </div>
            <div className="col-12 mt-2">
              <img
                className="img-fluid face_detected_card_image"
                src={imageUrl2}
                // src={unknownPerson}
                alt="face2"
              />
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};
FaceCard.propTypes = {
  imageUrl1: PropTypes.string.isRequired,
  imageUrl2: PropTypes.string.isRequired,
  facility: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  camera: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  matched: PropTypes.bool.isRequired,
};

export default FaceCard;
