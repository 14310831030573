import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardMedia,
  Typography,
  Button,
  Grid,
  Box,
  TextField,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";
import placeholderimage from "../../assets/images/placeholder.png";
import moment from "moment";
import AnprActions from "../../redux/Action/AnprActions";
import { fetchAnprDataWithParam } from "./utils/AnprFunctions";
import { useSelector, useDispatch } from "react-redux";
const AnprRowComponent = ({ anprData }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [driverName, setDriverName] = useState(anprData.driver_name);
  const [driverNumber, setDriverNumber] = useState(anprData.driver_number);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogImage, setDialogImage] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");
  const selectedFacility = useSelector((state) => state.anpr.selectedFacility);
  const selectedCamera = useSelector((state) => state.anpr.selectedCamera);
  const currentPage = useSelector((state) => state.anpr.currentPage);
  const pageSize = useSelector((state) => state.anpr.pageSize);
  const anprDateRange = useSelector((state) => state.anpr.dateRange);
  const anprDate = useSelector((state) => state.anpr.selectedDate);
  const anprSelectedMode = useSelector((state) => state.anpr.selectedMode);
  const [selectionRange] = useState({
    startDate: new Date(anprDateRange.startDate),
    endDate: new Date(anprDateRange.endDate),
    key: "selection",
  });
  const [selectedDate] = useState(new Date(anprDate));
  const [selectedMode] = useState(anprSelectedMode);
  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };
  const dispatch = useDispatch();

  const handleSave = async () => {
    // Save logic here (e.g., API call)
    let data = { driver_name: driverName, driver_number: driverNumber };
    setIsEditing(false);
    let status = await AnprActions.updateANPRdata(anprData.id, data);
    if (status !== 200) {
      setDriverName("");
      setDriverNumber("");
    } else {
      fetchAnprDataWithParam(
        selectedMode,
        selectedDate,
        selectedFacility,
        selectedCamera,
        selectionRange,
        currentPage,
        pageSize,
        dispatch
      );
    }
  };

  const handleCancel = () => {
    setDriverName(anprData.driver_name);
    setDriverNumber(anprData.driver_number);
    setIsEditing(false);
  };

  const handleImageClick = (image, title) => {
    setDialogImage(image);
    setDialogTitle(title);
    setDialogOpen(true);
  };

  // Function to format the date and time
  const formatDateTime = (dateString) => {
    const date = moment(dateString)
      .subtract(5, "hours")
      .subtract(30, "minutes");
    return date.format("DD-MM-YYYY hh:mm A");
  };

  return (
    <>
      <Card sx={{ my: 2, border: "1px solid #ccc", padding: "10px" }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="body1" color="primary" fontSize={"20px"}>
              ANPR Alert
            </Typography>
          </Grid>
          <Grid item xs={6} md={3} lg={2}>
            <Box sx={{ position: "relative" }}>
              <CardMedia
                component="img"
                image={
                  anprData.entry_image_url
                    ? anprData.entry_image_url
                    : placeholderimage
                }
                alt="Vehicle in"
                sx={{
                  width: "100%",
                  height: "100%", // Set a fixed height
                  borderRadius: "4px",
                  objectFit: "cover", // Ensure the image covers the area without distortion
                }}
                onClick={() =>
                  handleImageClick(
                    anprData.entry_image_url
                      ? anprData.entry_image_url
                      : placeholderimage,
                    "IN Image"
                  )
                }
              />
              <Box
                sx={{
                  position: "absolute",
                  top: "5px",
                  left: "5px",
                  backgroundColor: "blue",
                  color: "white",
                  padding: "2px 6px",
                  borderRadius: "3px",
                  fontSize: "12px",
                }}
              >
                IN
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} md={3} lg={2}>
            <Box sx={{ position: "relative" }}>
              <CardMedia
                component="img"
                image={
                  anprData.exit_image_url
                    ? anprData.exit_image_url
                    : placeholderimage
                }
                alt="Vehicle out"
                sx={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "4px",
                  objectFit: "cover",
                }}
                onClick={() =>
                  handleImageClick(
                    anprData.exit_image_url
                      ? anprData.exit_image_url
                      : placeholderimage,
                    "OUT Image"
                  )
                }
              />
              <Box
                sx={{
                  position: "absolute",
                  top: "5px",
                  left: "5px",
                  backgroundColor: "blue",
                  color: "white",
                  padding: "2px 6px",
                  borderRadius: "3px",
                  fontSize: "12px",
                }}
              >
                OUT
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} lg={2}>
            <Typography variant="h6" fontSize={"20px"} padding={0}>
              <strong>Vehicle Number:</strong> {anprData.vehicle_number}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={2}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {isEditing ? (
                <>
                  <TextField
                    variant="outlined"
                    size="small"
                    label="Driver Name"
                    value={driverName}
                    onChange={(e) => setDriverName(e.target.value)}
                    sx={{ mt: 1 }}
                  />
                  <TextField
                    variant="outlined"
                    size="small"
                    label="Driver Number"
                    value={driverNumber}
                    onChange={(e) => setDriverNumber(e.target.value)}
                    sx={{ mt: 2 }}
                  />
                </>
              ) : (
                <>
                  <Typography variant="body1" fontSize="16px">
                    <strong>Driver Name:</strong>{" "}
                    {driverName ? driverName : "N/A"}
                    <IconButton size="small" onClick={handleEditToggle}>
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Typography>
                  <Typography variant="body1" fontSize="16px">
                    <strong>Driver Number:</strong>
                    {driverNumber ? driverNumber : "N/A"}
                    <IconButton size="small" onClick={handleEditToggle}>
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Typography>
                </>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Box>
              <div style={{ display: "flex" }}>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  Entry Time:
                </Typography>
                <Typography variant="body1" ml={1}>
                  {formatDateTime(anprData.entry_time)}
                </Typography>
              </div>
              <div style={{ display: "flex" }}>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  Exit Time:
                </Typography>
                <Typography variant="body1" ml={1}>
                  {anprData.exit_time
                    ? formatDateTime(anprData.exit_time)
                    : "N/A"}
                </Typography>
              </div>
            </Box>
          </Grid>
        </Grid>
        {isEditing && (
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              sx={{ mr: 2 }}
              onClick={handleSave}
            >
              Save
            </Button>
            <Button variant="outlined" color="primary" onClick={handleCancel}>
              Cancel
            </Button>
          </Box>
        )}
      </Card>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="md"
      >
        <DialogTitle display={"flex"} justifyContent={"space-between"}>
          {dialogTitle}
          <IconButton onClick={() => setDialogOpen(false)}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <CardMedia
            component="img"
            image={dialogImage}
            alt="Large view"
            sx={{ width: "100%", height: "auto" }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

AnprRowComponent.propTypes = {
  anprData: PropTypes.object.isRequired,
};

export default AnprRowComponent;
