import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  IconButton,
  Paper,
  CircularProgress,
  TextField,
  Box,
  Button,
} from "@mui/material";
import { Edit, Delete, Add } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import SystemParametersActions from "../../../redux/Action/SystemParameterActions";
import FacilityDialog from "./FacilityDialog"; // Adjust the import path accordingly
import { addNewFacility } from "../../../redux/Action/RhmsAction";
import ConfirmationDialog from "../../../components/common/ConfirmationDailog";
import { changeTopbarTitle } from "../../../redux/Action/ChangeTopbarTitle";

const FacilityTable = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedFacility, setSelectedFacility] = useState(null);
  const dispatch = useDispatch();
  const [isDeleteConfirmModelOpen, setDeleteOpen] = useState(false);
  const [selectDeletedId, setSelectedDeleteId] = useState(null);
  const facilityList = useSelector(
    (state) => state.systemParameters.facilityList
  );
  const [access_permission] = useState(
    JSON.parse(localStorage.getItem("agx_permission")).Customization ?? []
  );
  const can_add_facility = access_permission.includes("add_facility");
  const can_edit_facility = access_permission.includes("edit_facility");
  const can_view_facility = access_permission.includes(
    "view_facility_customization"
  );
  useEffect(() => {
    dispatch(changeTopbarTitle("System Parameters"));
    setLoading(true);
    dispatch(SystemParametersActions.getFacilityByUserProfile()).finally(() => {
      setLoading(false);
    });
  }, [dispatch]);

  const handleEdit = (facility) => {
    setSelectedFacility(facility);
    setDialogOpen(true);
  };

  const handleAdd = () => {
    setSelectedFacility(null);
    setDialogOpen(true);
  };

  const handleDelete = (id) => {
    dispatch(SystemParametersActions.deleteFacility(id));
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setPage(0);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleSave = (facility) => {
    setLoading(true);
    facility.service_on = [1];
    if (facility.id) {
      dispatch(
        SystemParametersActions.editFacility(facility.id, facility)
      ).finally(() => {
        setLoading(true);
        dispatch(SystemParametersActions.getFacilityByUserProfile()).finally(
          () => {
            setLoading(false);
          }
        );
      });
    } else {
      const profile_id = JSON.parse(localStorage.getItem("profile")).id;
      dispatch(addNewFacility(facility, profile_id)).then(() => {
        setLoading(true);
        dispatch(SystemParametersActions.getFacilityByUserProfile()).finally(
          () => {
            setLoading(false);
          }
        );
      });
    }
  };

  const filteredFacilities = facilityList.filter((facility) =>
    facility.facility_name.toLowerCase().includes(search.toLowerCase())
  );
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <Box padding={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <div className="col-12 col-md-6 col-lg-4">
          <TextField
            variant="outlined"
            placeholder="Search by Facility Name"
            value={search}
            size="small"
            sx={{ backgroundColor: "white" }}
            fullWidth
            onChange={handleSearchChange}
          />
        </div>
        {can_add_facility && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={handleAdd}
          >
            Add Facility
          </Button>
        )}
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "15%" }}>S.N</TableCell>
              <TableCell align="left" sx={{ width: "30%" }}>
                Facility Name
              </TableCell>
              <TableCell sx={{ width: "45%" }}>Address</TableCell>
              {can_edit_facility && (
                <TableCell sx={{ width: "10%" }}>Action</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : filteredFacilities.length > 0 ? (
              (rowsPerPage > 0
                ? filteredFacilities.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filteredFacilities
              ).map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell sx={{ width: "15%" }}>{index + 1}</TableCell>
                  <TableCell align="left" sx={{ width: "30%" }}>
                    {row.facility_name}
                  </TableCell>
                  <TableCell sx={{ width: "45%" }}>
                    {row.address ? row.address : "Not Available"}
                  </TableCell>
                  {can_edit_facility && (
                    <TableCell sx={{ width: "10%" }}>
                      <IconButton onClick={() => handleEdit(row)}>
                        <Edit />
                      </IconButton>

                      {/* <IconButton
                      onClick={() => {
                        setSelectedDeleteId(row.id);
                        setDeleteOpen(true);
                      }}
                    >
                      <Delete />
                    </IconButton> */}
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No data present
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={4}
                count={filteredFacilities.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <ConfirmationDialog
        title={"Delete Facility"}
        dialogContent={"Are you sure to delete the facility"}
        handleConfirm={() => handleDelete(selectDeletedId)}
        handleDisconfirm={() => setDeleteOpen(false)}
        isOpen={isDeleteConfirmModelOpen}
        handleClose={() => setDeleteOpen(false)}
        confirmButtonLabel={"Yes"}
        disconfirmButtonLabel={"No"}
        confirmButtonVariant={"contained"}
        disconfirmButtonVariant={"outlined"}
      />
      <FacilityDialog
        open={dialogOpen}
        handleClose={handleDialogClose}
        handleSave={handleSave}
        facilityData={selectedFacility}
      />
    </Box>
  );
};

export default FacilityTable;
