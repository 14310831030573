import React from "react";
import { Divider, IconButton, Typography } from "@mui/material";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { extractDateTime } from "./utils/FormatDateAndTime";

const LastSeenDetails = ({ label, value }) => (
  <div>
    <Typography
      variant="body1"
      color="textSecondary"
      component="span"
      style={{ color: "var(--black-white-darkmode)" }}
    >
      {label}:
    </Typography>
    <Typography
      variant="body1"
      component="span"
      style={{ color: "var(--black-white-darkmode)" }}
    >
      {value}
    </Typography>
  </div>
);

LastSeenDetails.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const LastSeenCard = ({ data, usedIn }) => {
  const {
    image_url,
    metadata,
    known_face_id,
    camera_id,
    created_at,
    id,
    is_recognized,
  } = data;
  const isRecognized = usedIn !== "unrecognized";
  const imageSrc = isRecognized ? data.image : image_url;
  const cameraName = isRecognized
    ? camera_id?.camera_name
    : metadata?.camera?.name || "";
  const timeDetected = isRecognized
    ? extractDateTime(created_at)
    : extractDateTime(created_at);

  return (
    <div className="row last-seen-main-div">
      <div className="col-md-4">
        <img
          style={{ maxHeight: "100px", objectFit: "fill" }}
          src={imageSrc}
          className="img-fluid"
          alt="Face"
        />
      </div>
      <div className="col-12 col-md-8 last-seen-single-div">
        <LastSeenDetails
          label="Name"
          value={isRecognized ? known_face_id.name : ""}
        />
        <LastSeenDetails label="Camera" value={cameraName} />
        <LastSeenDetails
          label="Date"
          value={created_at ? timeDetected.date : "N/A"}
        />
        <LastSeenDetails
          label="Time"
          value={created_at ? timeDetected.time : "N/A"}
        />
        {usedIn == "recognized" && (
          <div className="last-seen-expand-div">
            <Link to={`/face-details/${id}/${is_recognized}`}>
              <IconButton>
                <UnfoldMoreIcon
                  color="primary"
                  fontSize="large"
                  sx={{ transform: "rotate(45deg)" }}
                />
              </IconButton>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

LastSeenCard.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.string,
    image_url: PropTypes.string,
    metadata: PropTypes.object,
    known_face_id: PropTypes.shape({
      name: PropTypes.string,
    }),
    camera_id: PropTypes.shape({
      camera_name: PropTypes.string,
    }),
    created_at: PropTypes.string,
    id: PropTypes.string,
    is_recognized: PropTypes.bool,
  }).isRequired,
  usedIn: PropTypes.string.isRequired,
};

export default LastSeenCard;
