import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";

const headerCellStyle = {
  color: "white",
  backgroundColor: "#425CDF",
  fontSize: "16px",
};
const rowData = [
  {
    imageUrl1:
      "https://img.freepik.com/free-photo/portrait-man-laughing_23-2148859448.jpg?size=338&ext=jpg&ga=GA1.1.1395880969.1709596800&semt=ais",
    ID: "00231",
    Name: "0023",
    Department: "0025",
    Gender: "Male",
    Age: "31",
  },
  {
    imageUrl1:
      "https://s3-alpha-sig.figma.com/img/f032/5b62/d76dd8bdc8fc36c51de37636e7b6d3ee?Expires=1711929600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=UPvdXLZ5VtUTPm4HjTCEAElj1AtoxZyziLuP~aw3BWwNWw9F0Mg5mOBE1DlCkDFxKC0f0JzKRfNPKOQOV1wK-gPmvRIh6Hu6pRyE7pTroMbT6CxPCJqlzbqd48-yWv9WnQUuELkukg76fZZWtJMuWjLdpwWUj5eoyMJVulz87FzXBNZOLUh6XzAocvh5GNlKb0tIrt8dfAOBwAOjFGsG9Vrc4qBIUmRb3SSIUa4YomXqxppB-Vz-11hFATT-CvRETSC1YxaQO5~BZ9CCSC5yAGTJlIlmthH4a7fErMNBvicYU948aKb5KSJ1MHj044KxV-~vAGNrloankNaKGGfTwA__",
    ID: "00232",
    Name: "0023",
    Department: "0025",
    Gender: "Female",
    Age: "32",
  },
  {
    imageUrl1:
      "https://s3-alpha-sig.figma.com/img/895c/1812/17905df11c6f443676836960008ca7c5?Expires=1711929600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=QywrAkCVhprdwCGhjEKG~o8RH4fgiacID4AsyA77E~43Z2KoUZHeMgRM-5a785kfxYkY6iNDZ6wEiMR9FwMJh05p1iQVruVpS~SK5O0v1bBHWXSGkzHUr0RMPvf3PXYNzREmw~xmD0oK2NkL9QZp4crFdP~nExon9qX04vZKVs4JyWXnjFq6Z2y8R9Wc52LJTtL3lPlmpPOiwAaJsNzIegrN7ZkyTy~vbg7Zi9xtE9rqWjhklAJIZo03qMGFZ9lXN5E6XGA6DaLDGjcTdu8vF6A6Foo3qiCQBTwYzkZtd4cWzx3P79TwT~deBofKYQJwbP75SFyKgmGaODneca-r7A__",
    ID: "00233",
    Name: "00235",
    Department: "0025",
    Gender: "Male",
    Age: "33",
  },
  {
    imageUrl1:
      "https://img.freepik.com/free-photo/portrait-man-laughing_23-2148859448.jpg?size=338&ext=jpg&ga=GA1.1.1395880969.1709596800&semt=ais",
    ID: "00244",
    Name: "0023",
    Department: "0025",
    Gender: "Male",
    Age: "34",
  },
  {
    imageUrl1:
      "https://s3-alpha-sig.figma.com/img/f032/5b62/d76dd8bdc8fc36c51de37636e7b6d3ee?Expires=1711929600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=UPvdXLZ5VtUTPm4HjTCEAElj1AtoxZyziLuP~aw3BWwNWw9F0Mg5mOBE1DlCkDFxKC0f0JzKRfNPKOQOV1wK-gPmvRIh6Hu6pRyE7pTroMbT6CxPCJqlzbqd48-yWv9WnQUuELkukg76fZZWtJMuWjLdpwWUj5eoyMJVulz87FzXBNZOLUh6XzAocvh5GNlKb0tIrt8dfAOBwAOjFGsG9Vrc4qBIUmRb3SSIUa4YomXqxppB-Vz-11hFATT-CvRETSC1YxaQO5~BZ9CCSC5yAGTJlIlmthH4a7fErMNBvicYU948aKb5KSJ1MHj044KxV-~vAGNrloankNaKGGfTwA__",
    ID: "00235",
    Name: "0023",
    Department: "0025",
    Gender: "Female",
    Age: "32",
  },
  {
    imageUrl1:
      "https://s3-alpha-sig.figma.com/img/895c/1812/17905df11c6f443676836960008ca7c5?Expires=1711929600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=QywrAkCVhprdwCGhjEKG~o8RH4fgiacID4AsyA77E~43Z2KoUZHeMgRM-5a785kfxYkY6iNDZ6wEiMR9FwMJh05p1iQVruVpS~SK5O0v1bBHWXSGkzHUr0RMPvf3PXYNzREmw~xmD0oK2NkL9QZp4crFdP~nExon9qX04vZKVs4JyWXnjFq6Z2y8R9Wc52LJTtL3lPlmpPOiwAaJsNzIegrN7ZkyTy~vbg7Zi9xtE9rqWjhklAJIZo03qMGFZ9lXN5E6XGA6DaLDGjcTdu8vF6A6Foo3qiCQBTwYzkZtd4cWzx3P79TwT~deBofKYQJwbP75SFyKgmGaODneca-r7A__",
    ID: "00236",
    Name: "0023",
    Department: "0025",
    Gender: "Male",
    Age: "32",
  },
];
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const AllocateModal = ({ isOpen, handleClose }) => {
  const [selectedRow, setSeletedRow] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  function selectUnselecedAll(isAllSelected) {
    setSelectAll(isAllSelected);
    if (isAllSelected) {
      const newArray = [];
      rowData.map((item) => newArray.push(item.ID));
      setSeletedRow(newArray);
    } else {
      setSeletedRow([]);
    }
  }
  function handleSingleSelection(id) {
    if (selectedRow.includes(id)) {
      let newArray = selectedRow.filter((item) => item != id);
      setSeletedRow(newArray);
    } else {
      setSeletedRow([...selectedRow, id]);
    }
  }
  console.log(selectedRow);
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        maxWidth="md"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        PaperProps={{
          sx: {
            width: "66%",
            maxWidth: "xl",
          },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Allocate
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "theme.palette.grey[500]",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ overflow: "auto" }}>
          <div className="row">
            <div className="col-12 d-flex justify-content-between align-item-center mt-2 p-2">
              <h3 style={{ fontSize: "1.3rem", fontWeight: "bolder" }}>
                Select Face
              </h3>
              <Button variant="contained">Done</Button>
            </div>
            <div className="col-12">
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        sx={{
                          font: "roboto",
                          letterSpacing: "-2%",
                          minWidth: "150px",
                          fontSize: "20px",
                          fontWeight: "700",
                        }}
                      >
                        Photo
                      </TableCell>
                      <TableCell
                        sx={{
                          font: "roboto",
                          letterSpacing: "-2%",
                          minWidth: "150px",
                          fontSize: "20px",
                          fontWeight: "700",
                        }}
                      >
                        ID
                      </TableCell>
                      <TableCell
                        sx={{
                          font: "roboto",
                          letterSpacing: "-2%",
                          minWidth: "150px",
                          fontSize: "20px",
                          fontWeight: "700",
                        }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        sx={{
                          font: "roboto",
                          letterSpacing: "-2%",
                          minWidth: "150px",
                          fontSize: "20px",
                          fontWeight: "700",
                        }}
                      >
                        Department
                      </TableCell>
                      <TableCell
                        sx={{
                          font: "roboto",
                          letterSpacing: "-2%",
                          minWidth: "150px",
                          fontSize: "20px",
                          fontWeight: "700",
                        }}
                      >
                        Gender
                      </TableCell>
                      <TableCell
                        sx={{
                          font: "roboto",
                          letterSpacing: "-2%",
                          minWidth: "150px",
                          fontSize: "20px",
                          fontWeight: "700",
                        }}
                      >
                        Age
                      </TableCell>
                      <TableCell
                        sx={{
                          font: "roboto",
                          letterSpacing: "-2%",
                          minWidth: "150px",
                          fontSize: "20px",
                          fontWeight: "700",
                        }}
                        // className="d-flex  flex-wrap"
                      >
                        {/* <div className="mt-2">Select All</div> */}
                        <div>
                          <Checkbox
                            {...label}
                            checked={selectAll}
                            onChange={(e) =>
                              selectUnselecedAll(e.target.checked)
                            }
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                    {rowData.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <div className="FacialRecognitionTable">
                            <img
                              src={row.imageUrl1}
                              alt={`face-${index}`}
                              style={{ height: "40px" }}
                            />
                          </div>
                        </TableCell>
                        <TableCell>{row.ID}</TableCell>
                        <TableCell>{row.Name}</TableCell>
                        <TableCell>{row.Department}</TableCell>
                        <TableCell>{row.Gender}</TableCell>
                        <TableCell>{row.Age}</TableCell>
                        <TableCell>
                          <Checkbox
                            {...label}
                            onChange={() => handleSingleSelection(row.ID)}
                            checked={selectedRow.includes(row.ID)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

AllocateModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default AllocateModal;
