import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";

const MultiSelectAutoAutoComplete = ({
  option,
  value,
  onChange,
  label,
  getOptionDisabled,
}) => {
  function handleGetOptionDisbale(item) {
    getOptionDisabled(item);
  }
  const isOptionEqualToValue = (option, value) => {
    return option.value === value.value;
  };
  function handleChange(event, newValue) {
    onChange(newValue);
  }
  return (
    <div>
      {value && (
        <Autocomplete
          multiple
          id="tags-standard"
          disableClearable
          options={option}
          getOptionLabel={(option) => `${option.label} (${option.value}) `}
          getOptionDisabled={getOptionDisabled}
          value={value}
          onChange={handleChange}
          isOptionEqualToValue={isOptionEqualToValue}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={label}
              InputLabelProps={{
                style: {
                  // fontSize: "20px",
                  // color: "black",
                  // fontWeight: "bold",
                },
              }}
            />
          )}
        />
      )}
    </div>
  );
};
MultiSelectAutoAutoComplete.propTypes = {
  option: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  getOptionDisabled: PropTypes.func.isRequired,
  value: PropTypes.any,
  label: PropTypes.string.isRequired,
};

export default MultiSelectAutoAutoComplete;
