import HeatMapAction from "../../redux/Action/HeatMapAction";
import moment from "moment";

const getHeatMapData = (dateValue, store, dispatch) => {
  const date = moment(dateValue);
  const formattedDate = date.format("YYYY-MM-DD");

  const obj = {
    id: store,
    date: formattedDate,
  };
  HeatMapAction.getHeatmapByStore(obj)(dispatch);
};

export default getHeatMapData;
