import React from "react";
import { MenuItem, Select } from "@mui/material";
import { getProfileIdByClient } from "../../../redux/Action/CommonAction";
import { useState } from "react";
import { useSelector } from "react-redux";
import Account from "../../../redux/Action/CommonAction";
import { useDispatch } from "react-redux";
import AlertTableAction from "../../../redux/Action/AlertTableActions";
import AlertDashAction from "../../../redux/Action/AlertDashAction";
import { getTableData } from "../../../pages/Alert/AlertChartData";
const inputselect = {
  boxShadow: "none",
  borderRadius: "8px",
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
  MenuProps: {
    PaperProps: {
      style: {
        backgroundColor: "var(--top-bar-bg-color)",
        maxHeight: 350,
        width: 50,
        overflowX: "auto",
      },
    },
  },
};

const ClientFilter = (propTypes) => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [selectedClient, setselectedClient] = useState(
    propTypes.type === "AlertTable"
      ? state.AlertTableStates.selectedClient
      : propTypes.type === "AlertDashboard"
      ? state.AlertDashboardStates.selectedClient
      : propTypes.type === "EventTable"
      ? state.EventTableStates.selectedClient
      : { value: "all" }
  );

  const handleClientChange = async (event) => {
    setselectedClient({ value: event.target.value });
    if (propTypes.type === "AlertTable") {
      dispatch(AlertTableAction.updateClient({ value: event.target.value }));
      dispatch(AlertTableAction.updateFacility({ value: "all" }));
      dispatch(AlertTableAction.updateDevice({ value: "all" }));
    }
    if (propTypes.type === "AlertDashboard") {
      dispatch(AlertDashAction.updateClient({ value: event.target.value }));
      dispatch(AlertDashAction.updateFacility({ value: "all" }));
      dispatch(AlertDashAction.updateDevice({ value: "all" }));
    }
    const store = { value: "all" };
    const device = { value: "all" };
    if (event.target.value !== "all") {
      await getProfileIdByClient(event.target.value).then((res) => {
        var profile = JSON.parse(localStorage.getItem("profile"));
        profile.id = res.data[0].account_owner;
        localStorage.setItem("profile", JSON.stringify(profile));
        Account.getStoresByAccount(res.data[0].account_owner)(dispatch);
        if (propTypes.type === "AlertTable") {
          getTableData(
            state.commonData.alerteventDate,
            state.AlertTableStates.selectedMode.value,
            state.AlertTableStates.selectedAlertType.value,
            store.value,
            device.value,
            state.AlertTableStates.timeRange,
            {
              startDate: new Date(state.commonData.dateRange.startDate),
              endDate: new Date(state.commonData.dateRange.endDate),
              key: "selection",
            },
            state.AlertTableStates.currentPage,
            state.AlertTableStates.tabValue,
            state.AlertTableStates.searchValue,
            state.commonData.pageSize,
            state.AlertTableStates.selectedUseCase,
            state.AlertTableStates.selectedPriority.value,
            state.AlertTableStates.alertOrEvent,
            dispatch
          );
        }
      }); // <-- Added closing parenthesis for the then method
    } else {
      var obj = JSON.parse(localStorage.getItem("profile"));
      obj.id = localStorage.getItem("profile_id");
      localStorage.setItem("profile", JSON.stringify(obj));
      Account.getStoresByAccount(obj.id)(dispatch);
      if (propTypes.type === "AlertTable") {
        getTableData(
          state.commonData.alerteventDate,
          state.AlertTableStates.selectedMode.value,
          state.AlertTableStates.selectedAlertType.value,
          state.AlertTableStates.selectedFacility.value,
          state.AlertTableStates.selectedDevice.value,
          state.AlertTableStates.timeRange,
          {
            startDate: new Date(state.commonData.dateRange.startDate),
            endDate: new Date(state.commonData.dateRange.endDate),
            key: "selection",
          },
          state.AlertTableStates.currentPage,
          state.AlertTableStates.tabValue,
          state.AlertTableStates.searchValue,
          state.commonData.pageSize,
          state.AlertTableStates.selectedUseCase,
          state.AlertTableStates.selectedPriority.value,
          state.AlertTableStates.alertOrEvent,
          dispatch
        );
      }
    }
  };
  return (
    <div>
      <div className="select-input-lebel">Clients</div>
      <Select
        value={selectedClient.value}
        onChange={handleClientChange}
        sx={{
          color: "var(--card-text-color)",
          ...inputselect,
        }}
        className="select-input-field"
        MenuProps={inputselect.MenuProps}
      >
        {state.commonData.clientList.map((item) => {
          return (
            <MenuItem
              key={item.value}
              value={item.value}
              sx={{
                paddingTop: "2px",
                paddingBottom: "2px",
                color: "var(--card-text-color)",
              }}
            >
              {item.text}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
};

export default ClientFilter;
