import React, { useEffect, useState } from "react";
import { List, Drawer } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Tooltip } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useSelector, useDispatch } from "react-redux";
import sizeConfigs from "../../configs/sizeConfigs";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import Account from "../../redux/Action/CommonAction";
import VmsHelperActions from "../../redux/Action/VmsHelperActions";
import LanguageIcon from "@mui/icons-material/Language";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import BarsRotateLoader from "./Loader/BarsRotateLoader";
import LiveViewAction from "../../redux/Action/LiveViewAction";
import useWindowDimensions from "./useWindowDimensions";
import assets from "../../assets";
function formatName(name) {
  return name.replace(/_/g, " ").replace(/\b\w/g, (c) => c.toUpperCase());
}
const VideoSideBarItem = (propTypes) => {
  const [open, setOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const dispatch = useDispatch();
  const state = useSelector((state) => state.vmsHelper);
  const liveviewState = useSelector((state) => state.liveview);
  const handleClick = () => {
    setOpen(!open);
  };
  const handleChildItemClick = async (id) => {
    if (state.liveViewFeature === "playback") {
      handleClickPlayback(id);
    } else {
      if (selectedItems.includes(id)) {
        setSelectedItems(selectedItems.filter((item) => item !== id));
      } else {
        setSelectedItems([...selectedItems, id]);
      }
      if (
        selectedItems.includes(id) ||
        isCameraPlaying(id) ||
        isCamePresentInLiveView(id)
      ) {
        if (
          state.SelectedCamera.length - 1 >= state.SelectedGrid &&
          isCamePresentInLiveView(id) &&
          state.currentPage !=
            Math.ceil(state.SelectedCamera.length / state.SelectedGrid)
        ) {
          dispatch(VmsHelperActions.increaseLoadingCameras([id]));
          dispatch(
            LiveViewAction.getLiveViewByIpCameras(
              state.SelectedCamera[state.SelectedGrid],
              state.selectedQuality
            )
          );
        }
        dispatch(VmsHelperActions.RemoveCameraSelected(id));
        dispatch(LiveViewAction.removeCameraFromLiveViewData(id));
      } else {
        if (
          state.SelectedCamera.length <
          state.SelectedGrid * state.currentPage
        ) {
          dispatch(VmsHelperActions.increaseLoadingCameras([id]));
          dispatch(
            LiveViewAction.getLiveViewByIpCameras(id, state.selectedQuality)
          );
        }
        dispatch(VmsHelperActions.updateCameraSelected(id));
      }
    }
  };
  const handleClickPlayback = (id) => {
    if (!state.playbackCameras.includes(id)) {
      if (
        state.playbackCameras.length > 0 &&
        state.playback_stream.length > 0
      ) {
        dispatch(VmsHelperActions.updateShowAlertDialog(true));
      } else {
        try {
          dispatch(
            VmsHelperActions.removeSelecetedPlaybackCamera(
              state.playbackCameras[0]
            )
          );
        } catch {
          console.log("error");
        }
        dispatch(VmsHelperActions.updateShowAlertDialog(false));
        const date = new Date(state.playbackDate);
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        dispatch(
          VmsHelperActions.getMonthlyAvailableRecording({
            cam_id: id,
            month: month,
            year: year,
          })
        );
      }
      dispatch(VmsHelperActions.updatePlaybackCamera(id));
    } else {
      dispatch(VmsHelperActions.removeSelecetedPlaybackCamera(id));
      dispatch(VmsHelperActions.removePlaybackStream());
    }
  };
  const handleDragStart = (id, e) => {
    e.dataTransfer.setData("cameraId", id);
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter((item) => item !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };
  const isCameraPlaying = (cam_id) => {
    if (state.liveViewFeature === "playback") {
      let flag = state.playbackCameras.some((item) => item == cam_id);
      return flag;
    } else {
      let flag = state.SelectedCamera.some((item) => item == cam_id);
      return flag;
    }
  };
  const isCameraPresentInLoading = (cam_id) => {
    if (state.liveViewFeature === "playback") {
      let flag = state.playbackLoading.some((item) => item == cam_id);
      return flag;
    } else {
      let flag = state.LoadingCameras.some((item) => item == cam_id);
      return flag;
    }
  };
  const isCamePresentInLiveView = (cam_id) => {
    let flag = liveviewState.liveViewData.some((item) => item.cam_id == cam_id);
    return flag;
  };
  return (
    <div>
      {propTypes.item.camera.length > 0 && (
        <div>
          <ListItemButton onClick={handleClick}>
            <span
              style={{ marginRight: "10px", color: "var(--card-text-color)" }}
            >
              <LanguageIcon />
            </span>
            <Tooltip
              title={
                propTypes.item.name.length > 18 ? propTypes.item.name : null
              }
              placement="right"
            >
              <ListItemText
                primary={
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      color: "var(--card-text-color)",
                    }}
                  >
                    {formatName(
                      propTypes.item.name.length > 18
                        ? propTypes.item.name.slice(0, 14) + "..."
                        : propTypes.item.name
                    )}
                  </span>
                }
              />
            </Tooltip>
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {propTypes.item.camera.map((child) => {
                const isPlaying =
                  isCameraPlaying(child.id) ||
                  isCamePresentInLiveView(child.id);

                return (
                  <ListItemButton
                    sx={{ pl: 3, pt: 0.1, pb: 1 }}
                    key={child.id}
                    onClick={() => handleChildItemClick(child.id)}
                    selected={isPlaying}
                    disabled={isCameraPresentInLoading(child.id)}
                    draggable={
                      state.liveViewFeature === "playback" ? false : !isPlaying
                    }
                    onDragStart={(e) => {
                      e.stopPropagation(); // Stop event propagation
                      handleDragStart(child.id, e);
                    }}
                  >
                    <span
                      style={{
                        marginRight: "10px",
                        color: "var(--card-text-color)",
                      }}
                    >
                      <VideoCallIcon />
                    </span>
                    <Tooltip
                      title={
                        child.camera_name.length > 20 ? child.camera_name : null
                      }
                      placement="right"
                    >
                      <ListItemText
                        sx={{
                          color: isPlaying ? "green" : "var(--card-text-color)",
                          marginRight: "10px",
                          overflowY: "hidden",
                        }}
                      >
                        {formatName(
                          child.camera_name.length > 20
                            ? child.camera_name.slice(0, 14) + "..."
                            : child.camera_name
                        )}
                      </ListItemText>
                    </Tooltip>
                    <Tooltip
                      title={isPlaying ? "Stop Live Stream" : "Play Live Stram"}
                      placement="right"
                    >
                      <span style={{ color: "var(--card-text-color)" }}>
                        {isPlaying ? <CancelIcon /> : <PlayCircleIcon />}
                      </span>
                    </Tooltip>
                  </ListItemButton>
                );
              })}
            </List>
          </Collapse>
        </div>
      )}
    </div>
  );
};

const VideoSidebar = () => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const toggle = state.commonData.toggleSidebar;
  const profile = JSON.parse(localStorage.getItem("profile"));
  let sidebarWidth = toggle
    ? sizeConfigs.sidebarMin.width
    : sizeConfigs.sidebar.width;
  const { width } = useWindowDimensions();
  function handleToggleVideoSidebar() {
    dispatch(Account.toglleVideoSidebar(false));
  }
  useEffect(() => {
    dispatch(VmsHelperActions.UpdateLiveviewFeature("live_view"));
    let profile_id = localStorage.getItem("profile_id");
    dispatch(VmsHelperActions.updateLoadingForFacilityList(true));
    dispatch(VmsHelperActions.getAllFacilitiesCameraLiveview(profile_id))
      .then(() => {
        dispatch(VmsHelperActions.updateLoadingForFacilityList(false));
      })
      .catch((error) => {
        console.error("Error occurred while fetching camera data:", error);
        dispatch(VmsHelperActions.updateLoadingForFacilityList(false));
      });
  }, []);
  return (
    <>
      <div>
        <Drawer
          variant="permanent"
          sx={{
            width: sidebarWidth,
            transition: "sidebarWidth 0.0s",
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: sidebarWidth,
              boxSizing: "border-box",
              borderRight: "0px",
              backgroundColor: "var(--side-bar-bg-color)",
              color: "var(--side-item-color)",
              transition: "sidebarWidth 0.0s",
            },
          }}
        >
          {width > 800 && (
            <>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={
                    "client_logo" in profile.account.config
                      ? profile.account.config.client_logo
                      : assets.images.logo
                  }
                  style={{ width: "85%", height: "50px" }}
                  id="sidebar-logo-image"
                  className="logo-visible"
                />
              </div>
              <div className="mt-2">
                <Divider sx={{ color: "var(--card-text-color)" }}>
                  <Tooltip title="Click to See Sidebar">
                    <Chip
                      style={{
                        color: "var(--card-text-color)",
                        backgroundColor: "var(--top-bar-bg-color)",
                      }}
                      label={<KeyboardDoubleArrowUpIcon />}
                      onClick={handleToggleVideoSidebar}
                    />
                  </Tooltip>
                </Divider>
              </div>
            </>
          )}
          <div
            style={{ overflow: "auto" }}
            className="video-sidebar-camera-list"
          >
            <List>
              {state.vmsHelper.laoding ? (
                <div className="loading-icon-videowall-sidebar">
                  <BarsRotateLoader
                    style={{
                      fontSize: "40px",
                      color: "var(--black-white-darkmode)",
                    }}
                  />
                </div>
              ) : state.vmsHelper.liveViewFeature === "live_view" ? (
                <>
                  {state.vmsHelper.FacilityCameraList.map((item, ind) => {
                    return <VideoSideBarItem item={item} key={ind} />;
                  })}
                </>
              ) : (
                <>
                  {state.vmsHelper.FacilityCameraListForPlayback.map(
                    (item, ind) => {
                      return <VideoSideBarItem item={item} key={ind} />;
                    }
                  )}
                  {state.vmsHelper.FacilityCameraListForPlayback.length ==
                    0 && (
                    <p>Sorry, Playback is not available for your camers.</p>
                  )}
                </>
              )}
            </List>
          </div>
        </Drawer>
      </div>
    </>
  );
};

export default VideoSidebar;
