import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SettingsIcon from "@mui/icons-material/Settings";
import VmsLiveviewActions from "../../../redux/Action/VmsActions";
import Tooltip from "@mui/material/Tooltip";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";

const inputselect = {
  boxShadow: "none",
  borderRadius: "8px",
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
  MenuProps: {
    PaperProps: {
      style: {
        backgroundColor: "var(--top-bar-bg-color)",
        maxHeight: 350,
        width: 50,
        overflowX: "auto",
      },
    },
  },
};

const QualitySetting = () => {
  const dispatch = useDispatch();
  const reduxQuality = useSelector((state) => state.vmsHelper.selectedQuality);
  const [quality, setQuality] = useState(reduxQuality ?? "sub_stream");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleQualityChange = (event) => {
    setQuality(event.target.value);
    dispatch(VmsLiveviewActions.updateSelectedQuality(event.target.value));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Change Quality">
          <IconButton onClick={handleClick}>
            <SettingsIcon
              style={{ color: "#425CDF", fontSize: "30px", margin: "5px" }}
            />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              bottom: -10,
              left: "50%",
              transform: "translateX(-50%) translateY(-50%) rotate(45deg)",
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "center", vertical: "bottom" }}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <MenuItem onClick={handleClose} disableRipple>
          <div>
            <div className="mb-4">
              <div className="select-input-lebel">Quality</div>
              <Select
                onChange={handleQualityChange}
                value={quality}
                sx={{
                  color: "var(--card-text-color)",
                  ...inputselect,
                  "&:hover": {
                    backgroundColor: "transparent", // Disable hover effect
                  },
                }}
                className="select-input-field"
                MenuProps={inputselect.MenuProps}
              >
                <MenuItem
                  value="main_stream"
                  sx={{
                    paddingTop: "2px",
                    paddingBottom: "2px",
                    color: "var(--card-text-color)",
                    "&:hover": {
                      backgroundColor: "background.paper", // Disable hover effect
                    },
                  }}
                >
                  Main Stream
                </MenuItem>
                <MenuItem
                  value="sub_stream"
                  sx={{
                    paddingTop: "2px",
                    paddingBottom: "2px",
                    color: "var(--card-text-color)",
                    "&:hover": {
                      backgroundColor: "background.paper", // Disable hover effect
                    },
                  }}
                >
                  Sub Stream
                </MenuItem>
              </Select>
            </div>
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default QualitySetting;
