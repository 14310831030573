import * as DateFns from "date-fns";
import moment from "moment";

const initialState = {
  peopleCountHourly: [],
  passerCountHourly: [],
  dwellTimeCountHourly: {
    avg: { time_recorded__avg: 0 },
    countlessthan2: 0,
    countmorethan2: 0,
    countmorethan10: 0,
    lessthan2: [],
    morethan2: [],
    morethan10: [],
  },
  weeklyPeopleCount: [],
  threeWeekPeopleCount: [],
  weekdayPeopleCount: [],
  weeklyPasserCount: [],
  threeWeekPasserCount: [],
  weekdayPasserCount: [],
  dwellTimeWeeklyData: [],
  lessthan2: [],
  morethan2: [],
  morethan10: [],
  weeklylessthan2: [],
  weeklymorethan2: [],
  weeklymorethan10: [],
  peopleCountDayData: [],
  passerCountDayData: [],
  dwellTimeDayData: [],
  groupCountData: 0,
  groupCountHourly: [],
};

export default function DashMixinsReducer(state = initialState, action) {
  switch (action.type) {
    case "PEOPLE_COUNT_HOURLY_DATA":
      return {
        ...state,
        peopleCountHourly: action.payload.map((datapoint) => {
          let dater = DateFns.setHours(
            new Date(action.date),
            new Date(datapoint.recorded_at__hour)
          );
          datapoint.date = moment(dater)
            .subtract(30, "minutes")
            .toLocaleString();
          delete datapoint.recorded_at__hour;
          return datapoint;
        }),
      };

    case "PASSER_COUNT_HOURLY_DATA":
      return {
        ...state,
        passerCountHourly: action.payload.map((datapoint) => {
          let dater = DateFns.setHours(
            new Date(action.date),
            new Date(datapoint.recorded_at__hour)
          );
          datapoint.date = moment(dater)
            .subtract(30, "minutes")
            .toLocaleString();
          delete datapoint.recorded_at__hour;
          return datapoint;
        }),
      };
    case "DWELLTIME_COUNT_HOURLY_DATA":
      return {
        ...state,
        dwellTimeCountHourly: action.payload,
        lessthan2: action.payload.lessthan2.map((datapoint) => {
          let dater = DateFns.setHours(
            new Date(action.date),
            new Date(datapoint.recorded_at__hour)
          );
          datapoint.date = moment(dater)
            .subtract(30, "minutes")
            .toLocaleString();
          delete datapoint.recorded_at__hour;
          return datapoint;
        }),
        morethan2: action.payload.morethan2.map((datapoint) => {
          let dater = DateFns.setHours(
            new Date(action.date),
            new Date(datapoint.recorded_at__hour)
          );
          datapoint.date = moment(dater)
            .subtract(30, "minutes")
            .toLocaleString();
          delete datapoint.recorded_at__hour;
          return datapoint;
        }),
        morethan10: action.payload.morethan10.map((datapoint) => {
          let dater = DateFns.setHours(
            new Date(action.date),
            new Date(datapoint.recorded_at__hour)
          );
          datapoint.date = moment(dater)
            .subtract(30, "minutes")
            .toLocaleString();
          delete datapoint.recorded_at__hour;
          return datapoint;
        }),
      };
    case "WEEKLY_PEOPLE_COUNT_DATA":
      return {
        ...state,
        weeklyPeopleCount: action.payload.map((datapoint) => {
          datapoint.date = new Date(datapoint.recorded_at__date).toISOString();
          return datapoint;
        }),
      };
    case "GROUP_COUNT_DATA":
      return {
        ...state,
        groupCountData: action.payload,
      };
    case "THREE_WEEK_PEOPLE_COUNT_DATA":
      return {
        ...state,
        threeWeekPeopleCount: action.payload.map((datapoint) => {
          datapoint.date =
            new Date(datapoint.end_date).toISOString() +
            "," +
            new Date(datapoint.start_date).toISOString();

          return datapoint;
        }),
      };
    case "WEEKDAY_PEOPLE_COUNT_DATA":
      return {
        ...state,
        weekdayPeopleCount: action.payload.map((datapoint) => {
          datapoint.date = new Date(datapoint.recorded_at__date).toISOString();
          return datapoint;
        }),
      };
    case "WEEKLY_PASSER_COUNT_DATA":
      return {
        ...state,
        weeklyPasserCount: action.payload.map((datapoint) => {
          datapoint.date = new Date(datapoint.recorded_at__date).toISOString();
          return datapoint;
        }),
      };
    case "THREE_WEEK_PASSER_COUNT_DATA":
      return {
        ...state,
        threeWeekPasserCount: action.payload.map((datapoint) => {
          datapoint.date =
            new Date(datapoint.end_date).toISOString() +
            "," +
            new Date(datapoint.start_date).toISOString();

          return datapoint;
        }),
      };
    case "WEEKDAY_PASSER_COUNT_DATA":
      return {
        ...state,
        weekdayPasserCount: action.payload.map((datapoint) => {
          datapoint.date = new Date(datapoint.recorded_at__date).toISOString();
          return datapoint;
        }),
      };
    case "DWELL_TIME_WEEKLY_DATA":
      return {
        ...state,
        dwellTimeWeeklyData: action.payload,
        weeklylessthan2: action.payload.lessthan2.map((datapoint) => {
          datapoint.date = new Date(datapoint.recorded_at__date).toISOString();
          return datapoint;
        }),
        weeklymorethan2: action.payload.morethan2.map((datapoint) => {
          datapoint.date = new Date(datapoint.recorded_at__date).toISOString();
          return datapoint;
        }),
        weeklymorethan10: action.payload.morethan10.map((datapoint) => {
          datapoint.date = new Date(datapoint.recorded_at__date).toISOString();
          return datapoint;
        }),
      };
    case "PEOPLE_COUNT_DAY_DATA":
      console.log("nskfbvjhbbsjh");
      return {
        ...state,
        peopleCountHourly: action.payload.map((datapoint) => {
          datapoint.date = new Date(datapoint.recorded_at__date).toISOString();
          return datapoint;
        }),
        peopleCountDayData: action.payload.map((datapoint) => {
          datapoint.date = new Date(datapoint.recorded_at__date).toISOString();
          return datapoint;
        }),
      };
    case "PASSER_COUNT_DAY_DATA":
      return {
        ...state,
        passerCountHourly: action.payload.map((datapoint) => {
          datapoint.date = new Date(datapoint.recorded_at__date).toISOString();
          return datapoint;
        }),
        passerCountDayData: action.payload.map((datapoint) => {
          datapoint.date = new Date(datapoint.recorded_at__date).toISOString();
          return datapoint;
        }),
      };

    case "DWELL_TIME_DAY_DATA":
      return {
        ...state,
        dwellTimeCountHourly: action.payload,
        lessthan2: action.payload.lessthan2.map((datapoint) => {
          datapoint.date = new Date(datapoint.recorded_at__date).toISOString();
          return datapoint;
        }),
        morethan2: action.payload.morethan2.map((datapoint) => {
          datapoint.date = new Date(datapoint.recorded_at__date).toISOString();
          return datapoint;
        }),
        morethan10: action.payload.morethan10.map((datapoint) => {
          datapoint.date = new Date(datapoint.recorded_at__date).toISOString();
          return datapoint;
        }),
        dwellTimeWeeklyData: action.payload,
        weeklylessthan2: action.payload.lessthan2.map((datapoint) => {
          datapoint.date = new Date(datapoint.recorded_at__date).toISOString();
          return datapoint;
        }),
        weeklymorethan2: action.payload.morethan2.map((datapoint) => {
          datapoint.date = new Date(datapoint.recorded_at__date).toISOString();
          return datapoint;
        }),
        weeklymorethan10: action.payload.morethan10.map((datapoint) => {
          datapoint.date = new Date(datapoint.recorded_at__date).toISOString();
          return datapoint;
        }),
      };
    case "GROUP_COUNT_HOURLY":
      return {
        ...state,
        groupCountHourly: action.payload.map((datapoint) => {
          let dater = DateFns.setHours(
            new Date(action.date),
            new Date(datapoint.hour)
          );
          datapoint.date = moment(dater)
            .subtract(30, "minutes")
            .toLocaleString();
          delete datapoint.hour;
          return datapoint;
        }),
      };

    default:
      return state;
  }
}
