const initialState = {
  heatMapData: [],
};

export default function HeatMapReducer(state = initialState, action) {
  switch (action.type) {
    case "HEAT_MAP_DATA":
      return {
        ...state,
        heatMapData: action.payload,
      };

    default:
      return state;
  }
}
