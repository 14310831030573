import { getChartDataConsolidated } from "./DashbaordChartDataConsolidated";
const getChartData = (
  dateValue,
  mode,
  store,
  device,
  dtRange,
  StartHour,
  EndHour,
  dispatch
) => {
  getChartDataConsolidated(
    dateValue,
    mode,
    store,
    device,
    dtRange,
    StartHour,
    EndHour,
    dispatch
  );
};

export default getChartData;
