import axios from "axios";
import FaceRecEndpoints from "../features/FaceRecognitionEndPoints";
import { notifiToasterSuccess } from "../../components/common/UseToast";
import { notifiToasterWarn } from "../../components/common/UseToast";
import AnprEndpoints from "../features/AnprEndpoints";
function buildUrlWithParams(params, base_url) {
  // Initialize URL with the base part
  const url = new URL(base_url);

  // Iterate over the params object
  Object.keys(params).forEach((key) => {
    if (params[key] !== undefined && params[key] !== null) {
      // Append only if the parameter is not undefined or null
      url.searchParams.append(key, params[key]);
    }
  });

  // Return the final URL
  return url.toString();
}
class AnprActions {
  // To upload the details

  static updateFacility(data) {
    return {
      type: "UPDATE_ANPR_SELECTED_FACILITY",
      payload: data,
    };
  }

  static updateDevice(data) {
    return {
      type: "UPDATE_ANPR_SELECTED_CAMERA",
      payload: data,
    };
  }

  static cancelTokenSource = null;

  static updateDate(date) {
    return async (dispatch) => {
      dispatch({
        type: "UPDATE_ANPR_SELECTED_DATE",
        payload: date.toISOString(),
      });
    };
  }

  static updateDateRange(dateRange) {
    return async (dispatch) => {
      dispatch({
        type: "UPDATE_ANPR_DATE_RANGE",
        payload: dateRange,
      });
    };
  }

  static updateModeChange(mode) {
    return async (dispatch) => {
      dispatch({
        type: "UPDATE_ANPR_SELECTED_MODE",
        payload: mode,
      });
    };
  }

  static updateSelectedTab(value) {
    return async (dispatch) => {
      dispatch({
        type: "UPDATE_ANPR_SELECTED_TAB",
        payload: value,
      });
    };
  }
  static updateCurrentPage(value) {
    return async (dispatch) => {
      dispatch({
        type: "UPDATE_ANPR_CURRENT_PAGE",
        payload: value,
      });
    };
  }
  static getANPRData(params) {
    return async (dispatch) => {
      dispatch({ type: "UPDATE_ANPR_DATA_LOADING", payload: true });
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      try {
        const baseUrl = AnprEndpoints.getDataForAnpr();
        const urlWithParams = buildUrlWithParams(params, baseUrl);
        const response = await axios.get(urlWithParams, { headers });

        dispatch({
          type: "SET_ANPR_DATA",
          payload: {
            count: response.data.count,
            results: response.data.results,
          },
        });
      } catch (error) {
        console.error(error);
      } finally {
        dispatch({ type: "UPDATE_ANPR_DATA_LOADING", payload: false });
      }
    };
  }
  static async updateANPRdata(id, data) {
    var token = localStorage.getItem("token");
    let headers = {
      Authorization: `Token ${token}`,
    };
    try {
      const baseUrl = AnprEndpoints.updateANPRinfo(id);
      const response = await axios.patch(baseUrl, data, { headers });

      if (response.status === 200) {
        notifiToasterSuccess("Information Updated Successfully");
        return 200;
      } else {
        notifiToasterWarn("Failed to update information");
        return response.status;
      }
    } catch (error) {
      notifiToasterWarn("Failed to update information");
      return error.response.status;
    }
  }
}

export default AnprActions;
