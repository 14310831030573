import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PlaybackToolbar from "./PlaybackToolbar";
import PlaybackVideoPlayer from "./PlaybackVideoPlayer";
import VmsHelperActions from "../../../redux/Action/VmsHelperActions";
import VmsLiveviewActions from "../../../redux/Action/VmsActions";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import PlaybackModeMethodTabs from "./PlaybackModeMethodTabs";
import TimelineSlider from "./TimeSlider";

const PlayBackMainPage = () => {
  const dispatch = useDispatch();
  const showDialog = useSelector((state) => state.vms.showDialog);
  const playbackCamera = useSelector((state) => state.vms.playbackCamera);
  const playbackFeed = useSelector((state) => state.vms.playbackFeed);
  const [loading, setLoading] = useState(false);

  const updateLoading = (data) => {
    setLoading(data);
  };

  const handleYesClick = () => {
    dispatch(VmsHelperActions.removeSelecetedPlaybackCamera(playbackCamera[0]));
    dispatch(VmsLiveviewActions.removePlaybackStream());
    handleClose();
  };

  const handleNoClick = () => {
    dispatch(VmsHelperActions.removeSelecetedPlaybackCamera(playbackCamera[1]));
    dispatch(VmsLiveviewActions.updateShowAlertDialog(false));
  };

  const onHide = () => {
    null;
  };

  const handleClose = () => {
    dispatch(VmsLiveviewActions.updateShowAlertDialog(false));
  };

  useEffect(() => {
    return () => {
      dispatch(VmsLiveviewActions.removePlaybackStream());
      dispatch(VmsHelperActions.removeAllPlaybackCamera());
    };
  }, [dispatch]);

  const renderNoData = () => (
    <center>
      <div className="heatmap-no-data-to-show pb-3">
        <p>SELECT CAMERA FROM THE SIDEBAR</p>
      </div>
    </center>
  );

  return (
    <div>
      {playbackCamera.length === 0 ? (
        renderNoData()
      ) : (
        <>
          <PlaybackToolbar updateLoading={updateLoading} />
          <div className="row align-items-stretch px-3">
            <div className="col-12 mb-1">
              <div className="d-flex justify-content-end">
                <PlaybackModeMethodTabs />
              </div>
            </div>
            <div className="col-10 col-lg-12 col-md-12">
              <PlaybackVideoPlayer
                src={playbackFeed[0]}
                onHide={onHide}
                usedIn={"playback"}
              />
            </div>
          </div>
          <div className="mt-2">
            <TimelineSlider />
          </div>
        </>
      )}

      <div>
        <Dialog
          open={showDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              backgroundColor: "var(--side-bar-bg-color)",
              color: "var(--card-text-color)",
            }}
          >
            {"Switch Camera Playback"}
          </DialogTitle>
          <DialogContent
            sx={{
              backgroundColor: "var(--side-bar-bg-color)",
            }}
          >
            <DialogContentText
              id="alert-dialog-description"
              sx={{ color: "var(--card-text-color)" }}
            >
              You can only watch the playback of one camera at a time. Would you
              like to switch to a different camera for playback?
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              backgroundColor: "var(--side-bar-bg-color)",
              color: "var(--card-text-color)",
            }}
          >
            <Button onClick={handleNoClick}>No</Button>
            <Button onClick={handleYesClick} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default PlayBackMainPage;
