import React, { useEffect, useState } from "react";
import HeatmapChart from "../../../components/charts/HeatMapChart";
import {
  getHeatmapDataOfNVRUptime,
  removeHeatMapData,
} from "../../../redux/Action/RhmsAction";
import { useDispatch, useSelector } from "react-redux";
const DeviceUptime = (propTypes) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const state = useSelector((state) => state.rhmsData);
  useEffect(() => {
    setLoading(true);
    dispatch(getHeatmapDataOfNVRUptime(propTypes.nvr_id))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
    return () => {
      dispatch(removeHeatMapData());
    };
  }, []);
  return (
    <div>
      {state.heatmapData.length > 0 || loading ? (
        <HeatmapChart data={state.heatmapData} title={"NVR/DVR Uptime"} />
      ) : (
        <div
          style={{
            height: "20vh",
          }}
        >
          <center>
            <p className="error-message">
              Unfortunately, Data cannot be fetched right now. Please try after
              some time.
            </p>
          </center>
        </div>
      )}
    </div>
  );
};

export default DeviceUptime;
