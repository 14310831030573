import moment from "moment";
import * as DateFns from "date-fns";
const initialState = {
  accountList: [],
  facilityList: [],
  cameraList: [],
  facilityByAccountList: [],
};

export default function OperationModeReducer(state = initialState, action) {
  switch (action.type) {
    case "STORE_LIST_DATA_MODAL":
      return {
        ...state,
        facilityList: action.payload,
      };
    case "CLIENT_LIST_DATA_MODAL":
      return {
        ...state,
        clientList: action.payload,
      };
    case "DIVICES_LIST_DATA_MODAL":
      return { ...state, deviceList: action.payload };
    case "CAMERAS_LIST_DATA_MODAL":
      return { ...state, cameraList: action.payload };
    case "ACCOUNT_LIST_DATA_MODAL":
      return { ...state, accountList: action.payload };
    case "FACILITY_BY_ACCOUNT_LIST_DATA_MODAL":
      return { ...state, facilityByAccountList: action.payload };
    default:
      return state;
  }
}
