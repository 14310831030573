import React from "react";
import Box from "@mui/material/Box";
import colorConfigs from "../../configs/colorConfigs";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "@mui/material";
import "../../assets/css/main.css";
import { changeTopbarTitle } from "../../redux/Action/ChangeTopbarTitle";
import { getDeviceControlData } from "../../redux/Action/DeviceControlAction";

const DeviceControl = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDeviceControlData());
    setTitile();
  });
  const setTitile = () => {
    dispatch(changeTopbarTitle("Device Control"));
  };

  return (
    <div
      style={{ backgroundColor: colorConfigs.sidebar.bg, borderRadius: "15px" }}
    >
      <div className="table-responsive">
        <Box m={5} pb={5} pt={10} className="table-responsive">
          <table className="device-status-table">
            <thead>
              <tr className="tableFirstRow">
                <th>Store</th>
                <th>Camera</th>
                <th>Last Online</th>
                <th>Status</th>
                <th>Switch</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>CP</td>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>
                  <span className="offline-span-device-status">OFF</span>
                </td>
                <td>
                  <Box>
                    OFF
                    <Switch style={{ color: "#18E0A7" }} defaultChecked />
                    ON
                  </Box>
                </td>
              </tr>
              <tr>
                <td>Malviv nagar</td>
                <td>Mark</td>
                <td>Otto</td>
                <td>
                  <span className="online-span-device-status">ON</span>
                </td>
                <td>
                  <Box>
                    OFF
                    <Switch style={{ color: "#18E0A7" }} defaultChecked />
                    ON
                  </Box>
                </td>
              </tr>
            </tbody>
          </table>
        </Box>
      </div>
    </div>
  );
};

export default DeviceControl;
