import React, { useState } from "react"; // Import React and useState
import DeviceUptime from "./DeviceUptime";
import NvrUptime from "./NvrUptime";
const GraphTab = (propTypes) => {
  const [graphEnable, setGraphEnable] = useState(1); // Renamed graphEneable to graphEnable
  function changeGraphEnable(value) {
    setGraphEnable(value);
  }
  return (
    <div>
      <div className="d-flex mt-4 mt-lg-2 justify-content-center">
        <div
          className={graphEnable === 1 ? "heatmap-tab-active" : "heatmap-tab"} // Used === for comparison
          onClick={() => changeGraphEnable(1)}
        >
          Network Uptime
        </div>
        <div
          className={graphEnable === 2 ? "heatmap-tab-active" : "heatmap-tab"} // Used === for comparison
          onClick={() => changeGraphEnable(2)}
        >
          NVR/DVR Uptime
        </div>
      </div>
      <div className="mt-4">
        {graphEnable === 1 ? (
          <DeviceUptime nvr_id={propTypes.nvr_id} />
        ) : (
          <NvrUptime nvr_id={propTypes.nvr_id} />
        )}
      </div>
    </div>
  );
};

export default GraphTab;
