import { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  FormControl,
  FormControlLabel,
  Switch,
  FormGroup,
  Button,
} from "@mui/material";
import { updateSelectedNotification } from "../../redux/Action/ProfileAction";

const switchTrackStyle = {
  "& .MuiSwitch-track": {
    border: "1px solid white",
  },
};

const EmailNotificationCustomization = () => {
  const alertTypeList = useSelector(
    (state) => state.AlertTableStates.AlertTypes
  );
  const profile_id = localStorage.getItem("profile_id");
  const alert_types = JSON.parse(localStorage.getItem("profile")).alert_type;
  const [selectedNotifications, setSelectedNotifications] = useState(
    alert_types ?? []
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleChange = useCallback(
    (event) => {
      const newSelection = event.target.name;
      setSelectedNotifications((prev) =>
        newSelection === "all"
          ? prev.length < alertTypeList.length
            ? alertTypeList.map((type) => type.notification_type)
            : []
          : prev.includes(newSelection)
          ? prev.filter((item) => item !== newSelection)
          : [...prev, newSelection]
      );
    },
    [alertTypeList, selectedNotifications]
  );

  const handleUpdateChoices = useCallback(async () => {
    setLoading(true);
    try {
      await dispatch(
        updateSelectedNotification(
          { alert_type: selectedNotifications },
          profile_id
        )
      );
    } catch (error) {
      console.error("Failed to update choices:", error);
    } finally {
      setLoading(false);
    }
  }, [dispatch, selectedNotifications]);

  const isAllSelected = selectedNotifications.length === alertTypeList.length;

  return (
    <div className="email-notification-div-parent profile-dropdown-div">
      <div className="email-notification-div-container">
        <div className="email-notification-div">
          <div className="recieve-email-text">Email/Whatsapp Notification</div>
          <FormControlLabel
            control={
              <Switch
                checked={isAllSelected}
                onChange={handleChange}
                name="all"
                sx={switchTrackStyle}
              />
            }
          />
        </div>
        <div className="email-notification-save-button">
          <Button
            variant="contained"
            size="small"
            onClick={handleUpdateChoices}
          >
            {loading ? "Updating ..." : "Save"}
          </Button>
        </div>
      </div>
      <FormControl component="fieldset">
        <FormGroup sx={{ color: "var(--card-text-color)", padding: "5px" }}>
          {alertTypeList.map(({ id, name, notification_type }) => (
            <FormControlLabel
              key={id}
              label={name}
              control={
                <Switch
                  checked={selectedNotifications.includes(notification_type)}
                  onChange={handleChange}
                  name={notification_type}
                  sx={switchTrackStyle}
                />
              }
            />
          ))}
        </FormGroup>
      </FormControl>
    </div>
  );
};

export default EmailNotificationCustomization;
