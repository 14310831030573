import React from "react";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import CameraAddEditPage from "../pages/SystemParameters/CameraManagement/CameraAddEditPage";
const pageRoutes = [
  {
    path: "/camera-config/:cam_id",
    element: <CameraAddEditPage />,
    state: "dashboard",
    sidebarProps: {
      displayText: "Dashboard",
      icon: <HomeRoundedIcon />,
    },
  },
];

export default pageRoutes;
