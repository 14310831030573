import { BACKEND_URL } from "../../routes/config";
class AnprEndpoints {
  constructor() {
    this.getDataForAnpr = () => {
      return `${BACKEND_URL}/anpr/get/data`;
    };
    this.updateANPRinfo = (id) => {
      return `${BACKEND_URL}/anpr/update/data/${id}/`;
    };
  }
}

export default new AnprEndpoints();
