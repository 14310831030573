import React from "react";
import PropTypes from "prop-types";
import "../../../assets/css/live_view.css";
import LiveViewVideoPlayer from "./LiveViewVideoPlayer";
import { useSelector } from "react-redux";
import RingLoader from "../../../components/common/Loader/RingLoader";
const SelectedGridView = ({ gridSize }) => {
  const playlist = useSelector((state) => state.vms.playlist);
  const loadingCameras = useSelector((state) => state.vmsHelper.LoadingCameras);
  const renderGrids = () => {
    const dimension = Math.sqrt(gridSize);
    if (!Number.isInteger(dimension)) {
      throw new Error("gridSize must be a perfect square");
    }

    const gridStyle = {
      display: "grid",
      gridTemplateColumns: `repeat(${dimension}, 1fr)`,
      gridTemplateRows: `repeat(${dimension}, 1fr)`,
      gap: "0px",
      height: "100%",
      width: "100%",
    };

    const grids = Array.from({ length: gridSize }, (_, i) => (
      <div
        className="grid"
        key={i}
        style={{
          width: "100%",
          display: "flex",
          borderColor: "grey",
        }}
      >
        {i < playlist.length ? (
          <LiveViewVideoPlayer
            src={playlist[i]}
            style={{ flex: 1 }}
            key={playlist[i].cam_id}
          />
        ) : loadingCameras[i] == "loading" ? (
          <>
            <RingLoader
              style={{
                height: "50px",
                width: "50px",
                color: "var(--card-text-color)",
              }}
            />
          </>
        ) : null}
      </div>
    ));

    return (
      <div style={gridStyle} className="grid-container">
        {grids}
      </div>
    );
  };

  return <div className="grid-view">{renderGrids()}</div>;
};

SelectedGridView.propTypes = {
  gridSize: PropTypes.number.isRequired,
};

export default SelectedGridView;
