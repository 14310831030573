import React, { useEffect } from "react";
import ANPRRowComponent from "./AnprRowComponent";
import AnprTopBar from "./AnprTopBar";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "../../components/common/Pagination";
import AnprActions from "../../redux/Action/AnprActions";
import BarsRotateLoader from "../../components/common/Loader/BarsRotateLoader";
import { changeTopbarTitle } from "../../redux/Action/ChangeTopbarTitle";
// import "./AnprMainPage.css"; // Import the CSS file

const AnprMainPage = () => {
  const anprData = useSelector((state) => state.anpr.anprData);
  const currentPage = useSelector((state) => state.anpr.currentPage);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.anpr.dataLoading);
  const pageSize = 10;
  const handlePagination = (page) => {
    dispatch(AnprActions.updateCurrentPage(page));
  };
  useEffect(() => {
    dispatch(changeTopbarTitle("ANPR"));
  }, []);
  return (
    <div>
      <AnprTopBar />

      {loading ? (
        <div
          className="loader-container"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            // zIndex: "2",
            fontSize: "50px",
          }}
        >
          <BarsRotateLoader />
        </div>
      ) : (
        <>
          {anprData.results.map((item, index) => (
            <ANPRRowComponent anprData={item} key={index} />
          ))}
          <div className="pagination-div">
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={anprData.count}
              pageSize={pageSize}
              onPageChange={(page) => handlePagination(page)}
              usedIn="AnprDashboard"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default AnprMainPage;
