import React from "react";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { changeTopbarTitle } from "../../../redux/Action/ChangeTopbarTitle";
import KnowFacesTable from "./KnowFacesTable";
import Account from "../../../redux/Action/CommonAction";
const KnownFacesMainComponent = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeTopbarTitle("Face Recognition"));
    const id = JSON.parse(localStorage.getItem("profile")).id;
    dispatch(Account.getStoresByAccount(id));
  }, []);

  return (
    <div>
      <div className="container-fluid">
        <div
          className="card card-custom p-3"
          style={{ backgroundColor: "var(--side-bar-bg-color)" }}
        >
          {/* <KnownFacesTopbar /> */}
          <KnowFacesTable />
        </div>
      </div>
    </div>
  );
};

export default KnownFacesMainComponent;
