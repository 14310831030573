import "../../assets/css/Login.css";
import play_store_image from "../../assets/images/googleplay.png";
import ios_image from "../../assets/images/appstore.png";
import React, { useRef, useState, useEffect } from "react";
import Layout from "../common/Layout";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import assets from "../../assets";
import { useDispatch } from "react-redux";
import { fetchAccessToken, getNewUserDetails } from "../../redux/Action/Login";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { notifiToasterSuccess } from "./UseToast";
import { getProfile } from "../../redux/Action/ProfileAction";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import FailureModal from "./LoginModal";
import { Link } from "react-router-dom";
// import SignInOption from "./SignInOption";

const Login = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState("");
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const [isLoadingg, setisLoadingg] = useState(false);
  const submitButtonRef = useRef(null);
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const dispatch = useDispatch();
  const [passwordShown, setPasswordShown] = useState(false);
  const [eyeIcon, setEyeIcon] = useState(false);
  const [formatError, setFormatError] = useState([]);
  const [shake, setShake] = useState(false);

  useEffect(() => {
    dispatch({ type: "UPDATE_THEME", payload: false });
    // If there is an error (formatError array has items), start the shake animation
    if (formatError.length > 0) {
      setShake(true);

      // Set a timeout to remove the shake class after the animation duration (e.g., 820ms)
      const timer = setTimeout(() => {
        setShake(false);
      }, 820); // Should match the animation duration

      // Clear the timeout if the component unmounts or if formatError changes again before the timer runs out
      return () => clearTimeout(timer);
    }
  }, [formatError]);
  const togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
    setEyeIcon(eyeIcon ? false : true);
  };

  const handleSubmit = (e) => {
    setisLoadingg(true);
    e.preventDefault();
    if (loginTest(username, password)) {
      fetchAccessToken({ username: username, password: password })
        .then((firstTrueKey) => {
          // Layout();

          setisLoadingg(false);
          notifiToasterSuccess("Login Successful");
          dispatch(getProfile());
          navigate("/" + firstTrueKey);
        })
        .catch((error) => {
          setisLoadingg(false);
          setError(error);
          setIsModalOpen(true);
          console.error(error);
        });
    }
  };

  const loginTest = (email, password) => {
    if (email && password) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(email.toLowerCase())) {
        setFormatError([
          "Email Error",
          "Please enter a valid email address to proceed.",
        ]);
        setisLoadingg(false);
        return false;
      }
    } else if (email && !password) {
      setFormatError([
        "Password Error",
        "Please provide your password for login.",
      ]);
      setisLoadingg(false);
      return false;
    } else if (password && !email) {
      setFormatError(["Email Error", "Please provide your email for login."]);
      setisLoadingg(false);
      return false;
    } else if (!email && !password) {
      setFormatError([
        "Credentials Error",
        "Please provide your email and password for login.",
      ]);
      setisLoadingg(false);
      return false;
    }
    setFormatError([]);
    return true;
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-4 colxl-4 form-section">
          <form className="login-form" onSubmit={handleSubmit} method="POST">
            <img
              alt=""
              src={assets.images.logo}
              style={{ maxWidth: "100%", marginBottom: "40px" }}
            />
            {formatError.length == 2 && (
              <Alert severity="error" className={shake ? "shakeAnimation" : ""}>
                <AlertTitle>{formatError[0]}</AlertTitle>
                {formatError[1]}
              </Alert>
            )}
            <div className="form-group">
              <label htmlFor="username" className="form-label-login-page">
                Email id/Username *
              </label>
              <input
                type="text"
                className="inputLogin"
                onChange={(e) => setusername(e.target.value)}
                placeholder="mail@sample.com"
              />
            </div>
            <div className="form-group">
              <label htmlFor="password" className="form-label-login-page">
                Password *
              </label>
              <div className="pwd">
                <input
                  className="inputLogin"
                  onChange={(e) => setpassword(e.target.value)}
                  placeholder="Min. 8 characters"
                  type={passwordShown ? "text" : "password"}
                  autoComplete="off"
                />
                <span className="p-viewer" onClick={togglePasswordVisibility}>
                  {eyeIcon ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
                </span>
              </div>
            </div>
            <br />
            <div style={{ marginTop: "20px" }}>
              <button
                type="submit"
                className="signButton"
                ref={submitButtonRef}
              >
                {!isLoadingg ? (
                  <span id="headSign">Sign In</span>
                ) : (
                  <CircularProgress style={{ color: "#ffff" }} />
                )}
              </button>
            </div>
            {/* <SignInOption signUp={false} /> */}
            <div className="form-group">
              <p className="not-registred-yet">
                Not Registered yet?{" "}
                <Link style={{ color: "#4318FF" }} to="/onboarding">
                  Register with us
                </Link>
              </p>
            </div>

            <div className="form-group">
              <div
                className="d-flex flex-column justify-content-center align-items-center h-100"
                style={{ marginTop: "50px" }}
              >
                <div id="allrightreserved">
                  &copy; 2023 Agrex.ai All Rights Reserved.
                </div>
              </div>
              <div
                style={{ marginTop: "80px" }}
                className="row d-flex justify-content-center"
              >
                <div className="col-5 mt-3 login-store-logo">
                  <a href="https://play.google.com/store/apps/details?id=com.agrex.agrexai&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                    <img
                      src={play_store_image}
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </a>
                </div>
                <div className="col-5 h-sm-50 mt-3 login-store-logo">
                  <a href="https://apps.apple.com/in/app/agrex-ai-analytics-app/id1586145482">
                    <img src={ios_image} alt="" style={{ width: "100%" }} />
                  </a>
                </div>
              </div>
            </div>
            <FailureModal
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              error={error}
            />
          </form>
        </div>
        <div className="col-12 col-lg-8 col-xl-8 d-sm-none d-md-none d-lg-block d-xl-block d-none image-section"></div>
      </div>
    </div>
  );
};

export default Login;
