import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import "./Onboarding.css";
import { useLocation, useNavigate } from "react-router-dom";
import CustomStepper from "./Utils/CustomStepper";
import StepContent from "./Utils/StepContent";
import { useDispatch, useSelector } from "react-redux";
import assets from "../../assets";
import LoginIcon from "@mui/icons-material/Login";
import { ToastContainer } from "react-toastify";
import OnboardingActions from "../../redux/Action/OnboardingActions";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const steps = [
  "Sign Up",
  "Create Organisation",
  "Create Facility",
  "Add NVR/DVR",
  "Add Cameras",
  "Select Services",
];

export default function OnboardingMain() {
  const activeStep = useSelector((state) => state.onbaording.currentStep);
  const dispatch = useDispatch();
  const [isEditable, setIsEditable] = React.useState(false);
  const query = useQuery();
  const userId = query.get("user_id");
  const userEmail = query.get("user_name");
  const name = query.get("name");
  const token = query.get("token");
  const navigate = useNavigate();

  React.useEffect(() => {
    dispatch({ type: "UPDATE_THEME", payload: false });
    if (userId && userEmail) {
      localStorage.setItem(
        "onboardUser",
        JSON.stringify({ userId, userEmail, name, token })
      );
      localStorage.setItem("completedStep", 0);
      // dispatch({ type: "updateCurrentStep", payload: 1 });
      let currentStep = JSON.parse(localStorage.getItem("currentStep"));
      if (currentStep) {
        dispatch({ type: "updateCurrentStep", payload: currentStep });
      } else {
        dispatch({ type: "updateCurrentStep", payload: 1 });
      }
      setIsEditable(true);
    }
  }, [userId, userEmail, name, token]);
  const handleNext = () => {
    if (activeStep == steps.length) {
      setTimeout(() => {
        navigate("/");
        localStorage.clear();
        dispatch({ type: "updateCurrentStep", payload: 0 });
      }, 1000);
    } else {
      dispatch({ type: "updateCurrentStep", payload: activeStep + 1 });
    }
  };

  const handleBack = () => {
    dispatch({ type: "updateCurrentStep", payload: activeStep - 1 });
  };

  return (
    <>
      <ToastContainer limit={1} autoClose={1500} />
      <div className="Client-onboard-Nav">
        <div onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
          <img src={assets.images.logo} alt="logo" id="agrex_logo" />
        </div>
        <div id="home_logo">
          {/* <div>
            <LoginIcon
              style={{
                color: "white",
                backgroundColor: "blue",
                borderRadius: "50px",
                width: "43px",
                height: "35px",
              }}
            />
          </div> */}
          {/* <div>
            <span style={{ color: "blue" }}>Login Page</span>
          </div> */}
        </div>
      </div>
      <h2 className="Onboard-text" style={{}} id="onboard_text">
        Client Onboard
      </h2>
      <Stack
        sx={{
          width: "100%",
          marginTop: "30px",
        }}
        spacing={4}
      >
        <CustomStepper activeStep={activeStep} steps={steps} />
        <center>
          <div className="onboarding-form-card">
            <StepContent activeStep={activeStep} isEditable={isEditable} />
          </div>
        </center>
        <Stack direction="row" className="action-button-stepper">
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            className="action-back-button"
          >
            Back
          </Button>
          {(activeStep === 6 ||
            activeStep <=
              JSON.parse(localStorage.getItem("completedStep") || "0")) && (
            <Button variant="contained" onClick={handleNext}>
              {activeStep === 6 ? "Finish" : "Next"}
            </Button>
          )}
        </Stack>
      </Stack>
    </>
  );
}
